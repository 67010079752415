import React, {Component} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {Card, Grid, Typography, withStyles} from "@material-ui/core";

const styles = theme => {
    return {
        root: {},
        content: {
            padding: 0
        },
        item: {
            padding: theme.spacing(3),
            textAlign: "center",
            [theme.breakpoints.up("md")]: {
                "&:not(:last-of-type)": {
                    borderRight: `1px solid ${theme.palette.divider}`
                }
            },
            [theme.breakpoints.down("sm")]: {
                "&:not(:last-of-type)": {
                    borderBottom: `1px solid ${theme.palette.divider}`
                }
            }
        },
        titleWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        label: {
            marginLeft: theme.spacing(1)
        },
        overline: {
            marginTop: theme.spacing(1)
        }
    };
};

class OrderStatistics extends Component {
    render() {
        const {className, classes} = this.props;
        const nf = new Intl.NumberFormat("en-IN");
        return (
            <Card className={clsx(classes.root, className)}>
                <Grid alignItems="center" container justify="space-between">
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.ordersStatsData?.order_count)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Orders Placed
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.otpModeData?.total_otp_quantity)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Quantity Delivered
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(
                                this.props.otpModeData?.total_otp_quantity /
                                this.props.ordersStatsData?.delivered_count
                            )}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Average Quantity per Order
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(
                                this.props.ordersStatsData?.confirmation_pending_count
                            )}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Confirmation Pending Orders
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.ordersStatsData?.driver_assigned_count)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Assigned Orders
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.ordersStatsData?.vehicle_dispached_count)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Dispatched Orders
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.ordersStatsData?.dispensing_count)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Dispensing Orders
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.ordersStatsData?.delivered_count)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Delivered Orders
                        </Typography>
                    </Grid>
                    <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">
                            {nf.format(this.props.ordersStatsData?.canceled_count)}
                        </Typography>
                        <Typography className={classes.overline} variant="overline">
                            Cancelled Orders
                        </Typography>
                    </Grid>
                    {/*<Grid*/}
                    {/*  className={classes.item}*/}
                    {/*  item*/}
                    {/*  md={3}*/}
                    {/*  sm={6}*/}
                    {/*  xs={12}*/}
                    {/*>*/}
                    {/*  <div className={classes.titleWrapper}>*/}
                    {/*    <Typography*/}
                    {/*      component="span"*/}
                    {/*      variant="h2"*/}
                    {/*    >*/}
                    {/*      20M*/}
                    {/*    </Typography>*/}
                    {/*    <Label*/}
                    {/*      className={classes.label}*/}
                    {/*      color={colors.green[600]}*/}
                    {/*    >*/}
                    {/*      Live*/}
                    {/*    </Label>*/}
                    {/*  </div>*/}
                    {/*  <Typography*/}
                    {/*    className={classes.overline}*/}
                    {/*    variant="overline"*/}
                    {/*  >*/}
                    {/*    Watching now*/}
                    {/*  </Typography>*/}
                    {/*</Grid>*/}
                </Grid>
            </Card>
        );
    }
}

OrderStatistics.propTypes = {
    className: PropTypes.string
};

export default withStyles(styles)(OrderStatistics);
