import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {withStyles} from "@material-ui/core";

// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Custom components
import {UsersTable, UsersToolbar} from "./components";

// Component styles
import styles from "./style";
import {activateRpp, getAllRefuellers, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import axios from "axios";
import {Breakpoint} from "react-socks";
import Cards from "views/AllRefuellers/components/Cards/Cards";
import secureLocalStorage from "react-secure-storage";

class AllRefuellers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0,
            value: null,
            offset: "",
            mobileView: window.matchMedia("(min-width: 768px)").matches
        };
        this.abortController = "";
    }

    // onSearchTerm = (searchTerm) => {
    //   if(searchTerm !== ""){
    //     this.setState({
    //       searchString: searchTerm,
    //     });
    //     this.getRefuellerData(searchTerm);
    //   } else {
    //     this.setState({
    //       searchString: "",
    //     });
    //     this.getRefuellerData();
    //   }
    // };

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getRefuellerData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getRefuellerData("");
        }
    };

    onSelectedPartner = value => {
        if (value === "") {
            this.getRefuellerData("", this.state.rowsPerPage, 0);
        } else {
            this.setState({value});
            this.getRefuellerData("", this.state.rowsPerPage, 0, value);
        }
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page, offset});

        this.getRefuellerData(
            this.state.searchString,
            limit,
            offset,
            this.state.value
        );
    };

    getRefuellerData = (
        searchTerm = this.state.searchString,
        limit = this.state.rowsPerPage,
        offset = this.state.offset,
        partner,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllRefuellers(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            partner,
            abortController
        );
    };

    activationStatus = (status, id) => {
        this.props.handleChangeActivationStatus(
            secureLocalStorage.getItem("accessToken"),
            id,
            status
        );
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getRefuellerData();
        const handler = e => this.setState({mobileView: e.matches});
        window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="Refuellers">
                    <div className={classes.root}>
                        {/*{*/}
                        {/*  this.props.allRefuellerData.count === 0*/}
                        {/*      ?*/}
                        {/*      <div className={classes.progressWrapper}>*/}
                        {/*        <CircularProgress className={classes.progress}/>*/}
                        {/*      </div>*/}
                        {/*      :*/}
                        <div>
                            <UsersToolbar
                                allRefuellerData={this.props.allRefuellerData}
                                searchedTerm={this.onSearchTerm}
                                selectedPartner={this.onSelectedPartner}
                                mobileView={this.state.mobileView}
                            />
                            <Breakpoint medium up>
                                <UsersTable
                                    dataCount={this.props.dataCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    allRefuellerData={this.props.allRefuellerData}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                    activationStatus={this.activationStatus}
                                    reloadData={this.getRefuellerData}
                                    mobileView={this.state.mobileView}
                                />
                            </Breakpoint>
                            <Breakpoint small down>
                                <Cards
                                    dataCount={this.props.dataCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    allRefuellerData={this.props.allRefuellerData}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                    activationStatus={this.activationStatus}
                                    reloadData={this.getRefuellerData}
                                    mobileView={this.state.mobileView}
                                />
                            </Breakpoint>
                        </div>
                        {/*}*/}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllRefuellers.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allRefuellerData: state.allRefuellerData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllRefuellers: (
            accessToken,
            searchString,
            limit,
            offset,
            partner,
            abortController
        ) => {
            dispatch(
                getAllRefuellers(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    partner,
                    abortController
                )
            );
        },
        handleChangeActivationStatus: (accessToken, status, id) => {
            dispatch(activateRpp(accessToken, status, id));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(AllRefuellers));
