import Toolbar from "@material-ui/core/Toolbar";
import {Button, Typography} from "@mui/material";
import {Tooltip} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import {rest} from "underscore";
import {makeStyles} from "@material-ui/styles";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "0px"
    }
}));

console.log(secureLocalStorage.getItem("department"));

const EnhancedTableToolbar = props => {
    const {numSelected, setOpen, className} = props;
    const classes = useStyles();
    const sadEmoji = "\u{1F61E}";

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Toolbar style={{backgroundColor: numSelected > 0 && "#d6d7d8"}}>
                {numSelected > 0 && (
                    <Typography
                        sx={{flex: "1 1 100%"}}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                )}

                {(secureLocalStorage.getItem("department") === 13 ||
                    secureLocalStorage.getItem("department") === 10 ||
                    secureLocalStorage.getItem("department") === 5 ||
                    secureLocalStorage.getItem("department") === 1 ||
                    secureLocalStorage.getItem("crm_rank") === 3) &&
                secureLocalStorage.getItem("access_type") === true ? (
                    numSelected > 0 && (
                        <Tooltip title="Delete">
                            <Button variant={"contained"} onClick={() => setOpen(true)}>
                                Action
                            </Button>
                        </Tooltip>
                    )
                ) : (
                    <Typography
                        sx={{flex: "1 1 100%"}}
                        color="red"
                        align={"left"}
                        variant="h6"
                        component="div"
                    >
                        Sorry you don't have access..! {sadEmoji}
                    </Typography>
                )}
            </Toolbar>
        </div>
    );
};

export default EnhancedTableToolbar;

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    setOpen: PropTypes.func.isRequired
};
