import React, {Component} from "react";

import styles from "./styles";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

class NoDataInTable extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div
                className={classes.emptyImageWrapper}
                style={{textAlign: "center"}}
            >
                <img
                    alt="Empty list"
                    className={classes.emptyImage}
                    src="/images/empty.png"
                />
                <h2>Uh-Oh! No Data Found</h2>
            </div>
        );
    }
}

NoDataInTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NoDataInTable);
