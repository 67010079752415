export default {
    userName: {
        presence: {allowEmpty: false, message: "is required"},
        email: false,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    }
};
