import React, {useEffect, useState} from "react";

// Externals
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

// Material components
import {Button, TextField, Typography, withStyles} from "@material-ui/core";

// Component styles
import styles from "views/AllDrivers/components/CreateDriver/styles";

// Form validation schema
import {AddTracker} from "../../../../actions/actions";
import compose from "recompose/compose";
import moment from "moment";
import validate from "validate.js";
import secureLocalStorage from "react-secure-storage";

const schema = {
    name: {
        presence: {allowEmpty: false, message: "is required"},
        email: false
    },
    gps_hm_imei: {
        presence: {allowEmpty: false, message: "is required"}
    },
    dip_constants: {
        presence: {allowEmpty: false, type: "array", message: "is required"}
    }
};

const AddTrackerForm = ({classes, handleDrawerClose}) => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [formData, setFormData] = useState({});

    const [validation, setValidation] = useState({
        touched: {},
        errors: {}
    });

    useEffect(() => {
        const errors = validate(formData, schema);

        setValidation(validation => ({
            ...validation,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formData]);

    const hasError = field =>
        !!(validation?.touched[field] && validation?.errors[field]);

    const handleChange = event => {
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value
        });

        setValidation(validation => ({
            ...validation,
            touched: {
                ...validation.touched,
                [id]: true
            }
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = {
            name: formData.name,
            gps_hm_imei: formData.gps_hm_imei,
            dip_constants: formData.dip_constants,
            time_format: moment(formData.date).format("YYYY/MM/DD kk:mm:ss")
        };
        dispatch(
            AddTracker(
                secureLocalStorage.getItem("accessToken"),
                payload,
                enqueueSnackbar,
                handleDrawerClose
            )
        );
    };

    return (
        <div className={classes.root}>
            <form autoComplete="off" noValidate={false} method={"POST"} action={"#"}>
                <div className={classes.field}>
                    <TextField
                        className={classes.textField}
                        error={hasError("name")}
                        label="Name"
                        margin="dense"
                        fullWidth
                        value={formData.name}
                        id={"name"}
                        onChange={handleChange}
                        variant="outlined"
                    />

                    <TextField
                        className={classes.textField}
                        error={hasError("gps_hm_imei")}
                        label="IMEI No"
                        margin="dense"
                        fullWidth
                        value={formData.gps_hm_imei}
                        id={"gps_hm_imei"}
                        onChange={handleChange}
                        variant="outlined"
                    />
                    <Typography style={{fontWeight: "700"}}>Tank Constants</Typography>
                    <TextField
                        error={hasError("dip_constants")}
                        label="Enter Dip Constants"
                        margin="dense"
                        id="dip_constants"
                        fullWidth
                        value={formData?.dip_constants}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        rows={30}
                    />
                </div>

                <div className={classes.field}>
                    <Button
                        className={classes.addNewPartnerButton}
                        color="primary"
                        name="create_operator_submit"
                        id="create_operator_submit"
                        disabled={
                            validation.errors.name ||
                            validation.errors.gps_hm_imei ||
                            validation.errors.dip_constants
                        }
                        onClick={e => handleSubmit(e)}
                        size="large"
                        variant="contained"
                    >
                        Add Tracker
                    </Button>
                </div>
            </form>
        </div>
    );
};

AddTrackerForm.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingPartner: state.isLoadingPartner
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(AddTrackerForm));
