import React, {Component} from "react";
import "./TimeLine.css";
import {
    DeleteTwoTone as DeleteIcon,
    MoreHorizOutlined as MoreIcon,
    PlayArrowOutlined as ArrowRightIcon
} from "@material-ui/icons";
import qs from "qs";
import {withSnackbar} from "notistack";
import arrayMove from "array-move";
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
// import {capitalize} from "@material-ui/core/utils";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import styles from "./styles";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import compose from "recompose/compose";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import reposWebApi from "../../../../actions/configuration";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";

class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersArray: [],
            value: null,
            drawerIsOpen: false,
            snackIsOpen: false,
            snackIsOpenForAssignment: false,
            snackIsOpenForUnassignment: false,
            right: false,
            orderData: {},
            ordersData: [],
            isLoading: false,
            open: false
        };
        this.abortController = "";
        this.searchTerm = "";
    }

    moveNext = (e, order) => {
        e.preventDefault();

        const indexOf = this?.state?.ordersArray?.indexOf(order);

        const ordersArray = arrayMove(
            this?.state?.ordersArray,
            indexOf,
            indexOf + 1
        );

        this.setState({
            ordersArray: ordersArray,
            snackIsOpen: true
        });

        if (this?.props?.orderSequenceChanged !== null) {
            this.setState({
                snackIsOpen: true
            });
        }

        this?.props?.moveOrder(order.id, 1);
    };

    movePrev = (e, order) => {
        e.preventDefault();

        const indexOf = this?.state?.ordersArray?.indexOf(order);

        const ordersArray = arrayMove(
            this?.state?.ordersArray,
            indexOf,
            indexOf - 1
        );

        this.setState({
            ordersArray: ordersArray,
            snackIsOpen: true
        });

        if (this?.props?.orderSequenceChanged !== null) {
            this.setState({
                snackIsOpen: true
            });
        }

        this?.props?.moveOrder(order.id, -1);
    };

    handleChange = (e, value) => {
        this.setState({
            value: value?.id
        });
    };

    redirectUponSuccessfulCreation() {
        window?.location?.reload();
    }

    addOrderToRefueler = (e, refueler) => {
        // e.preventDefault();
        let temp = this?.state?.ordersArray;
        temp.push(this?.state?.value);
        this.setState({ordersArray: temp});

        // this.setState({
        //     snackIsOpenForAssignment: true,
        // });

        // setTimeout(this.redirectUponSuccessfulCreation, 3000);

        this.props.getAssignmentData(this?.state?.value, refueler.id);
    };

    defaultOrdersOnRefueler = e => {
        this.setState({
            ordersArray: this?.props?.assignedRefuelerOrders,
            ordersData: this?.props?.ordersData
        });
    };

    handleOpen = e => {
        e.preventDefault();
        this.setState({open: true});
    };

    handelClose = () => {
        this.setState({open: false});
    };

    handleDrawerOpen = (e, orderData) => {
        e.preventDefault();
        this.setState({
            drawerIsOpen: true,
            orderData: orderData
        });
    };

    handleDrawerClose = () => {
        this.setState({drawerIsOpen: false});
    };

    handleSnackOpen = e => {
        e.preventDefault();

        this.setState({
            snackIsOpen: true
        });
    };

    handleSnackClose = () => {
        this.setState({snackIsOpen: false});
    };

    handleSnackOpenForAssignment = e => {
        e.preventDefault();

        this.setState({
            snackIsOpen: true
        });
    };

    handleSnackCloseForAssignment = () => {
        this.setState({snackIsOpenForAssignment: false});
    };

    handleOrderUnassignment = (e, order) => {
        e.preventDefault();

        this.props.orderUnassignmentData(order.id);

        let temp = this?.state?.ordersArray;
        temp.pop(order);

        this.setState({
            snackIsOpenForUnassignment: true,
            ordersArray: temp
        });

        setTimeout(this.redirectUponSuccessfulCreation, 2000);
    };

    handleSnackOpenForUnssignment = e => {
        e.preventDefault();

        this.setState({
            snackIsOpenForUnassignment: true
        });
    };

    handleSnackCloseForUnassignment = () => {
        this.setState({snackIsOpenForUnassignment: false});
    };

    onSearchTerm = searchTerm => {
        this.searchTerm = searchTerm;
        if (this?.searchTerm?.length > 4) {
            if (this?.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.getOrderData(searchTerm);
        } else {
            this.getOrderData("");
        }
    };

    getOrderData = searchTerm => {
        let queryParams = {};
        queryParams.search = searchTerm;
        reposWebApi
            .get("/order/get_orders_for_assignment", {
                cancelToken: this.abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: queryParams
            })
            .then(orders => {
                if (orders.status === 200) {
                    this.setState({
                        ordersData: decryption(orders).data?.results,
                        isLoading: false
                    });
                }
            });
    };

    getData = (e, refueller) => {
        const requestBody = {
            refueler_id: refueller?.id,
            order_id: this?.state?.value
        };
        reposWebApi
            .post("/order/change_order_assignment", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(orders => {
                if (orders?.status === 200) {
                    this.props?.getRefuellerData();
                    this.props.enqueueSnackbar(orders?.data?.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        }
                    });
                }
            });
    };

    componentDidMount() {
        this.defaultOrdersOnRefueler();
    }

    render() {
        const {classes} = this.props;

        return (
            <div>
                {this?.props?.isLoading ? (
                    <div className={classes.progressWrapper}>
                        <CircularProgress className={classes.progress}/>
                    </div>
                ) : (
                    <div>
                        <section className="timeline">
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={"2"} style={{borderBottom: "none"}}>
                                            {/*<FormControl>*/}
                                            {/*<InputLabel id="select_order_label" style={{width: '100px'}}>Select Order</InputLabel>*/}
                                            {/*<Select*/}
                                            {/*    labelId="select_order_label"*/}
                                            {/*    id="select_order"*/}
                                            {/*    value={this.state.value}*/}
                                            {/*    onChange={this.handleChange}*/}
                                            {/*    style={{width: '100px'}}*/}
                                            {/*>*/}
                                            {/*    <MenuItem value={null}>*/}
                                            {/*        <em>None</em>*/}
                                            {/*    </MenuItem>*/}
                                            {/*    {*/}
                                            {/*        this.props.ordersData.map(singleOrder =>*/}
                                            {/*            (  <MenuItem value={singleOrder}>*/}
                                            {/*                    {singleOrder.order_id} {singleOrder.customer_name}*/}
                                            {/*                </MenuItem>*/}
                                            {/*            )*/}
                                            {/*        )*/}
                                            {/*    }*/}
                                            {/*</Select>*/}
                                            {/*<Autocomplete*/}
                                            {/*    id="combo-box-for-order"*/}
                                            {/*    onChange={this.handleChange}*/}
                                            {/*    options={this.props.ordersData}*/}
                                            {/*    style={{margin: '10px 0'}}*/}
                                            {/*    fullWidth*/}
                                            {/*    loading={this.state.isLoading}*/}
                                            {/*    getOptionLabel={option => option.order_id}*/}
                                            {/*    renderInput={params => (*/}
                                            {/*        <TextField*/}
                                            {/*            {...params}*/}
                                            {/*            fullWidth*/}
                                            {/*            label="Select Order"*/}
                                            {/*            variant="outlined"*/}
                                            {/*            onChange={event => this.handleOnSearch(event.target.value)}*/}
                                            {/*        />*/}
                                            {/*    )}*/}
                                            {/*/>*/}
                                            <Autocomplete
                                                id="combo-box-for-tracker"
                                                onChange={this.handleChange}
                                                options={this?.state?.ordersData}
                                                style={{margin: "10px 0", width: 150}}
                                                // fullWidth
                                                loading={this?.state?.isLoading}
                                                getOptionLabel={option => option?.order_id}
                                                disabled={
                                                    secureLocalStorage.getItem("access_type") === false
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Select Order"
                                                        variant="outlined"
                                                        onChange={e => this.onSearchTerm(e?.target?.value)}
                                                        disabled={
                                                            secureLocalStorage.getItem("access_type") ===
                                                            false
                                                        }
                                                    />
                                                )}
                                            />
                                            {this?.state?.value === null ? (
                                                ""
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{width: "100%", marginTop: "5px"}}
                                                    // onClick={e => this.addOrderToRefueler(e, this.props.refueler)}
                                                    onClick={e => {
                                                        this.handleOpen(e);
                                                    }}
                                                >
                                                    Assign this order
                                                </Button>
                                            )}
                                            {/*</FormControl>*/}
                                        </TableCell>
                                        <TableCell colSpan={"2"} style={{borderBottom: "none"}}>
                                            <ol style={{margin: "20px 10px"}}>
                                                {this.state.ordersArray?.map((singleOrder, index) => (
                                                    <li>
                                                        <div>
                                                            <time>{singleOrder?.order_id}</time>
                                                            <p>Customer: {singleOrder?.customer_name}</p>
                                                            <p>Quantity: {singleOrder?.quantity} Litres</p>
                                                            {/*<p>Delivery Date: {capitalize(singleOrder.delivery_date)}</p>*/}
                                                            {index === 0 ? (
                                                                ""
                                                            ) : (
                                                                <button
                                                                    onClick={e => this.movePrev(e, singleOrder)}
                                                                >
                                                                    <ArrowRightIcon
                                                                        style={{transform: "rotate(180deg)"}}
                                                                    />
                                                                </button>
                                                            )}
                                                            {index ===
                                                            this?.state?.ordersArray?.length - 1 ? (
                                                                ""
                                                            ) : (
                                                                <button
                                                                    onClick={e => this.moveNext(e, singleOrder)}
                                                                >
                                                                    <ArrowRightIcon/>
                                                                </button>
                                                            )}
                                                            <button
                                                                onClick={e =>
                                                                    this.handleOrderUnassignment(e, singleOrder)
                                                                }
                                                                style={{float: "right"}}
                                                                disabled={
                                                                    secureLocalStorage?.access_type === false
                                                                }
                                                            >
                                                                <DeleteIcon/>
                                                            </button>
                                                            <button
                                                                onClick={e =>
                                                                    this.handleDrawerOpen(e, singleOrder)
                                                                }
                                                                style={{float: "right"}}
                                                            >
                                                                <MoreIcon/>
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                                <li></li>
                                            </ol>
                                        </TableCell>
                                        {/* <TableCell colSpan={"2"} style={{borderBottom:'none'}}>

                                        </TableCell> */}
                                    </TableRow>
                                </TableBody>
                            </Table>

                            {/* Dialog for Assign order  */}

                            <Dialog
                                open={this?.state?.open}
                                onClose={this.handelClose.bind(this)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                    id="simple-dialog-title"
                                >
                                    <Box marginBottom="3%">
                                        <Typography style={{color: "#fff"}} variant="h4">
                                            {" Are you sure you want to swap partner ?"}
                                        </Typography>
                                    </Box>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Box
                                            display={"flex"}
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                                    color: "#fff",
                                                    margin: "2%"
                                                }}
                                                onClick={e =>
                                                    this.addOrderToRefueler(e, this?.props?.refueler)
                                                }
                                            >
                                                with rate change
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                                    color: "#fff",
                                                    margin: "2%"
                                                }}
                                                onClick={e => this.getData(e, this?.props?.refueler)}
                                            >
                                                without rate change
                                            </Button>
                                        </Box>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                        </section>
                        <SwipeableDrawer
                            open={this?.state?.drawerIsOpen}
                            onClose={this.handleDrawerClose}
                            onOpen={this.handleDrawerClose}
                            anchor="right"
                            classes={{
                                paper: classes.drawerWidth
                            }}
                        >
                            <div className={classes.list} role="presentation">
                                <Typography
                                    variant="h3"
                                    style={{margin: "2% 5%", color: "#808080"}}
                                >
                                    {this?.state?.orderData.order_id}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{margin: "2% 5%", color: "green"}}
                                >
                                    {this?.state?.orderData?.status === "DRA"
                                        ? "DRIVER ASSIGNED FOR YOUR ORDER"
                                        : this?.state?.orderData?.status}
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>
                                                {"Customer Information"}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Asset Name</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.asset_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Customer Name</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.customer_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Delivery Date</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.delivery_date}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Placed At</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.created_at}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>
                                                {"Order & Payments"}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.quantity} Litres
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Rate</TableCell>
                                            <TableCell align="left">
                                                ₹ {this?.state?.orderData?.rate} /l
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Sub Total</TableCell>
                                            <TableCell align="left">
                                                ₹ {this?.state?.orderData?.sub_total_amount}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Tax</TableCell>
                                            <TableCell align="left">
                                                ₹ {this?.state?.orderData?.tax_amount}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Delivery Charges</TableCell>
                                            <TableCell align="left">
                                                ₹ {this?.state?.orderData?.delivery_charges}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Grand Total</TableCell>
                                            <TableCell align="left">
                                                <Typography variant="h6">
                                                    ₹ {this?.state?.orderData?.grand_total_amount}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>
                                                {"Payment Details"}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Payment Mode</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.payment_method === "CASH" ? (
                                                    <img
                                                        alt="Cash Logo"
                                                        src={require("../TimeLine/money.png")}
                                                    />
                                                ) : (
                                                    this?.state?.orderData?.payment_method
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Payment Status</TableCell>
                                            <TableCell align="left">
                                                {this?.state?.orderData?.payment_status}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                            {"Driver Details"}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Driver Name</TableCell>
                                        <TableCell align="left">
                                            {this?.state?.orderData?.driver_name}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </SwipeableDrawer>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={this?.state?.snackIsOpen}
                            autoHideDuration={6000}
                            onClose={this.handleSnackClose}
                            onOpen={event => this.handleSnackOpen(event)}
                            ContentProps={{
                                "aria-describedby": "message-id"
                            }}
                            message={<span id="message-id">Order Moved Successfully</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={this.handleSnackClose}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            ]}
                        ></Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={this?.state?.snackIsOpenForAssignment}
                            autoHideDuration={6000}
                            onClose={this.handleSnackCloseForAssignment}
                            onOpen={event => this.handleSnackOpenForAssignment(event)}
                            ContentProps={{
                                "aria-describedby": "message-id"
                            }}
                            message={<span id="message-id">Order Assigned Successfully</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={this.handleSnackCloseForAssignment}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            ]}
                        ></Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={this?.state?.snackIsOpenForUnassignment}
                            autoHideDuration={6000}
                            onClose={this.handleSnackCloseForUnassignment}
                            onOpen={event => this.handleSnackOpenForUnssignment(event)}
                            ContentProps={{
                                "aria-describedby": "message-id"
                            }}
                            message={
                                <span id="message-id">Order Removed from RPP Successfully</span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={this.handleSnackCloseForUnassignment}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            ]}
                        ></Snackbar>
                    </div>
                )}
            </div>
        );
    }
}

TimeLine.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
        orderSequenceChanged: state.orderSequenceChanged,
        orderAssignedSuccessfully: state.orderAssignedSuccessfully
    };
};

export default connect(mapStateToProps)(
    compose(withStyles(styles))(withSnackbar(TimeLine))
);
