export default theme => ({
    root: {
        marginTop: "16px"
    },
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    deleteButton: {
        color: theme.palette.danger.main,
        marginRight: theme.spacing(1)
    },
    editButton: {
        color: theme.palette.info.main,
        marginRight: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    importIcon: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        // marginRight: theme.spacing(1)
    },
    exportIcon: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    drawerWidth: {
        zIndex: 1200,
        width: "340px"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "1.5%"
    },
    drawerHeading: {
        float: "left",
        marginBottom: "10px"
    },
    filterDrawer: {
        width: "25%"
    },
    nameOne: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer",
        color: "gray"
    }
});
