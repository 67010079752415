import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {LinearProgress, SwipeableDrawer, Typography} from "@material-ui/core";
import AddLeadForm from "../AddLeadForm";
import Nuturing from "../Nuturing/Nurturing";
import DatumQuestions from "../DatumQuestions";
import AddProductDetailForm from "../AddProductDetailForm";
import AddDatumDetails from "../AddDatumDetails";
import NegotiationForm from "../NegotiationForm";
import DatumNegotiationForm from "../DatumNegotiationForm";
import PurchaseOrderForm from "../PurchaseOrderForm";
import BookingAmountForm from "../BookingAmountForm";
import MoreInfo from "../MoreInfo";
import DatumMoreInfo from "../DatumMoreInfo";
import React from "react";

const Drawers = ({drawerOpen, HandleDrawer, title, handleClose, stateData, listLeads, param, classes}) => {
    return (
        <>
            <SwipeableDrawer
                anchor={title === "Edit" ? "left" : "right"}
                classes={{
                    paper:
                        title === "Nurturing"
                            ? classes.NewdrawerWidth
                            : classes.drawerWidth
                }}
                open={drawerOpen}
                // open={true}
                // onClose={handleClose}
                onOpen={HandleDrawer}
            >
                <div>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>

                <div
                    className={classes.drawerHeading}
                    style={{marginLeft: "10px"}}
                >
                    <Typography
                        variant="h2"
                        style={{
                            marginTop: "3%",
                            marginLeft: "16px",
                            color: "#808080"
                        }}
                    >
                        {title}
                    </Typography>
                    {title === "Edit" ? (
                        <AddLeadForm
                            stateData={stateData}
                            handleDrawerClose={handleClose}
                            listLeads={listLeads}
                            idEdit={true}
                            param={param}
                        />
                    ) : title === "Nurturing" ? (
                        <Nuturing
                            stateData={stateData}
                            listLeads={listLeads}
                            handleDrawerClose={handleClose}
                        />
                    ) : title === "Questionnaire" ? (
                        listLeads.product === 2 || listLeads.product === 3 ? (
                            <DatumQuestions
                                stateData={stateData}
                                handleDrawerClose={handleClose}
                                listLeads={listLeads}
                                param={param}
                            />
                        ) : (
                            ""
                        )
                    ) : title === "Add Product Details" ? (
                        listLeads.product === 1 || listLeads.product === 3 ? (
                            <AddProductDetailForm
                                stateData={stateData}
                                handleDrawerClose={handleClose}
                                listLeads={listLeads}
                                param={param}
                            />
                        ) : (
                            <AddDatumDetails
                                stateData={stateData}
                                listLeads={listLeads}
                                handleDrawerClose={handleClose}
                                param={param}
                            />
                        )
                    ) : title === "Negotiation" ? (
                        listLeads?.rpp_variant_display === "PROJECT" ? (
                            listLeads.product === 1 || listLeads.product === 3 ? (
                                <NegotiationForm
                                    stateData={stateData}
                                    listLeads={listLeads}
                                    handleDrawerClose={handleClose}
                                    param={param}
                                />
                            ) : (
                                // ) : listLeads.rpp_variant === 9 ? (
                                //     <NegoFormProject
                                //         stateData={stateData}
                                //         listLeads={listLeads}
                                //         handleDrawerClose={handleClose}
                                //     />
                                <DatumNegotiationForm
                                    param={param}
                                    stateData={stateData}
                                    listLeads={listLeads}
                                    handleDrawerClose={handleClose}
                                />
                            )
                        ) : stateData.isLoading || stateData?.isPrice ? (
                            <div>
                                <LinearProgress/>
                            </div>
                        ) : listLeads.product === 1 || listLeads.product === 3 ? (
                            <NegotiationForm
                                param={param}
                                stateData={stateData}
                                listLeads={listLeads}
                                handleDrawerClose={handleClose}
                            />
                        ) : (
                            // ) : listLeads.rpp_variant === 9 ? (
                            //     <NegoFormProject
                            //         stateData={stateData}
                            //         listLeads={listLeads}
                            //         handleDrawerClose={handleClose}
                            //     />
                            <DatumNegotiationForm
                                param={param}
                                stateData={stateData}
                                listLeads={listLeads}
                                handleDrawerClose={handleClose}
                            />
                        )
                    ) : title === "Purchase Order" ? (
                        <PurchaseOrderForm
                            param={param}
                            stateData={stateData}
                            listLeads={listLeads}
                            handleDrawerClose={handleClose}
                        />
                    ) : title === "Booking Amount" ? (
                        <BookingAmountForm
                            stateData={stateData}
                            listLeads={listLeads}
                            handleDrawerClose={handleClose}
                        />
                    ) : listLeads.product === 1 || listLeads.product === 3 ? (
                        <MoreInfo
                            param={param}
                            stateData={stateData}
                            listLeads={listLeads}
                            handleDrawerClose={handleClose}
                        />
                    ) : (
                        <DatumMoreInfo
                            param={param}
                            stateData={stateData}
                            listLeads={listLeads}
                            handleDrawerClose={handleClose}
                        />
                    )}
                </div>
            </SwipeableDrawer>
        </>
    )
}
export default Drawers