import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line
// App
import App from "./App";
import reducer from "./reducers/reducer";

//store
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Sentry.init({dsn: "https://5d61daff20fd43829dd9f8a862119ce3@sentry.io/3616788"});

//
// const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <DevSupport
            ComponentPreviews={ComponentPreviews}
            useInitialHook={useInitial}
        >
            <App/>
        </DevSupport>
    </Provider>,
    document.getElementById("root")
);
