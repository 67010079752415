import {Button, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const DatumMoreInfo = props => {
    const {stateData, listLeads, handleDrawerClose, className, ...rest} = props;
    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>
                            Booking Amount Recieved
                        </TableCell>
                        <TableCell align="left">
                            {listLeads.booking_amount_recieved === true ? "Yes" : "No"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Region</TableCell>
                        <TableCell align="left">{listLeads.region}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>State</TableCell>
                        <TableCell align="left">{listLeads.partner_state}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Territory</TableCell>
                        <TableCell align="left">{listLeads.partner_territory}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Comment</TableCell>
                        <TableCell align="left">{listLeads.comment}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Datum Type</TableCell>
                        <TableCell align="left">{listLeads.datum_type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Datum Variant</TableCell>
                        <TableCell align="left">{listLeads.datum_variant}</TableCell>
                    </TableRow>
                    {listLeads.datum_type === "Z" ? (
                        <TableRow>
                            <TableCell style={{fontWeight: "bold"}}>
                                Datum Capacity
                            </TableCell>
                            <TableCell align="left">{listLeads.datum_capacity}KL</TableCell>
                        </TableRow>
                    ) : (
                        ""
                    )}
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>
                            Requirement Comment{" "}
                        </TableCell>
                        <TableCell align="left">{listLeads.requirement_comment}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Amount</TableCell>
                        <TableCell align="left">{listLeads.amount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Discount</TableCell>
                        <TableCell align="left">{listLeads.discount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Total Amount</TableCell>
                        <TableCell align="left">{listLeads.total_amount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>GST Number</TableCell>
                        <TableCell align="left">{listLeads.gst_number}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Booking Date </TableCell>
                        <TableCell align="left">{listLeads.booking_date}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Booking Amount</TableCell>
                        <TableCell align="left">{listLeads.booking_amount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>UTR Number</TableCell>
                        <TableCell align="left">{listLeads.utr_number}</TableCell>
                    </TableRow>
                </TableBody>
                <Button
                    className={classes.addNewPartnerButton}
                    color="primary"
                    size="medium"
                    variant="contained"
                    // disabled={!isFormValid()}
                    onClick={handleDrawerClose}
                >
                    Cancel
                </Button>
            </Table>
        </div>
    );
};
export default compose(withStyles(styles))(DatumMoreInfo);
