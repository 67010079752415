import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import styles from "../../../AllAssets/style";
import compose from "recompose/compose";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Portlet, PortletContent} from "components";
import NoDataFound from "../../../AllCustomers/components/NoDataInTable";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import secureLocalStorage from "react-secure-storage";
import {addLogsCrm, CompanyDetails, getLeadOwner, getListLogInfo, leadReason} from "actions/actions";
import {departmentList, PostQualification, priorityType, stages} from "../../../../constants/constants";
import {Accordion, AccordionDetails, AccordionSummary, Box, Container, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Cards from "../../../../components/Cards/Cards";
import {Breakpoint} from "react-socks";
import DatumQuestions from "../DatumQuestions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import validate from "validate.js";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "24px"
    },
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: 500,
        height: "36px",
        width: "36px"
    },
    nameText: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20
    },
    details: {
        alignItems: "center"
    },
    backgroundMarker: {
        opacity: 0.1,
        position: "absolute",
        right: 0,
        top: 0
    },
    column: {
        flexBasis: "33.333333%"
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2)
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    panelActions: {
        padding: "10px 22px"
    },
    fab: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "3.5%"
    },
    list: {
        width: "550px"
    },
    fullList: {
        width: "auto"
    },
    width20: {
        width: "310px"
    },
    iconCustomizer: {
        width: "60px",
        height: "40px",
        position: "relative",
        top: "10px",
        color: "#8a2be2"
    },
    drawerHeading: {
        float: "left",
        marginBottom: "10px"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    checked: {},
    track: {},
    field: {
        margin: theme.spacing(3)
    },
    disabledButton: {
        backgroundColor: "#ccc",
        color: "#888",
        "&:hover": {
            backgroundColor: "#ccc"
        }
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const Nurturing = props => {
    const {stateData, listLeads, handleDrawerClose, className, ...rest} = props;
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [mouseAction, setMouseAction] = useState(false);
    const isReasonArray = [1, 13, 12, 15, 2, 16, 29, 27];
    const [description, setDescription] = useState('');
    const [dialog, setDialog] = useState(false);
    const [param, setParam] = useState({
        offset: 0,
        limit: 10,
        lead_id: listLeads.id
    });

    const [formData, setFormData] = useState({
        value: {},
        errors: {},
        isValid: false
    });

    const [formDataForQus, setFormDataForQus] = useState({
        id: listLeads?.id,
        company_type: listLeads?.company_type,
        employee_size: listLeads?.employee_size,
        turn_over: listLeads?.turn_over,
        asset_owned: listLeads?.asset_owned,
        monthly_diesel_consumption: listLeads?.monthly_diesel_consumption,
        category: listLeads?.category,
        sub_category: listLeads?.sub_category,
        usage: listLeads?.usage
    });

    const isMounted = useRef(true);

    const [schema, setSchema] = useState({
        passed_to: {
            presence: {allowEmpty: false}
        },
        priority: {
            presence: {allowEmpty: false}
        },
        description: {
            presence: {allowEmpty: false}
        }
    })

    useEffect(() => {
        if (isMounted.current) {
            const errors = validate(formData.value, schema);
            setFormData(
                formData => ({
                    ...formData,
                    isValid: !errors,
                    errors: errors || {}
                }));
        }
        isMounted.current = false;

    }, [formData.value, schema]);


    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForProduct = [
            // "company_type",
            // "employee_size",
            // "turn_over",
            // "asset_owned",
            "monthly_diesel_consumption",
            "category",
            // "sub_category",
            // "usage",
        ];

        return requiredFieldsForProduct.every((field) => formDataForQus[field]);
    };

    /************************* ************ ****************************/

    /**
     * Clears the form data by resetting it to initial values.
     *
     * @return {void}
     */
    const handleClear = () => {
        setFormData({
            value: {
                reminder_date: new Date().toISOString().split("T")[0]
            },
            errors: {},
            isValid: false
        });
        setFormDataForQus({
            id: listLeads?.id,
            company_type: listLeads?.company_type,
            employee_size: listLeads?.employee_size,
            turn_over: listLeads?.turn_over,
            asset_owned: listLeads?.asset_owned,
            monthly_diesel_consumption: listLeads?.monthly_diesel_consumption,
            category: listLeads?.category,
            sub_category: listLeads?.sub_category,
            usage: listLeads?.usage
        })
    };

    const handleArr = value => {
        let array = [];
        value?.map((value, key) => (array = [...array, value.key]));
        return array;
    };

    /**
     * Updates the form data based on the selected reason.
     *
     * @param {Event} event - The event object triggered by the selection.
     * @param {Object} newValue - The selected value.
     * @param {string} name - The name of the field to update.
     * @param {boolean} isMultipleEntry - Indicates if multiple entries are allowed.
     * @return {void}
     */
    const handleReason = (event, newValue, name, isMultipleEntry) => {
        isMounted.current = true;
        !isMultipleEntry && delete formData.value["qua_array"];

        isMultipleEntry
            ? setFormData(prevFormData => ({
                ...prevFormData,
                value: {
                    ...prevFormData.value,
                    qua_array: JSON.stringify(handleArr(newValue))
                }
            }))
            : setFormData(prevFormData => ({
                ...prevFormData,
                value: {
                    ...prevFormData.value,
                    [name]: newValue?.value
                }
            }));
    };

    /**
     * Handles the auto selection based on the name.
     *
     * @param {type} event - description of event parameter
     * @param {type} newValue - description of newValue parameter
     * @param {type} name - description of name parameter
     * @return {type} description of return value
     */
    const handleAutoSelect = (event, newValue, name) => {
        name === "passed_to"
            ? setFormData(prevFormData => ({
                ...prevFormData,
                value: {
                    ...prevFormData.value,
                    id: newValue?.id,
                    passed_to: newValue?.name
                }
            }))
            : setFormData(prevFormData => ({
                ...prevFormData,
                value: {
                    ...prevFormData.value,
                    [name]: newValue
                }
            }));
        isMounted.current = true;
    };

    /**
     * Updates the schema based on the given value.
     *
     * @param {string} value - The value to determine the schema update.
     * @return {void} This function does not return anything.
     */
    const removeAddObject = (value) => {
        delete schema["reason"]
        delete schema["reminder_date"]
        delete schema["qua_array"]
        switch (value) {
            case "1":
                setSchema({
                    ...schema,
                    qua_array: {
                        presence: {allowEmpty: false}
                    }
                })
                break;
            case "13":
                setSchema({
                    ...schema,
                    reason: {
                        presence: {allowEmpty: false}
                    }
                })
                break;
            case "12":
                setSchema({
                    ...schema,
                    reminder_date: {
                        presence: {allowEmpty: false}
                    }
                })
                break;
            case "29":
                setSchema({
                    ...schema,
                    reminder_date: {
                        presence: {allowEmpty: false}
                    }
                })
                break;
            case "27":
                setSchema({
                    ...schema,
                    reminder_date: {
                        presence: {allowEmpty: false}
                    }
                })
                break;
            default:
                break;
        }
    }

    const handleInputChange = event => {
        const {name, value} = event.target;
        isMounted.current = true;
        if (name === "lead_stage" || name === "postlead_stage") {
            removeAddObject(value)

            setFormData(prevFormData => ({
                ...prevFormData,
                value: {
                    ...prevFormData.value,
                    [name]: value ? value : ""
                }
            }));

            let param = {
                stage: value,
                is_datum: listLeads?.product === 1 || listLeads?.product === 3 ? 0 : 1
            };
            isReasonArray?.includes(Number(value)) &&
            dispatch(leadReason(enqueueSnackbar, param));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                value: {
                    ...prevFormData.value,
                    [name]: value,
                }
            }));
        }
    };

    /**
     * A function that handles form submission by creating a payload with form data and lead ID,
     * dispatching an action to add logs to CRM, and resetting the form data.
     *
     * @return {void} No return value
     */
    const handelSubmit = () => {
        const payload = {
            ...formData.value,
            lead_id: listLeads.id
        };
        dispatch(
            addLogsCrm(
                secureLocalStorage.getItem("accessToken"),
                payload,
                enqueueSnackbar,
                handleCompanyDetails
            )
        );
        setFormData({
            value: {},
            errors: {},
            isValid: false
        });
    };

    const getLeadList = () => {
        // dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleCompanyDetails = () => {
        dispatch(CompanyDetails(
                secureLocalStorage.getItem("accessToken"),
                formDataForQus,
                enqueueSnackbar,
                getLeadList
            )
        );
    }

    useEffect(() => {
        if (stateData?.userCreationSuccess) {
            dispatch(getListLogInfo(secureLocalStorage.getItem("accessToken"), param));
        } else {
            dispatch(getListLogInfo(secureLocalStorage.getItem("accessToken"), param));
            dispatch(getLeadOwner(secureLocalStorage.getItem("accessToken")));
        }
    }, [stateData?.userCreationSuccess, dispatch]);


    let offset = 0;
    /**
     * A function to handle pagination by updating the page number and parameters.
     *
     * @param {type} limit - The limit of items per page.
     * @param {type} page - The current page number.
     * @return {type} This function does not return a value.
     */
    const onPagination = (limit, page) => {
        setPage(page);
        offset = limit * page;

        setParam({
            ...param,
            limit: limit,
            offset: offset
        });
    };

    /**
     * Updates the dialog state and sets the description value.
     *
     * @param {type} value - The new value for the description.
     * @return {type} Description of the return value.
     */
    const handleDescription = (value) => {
        setDialog(true);
        setDescription(value)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        onPagination(event.target.value, 0, null);
    };

    /**
     * Handles the change in the page number and triggers pagination.
     *
     * @param {type} event - The event object triggered by the user's action.
     * @param {type} page - The new page number.
     * @return {type} This function does not return a value.
     */
    const handleChangePage = (event, page) => {
        onPagination(rowsPerPage, page, null);
    };

    const cardData = [
        {
            align: "center",
            name: "Call",
            dataKey: 'call_log_number'
        },
        {
            align: "center",
            name: "Team",
            dataKey: 'lead_owner_name'
        },
        {
            align: "center",
            name: "Member",
            dataKey: 'name'
        },
        {
            align: "center",
            name: "Pre Stage",
            dataKey: 'lead_stage'
        },
        {
            align: "center",
            name: "Post Stage",
            dataKey: 'postlead_stage'
        },
        {
            align: "center",
            name: "Reason",
            dataKey: 'reson'
        },
        {
            align: "center",
            name: "Description",
            dataKey: 'description',
            isDescriptionLoading: true
        },
        {
            align: "center",
            name: "Priority",
            dataKey: 'priority'
        },
        {
            align: "center",
            name: "Passed To",
            dataKey: 'passed_to'
        },
        {
            align: "center",
            name: "Reminder",
            dataKey: 'interaction_date'
        },
        {
            align: "center",
            name: "Last Interaction",
            dataKey: 'last_interaction'
        }
    ]

    console.log(listLeads.product);

    const FormSubmitValidation = (obj) => {
        switch (obj) {
            case 1:
                return (formData.isValid)
            case 6:
                return (formData.isValid)
            default:
                return (formData.isValid && ((
                    listLeads.product === 2 ||
                    listLeads.product === 3 ||
                    listLeads.product === 4 ||
                    listLeads.product === 7 ||
                    listLeads.product === 8 ||
                    listLeads.product === 5 ||
                    listLeads.product === 9
                ) && ((formData.value.lead_stage === '1') ? isFormValid() : true)))
        }
    }

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Container>
                <Paper elevation={1} style={{borderRadius: "8px"}}>
                    <Box padding={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Box display={"flex"} flexDirection={"row"}>
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <Box display={"flex"} flexDirection={"row"}>
                                            <Box mr={2}>
                                                <img loading="lazy" alt="" src="/images/company.svg"/>
                                            </Box>
                                            <Box display={"flex"} flexDirection={"column"}>
                                                <Typography
                                                    variant={"h4"}
                                                    fontWeight={500}
                                                    color={"#253858"}
                                                >
                                                    {listLeads.company_name}
                                                </Typography>
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Typography
                                                        variant={"h5"}
                                                        fontWeight={600}
                                                        color={"#5E6C84"}
                                                        margin={"0 2px"}
                                                    >
                                                        Created on:
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                margin: "0 5px",
                                                                fontWeight: "400"
                                                            }}
                                                        >{listLeads.created_at}</span>
                                                    </Typography>
                                                    <Typography
                                                        variant={"h5"}
                                                        fontWeight={600}
                                                        color={"#5E6C84"}
                                                        margin={"0 2px"}
                                                    >
                                                        Worked Days :
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                margin: "0 5px",
                                                                fontWeight: "400"
                                                            }}
                                                        >{listLeads.worked_on}</span>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box padding={2}>
                        <Grid container spacing={2}>
                            <Grid item lg={2} md={2} xs={6}>
                                <TextField
                                    name="lead_stage"
                                    label="Pre Stage"
                                    required
                                    margin="dense"
                                    fullWidth
                                    value={
                                        formData.value.lead_stage === undefined ? "" : formData.value.lead_stage
                                    }
                                    onChange={handleInputChange}
                                    id="lead_stage"
                                    select
                                    SelectProps={{native: true}}
                                    variant="outlined"
                                    style={{marginTop: -1}}
                                    disabled={formData?.value?.postlead_stage}
                                >
                                    <option value="Select Option"></option>
                                    {stages?.map(option => (
                                        <option key={option.key} value={option.key}>
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item lg={2} md={2} xs={6}>
                                <TextField
                                    name="postlead_stage"
                                    label="Post Stage"
                                    required
                                    margin="dense"
                                    fullWidth
                                    value={
                                        formData.value.postlead_stage === undefined
                                            ? ""
                                            : formData.value.postlead_stage
                                    }
                                    onChange={handleInputChange}
                                    id="postlead_stage"
                                    select
                                    SelectProps={{native: true}}
                                    variant="outlined"
                                    style={{marginTop: -1}}
                                    disabled={formData?.value?.lead_stage}
                                >
                                    <option value="Select Option"></option>
                                    {PostQualification?.map(option => (
                                        <option key={option.key} value={option.key}>
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            {stateData?.isLoadingReason && !formData?.value?.postlead_stage ? (
                                <>
                                    <CircularProgress/>
                                </>
                            ) : (
                                <Grid item lg={2} md={2} xs={6}>
                                    {isReasonArray?.includes(
                                            Number(
                                                formData?.value.lead_stage
                                                    ? formData?.value.lead_stage
                                                    : formData?.value.postlead_stage
                                            )
                                        ) &&
                                        (!stateData?.state_reason[0]?.value?.is_date ? (
                                            <Autocomplete
                                                required
                                                multiple={formData?.value.lead_stage === "1"}
                                                id="checkboxes"
                                                onChange={(event, newValue) =>
                                                    handleReason(
                                                        event,
                                                        newValue,
                                                        formData?.value.postlead_stage ? "post_reason" : "reason",
                                                        formData?.value?.lead_stage === "1"
                                                    )
                                                }
                                                options={stateData?.state_reason}
                                                disableCloseOnSelect={formData?.value.lead_stage === "1"}
                                                getOptionLabel={option => option?.value}
                                                renderOption={(props, option, {selected}) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{marginRight: 8}}
                                                            checked={selected}
                                                            key={option?.key}
                                                        />
                                                        {option?.value}
                                                    </li>
                                                )}
                                                // style={{ width: 500 }}
                                                renderInput={params => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        label="Reason"
                                                        placeholder="Select"
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <TextField
                                                required
                                                label={"Date"}
                                                fullWidth
                                                type={"date"}
                                                value={formData?.value.remainder_date}
                                                name={"reminder_date"}
                                                onChange={handleInputChange}
                                                inputProps={{
                                                    min: new Date().toISOString().slice(0, 10),
                                                    // max: new Date().toISOString().slice(0, 10)
                                                }}
                                            />
                                        ))}
                                </Grid>
                            )}

                            {(formData?.value?.lead_stage || formData?.value?.postlead_stage) && (
                                <>
                                    <Grid item lg={2} md={2} xs={6}>
                                        <Autocomplete
                                            required
                                            id="priority"
                                            label="Priority"
                                            value={formData?.value.priority}
                                            options={priorityType}
                                            autoHighlight
                                            getOptionLabel={option => option?.name}
                                            onChange={(event, newValue) =>
                                                handleAutoSelect(event, newValue?.name, "priority")
                                            }
                                            // onChange={handleInputChange}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option?.name}
                                                </Box>
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    value={formData.value.priority}
                                                    label="Priority"
                                                    inputProps={{
                                                        ...params.inputProps
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={2} xs={6}>
                                        <Autocomplete
                                            required
                                            id="passed_to"
                                            label="Passed to"
                                            options={stateData?.leadOwner}
                                            value={formData.value.passed_to}
                                            autoHighlight
                                            getOptionLabel={option => option?.name}
                                            onChange={(event, newValue) =>
                                                handleAutoSelect(event, newValue, "passed_to")
                                            }
                                            // onChange={handlePassedToChange}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                                    {...props}
                                                >
                                                    {option.name}
                                                </Box>
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    value={formData.passed_to}
                                                    label="Passed to"
                                                    inputProps={{
                                                        ...params.inputProps
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={2} xs={6}>
                                        <TextField
                                            style={{marginTop: -1}}
                                            required
                                            name="description"
                                            label="Enter Description"
                                            margin="dense"
                                            placeholder="Reason For Buying"
                                            id="Enter Description"
                                            fullWidth
                                            value={formData?.value?.description || ""}
                                            onChange={handleInputChange}
                                            onInput={(e) => setMouseAction(true)}
                                            onMouseEnter={() => setMouseAction(true)}
                                            onMouseLeave={() => setMouseAction(false)}
                                            onL
                                            variant="outlined"
                                            multiline
                                            rows={mouseAction ? 10 : 1}
                                        />
                                    </Grid>
                                </>
                            )}

                            {/*{(formData.lead_stage || formData.postlead_stage) &&*/}
                            {/*    <>*/}
                            {/*        <Typography>*/}
                            {/*            Questionnaire*/}
                            {/*        </Typography>*/}
                            {/*        <DatumQuestions*/}
                            {/*            stateData={stateData}*/}
                            {/*            handleDrawerClose={handleDrawerClose}*/}
                            {/*            listLeads={listLeads}*/}
                            {/*            param={param}*/}
                            {/*        />*/}
                            {/*    </>*/}
                            {/*}*/}
                        </Grid>

                        {/***************************** DatumQuestions component ********************************/}
                        {(
                                listLeads.product === 2 ||
                                listLeads.product === 3 ||
                                listLeads.product === 4 ||
                                listLeads.product === 7 ||
                                listLeads.product === 8 ||
                                listLeads.product === 5 ||
                                listLeads.product === 9
                            ) &&
                            (formData.value.lead_stage === '1') &&
                            <div style={{marginTop: "1%"}}>
                                <Accordion elevation={0}>
                                    <AccordionSummary
                                        style={{backgroundColor: '#e7e7e7'}}
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography variant={"h6"}> Questionnaire</Typography>
                                        <Box ml={2}>
                                            <Typography variant={"h6"} style={{color: '#f44336'}}>Please select all
                                                Questionnaire filed and submit</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails style={{backgroundColor: "#f9f9f9"}}>
                                        <div>
                                            <>
                                                <DatumQuestions
                                                    stateData={stateData}
                                                    handleDrawerClose={handleDrawerClose}
                                                    isOpenDrawer={true}
                                                    listLeads={listLeads}
                                                    param={param}
                                                    isFormValid={isFormValid}
                                                    formDataForQus={formDataForQus}
                                                    setFormDataForQus={setFormDataForQus}
                                                />
                                            </>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>}
                        {/********************* ********************************* ********************************/}

                        {/*********************************** main for controls **********************************/}
                        <Grid container spacing={2}>
                            <Grid item lg={2} md={2} xs={6}>
                                {FormSubmitValidation(listLeads.product) &&
                                    <Box display={"flex"} flexDirection={"row-reverse"} padding={2}>
                                        <Button
                                            fullWidth
                                            variant={"contained"}
                                            style={{
                                                textTransform: "none",
                                                backgroundColor: "#059492",
                                                color: "White"
                                            }}
                                            onClick={handelSubmit}
                                        >
                                            Save
                                        </Button>
                                    </Box>}
                            </Grid>
                            <Grid item lg={2} md={2} xs={6}>
                                <Box display={"flex"} flexDirection={"row-reverse"} padding={2}>
                                    <Button
                                        fullWidth
                                        variant={"outlined"}
                                        style={{
                                            textTransform: "none",
                                            color: "#059492"
                                        }}
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        {/******************************** ******************** **********************************/}
                    </Box>
                </Paper>

                <Breakpoint medium down>
                    <Divider
                        style={{
                            marginBottom: "4%",
                            marginTop: "10%",
                            color: "#aeafb0"
                        }}
                    />


                    {/** Displays a component with cards based on the state data.*/}
                    {/***/}
                    {/** @param {Object[]} stateData - The state data containing listLogInfo.results.*/}
                    {/** @param {boolean} isLoading - Indicates if the component is in a loading state.*/}
                    {/** @param {boolean} noDataFound - Indicates if no data is found based on the count from listLogInfo.*/}
                    {/** @param {Function} onPagination - The function to handle pagination.*/}
                    {/** @param {number} rowsPerPage - The number of rows per page.*/}
                    {/** @param {number} page - The current page number.*/}
                    {/** @param {number} count - The total count of items in the listLogInfo.*/}

                    <Cards
                        stateData={stateData?.listLogInfo?.results}
                        isLoading={stateData?.isLoadingLog}
                        noDataFound={stateData.listLogInfo?.count < 1}
                        onPagination={onPagination}
                        rowsPerPage={param?.limit}
                        page={page}
                        count={stateData?.listLogInfo?.count}
                        cardData={cardData}
                        handleDescription={handleDescription}
                        isEdit={true}
                    />

                </Breakpoint>

                <Breakpoint large up>
                    <Portlet className={classes.root} style={{marginTop: "16px"}}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="center">Call</TableCell>
                                                <TableCell align="center">Team</TableCell>
                                                <TableCell align="center">Member</TableCell>
                                                <TableCell align="center">Pre Stage</TableCell>
                                                <TableCell align="center">Post Stage</TableCell>
                                                <TableCell align="center">Reason</TableCell>
                                                <TableCell align="center">Description</TableCell>
                                                <TableCell align="center">Priority</TableCell>
                                                <TableCell align="center">Passed To</TableCell>
                                                <TableCell align="center">Reminder</TableCell>
                                                <TableCell align="center">Last Interaction</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {stateData?.isLoadingLog ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"16"}>
                                                        <LinearProgress/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : stateData.listLogInfo?.count === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"16"}>
                                                        <NoDataFound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {Array.isArray(stateData?.listLogInfo?.results) &&
                                                    stateData?.listLogInfo?.results?.map(listInfo => (
                                                        <TableRow className={classes.tableRow} hover>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.call_log_number}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {departmentList?.find(
                                                                        dept => dept.key === listInfo.department
                                                                    )?.name || "Unknown Department"}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.name}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo?.lead_stage
                                                                        ? listInfo.lead_stage
                                                                        : "Not available"}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo?.postlead_stage
                                                                        ? listInfo.postlead_stage
                                                                        : "Not available"}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.reson
                                                                        ? listInfo.reson
                                                                        : "Not available"}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center" size={'medium'}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                    onClick={() => handleDescription(listInfo.description)}
                                                                >
                                                                    {listInfo?.description?.length <= 18 ?
                                                                        listInfo?.description :
                                                                        (listInfo?.description?.substr(0, 18) + "...")}
                                                                    <Button
                                                                        variant={"contained"}
                                                                        style={{backgroundColor: '#123865'}}
                                                                        onClick={() => handleDescription(listInfo.description)}>
                                                                        <Typography style={{
                                                                            fontSize: '0.6rem',
                                                                            textTransform: "none",
                                                                            lineHeight: 'normal',
                                                                            color: '#ffffff',
                                                                            fontWeight: 500
                                                                        }}>
                                                                            Know More
                                                                        </Typography>
                                                                    </Button>
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.priority}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.passed_to}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.interaction_date}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {listInfo.last_interaction}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>

                                <Divider/>
                                {!(stateData?.listLogInfo?.count === 0) &&
                                    (stateData?.isLoadingLog ? (
                                        <LinearProgress fullWidth className={classes.progress}/>
                                    ) : (
                                        <TablePagination
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page"
                                            }}
                                            component="div"
                                            count={stateData?.listLogInfo?.count}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page"
                                            }}
                                            // onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            page={page}
                                            rowsPerPage={param?.limit}
                                            rowsPerPageOptions={[10, 100, 500]}
                                            onPageChange={handleChangePage}
                                        />
                                    ))}
                            </PerfectScrollbar>
                        </PortletContent>
                    </Portlet>
                </Breakpoint>
            </Container>

            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogContent>
                    <Typography>
                        {description}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialog(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

Nurturing.propTypes = {
    className: PropTypes.string
};
export default compose(withStyles(styles))(Nurturing);
