import React, {Component} from "react";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import {SnackbarProvider} from "notistack";
import {BreakpointProvider} from "react-socks";

// Externals
import {Chart} from "react-chartjs-2";

// Material helpers
import {ThemeProvider} from "@material-ui/styles";

// ChartJS helpers
import {chartjs} from "./helpers";

// Theme
import theme from "./theme";

// Styles
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";

// Routes
import Routes from "./Routes";

//redux
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Divider, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ReactCountdownClock from "react-countdown-clock";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {logoutRequest} from "./actions/actions";
import InternalServerError from "./views/InternalServerError/internalServerError";

// Browser history
const browserHistory = createBrowserHistory();

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});

class App extends Component {
    style = {
        nameText: {
            display: "inline-block",
            marginLeft: theme.spacing(2),
            fontWeight: 500,
            cursor: "pointer"
        },
        ctClass: {
            marginLeft: "35%",
            marginTop: "10%"
        },
        signInButton: {
            marginTop: theme.spacing(2),
            width: "100%"
        },
        listDivider: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2)
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            open: true
        };
    }

    render() {
        const handleClose = () => {
            this.props.handleLogoutRequest(browserHistory);
        };

        return (
            <ThemeProvider theme={theme}>
                <BreakpointProvider>
                    <SnackbarProvider>
                        <Router history={browserHistory}>
                            {this.props.sessionExpired ? (
                                <Dialog open={this.state.open}>
                                    <DialogTitle>
                                        <Typography variant={"h2"} style={{color: "#12161ba8"}}>
                                            Session Expired!
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent style={{textAlign: "center"}}>
                                        <DialogContentText>
                                            <Typography
                                                variant={"body"}
                                                style={{color: "#12161ba8"}}
                                            >
                                                Hey! you just signed out due to inactivity,
                                                <br/>
                                                please sign in again in order to continue!
                                            </Typography>
                                        </DialogContentText>
                                        <Divider
                                            style={{
                                                marginBottom: theme.spacing(2),
                                                marginTop: theme.spacing(2)
                                            }}
                                        />
                                        <Typography
                                            variant={"body2"}
                                            style={{color: "#12161ba8", marginTop: "5%"}}
                                        >
                                            redirecting you automatically...
                                        </Typography>
                                        <div
                                            style={{
                                                marginLeft: "26%",
                                                marginTop: "10%"
                                            }}
                                        >
                                            <ReactCountdownClock
                                                seconds={15}
                                                color="#0866db"
                                                alpha={0.8}
                                                size={60}
                                                weight={4}
                                                onComplete={handleClose}
                                            />
                                        </div>
                                        <div style={{marginTop: "5%"}}>
                                            <Typography
                                                variant={"body"}
                                                style={{color: "#12161ba8"}}
                                            >
                                                OR
                                            </Typography>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            style={{
                                                marginTop: theme.spacing(2),
                                                width: "100%"
                                            }}
                                            onClick={handleClose}
                                            color="primary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Sign in Again
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            ) : this.props.internalServerError ? (
                                <InternalServerError history={browserHistory}/>
                            ) : (
                                <Routes/>
                            )}
                        </Router>
                    </SnackbarProvider>
                </BreakpointProvider>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        sessionExpired: state.sessionExpired,
        internalServerError: state.internalServerError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleLogoutRequest: history => {
            dispatch(logoutRequest(history));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
