import {Button, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {getAllLeads, NegotiationInfo} from "actions/actions.js";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const NegotiationForm = props => {
    const {stateData, listLeads, handleDrawerClose, param, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [saveButton, setSaveButton] = useState(true);
    const [totalButton, setTotalButton] = useState(false);

    const [formData, setFormData] = useState({
        id: listLeads?.id,
        amount: stateData?.rppPrice?.amount,
        adjustment: 0,
        discount: listLeads?.discount === null ? 0 : listLeads?.discount,
        total_amount: listLeads?.total_amount,
        quantity: listLeads?.quantity === null ? 1 : listLeads?.quantity,
        tcs_amount: stateData?.rppPrice?.tcs_amount
            ? stateData?.rppPrice?.tcs_amount
            : 0,
        tax_amount: stateData?.rppPrice?.tax_amount
            ? stateData?.rppPrice?.tax_amount
            : 0
    });

    const handleInputChange = event => {
        setSaveButton(true);
        setTotalButton(false);
        const {id, value} = event.target;
        const newDiscount = Math.min(50000, Math.max(0, parseInt(value, 10)));
        if (listLeads?.rpp_variant_display === "PROJECT") {
            setFormData({
                ...formData,
                [id]: id === "discount" ? newDiscount : value
            });
        } else {
            setFormData({
                ...formData,
                [id]: id === "discount" ? newDiscount : value,
                amount: stateData?.rppPrice?.amount
                // discount: formData.discount * (formData?.quantity? formData?.quantity : 1),
            });
        }
    };

    const AmountTotal = () => {
        setSaveButton(false);
        setTotalButton(true);
        if (listLeads?.rpp_variant_display === "PROJECT") {
            if (listLeads?.rpp_invoice_type === "SI") {
                const totalAmount =
                    (formData.amount - formData.discount) *
                    (formData?.quantity ? formData?.quantity : 1);
                const updatedTax = totalAmount * 0.18;
                const tcsAmount = totalAmount / 100;

                // for single invoice Update the formData state with the calculated total amount
                setFormData({
                    ...formData,
                    id: listLeads?.id,
                    total_amount: totalAmount + tcsAmount,
                    amount: totalAmount,
                    adjustment: formData?.adjustment,
                    tcs_amount: tcsAmount,
                    tax_amount: updatedTax
                    // discount: formData.discount * (formData?.quantity? formData?.quantity : 1),// Convert to string if necessary
                });
            } else {
                const totalAmount =
                    (formData.amount - formData.discount) *
                    (formData?.quantity ? formData?.quantity : 1);
                // const updatedTax = (totalAmount * 0.18);
                // const tcsAmount = (totalAmount) / 100;
                // const totalAmount = (formData.amount - formData.discount) * (formData?.quantity ? formData?.quantity : 1);
                // const updatedTax = (((totalAmount - (stateData?.rppPrice?.abs_price * formData?.quantity ? formData?.quantity : 1)) / 100) * 18) + (stateData?.rppPrice?.abs_taxAmount * (formData?.quantity ? formData?.quantity : 1));
                // const tcsAmount = (totalAmount + updatedTax) / 100;
                // Update the formData state with the calculated total amount

                setFormData({
                    ...formData,
                    id: listLeads?.id,
                    total_amount: totalAmount,
                    amount:
                        formData.amount * (formData?.quantity ? formData?.quantity : 1),
                    adjustment: formData?.adjustment
                    // discount: formData.discount * (formData?.quantity? formData?.quantity : 1),// Convert to string if necessary
                });
            }
        } else {
            if (listLeads?.rpp_invoice_type === "SI") {
                const totalAmount =
                    (formData.amount - formData.discount) *
                    (formData?.quantity ? formData?.quantity : 1);
                const updatedTax = totalAmount * 0.18;
                const tcsAmount = totalAmount / 100;

                // for single invoice Update the formData state with the calculated total amount
                setFormData({
                    ...formData,
                    id: listLeads?.id,
                    total_amount: totalAmount + tcsAmount,
                    amount: totalAmount,
                    adjustment: formData?.adjustment,
                    tcs_amount: tcsAmount,
                    tax_amount: updatedTax
                    // discount: formData.discount * (formData?.quantity? formData?.quantity : 1),// Convert to string if necessary
                });
            } else {
                const totalAmount =
                    (formData.amount - formData.discount) *
                    (formData?.quantity ? formData?.quantity : 1);
                const updatedTax = totalAmount * 0.18;

                // for double invoice Update the formData state with the calculated total amount
                setFormData({
                    ...formData,
                    id: listLeads?.id,
                    total_amount: totalAmount,
                    amount: totalAmount,
                    adjustment: formData?.adjustment,
                    tcs_amount: 0,
                    tax_amount: updatedTax
                });
            }
        }
    };

    // console.log(listLeads?.rpp_invoice_type, formData?.total_amount);

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleSubmit = () => {
        dispatch(
            NegotiationInfo(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar,
                handleDrawerClose,
                getLeadList
            )
        );
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography>Quantity</Typography>
            <TextField
                className={classes.textField}
                // label={"Amount"}
                margin="dense"
                type={"number"}
                fullWidth
                value={formData?.quantity}
                onChange={handleInputChange}
                // required
                id={"quantity"}
                // SelectProps={{ native: true }}
                variant="outlined"
            />
            <Typography>Amount</Typography>
            <TextField
                className={classes.textField}
                // label={"Amount"}
                margin="dense"
                disabled={listLeads?.rpp_variant_display !== "PROJECT"}
                fullWidth
                value={formData?.amount}
                onChange={handleInputChange}
                // required
                id={"amount"}
                // SelectProps={{ native: true }}
                variant="outlined"
            />

            <TextField
                className={classes.textField}
                label="Adjustment"
                margin="dense"
                type="number"
                disabled={listLeads?.rpp_variant_display !== "PROJECT"}
                value={formData.adjustment}
                id={"adjustment"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />
            <Typography>Discount</Typography>
            <TextField
                className={classes.textField}
                label="Discount"
                margin="dense"
                type="number"
                value={formData?.discount}
                id={"discount"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />
            <Typography>Tax Amount</Typography>
            <TextField
                className={classes.textField}
                // label={"Discount"}
                margin="dense"
                fullWidth
                value={formData?.tax_amount}
                onChange={handleInputChange}
                disabled={listLeads?.rpp_variant_display !== "PROJECT"}
                // required
                id={"tax_amount"}
                Props={{native: true}}
                variant="outlined"
            />
            <Typography>TCS Amount</Typography>
            <TextField
                className={classes.textField}
                // label={"Discount"}
                margin="dense"
                fullWidth
                value={formData.tcs_amount}
                onChange={handleInputChange}
                disabled={listLeads?.rpp_variant_display !== "PROJECT"}
                // required
                id={"tcs_amount"}
                Props={{native: true}}
                variant="outlined"
            />
            <Typography>Total Amount</Typography>
            <TextField
                className={classes.textField}
                // label="Total Amount"
                margin="dense"
                type="text"
                disabled
                value={formData.total_amount}
                id={"total_amount"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                disabled={totalButton}
                onClick={AmountTotal}
            >
                Calculate Total Amount
            </Button>
            <Button
                disabled={formData?.total_amount === "" || saveButton}
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleSubmit}
            >
                Save
            </Button>
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleDrawerClose}
            >
                Cancel
            </Button>
        </div>
    );
};
export default compose(withStyles(styles))(NegotiationForm);
