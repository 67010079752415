import React, {useEffect, useState} from "react";
import {Dashboard as DashboardLayout} from "layouts";
import UserToolbar from "./components/UsersToolbar";
import UsersTable from "./components/UsersTable";
import {connect, useDispatch} from "react-redux";
import {compose} from "underscore";
import {makeStyles, withStyles} from "@material-ui/styles";
import styles from "../AllPumps/components/CreatePumpSingleSignOn/styles";
import {mapStateToProps} from "../FetchDataFromReducer/mapStateToProps";
import {deleteLead, exportReport, getAllLeads, getDatumPrice, getRppPrice, leadBulkDelete} from "actions/actions";
import secureLocalStorage from "react-secure-storage";
import EnhancedTableToolbar from "./components/SelectedRow";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Box, Button, Typography} from "@mui/material";
import AlertDialogSlide from "../../components/DialogSmall/AlertDialogSlide";
import {useSnackbar} from "notistack";
import DialogDateRange from "../../components/DialogSmall/DialogDateRange";
import {Divider} from "@material-ui/core";
import {Breakpoint} from "react-socks";
import Cards from "../../components/Cards/Cards";
import Drawers from "./components/Drawer/Drawers";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
        paddingTop: "2px"
    },
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        // backgroundColor: theme.palette.primary.main,
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: 500,
        height: "36px",
        width: "36px"
    },
    nameText: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20
    },
    details: {
        alignItems: "center"
    },
    backgroundMarker: {
        opacity: 0.1,
        position: "absolute",
        right: 0,
        top: 0
    },
    column: {
        flexBasis: "33.333333%"
        // flexBasis: '24.55%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2)
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    panelActions: {
        // textAlign:'center',
        padding: "10px 22px"
    },
    fab: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "3.5%"
    },
    list: {
        width: "550px"
    },
    fullList: {
        width: "auto"
    },
    width20: {
        width: "310px"
    },
    iconCustomizer: {
        width: "60px",
        height: "40px",
        position: "relative",
        top: "10px",
        color: "#8a2be2"
    },
    drawerHeading: {
        float: "left",
        marginBottom: "10px"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    switchBase: {
        // color: red[300],
        "&$checked": {
            // color: green[500]
        },
        "&$checked + $track": {
            // backgroundColor: green[500]
        }
    },
    checked: {},
    track: {},
    field: {
        margin: theme.spacing(3)
    },
    drawerWidth: {
        zIndex: 1200,
        width: "340px"
    },
    NewDrawerWidth: {
        zIndex: 1200,
        width: "auto"
    }
}));

/**
 * Renders the ReposCRM component.
 *
 * @param {Object} stateData - The state data object.
 * @return {JSX.Element} The rendered ReposCRM component.
 *
 * This code snippet defines a React functional component called ReposCRM. It takes a prop called stateData as input.
 * Inside the component, it uses React hooks like useState, useEffect, and useDispatch to manage state and side effects.
 * The component renders a DashboardLayout component with a title of "Repos CRM". It also renders a UserToolbar component and an EnhancedTableToolbar component.
 * The ReposCRM component has several state variables defined using the useState hook, such as rowsPerPage, page, offset, searchString, selectedState, selectedProduct, etc.
 * There are also several event handler functions defined, such as onStateChange, onProductChange, onLeadStatusChange, etc., which update the corresponding state variables.
 * The component also uses the useEffect hook to perform side effects when certain state variables change. In this case, it makes API calls to fetch data using the getAllLeads and getLeadAnalytics functions from the Redux store.
 * The component renders a UsersTable component, passing in various props including stateData, onPagination, rowsPerPage, page, handleCall, selected, and setSelected.
 * Overall, this component is responsible for rendering a dashboard layout with a toolbar and a table of users, and it handles user interactions and API calls to fetch and update data.
 */
const ReposCRM = ({stateData}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [alertDialogSlide, setAlertDialogSlide] = useState(false);
    const [selectDate, setSelectDate] = useState(false);
    const [keys, setKeys] = useState({});
    const [page, setPage] = useState(0);
    const [title, setTitle] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [listLeads, setListLeads] = useState({});
    const [param, setParam] = useState({
        offset: 0,
        limit: 10
    });
    let offset = 0;
    const {enqueueSnackbar} = useSnackbar();

    /**
     * A function to handle getting all leads.
     *
     * @param {type} params - description of the parameters
     * @return {type} description of the return value
     */
    const handleGetAllLeads = params => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param));
    };

    /**
     * A function that updates a parameter with a new value based on the given name.
     *
     * @param {type} value - description of the value parameter
     * @param {type} name - description of the name parameter
     * @return {type} description of return value
     */
    const onAssignTo = (value, name) => {
        setParam({
            ...param,
            limit: param.limit,
            offset: param.offset,
            [name]: value
        });
    };

    useEffect(() => {
        dispatch(
            getAllLeads(
                secureLocalStorage.getItem("accessToken"),
                param,
                enqueueSnackbar
            )
        );
        // dispatch(getLeadAnalytics(secureLocalStorage.getItem("accessToken"), param))
    }, [param, stateData?.leadAPI_call, page]);

    useEffect(() => {
        handleClose();
    }, [stateData?.leadAPI_call]);

    /**
     * Updates the pagination parameters and triggers a state update.
     *
     * @param {number} limit - The number of items per page.
     * @param {number} page - The current page number.
     * @return {void} This function does not return anything.
     */
    const onPagination = (limit, page) => {
        setPage(page);
        offset = limit * page;

        setParam({
            ...param,
            limit: limit,
            offset: offset
        });
    };

    /**
     * A function that handles a call by invoking the handleGetAllLeads function with the parameter 'param'.
     *
     * @return {void} This function does not return anything.
     */
    const handleCall = () => {
        handleGetAllLeads(param);
    };

    const onSearchTerm = searchTerm => {
        if (searchTerm?.length >= 3) {
            setParam({
                ...param,
                search: searchTerm
            });
        } else {
            setParam({
                ...param,
                search: ""
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setAlertDialogSlide(false);
        setSelected([]);
        setSelectDate(false);
        setDrawerOpen(false)
    };

    const handleLeadAction = () => {
        let data = {
            ids: selected
        };
        dispatch(leadBulkDelete(enqueueSnackbar, data));
    };

    const handleOpenAlert = () => {
        setAlertDialogSlide(true);
        setOpen(false);
    };

    const handleDate = value => {
        setParam({
            [keys.start_date]: value.start_date,
            [keys.end_date]: value.end_date
        });
    };

    const handleExportReport = value => {
        dispatch(exportReport(enqueueSnackbar, value));
    };

    const clearFilter = () => {
        setPage(0);
        setKeys({});
        setParam({
            offset: 0,
            limit: 10
        });
    };

    /**
     * Handles the logic for opening a drawer based on the given titles and listLead.
     *
     * @param {string} titles - The titles to determine the type of drawer to open.
     * @param {object} listLead - The lead object containing information for the drawer.
     * @return {void} This function does not return anything.
     */

    const HandleDrawer = (titles, listLead) => {
        if (titles === "Negotiation") {
            if (listLead.product === 1 || listLead.product === 3) {
                if (listLead?.rpp_variant_display !== "PROJECT") {
                    dispatch(
                        getRppPrice(
                            secureLocalStorage.getItem("accessToken"),
                            {
                                invoice_type: listLead?.rpp_invoice_type,
                                loading_type: listLead?.rpp_loading_type,
                                rbml_type: listLead?.rpp_rbml_type ? 1 : 0,
                                chassis: listLead?.rpp_chassis,
                                rpp_model: listLead?.rpp_variant,
                                abs: listLead?.rpp_abs
                            },
                            enqueueSnackbar
                        )
                    );
                }
            } else {
                if (listLead?.product_display !== "DATUS") {
                    switch (listLead?.datum_type) {
                        case "Z":
                            dispatch(
                                getDatumPrice(
                                    secureLocalStorage.getItem("accessToken"),
                                    {
                                        name: "Z3" + "-" + listLead.datum_capacity + "K"
                                    },
                                    enqueueSnackbar
                                )
                            );
                            break;
                        case "Y":
                            dispatch(
                                getDatumPrice(
                                    secureLocalStorage.getItem("accessToken"),
                                    {
                                        name: "Y" + "-" + listLead.datum_capacity + "K"
                                    },
                                    enqueueSnackbar
                                )
                            );
                            break;
                        case "X":
                            dispatch(
                                getDatumPrice(
                                    secureLocalStorage.getItem("accessToken"),
                                    {
                                        // name: listLead?.datum_type + listLead.datum_variant,
                                        name: "X300"
                                    },
                                    enqueueSnackbar
                                )
                            );
                            break;
                        case "F":
                            dispatch(
                                getDatumPrice(
                                    secureLocalStorage.getItem("accessToken"),
                                    {
                                        name: listLead?.datum_type + listLead.datum_variant
                                    },
                                    enqueueSnackbar
                                )
                            );
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        setTitle(titles);
        setDrawerOpen(true);
        setListLeads(listLead);
    };

    /**
     * Deletes a lead with the given ID.
     *
     * @param {string} name - description of the name parameter.
     * @param {string} id - The ID of the lead to be deleted.
     * @return {void} This function does not return a value.
     */
    const handleDelete = (name, id) => {
        const formData = {
            id: id?.id
        };
        dispatch(
            deleteLead(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar
            )
        );
    };

    const cardData = [
        // {
        //     align: "center",
        //     name: "Edit"
        // },
        {
            align: "center",
            name: "Lead Created By",
            dataKey: 'created_by'
        },
        {
            align: "center",
            name: "Lead owner name",
            dataKey: 'lead_owner_name'
        },
        {
            align: "center",
            name: "Company Name",
            dataKey: 'company_name'
        },
        {
            align: "center",
            name: "Partner Name",
            dataKey: 'partner_name'
        },
        {
            align: "center",
            name: "State",
            dataKey: 'partner_state'
        },
        {
            align: "center",
            name: "Territory",
            dataKey: 'partner_territory'
        },
        {
            align: "center",
            name: "Lead Source",
            dataKey: 'lead_source'
        },
        {
            align: "center",
            name: "Company mobile",
            dataKey: 'company_mobile'
        },
        {
            align: "center",
            name: "Product name",
            dataKey: 'product_display'
        },
        {
            align: "center",
            name: "Lead Status",
            dataKey: 'lead_status_display'
        },
        {
            align: "center",
            name: "Lead Stage",
            dataKey: 'lead_stage_display'
        },
        {
            align: "center",
            name: "Lead Score",
            dataKey: 'lead_score'
        },
        {
            align: "center",
            name: "Quantity",
            dataKey: 'quantity'
        },
        {
            align: "center",
            name: "Actionable",
            dataKey: 'quantity',
            actionButtons: [
                {
                    iconName: "Nurturing",
                    icon: <img
                        src="/images/nuturing.svg"
                        alt="nuturing"
                    />,
                    fun: HandleDrawer
                },
                {
                    iconName: "Questionnaire",
                    icon: <InfoOutlinedIcon/>,
                    fun: HandleDrawer
                },
                {
                    iconName: "Add Product Details",
                    icon: <ProductionQuantityLimitsIcon/>,
                    fun: HandleDrawer
                },
                {
                    iconName: "Negotiation",
                    icon: <CurrencyRupeeIcon/>,
                    fun: HandleDrawer
                },
                {
                    iconName: "Purchase Order",
                    icon: <FileUploadOutlinedIcon/>,
                    fun: HandleDrawer
                },
                {
                    iconName: "Booking Amount",
                    icon: <BookmarkAddedOutlinedIcon/>,
                    fun: HandleDrawer
                },
                {
                    iconName: "More Information",
                    icon: <MoreIcon/>,
                    fun: HandleDrawer
                },
                {
                    iconName: "delete",
                    icon: <DeleteIcon/>,
                    fun: handleDelete
                }
            ]
        }
    ]

    const buttonCondition = (key, item) => {
        switch (key) {
            case "Nurturing":
                return true
            case "Questionnaire":
                return (item.product === 2 || item.product === 3)
            case "Add Product Details":
                return true;
            case "Negotiation":
                return (item.product === 1 || item.product === 3 ?
                    !item?.rpp_invoice_type || !item?.rpp_loading_type || !item?.rpp_chassis || !item?.rpp_variant :
                    item.datum_variant);
            case "Purchase Order":
                return true;
            case "Booking Amount":
                return true;
            case "More Information":
                return true;
            case "delete":
                return ((secureLocalStorage.getItem("crm_rank") === 1 ||
                        secureLocalStorage.getItem("crm_rank") === 2 ||
                        secureLocalStorage.getItem("crm_rank") === 3 ||
                        secureLocalStorage.getItem("crm_rank") === 14 ||
                        secureLocalStorage.getItem("department") === 13 ||
                        secureLocalStorage.getItem("department") === 10 ||
                        secureLocalStorage.getItem("department") === 5 ||
                        secureLocalStorage.getItem("department") === 1) &&
                    secureLocalStorage.getItem("access_type") === true)
                    ;
            default:
                return true;
        }
    }

    return (
        <DashboardLayout title="Repos CRM">
            <UserToolbar
                stateData={stateData}
                onSearchTerm={onSearchTerm}
                fliters={onAssignTo}
                openDate={setSelectDate}
                keys={keys}
                setKeys={setKeys}
                param={param}
                clearFilter={clearFilter}
                handleExportReport={handleExportReport}
            />
            {selected?.length !== 0 && (
                <EnhancedTableToolbar
                    numSelected={selected?.length}
                    setOpen={setOpen}
                />
            )}

            <Breakpoint large up>
                <UsersTable
                    stateData={stateData}
                    onPagination={onPagination}
                    rowsPerPage={param?.limit}
                    page={page}
                    handleCall={handleCall}
                    selected={selected}
                    setSelected={setSelected}
                    param={param}
                    classes={classes}
                    setListLeads={setListLeads}
                    setDrawerOpen={setDrawerOpen}
                    setTitle={setTitle}
                    HandleDrawer={HandleDrawer}
                    handleDelete={handleDelete}
                />
            </Breakpoint>

            <Breakpoint medium down>
                <Divider
                    style={{
                        marginBottom: "4%",
                        marginTop: "10%",
                        color: "#aeafb0"
                    }}
                />
                <Cards
                    noDataFound={stateData?.noDataFound}
                    isLoading={stateData?.isLoading}
                    stateData={stateData?.allUserLeadData}
                    count={stateData.dataCount}
                    isLeadTableLoading={stateData?.isLeadTableLoading}
                    onPagination={onPagination}
                    rowsPerPage={param?.limit}
                    page={page}
                    handleCall={handleCall}
                    selected={selected}
                    setSelected={setSelected}
                    param={param}
                    classes={classes}
                    cardData={cardData}
                    setListLeads={setListLeads}
                    setDrawerOpen={setDrawerOpen}
                    setTitle={setTitle}
                    HandleDrawer={HandleDrawer}
                    handleDelete={handleDelete}
                    buttonCondition={buttonCondition}
                />
            </Breakpoint>

            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant={"h6"}>Actions On Leads</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>Delete Leads</Typography>
                    </DialogContentText>
                    <DialogContent>
                        <Box display={"flex"} flexDirection={"row"}>
                            <Button
                                fullWidth
                                variant={"outlined"}
                                style={{marginRight: "10px", textTransform: "none"}}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                fullWidth
                                variant={"contained"}
                                style={{textTransform: "none"}}
                                onClick={() => handleOpenAlert()}
                            >
                                Delete
                            </Button>
                        </Box>
                    </DialogContent>
                </DialogContent>
            </Dialog>

            {/********************** Drawer for action buttons **********************/}
            <Drawers drawerOpen={drawerOpen} title={title} HandleDrawer={HandleDrawer} stateData={stateData}
                     listLeads={listLeads} handleClose={handleClose} param={param} classes={classes}/>

            <AlertDialogSlide
                handleAction={handleLeadAction}
                alertDialogSlide={alertDialogSlide}
                setAlertDialogSlide={setAlertDialogSlide}
            />

            <DialogDateRange
                open={selectDate}
                handleClose={handleClose}
                handleDate={handleDate}
                isRange={true}
            />
        </DashboardLayout>
    );
};

export default connect(mapStateToProps)(compose(withStyles(styles))(ReposCRM));
