import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {withStyles} from "@material-ui/core";

// Material components
// import { CircularProgress, Typography } from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Shared services
// import { getUsers } from '../../services/user';
// Custom components
import {UsersTable, UsersToolbar} from "./components";

// Component styles
import styles from "./style";
import {
    assignOrderToRefueler,
    changeOrderAssignmentSequence,
    changeOrderUnssignment,
    getAllRefuellersAndOrders,
    versionControl
} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import axios from "axios";
import {withSnackbar} from "notistack";
import secureLocalStorage from "react-secure-storage";

class AllOrderAssignments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0,
            partner: null
        };
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm
            });
            this.getRefuellerData(searchTerm);
        } else {
            this.setState({
                searchString: ""
            });
            this.getRefuellerData("");
        }
    };

    onSelectedPartner = value => {
        this.setState({
            partner: value
        });
        if (value === "") {
            this.getRefuellerData("", this.state.rowsPerPage, 0, null);
        } else this.getRefuellerData("", this.state.rowsPerPage, 0, value);
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        this.setState({rowsPerPage: limit, page});

        this.getRefuellerData(this.state.searchString, limit, offset);
    };

    getRefuellerData = (
        searchTerm = "",
        limit = this.state.rowsPerPage,
        offset = 0,
        partner,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllRefuellersAndOrders(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            partner,
            abortController
        );
    };

    getRefuelerAssignmentData = (order, refueler) => {
        this.props.handleAssignOrderToRefueler(
            secureLocalStorage.getItem("accessToken"),
            order,
            refueler,
            this.props,
            this.state.searchString,
            this.state.rowsPerPage,
            this.state.page * this.state.rowsPerPage,
            "",
            this.abortController
        );
    };

    getRefuelerUnassignmentData = orderId => {
        this.props.handleUnAssignOrderToRefueler(
            secureLocalStorage.getItem("accessToken"),
            orderId
        );
    };

    changeOrderSequence = (order, operation) => {
        this.props.handleChangeOrderSequence(
            secureLocalStorage.getItem("accessToken"),
            order,
            operation
        );
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getRefuellerData();
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="Assignments">
                    <div className={classes.root}>
                        {/*{*/}
                        {/*  this.props.allPartnersMinimalData.length === 0*/}
                        {/*      ?*/}
                        {/*      <div className={classes.progressWrapper}>*/}
                        {/*        <CircularProgress className={classes.progress}/>*/}
                        {/*      </div>*/}
                        {/*      :*/}
                        <div>
                            <UsersToolbar
                                allRefuellerData={this.props.allRefuellerData}
                                searchedTerm={this.onSearchTerm}
                                partnersData={this.props.allPartnersMinimalData}
                                selectedPartner={this.onSelectedPartner}
                            />
                            <UsersTable
                                dataCount={this.props.dataCount}
                                allRefuellerData={this.props.allRefuellerData}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                allOrdersData={this.props.allOrdersData}
                                onPagination={this.onPagination}
                                noDataFound={this.props.noDataFound}
                                isLoading={this.props.isLoading}
                                orderUnassignedSuccessfully={
                                    this.props.orderUnassignedSuccessfully
                                }
                                getRefuelerOrderAssignment={this.getRefuelerAssignmentData}
                                getRefuelerOrderUnssignment={this.getRefuelerUnassignmentData}
                                changeOrderSequence={this.changeOrderSequence}
                                getRefuellerData={this.getRefuellerData}
                            />
                        </div>
                        {/*}*/}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllOrderAssignments.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allRefuellerData: state.allRefuellerData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allPartnersMinimalData: state.allPartnersMinimalData,
        allOrdersData: state.allOrdersData,
        isLoading: state.isLoading,
        orderUnassignedSuccessfully: state.orderUnassignedSuccessfully
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllRefuellersAndOrders: (
            accessToken,
            searchString,
            limit,
            offset,
            partner,
            abortController
        ) => {
            dispatch(
                getAllRefuellersAndOrders(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    partner,
                    abortController
                )
            );
        },
        handleAssignOrderToRefueler: (
            accessToken,
            order,
            refueler,
            props,
            searchString,
            limit,
            offset,
            partner,
            abortController
        ) => {
            dispatch(
                assignOrderToRefueler(
                    accessToken,
                    order,
                    refueler,
                    props,
                    searchString,
                    limit,
                    offset,
                    partner,
                    abortController
                )
            );
        },
        handleChangeOrderSequence: (accessToken, order, operation) => {
            dispatch(changeOrderAssignmentSequence(accessToken, order, operation));
        },
        handleUnAssignOrderToRefueler: (accessToken, orderId) => {
            dispatch(changeOrderUnssignment(accessToken, orderId));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllOrderAssignments)));
