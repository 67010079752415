import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {getRppPrice} from "actions/actions.js";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const RPP_Chassis = [
    {
        key: 1,
        name: "AL_ECOMET "
    },
    {
        key: 2,
        name: "EICHER_2055XP_PRO "
    },
    {
        key: 3,
        name: "EICHER_2075XP_PRO "
    },
    {
        key: 4,
        name: "EICHER_2095_PRO "
    },
    {
        key: 5,
        name: "MAHINDRA_FURIO_10_MH "
    },
    {
        key: 6,
        name: "MAHINDRA_FURIO_10_ROI "
    },
    {
        key: 7,
        name: "MAHINDRA_FURIO_11_MH "
    },
    {
        key: 8,
        name: "MAHINDRA_FURIO_11_ROI "
    },
    {
        key: 9,
        name: "MAHINDRA_FURIO_7_MH "
    },
    {
        key: 10,
        name: "MAHINDRA_FURIO_7_ROI "
    },
    {
        key: 11,
        name: "T_7_ULTRA "
    },
    {
        key: 12,
        name: "TATA_1112 "
    },
    {
        key: 13,
        name: "TATA_T_6 "
    },
    {
        key: 14,
        name: "TATA_T_11 "
    },
    {
        key: 15,
        name: "TATA_T_19_ULTRA "
    },
    {
        key: 16,
        name: "TATA_T_14_ULTRA "
    },
    {
        key: 17,
        name: "BB_1117R "
    }
];

const RPP_Make = [
    {
        key: 1,
        name: "Ashok Leyland "
    },
    {
        key: 2,
        name: "Eicher "
    },
    {
        key: 3,
        name: "Mahindra "
    },
    {
        key: 4,
        name: "Tata "
    },
    {
        key: 5,
        name: "Bharat Benz "
    }
];

const ViewPriceForm = props => {
    const {stateData, listLeads, handleDrawerClose, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [saveButton, setSaveButton] = useState(true);
    const [showTable, setShowTable] = useState(false);

    const [formData, setFormData] = useState({
        invoice_type: listLeads?.invoice_type ? listLeads?.invoice_type : "SI",
        loading_type: listLeads?.loading_type ? listLeads?.loading_type : "TL",
        rbml_type: listLeads?.rbml_type ? 1 : 0,
        chassis: listLeads?.chassis,
        rpp_model: listLeads?.rpp_model,
        abs: listLeads?.abs ? 1 : 0
    });

    const handleInputChange = event => {
        setSaveButton(false);
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            abs:
                prevData.rpp_model === "4" || prevData.rpp_model === "8"
                    ? 1
                    : prevData.abs
        }));
    }, [formData.rpp_model]);

    const rppChassisMapping = {
        "1": {
            "2": [2],
            "3": [9, 10],
            "4": [13]
        },
        "2": {
            "2": [3],
            "4": [11]
        },
        "3": {
            "1": [1],
            "2": [4],
            "3": [5, 6, 7, 8],
            "4": [12, 14],
            "5": [17]
        },
        "4": {
            "4": [16]
        },
        "5": {
            "4": [15]
        }
    };

    const handleRPPMakeChange = event => {
        const capacityValue = formData.capacity;
        const rppMakeValue = event.target.value;

        // Get the corresponding chassis options based on the selected capacity and RPP Make
        const chassisOptions =
            rppChassisMapping[capacityValue]?.[rppMakeValue] || [];

        setFormData({
            ...formData,
            rpp_make: rppMakeValue,
            chassis: "", // Reset the RPP Chassis value when RPP Make changes
            chassisOptions
        });
    };

    const getFilteredRPPMakeOptions = () => {
        const capacityValue = formData.capacity;
        return (
            Object.keys(rppChassisMapping[capacityValue] || {}).map(Number) || []
        );
    };

    const getFilteredRPPChassisOptions = () => {
        const capacityValue = formData.capacity;
        const rppMakeValue = formData.rpp_make;

        // Get the corresponding chassis keys based on the selected capacity and RPP Make
        const chassisKeys = rppChassisMapping[capacityValue]?.[rppMakeValue] || [];

        // Map the keys to the corresponding name from RPP_Chassis
        return (
            chassisKeys.map(key => ({
                key,
                name: RPP_Chassis.find(option => option.key === key)?.name
            })) || []
        );
    };

    const handleSubmit = () => {
        const {
            capacity,
            chassisOptions,
            rpp_make,
            ...formDataWithoutRppMake
        } = formData;
        dispatch(
            getRppPrice(
                secureLocalStorage.getItem("accessToken"),
                formDataWithoutRppMake,
                enqueueSnackbar,
                stateData,
                handleDrawerClose
            )
        );
        setShowTable(true);
    };

    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForProduct = ["chassis", "rpp_model"];

        return requiredFieldsForProduct.every(field => formData[field]);
    };

    /************************* ************ ****************************/

    const RPP_varient = [
        {
            key: 2,
            name: "RE_X "
        },
        {
            key: 3,
            name: "RE_Y "
        },
        {
            key: 4,
            name: "RE_Z "
        },
        {
            key: 6,
            name: "VO_X "
        },
        {
            key: 7,
            name: "VO_Y "
        },
        {
            key: 8,
            name: "VO_Z "
        },
        {
            key: 9,
            name: "PROJECT "
        }
    ];

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <TextField
                className={classes.textField}
                label={"Invoice Type"}
                margin="dense"
                fullWidth
                value={formData.invoice_type}
                onChange={handleInputChange}
                // required
                id={"invoice_type"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type}*/}
                {/* <option key={0} value={""}></option>  */}
                <option value={"SI"}>Single Invoice</option>
                <option value={"DI"}>Double Invoice</option>
            </TextField>
            <TextField
                className={classes.textField}
                label={"Loading Type"}
                margin="dense"
                fullWidth
                value={formData.loading_type}
                onChange={handleInputChange}
                // required
                id={"loading_type"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                {/* <option key={0} value={""}></option> */}
                <option value={"TL"}> Top Load</option>
                <option value={"BL"}> Bottom Load</option>
            </TextField>

            <TextField
                className={classes.textField}
                label={"RBML Type"}
                margin="dense"
                fullWidth
                value={formData.rbml_type}
                onChange={handleInputChange}
                // required
                id={"rbml_type"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                {/* <option key={0} value={""}></option> */}
                <option value={"0"}>No</option>
                <option value={"1"}>Yes</option>
            </TextField>

            <TextField
                className={classes.textField}
                label={"Capacity"}
                margin="dense"
                fullWidth
                // value={formData.rpp_rbml_type}
                onChange={event =>
                    setFormData({...formData, capacity: event.target.value})
                }
                // required
                id={"capacity"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                <option key={0} value={""}></option>
                <option value={"1"}>3 KL</option>
                <option value={"2"}>4 KL</option>
                <option value={"3"}>6 KL</option>
                <option value={"4"}>8 KL</option>
                <option value={"5"}>12 KL</option>
            </TextField>

            <TextField
                className={classes.textField}
                label={"RPP OEM"}
                margin="dense"
                fullWidth
                // value={formData.rpp_rbml_type}
                onChange={handleRPPMakeChange}
                // required
                id={"rpp_make"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option key={0} value={""}></option>
                {getFilteredRPPMakeOptions().map(value => (
                    <option key={value} value={value}>
                        {RPP_Make.find(option => option.key === value)?.name}
                    </option>
                ))}
            </TextField>

            <TextField
                className={classes.textField}
                label={"RPP Chassis"}
                margin="dense"
                fullWidth
                value={formData.chassis}
                onChange={handleInputChange}
                // required
                id={"chassis"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option key={0} value={""}></option>
                {getFilteredRPPChassisOptions().map(option => (
                    <option key={option.key} value={option.key}>
                        {option.name}
                    </option>
                ))}
            </TextField>
            {formData.rbml_type === "1" ? (
                <TextField
                    className={classes.textField}
                    label={"RPP Model"}
                    margin="dense"
                    fullWidth
                    value={formData.rpp_model}
                    onChange={handleInputChange}
                    // required
                    id={"rpp_model"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    <option key={0} value={""}></option>
                    <option key={1} value={1}>
                        RE
                    </option>
                    <option key={2} value={2}>
                        VO
                    </option>
                </TextField>
            ) : (
                <TextField
                    className={classes.textField}
                    label={"RPP Model"}
                    margin="dense"
                    fullWidth
                    value={formData.rpp_model}
                    onChange={handleInputChange}
                    // required
                    id={"rpp_model"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    <option key={0} value={""}></option>

                    {RPP_varient.map(option => (
                        <option key={option.key} value={option.key}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
            )}
            {formData.rpp_model == 4 || formData.rpp_model == 8 ? (
                <TextField
                    className={classes.textField}
                    label={"RPP abs"}
                    margin="dense"
                    fullWidth
                    value={formData.abs}
                    onChange={handleInputChange}
                    // required
                    id={"abs"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    {/* value={this.state.partner_type} */}
                    {/* <option key={0} value={""}></option> */}
                    <option value={1}>Yes</option>
                    {/* <option value={0}>No</option> */}
                </TextField>
            ) : (
                <TextField
                    className={classes.textField}
                    label={"RPP abs"}
                    margin="dense"
                    fullWidth
                    value={formData.abs}
                    onChange={handleInputChange}
                    // required
                    id={"abs"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    {/* value={this.state.partner_type} */}
                    {/* <option key={0} value={""}></option> */}
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                </TextField>
            )}
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleSubmit}
                disabled={!isFormValid() || saveButton}
            >
                View
            </Button>
            {showTable && stateData && (
                <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>{stateData?.rppPrice?.amount}</TableCell>
                                </TableRow>
                                {/* <TableRow>
              <TableCell>Tax Amount</TableCell>
              <TableCell>{stateData.rppPrice.tax_amount}</TableCell>
            </TableRow> */}
                                <TableRow>
                                    <TableCell>TCS Amount</TableCell>
                                    <TableCell>{stateData?.rppPrice?.tcs_amount}</TableCell>
                                </TableRow>
                                {/* <TableRow>
              <TableCell>Total Amount</TableCell>
              <TableCell>
                {stateData.rppPrice.amount + stateData.rppPrice.tax_amount + stateData.rppPrice.tcs_amount}
              </TableCell>
            </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleDrawerClose}
                // disabled={!isFormValid()}
            >
                Cancel
            </Button>
        </div>
    );
};
export default compose(withStyles(styles))(ViewPriceForm);
