import React, {Component} from "react";
import {Link} from "react-router-dom";

// Externals
import PropTypes from "prop-types";

// Material helpers
// Material components
import {CircularProgress, Grid, IconButton, Typography, withStyles} from "@material-ui/core";

// Material icons
import {ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon} from "@material-ui/icons";

// Shared layouts
import {Dashboard as DashboardLayout} from "layouts";

// Shared services
import {getProducts} from "services/product";

// Custom components
import {ProductCard, ProductsToolbar} from "./components";

// Component styles
import styles from "./styles";

class ProductList extends Component {
    signal = true;

    state = {
        isLoading: false,
        limit: 6,
        products: [],
        productsTotal: 0,
        error: null
    };

    async getProducts(limit) {
        try {
            this.setState({isLoading: true});

            const {products, productsTotal} = await getProducts(limit);

            if (this.signal) {
                this.setState({
                    isLoading: false,
                    products,
                    productsTotal,
                    limit
                });
            }
        } catch (error) {
            if (this.signal) {
                this.setState({
                    isLoading: false,
                    error
                });
            }
        }
    }

    componentWillMount() {
        this.signal = true;

        const {limit} = this.state;

        this.getProducts(limit);
    }

    componentWillUnmount() {
        this.signal = false;
    }

    renderProducts() {
        const {classes} = this.props;
        const {isLoading, products} = this.state;

        if (isLoading) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress/>
                </div>
            );
        }

        if (products.length === 0) {
            return (
                <Typography variant="h6">There are no products available</Typography>
            );
        }

        return (
            <Grid container spacing={3}>
                {products.map(product => (
                    <Grid item key={product.id} lg={4} md={6} xs={12}>
                        <Link to="#">
                            <ProductCard product={product}/>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <DashboardLayout title="Products">
                <div className={classes.root}>
                    <ProductsToolbar/>
                    <div className={classes.content}>{this.renderProducts()}</div>
                    <div className={classes.pagination}>
                        <Typography variant="caption">1-6 of 20</Typography>
                        <IconButton>
                            <ChevronLeftIcon/>
                        </IconButton>
                        <IconButton>
                            <ChevronRightIcon/>
                        </IconButton>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

ProductList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductList);
