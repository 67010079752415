import React, {Component} from "react";
import {Button, InputAdornment, TextField, Typography, withStyles} from "@material-ui/core";
import styles from "../CreatePumpSingleSignOn/styles";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import reposWebApi from "../../../../actions/configuration";
import qs from "qs";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {createPartner} from "../../../../actions/actions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import {values} from "underscore";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import secureLocalStorage from "react-secure-storage";

class CreatePumpSingleSignOn extends Component {
    state = {
        state: "",
        city: "",
        longitude: "",
        latitude: "",
        pumpName: "",
        address: "",
        partner_type: "",
        ownerName: "",
        monetized: 0,
        automation: 0,
        is_monetized: false,
        asd: "",
        repos_share: "",
        email: "",
        availablityStatus: 0,
        showMobileField: false,
        mobile: "",
        mobileAvailablityStatus: 0,
        showPumpDetailsForm: false,
        checked: false,
        user_exists: 0,
        dealersOf: "",
        showNewUserForm: false,
        disableFields: false,
        zoneSelected: "",
        regionSelected: "",
        zones: [],
        passwordLengthError: false,
        passwordCnfError: false,
        pan_card: "",
        gst_number: "",
        vat_tin_number: "",
        cst_tin_number: "",
        bank_account_number: "",
        ifsc: "",
        showLatitudeError: false,
        showLongitudeError: false,
        showGSTError: false,
        showOwnerNameError: false,
        buttonClick: false,
        licenseNumberError: false,
        licenseNumber: "",
        pinCode: ""
    };

    handleFieldChange = (field, value) => {
        if (field === "ownerName" && value === "") {
            this.setState({
                showOwnerNameError: true
            });
        }
        if (field === "monetized" && value === "1") {
            this.setState({
                is_monetized: true
            });
        } else if (field === "monetized" && value === "0") {
            this.setState({
                is_monetized: false
            });
        }
        if (field === "repos_share") {
            this.setState({
                [field]: value
            });
        }
        if (field === "regionSelected" && value !== "") {
            this.getRegionsAndZone(value);
        }
        if (field === "password" && value.length < 8) {
            this.setState({
                passwordLengthError: true
            });
        } else {
            this.setState({
                passwordLengthError: false
            });
        }

        if (field === "latitude" && value === "") {
            this.setState({
                showLatitudeError: true
            });
        }

        if (field === "longitude" && value === "") {
            this.setState({
                showLongitudeError: true
            });
        }

        if (field === "license_number" && value === "") {
            this.setState({
                licenseNumberError: true
            });
        } else {
            this.setState({
                licenseNumberError: false
            });
        }

        if (field === "confirmPassword" && value !== this.state.password) {
            this.setState({
                passwordCnfError: true
            });
        } else {
            this.setState({
                passwordCnfError: false
            });
        }

        this.setState({[field]: value});
    };

    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
            user_exists: 1,
            disableFields: !this.state.disableFields
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        this.createPartner(this.state);
        this.setState({
            buttonClick: true
        });
        return false;
    };

    createPartner = partnerFormData => {
        this.props.handleCreatePartner(
            secureLocalStorage.getItem("accessToken"),
            partnerFormData
        );
        return false;
    };

    viewPassword = () => {
        if (this.state.viewPass) {
            this.setState({
                viewPass: false
            });
        } else {
            this.setState({
                viewPass: true
            });
        }
    };

    viewConfirmPassword = () => {
        if (this.state.viewCnfPass) {
            this.setState({
                viewCnfPass: false
            });
        } else {
            this.setState({
                viewCnfPass: true
            });
        }
    };

    redirectOnSuccess = () => {
        this.props.pumpCreationSuccessCallback();
    };

    validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validate = (event, mail) => {
        event.preventDefault();
        const email = mail;

        const requestBody = {
            is_email: 1,
            parameter: this.state.email,
            partner_check: 1
        };

        if (this.validateEmail(email)) {
            reposWebApi
                .post("/user/check/email/mobile/available", qs.stringify(requestBody), {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            availablityStatus: response.status,
                            showMobileField: true,
                            showPumpDetailsForm: false
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status === 406) {
                        this.setState({
                            availablityStatus: error.response.status,
                            showPumpDetailsForm: true,
                            showMobileField: false
                        });
                    }
                    if (error.response.status === 503) {
                        this.setState({
                            availablityStatus: error.response.status,
                            showPumpDetailsForm: false,
                            showMobileField: false
                        });
                    }
                });
            return true;
        } else {
            this.setState({
                showMobileField: false
            });
            alert("Please enter valid e-mail");
        }
        return false;
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const requestBody = {
            is_email: 1,
            parameter: nextState.email,
            partner_check: 1
        };

        if (this.state.showMobileField) {
            requestBody.is_mobile = 1;
            requestBody.is_email = 0;
            requestBody.parameter = nextState.mobile;
            requestBody.partner_check = 1;
            if (this.state.mobile !== nextState.mobile && nextState.mobile !== "") {
                if (nextState.mobile.length > 10) {
                    alert("Please enter a Valid 10 Digit mobile Number");
                } else {
                    if (nextState.mobile.length === 10) {
                        reposWebApi
                            .post(
                                "/user/check/email/mobile/available",
                                qs.stringify(requestBody),
                                {
                                    headers: {
                                        Authorization:
                                            "Bearer " + secureLocalStorage.getItem("accessToken")
                                    }
                                }
                            )
                            .then(res => {
                                if (res.status === 200) {
                                    this.setState({
                                        mobileAvailablityStatus: res.status,
                                        showPumpDetailsForm: false
                                    });
                                }
                            })
                            .catch(error => {
                                if (error.response.status === 406) {
                                    this.setState({
                                        mobileAvailablityStatus: error.response.status,
                                        showPumpDetailsForm: true
                                    });
                                }
                                if (error.response.status === 503) {
                                    this.setState({
                                        mobileAvailablityStatus: error.response.status,
                                        showPumpDetailsForm: false
                                    });
                                }
                            });
                    }
                }
            }
        }
    }

    getRegionsAndZone = region => {
        const requestBody = {
            region
        };
        reposWebApi
            .post("/executives/get_region_zone", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                this.setState({
                    zones: response.data
                });
            });
    };

    render() {
        const {classes} = this.props;
        const monetized = [
            {
                key: 0,
                name: "Non-monetized "
            },
            {
                key: 1,
                name: "Monetized "
            }
        ];

        const automation = [
            {
                key: 0,
                name: "No "
            },
            {
                key: 1,
                name: "Yes"
            }
        ];

        const partner_type = [
            {
                key: "PAF",
                name: "Partner as fuelent"
            },
            {
                key: "PAR",
                name: "Partner as RO"
            },
            {
                key: "SAR",
                name: "Supplier as RO"
            },
            {
                key: "SAD",
                name: "Supplier as DEPO"
            }
        ];

        const dealers_of = [
            {
                value: "HPCL",
                label: "Hindustan Petroleum Corporation Limited"
            },
            {
                value: "BPCL",
                label: "Bharat Petroleum Corporation Limited"
            },
            {
                value: "IOCL",
                label: "Indian Oil Corporation Limited"
            },
            {
                value: "SHELL",
                label: "Royal Dutch SHELL Oil and Gas Company "
            },
            {
                value: "ESSAR",
                label: "Essar Oil Nayara Energy"
            },
            {
                value: "REL",
                label: "Reliance Petroleum"
            },
            {
                value: "OTHER",
                label: "Others"
            }
        ];

        const states = [
            {
                key: "AN",
                name: "Andaman and Nicobar Islands"
            },
            {
                key: "AP",
                name: "Andhra Pradesh"
            },
            {
                key: "AR",
                name: "Arunachal Pradesh"
            },
            {
                key: "AS",
                name: "Assam"
            },
            {
                key: "BR",
                name: "Bihar"
            },
            {
                key: "CG",
                name: "Chandigarh"
            },
            {
                key: "CH",
                name: "Chhattisgarh"
            },
            {
                key: "DN",
                name: "Dadra and Nagar Haveli"
            },
            {
                key: "DD",
                name: "Daman and Diu"
            },
            {
                key: "DL",
                name: "Delhi"
            },
            {
                key: "GA",
                name: "Goa"
            },
            {
                key: "GJ",
                name: "Gujarat"
            },
            {
                key: "HR",
                name: "Haryana"
            },
            {
                key: "HP",
                name: "Himachal Pradesh"
            },
            {
                key: "JK",
                name: "Jammu and Kashmir"
            },
            {
                key: "JH",
                name: "Jharkhand"
            },
            {
                key: "KA",
                name: "Karnataka"
            },
            {
                key: "KL",
                name: "Kerala"
            },
            {
                key: "LD",
                name: "Lakshadweep"
            },
            {
                key: "MP",
                name: "Madhya Pradesh"
            },
            {
                key: "MH",
                name: "Maharashtra"
            },
            {
                key: "MN",
                name: "Manipur"
            },
            {
                key: "ML",
                name: "Meghalaya"
            },
            {
                key: "MZ",
                name: "Mizoram"
            },
            {
                key: "NL",
                name: "Nagaland"
            },
            {
                key: "OD",
                name: "Odisha"
            },
            {
                key: "PY",
                name: "Puducherry"
            },
            {
                key: "PB",
                name: "Punjab"
            },
            {
                key: "RJ",
                name: "Rajasthan"
            },
            {
                key: "SK",
                name: "Sikkim"
            },
            {
                key: "TN",
                name: "Tamil Nadu"
            },
            {
                key: "TS",
                name: "Telangana"
            },
            {
                key: "TR",
                name: "Tripura"
            },
            {
                key: "UP",
                name: "Uttar Pradesh"
            },
            {
                key: "UK",
                name: "Uttarakhand"
            },
            {
                key: "WB",
                name: "West Bengal"
            }
        ];

        const region = ["NORTH", "EAST", "WEST", "SOUTH"];

        return (
            <div>
                <div className={classes.field}>
                    <Typography
                        variant="subtitle2"
                        style={{marginTop: "2%", color: "#808080"}}
                    >
                        Basic Information
                    </Typography>
                    <TextField
                        className={classes.textField}
                        label="Email"
                        margin="dense"
                        type="email"
                        id={"email"}
                        value={this.state.email}
                        disabled={this.state.disableFields}
                        style={{width: "95%"}}
                        onChange={event =>
                            this.handleFieldChange("email", event.target.value)
                        }
                        variant="outlined"
                    />
                    <Typography
                        variant="caption"
                        style={{
                            color:
                                this.state.availablityStatus === 406
                                    ? "#ff781f"
                                    : this.state.availablityStatus === 200
                                        ? "#008000"
                                        : this.state.availablityStatus === 503
                                            ? "#ff781f"
                                            : ""
                        }}
                    >
                        {this.state.availablityStatus === 406 && this.state.email !== ""
                            ? "User Already Registered"
                            : this.state.availablityStatus === 503 && this.state.email !== ""
                                ? "A Partner already exists with this email."
                                : ""}
                    </Typography>
                    <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        style={{
                            float: "right",
                            position: "relative",
                            bottom: 45
                        }}
                        onClick={event => this.validate(event, this.state.email)}
                    >
                        <AddIcon/>
                    </Fab>
                    {this.state.showMobileField && (
                        <div>
                            <TextField
                                className={classes.textField}
                                label="Mobile"
                                margin="dense"
                                type="number"
                                id={"mobile"}
                                value={this.state.mobile}
                                disabled={this.state.disableFields}
                                fullWidth
                                maxLength={12}
                                onInput={e => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 10);
                                }}
                                onChange={event =>
                                    this.handleFieldChange("mobile", event.target.value)
                                }
                                variant="outlined"
                            />
                            <Typography
                                variant="caption"
                                style={{
                                    color:
                                        this.state.mobileAvailablityStatus === 406
                                            ? "#ff781f"
                                            : this.state.mobileAvailablityStatus === 200
                                                ? "#008000"
                                                : this.state.mobileAvailablityStatus === 503
                                                    ? "#ff781f"
                                                    : ""
                                }}
                            >
                                {this.state.mobileAvailablityStatus === 406 &&
                                this.state.email !== ""
                                    ? "Mobile Already assigned to an account."
                                    : this.state.mobileAvailablityStatus === 200 &&
                                    this.state.email !== ""
                                        ? "Mobile Available"
                                        : this.state.mobileAvailablityStatus === 503 &&
                                        this.state.email !== ""
                                            ? "A Partner already exists with this number"
                                            : ""}
                            </Typography>
                        </div>
                    )}
                    {this.state.showPumpDetailsForm && (
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.checked}
                                        onChange={this.handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Do you want to Grant partner access to this user?"
                            />
                            <Typography
                                variant="subtitle2"
                                style={{marginTop: "2%", color: "#808080"}}
                            >
                                Pump Information
                            </Typography>
                            <TextField
                                className={classes.textField}
                                label="Pump name"
                                margin="dense"
                                required
                                value={this.state.pumpName}
                                id={"pump_name"}
                                fullWidth
                                onChange={event =>
                                    this.handleFieldChange("pumpName", event.target.value)
                                }
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="License Number"
                                onChange={event =>
                                    this.handleFieldChange("licenseNumber", event.target.value)
                                }
                                margin="dense"
                                id={"license_number"}
                                fullWidth
                                value={this.state.licenseNumber}
                                variant="outlined"
                            />
                            {this.state.licenseNumberError && <p>Enter License Number</p>}
                            <TextField
                                className={classes.textField}
                                label={"Dealers of"}
                                id={"select_dealers_of"}
                                margin="dense"
                                onChange={event =>
                                    this.handleFieldChange("dealersOf", event.target.value)
                                }
                                required
                                fullWidth
                                select
                                SelectProps={{native: true}}
                                variant="outlined"
                            >
                                value={this.state.dealersOf}
                                <option key={0} value={""}></option>
                                {dealers_of.map(option => (
                                    <option
                                        key={option.value}
                                        id={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>

                            <TextField
                                className={classes.textField}
                                label="Pan Number"
                                inputProps={{
                                    maxlength: 10
                                }}
                                onChange={event =>
                                    this.handleFieldChange("pan_card", event.target.value)
                                }
                                margin="dense"
                                id={"pan_card"}
                                fullWidth
                                value={this.state.pan_card}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="GST Number"
                                onChange={event =>
                                    this.handleFieldChange("gst_number", event.target.value)
                                }
                                margin="dense"
                                id={"gst_number"}
                                fullWidth
                                value={this.state.gst_number}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="VAT TIN Number"
                                onChange={event =>
                                    this.handleFieldChange("vat_tin_number", event.target.value)
                                }
                                margin="dense"
                                id={"vat_tin_number"}
                                fullWidth
                                value={this.state.vat_tin_number}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="CST TIN Number"
                                onChange={event =>
                                    this.handleFieldChange("cst_tin_number", event.target.value)
                                }
                                margin="dense"
                                id={"cst_tin_number"}
                                fullWidth
                                value={this.state.cst_tin_number}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="Bank Account Number"
                                onChange={event =>
                                    this.handleFieldChange(
                                        "bank_account_number",
                                        event.target.value
                                    )
                                }
                                margin="dense"
                                id={"bank_account_number"}
                                fullWidth
                                value={this.state.bank_acc_no}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="IFSC"
                                inputProps={{
                                    maxlength: 20
                                }}
                                onChange={event =>
                                    this.handleFieldChange("ifsc", event.target.value)
                                }
                                margin="dense"
                                id={"ifsc"}
                                fullWidth
                                value={this.state.ifsc}
                                variant="outlined"
                            />

                            <Typography
                                variant="subtitle2"
                                style={{marginTop: "2%", color: "#808080"}}
                            >
                                Address Information
                            </Typography>
                            <TextField
                                className={classes.textField}
                                inputProps={{
                                    maxlength: 80
                                }}
                                label="Address"
                                margin="dense"
                                type="text"
                                id={"address"}
                                fullWidth
                                value={this.state.address}
                                onChange={event =>
                                    this.handleFieldChange("address", event.target.value)
                                }
                                variant="outlined"
                            />

                            <TextField
                                type={"number"}
                                className={classes.textField}
                                label={"Pin Code"}
                                maxLength={6}
                                onInput={e => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 6);
                                }}
                                onChange={event =>
                                    this.handleFieldChange("pinCode", event.target.value)
                                }
                                margin="dense"
                                id={"pinCode"}
                                fullWidth
                                required
                                value={values.pinCode}
                                variant="outlined"
                            />

                            <Typography
                                variant="caption"
                                style={{
                                    color: this.state.pinCode === "" ? "#ff781f" : ""
                                }}
                            >
                                {this.state.pinCode === "" && this.state.buttonClick
                                    ? "This field is required"
                                    : ""}
                            </Typography>

                            <TextField
                                className={classes.textField}
                                label={"Select Region"}
                                id={"select_region"}
                                margin="dense"
                                onChange={event =>
                                    this.handleFieldChange("regionSelected", event.target.value)
                                }
                                required
                                fullWidth
                                select
                                SelectProps={{native: true}}
                                variant="outlined"
                            >
                                value={this.state.regionSelected}
                                <option key={0} value={""}></option>
                                {region.map(option => (
                                    <option key={option} id={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>

                            <TextField
                                className={classes.textField}
                                label={"Select Zone"}
                                id={"select_zone"}
                                margin="dense"
                                onChange={event =>
                                    this.handleFieldChange("zoneSelected", event.target.value)
                                }
                                required
                                fullWidth
                                select
                                SelectProps={{native: true}}
                                variant="outlined"
                            >
                                value={this.state.zoneSelected}
                                <option key={0} value={""}></option>
                                {this.state.zones.map(option => (
                                    <option key={option} id={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                className={classes.textField}
                                label="City"
                                onChange={event =>
                                    this.handleFieldChange("city", event.target.value)
                                }
                                margin="dense"
                                id={"city"}
                                fullWidth
                                required
                                value={this.state.city}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                label="Latitude"
                                margin="dense"
                                type="number"
                                fullWidth
                                required
                                id={"latitude"}
                                value={this.state.latitude}
                                onChange={event =>
                                    this.handleFieldChange("latitude", event.target.value)
                                }
                                variant="outlined"
                            />
                            <div>
                                {this.state.showLatitudeError && (
                                    <Typography className={classes.fieldError} variant="body2">
                                        {"Latitude is required"}
                                    </Typography>
                                )}
                            </div>
                            <TextField
                                className={classes.textField}
                                label="Longitude"
                                margin="dense"
                                type="number"
                                id={"longitude"}
                                required
                                fullWidth
                                value={this.state.longitude}
                                onChange={event =>
                                    this.handleFieldChange("longitude", event.target.value)
                                }
                                variant="outlined"
                            />
                            <div>
                                <Typography className={classes.fieldError} variant="body2">
                                    {"Latitude is required"}
                                </Typography>
                            </div>
                            <TextField
                                className={classes.textField}
                                label={"State"}
                                margin="dense"
                                fullWidth
                                onChange={event =>
                                    this.handleFieldChange("state", event.target.value)
                                }
                                id={"select_state"}
                                select
                                SelectProps={{native: true}}
                                variant="outlined"
                            >
                                value={this.state.state}
                                <option key={0} value={""}></option>
                                {states.map(option => (
                                    <option key={option.key} value={option.key}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                            {this.props.pumpCreationIsLoading ? (
                                <CircularProgress className={classes.progress}/>
                            ) : this.props.pumpCreationSuccess ? (
                                <Button
                                    className={classes.addNewPartnerButton}
                                    color="primary"
                                    name="create_partner_submit"
                                    id="create_partner_submit"
                                    onClick={e => this.redirectOnSuccess(e)}
                                    size="large"
                                    variant="contained"
                                >
                                    GO TO ALL PUMPS
                                </Button>
                            ) : (
                                <Button
                                    className={classes.addNewPartnerButton}
                                    color="primary"
                                    name="create_partner_submit"
                                    id="create_partner_submit"
                                    onClick={e => this.handleSubmit(e)}
                                    size="large"
                                    variant="contained"
                                >
                                    Add New Partner
                                </Button>
                            )}
                            {this.props.pumpCreationSuccess ? (
                                <Typography className={classes.fieldPassed} variant="body2">
                                    {this.props.pumpCreationSuccessMessage}
                                </Typography>
                            ) : (
                                ""
                            )}
                            {this.props.pumpCreationError ? (
                                this.props.pumpCreationSuccess ? (
                                    ""
                                ) : (
                                    <Typography className={classes.fieldError} variant="body2">
                                        Email or Mobile already Exists! please choose another
                                        username.
                                    </Typography>
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                    {this.state.mobileAvailablityStatus === 200 &&
                        this.state.mobileAvailablityStatus === 200 && (
                            <div>
                                <TextField
                                    className={classes.textField}
                                    label="Owner name"
                                    margin="dense"
                                    required={"true"}
                                    value={this.state.ownerName}
                                    id={"owner_name"}
                                    fullWidth
                                    onChange={event =>
                                        this.handleFieldChange("ownerName", event.target.value)
                                    }
                                    variant="outlined"
                                />
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: this.state.ownerName === "" ? "#ff781f" : ""
                                    }}
                                >
                                    {this.state.ownerName === "" && this.state.buttonClick
                                        ? "This field is required"
                                        : ""}
                                </Typography>
                                <div>
                                    <FormControl
                                        className={classes.textField}
                                        fullWidth
                                        style={{margin: "10px 0 5px"}}
                                        size={"small"}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password">
                                            Password
                                        </InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            type={this.state.viewPass ? "text" : "password"}
                                            value={values.password}
                                            onChange={event =>
                                                this.handleFieldChange("password", event.target.value)
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.viewPassword}
                                                        edge="end"
                                                    >
                                                        {this.state.viewPass ? (
                                                            <Visibility/>
                                                        ) : (
                                                            <VisibilityOff/>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                </div>
                                {this.state.passwordLengthError ? (
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "red"}}
                                    >
                                        Password Length is less than 8, it should be 8 characters or
                                        more
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: this.state.password === "" ? "#ff781f" : ""
                                    }}
                                >
                                    {this.state.password === "" && this.state.buttonClick
                                        ? "This field is required"
                                        : ""}
                                </Typography>

                                <FormControl
                                    className={classes.textField}
                                    fullWidth
                                    style={{margin: "10px 0 5px"}}
                                    size={"small"}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        Confirm Password
                                    </InputLabel>
                                    <OutlinedInput
                                        label={"Confirm Password"}
                                        id={"confirm_password"}
                                        type={this.state.viewCnfPass ? "text" : "password"}
                                        value={this.state.confirmPassword}
                                        onChange={event =>
                                            this.handleFieldChange(
                                                "confirmPassword",
                                                event.target.value
                                            )
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.viewConfirmPassword}
                                                    edge="end"
                                                >
                                                    {this.state.viewCnfPass ? (
                                                        <Visibility/>
                                                    ) : (
                                                        <VisibilityOff/>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                                {this.state.passwordCnfError ? (
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "red"}}
                                    >
                                        Passwords Do not match
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: this.state.confirmPassword === "" ? "#ff781f" : ""
                                    }}
                                >
                                    {this.state.confirmPassword === "" && this.state.buttonClick
                                        ? "This field is required"
                                        : ""}
                                </Typography>
                                <div>
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "#808080"}}
                                    >
                                        Pump Details
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label="Pump name"
                                        margin="dense"
                                        required
                                        value={this.state.pumpName}
                                        id={"pump_name"}
                                        fullWidth
                                        onChange={event =>
                                            this.handleFieldChange("pumpName", event.target.value)
                                        }
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.pumpName === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.pumpName === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label={"Dealers of"}
                                        id={"select_dealers_of"}
                                        margin="dense"
                                        onChange={event =>
                                            this.handleFieldChange("dealersOf", event.target.value)
                                        }
                                        required
                                        fullWidth
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.state}
                                        <option key={0} value={""}></option>
                                        {dealers_of.map(option => (
                                            <option
                                                key={option.value}
                                                id={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.dealersOf === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.dealersOf === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label="License Number"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                "licenseNumber",
                                                event.target.value
                                            )
                                        }
                                        margin="dense"
                                        id={"license_number"}
                                        fullWidth
                                        value={this.state.licenseNumber}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label="Pan Number"
                                        inputProps={{
                                            maxlength: 10
                                        }}
                                        onChange={event =>
                                            this.handleFieldChange("pan_card", event.target.value)
                                        }
                                        margin="dense"
                                        id={"pan_card"}
                                        required
                                        fullWidth
                                        value={this.state.pan_card}
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.pan_card === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.pan_card === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label="GST Number"
                                        onChange={event =>
                                            this.handleFieldChange("gst_number", event.target.value)
                                        }
                                        required
                                        margin="dense"
                                        id={"gst_number"}
                                        fullWidth
                                        value={this.state.gst_number}
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.gst_number === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.gst_number === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label="VAT TIN Number"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                "vat_tin_number",
                                                event.target.value
                                            )
                                        }
                                        margin="dense"
                                        id={"vat_tin_number"}
                                        fullWidth
                                        value={this.state.vat_tin_number}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label="CST TIN Number"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                "cst_tin_number",
                                                event.target.value
                                            )
                                        }
                                        margin="dense"
                                        id={"cst_tin_number"}
                                        fullWidth
                                        value={this.state.cst_tin_number}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label="Bank Account Number"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                "bank_account_number",
                                                event.target.value
                                            )
                                        }
                                        margin="dense"
                                        id={"bank_account_number"}
                                        fullWidth
                                        value={this.state.bank_acc_no}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label="IFSC"
                                        onChange={event =>
                                            this.handleFieldChange("ifsc", event.target.value)
                                        }
                                        margin="dense"
                                        id={"ifsc"}
                                        fullWidth
                                        value={this.state.ifsc}
                                        variant="outlined"
                                    />

                                    <TextField
                                        className={classes.textField}
                                        label={"Partner Type"}
                                        margin="dense"
                                        fullWidth
                                        onChange={event =>
                                            this.handleFieldChange("partner_type", event.target.value)
                                        }
                                        // required
                                        id={"select_partner_type"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.partner_type}
                                        <option key={0} value={""}></option>
                                        {partner_type.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.partner_type === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.partner_type === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                </div>

                                <div>
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "#808080"}}
                                    >
                                        Address
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label="Address"
                                        margin="dense"
                                        type="text"
                                        id={"address"}
                                        inputProps={{
                                            maxlength: 80
                                        }}
                                        fullWidth
                                        value={this.state.address}
                                        onChange={event =>
                                            this.handleFieldChange("address", event.target.value)
                                        }
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.address === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.address === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>

                                    <TextField
                                        type={"number"}
                                        className={classes.textField}
                                        label={"Pin Code"}
                                        maxLength={6}
                                        onInput={e => {
                                            e.target.value = Math.max(0, parseInt(e.target.value))
                                                .toString()
                                                .slice(0, 6);
                                        }}
                                        onChange={event =>
                                            this.handleFieldChange("pinCode", event.target.value)
                                        }
                                        margin="dense"
                                        id={"pinCode"}
                                        fullWidth
                                        required
                                        value={values.pinCode}
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.city === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.pinCode === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>

                                    <TextField
                                        className={classes.textField}
                                        label={"Select Region"}
                                        id={"select_region"}
                                        margin="dense"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                "regionSelected",
                                                event.target.value
                                            )
                                        }
                                        required
                                        fullWidth
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.regionSelected}
                                        <option key={0} value={""}></option>
                                        {region.map(option => (
                                            <option key={option} id={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.regionSelected === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.regionSelected === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>

                                    <TextField
                                        className={classes.textField}
                                        label={"Select Zone"}
                                        id={"select_zone"}
                                        margin="dense"
                                        onChange={event =>
                                            this.handleFieldChange("zoneSelected", event.target.value)
                                        }
                                        required
                                        fullWidth
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.zoneSelected}
                                        <option key={0} value={""}></option>
                                        {this.state.zones.map(option => (
                                            <option key={option} id={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.zoneSelected === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.zoneSelected === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>

                                    <TextField
                                        className={classes.textField}
                                        label="Latitude"
                                        margin="dense"
                                        type="number"
                                        fullWidth
                                        id={"latitude"}
                                        value={this.state.latitude}
                                        onChange={event =>
                                            this.handleFieldChange("latitude", event.target.value)
                                        }
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.latitude === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.latitude === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>

                                    <TextField
                                        className={classes.textField}
                                        label="Longitude"
                                        margin="dense"
                                        type="number"
                                        id={"longitude"}
                                        fullWidth
                                        value={this.state.longitude}
                                        onChange={event =>
                                            this.handleFieldChange("longitude", event.target.value)
                                        }
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.longitude === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.longitude === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        inputProps={{
                                            maxlength: 15
                                        }}
                                        label="City"
                                        onChange={event =>
                                            this.handleFieldChange("city", event.target.value)
                                        }
                                        margin="dense"
                                        id={"city"}
                                        fullWidth
                                        required
                                        value={this.state.city}
                                        variant="outlined"
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.city === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.city === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>

                                    {/*<div>*/}
                                    {/*    {showPinCodeError && (*/}
                                    {/*        <Typography*/}
                                    {/*            className={classes.fieldError}*/}
                                    {/*            variant="body2"*/}
                                    {/*        >*/}
                                    {/*            {errors.pinCode[0]}*/}
                                    {/*        </Typography>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}

                                    <TextField
                                        className={classes.textField}
                                        label={"State"}
                                        margin="dense"
                                        fullWidth
                                        onChange={event =>
                                            this.handleFieldChange("state", event.target.value)
                                        }
                                        // required
                                        id={"select_state"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.state}
                                        <option key={0} value={""}></option>
                                        {states.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: this.state.state === "" ? "#ff781f" : ""
                                        }}
                                    >
                                        {this.state.state === "" && this.state.buttonClick
                                            ? "This field is required"
                                            : ""}
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label={"Automation "}
                                        margin="dense"
                                        fullWidth
                                        required
                                        onChange={event =>
                                            this.handleFieldChange("automation", event.target.value)
                                        }
                                        // required
                                        id={"automation"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.automation}
                                        {automation.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                    <TextField
                                        className={classes.textField}
                                        label={"Partner Monetization"}
                                        margin="dense"
                                        fullWidth
                                        required
                                        onChange={event =>
                                            this.handleFieldChange("monetized", event.target.value)
                                        }
                                        // required
                                        id={"is_monetized"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        value={this.state.monetized}
                                        {monetized.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                    {this.state.is_monetized ? (
                                        <div>
                                            <FormControl
                                                className={classes.textField}
                                                required
                                                fullWidth
                                                style={{margin: "10px 0 5px"}}
                                                size={"small"}
                                                variant="outlined"
                                            >
                                                <InputLabel htmlFor="outlined-adornment-password">
                                                    Repos share
                                                </InputLabel>
                                                <OutlinedInput
                                                    label={"Repos share"}
                                                    id={"repos_share"}
                                                    onChange={event =>
                                                        this.handleFieldChange(
                                                            "repos_share",
                                                            (event.target.value = Math.max(
                                                                0,
                                                                parseFloat(event.target.value)
                                                            )
                                                                .toString()
                                                                .slice(0, 6))
                                                        )
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">%</InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                    maxLength={6}
                                                    type={"number"}
                                                />
                                            </FormControl>
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    color: this.state.repos_share === "" ? "#ff781f" : ""
                                                }}
                                            >
                                                {this.state.repos_share === "" &&
                                                this.state.buttonClick &&
                                                this.state.is_monetized
                                                    ? "This field is required"
                                                    : ""}
                                            </Typography>

                                            <TextField
                                                className={classes.textField}
                                                id="date"
                                                label="Agreement Start Date"
                                                type="date"
                                                onChange={event =>
                                                    this.handleFieldChange("asd", event.target.value)
                                                }
                                                margin="dense"
                                                defaultValue="yyyy-mm-dd"
                                                required
                                                fullWidth
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {this.props.pumpCreationIsLoading ? (
                                        <CircularProgress className={classes.progress}/>
                                    ) : this.props.pumpCreationSuccess ? (
                                        <Button
                                            className={classes.addNewPartnerButton}
                                            color="primary"
                                            name="create_partner_submit"
                                            id="create_partner_submit"
                                            // disabled={!this.state.isValid}
                                            onClick={e => this.redirectOnSuccess(e)}
                                            size="large"
                                            variant="contained"
                                        >
                                            GO TO ALL PUMPS
                                        </Button>
                                    ) : (
                                        <Button
                                            className={classes.addNewPartnerButton}
                                            color="primary"
                                            name="create_partner_submit"
                                            id="create_partner_submit"
                                            onClick={e => this.handleSubmit(e)}
                                            size="large"
                                            variant="contained"
                                        >
                                            Add New Partner
                                        </Button>
                                    )}
                                    {this.props.pumpCreationSuccess ? (
                                        <Typography className={classes.fieldPassed} variant="body2">
                                            {this.props.pumpCreationSuccessMessage}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

CreatePumpSingleSignOn.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingPartner: state.isLoadingPartner,
        pumpCreationIsLoading: state.pumpCreationisLoading,
        pumpCreationError: state.pumpCreationError,
        pumpCreationSuccess: state.pumpCreationSuccess,
        pumpCreationSuccessMessage: state.pumpCreationSuccessMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCreatePartner: (accessToken, partnerForm) => {
            dispatch(createPartner(accessToken, partnerForm));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(CreatePumpSingleSignOn));
