import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SwipeableDrawer,
    Typography,
    withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SearchInput} from "components";
import styles from "../../../AllAssets/style";
import compose from "recompose/compose";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import AddLeadForm from "../AddLeadForm";
import ViewPrice from "../ViewPrice";
import secureLocalStorage from "react-secure-storage";
import {downloadReport, exportReport, importFile, openImportFile} from "actions/actions";
import {useDispatch} from "react-redux";
import UploadIcon from "@mui/icons-material/Upload";
import Dialog from "@material-ui/core/Dialog";
import {useSnackbar} from "notistack";
import DialogActions from "@material-ui/core/DialogActions";
import {cummins_dealer, LeadSourceChoice, leadStage, leadStatus, product, state} from "../../../../constants/constants";
import {Accordion, AccordionDetails, AccordionSummary, Box} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DialogDateRange from "../../../../components/DialogSmall/DialogDateRange";


const useStyles = makeStyles(theme => ({
    root: {
        padding: "24px"
    },
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme?.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    deleteButton: {
        color: theme.palette.danger.main,
        marginRight: theme?.spacing(1)
    },
    importButton: {
        marginRight: theme?.spacing(1)
    },
    importIcon: {
        marginRight: theme?.spacing(1)
    },
    exportButton: {
        marginRight: theme?.spacing(1)
    },
    exportIcon: {
        marginRight: theme?.spacing(1)
    },
    searchInput: {
        marginRight: theme?.spacing(1)
    },
    nameText: {
        marginTop: theme?.spacing(2),
        marginBottom: theme?.spacing(1),
        color: "gray"
    },
    drawerWidth: {
        zIndex: 1200,
        width: "340px"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "1.5%"
    },
    hoverBox: {
        borderRadius: "20px",
        boxShadow: "0 0 11px rgba(33,33,33,.2)",
        transform: "scale(1.01)",
        transition:
            ".3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)",
        "&:hover": {
            boxShadow: "inset 0 0 5px rgba(33,33,33,.2)",
            transform: "scale(1.0)"
        }
    }
}));

/**
 * Renders the UserToolbar component.
 *
 * @param {Object} props - The props object containing stateData, onSearchTerm, onStateChange, onProductChange, onLeadStatusChange, onLeadStageChange, onCrmUserChange, onCreatedOnDate, onInteractedOnDate, onCumminsDealer, onAssignTo, className, and rest.
 * @return {JSX.Element} The rendered UserToolbar component.
 *
 * This is a React functional component called UserToolbar. It takes in a set of props and uses them to render a toolbar with various input fields and buttons.
 * The component uses the useState and useEffect hooks to manage its own state. It also uses the useDispatch hook from the react-redux library to dispatch actions to update the Redux store.
 * The component has several event handlers for different user interactions, such as selecting a date, changing a dropdown value, or clicking a button. These handlers update the component's state and call the corresponding props functions.
 * The component also renders a set of cards displaying various counts and statistics.
 */
const UserToolbar = props => {
    const {
        stateData,
        onSearchTerm,
        onStateChange,
        onProductChange,
        onLeadStatusChange,
        onLeadStageChange,
        onCrmUserChange,
        onCreatedOnDate,
        onInteractedOnDate,
        onCumminsDealer,
        fliters,
        setKeys,
        param,
        clearFilter,
        keys,
        openDate,
        className,
        ...rest
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [drawerIsOpen, setdrawerIsOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openCRMdata, setOpenCRMdata] = useState(false);
    const [file, setFile] = useState("");
    const {enqueueSnackbar} = useSnackbar();
    const [selectDate, setSelectDate] = useState(false);
    const [isDateRange, setDateRange] = useState(false);

    const handleExportReport = value => {
        dispatch(downloadReport(enqueueSnackbar, value))
        setDateRange(false);
    };

    const handleOpenDate = () => {
        // setSelectDate(true);
        // setDateRange(true)
        dispatch(exportReport(enqueueSnackbar, param))
    }

    /**
     * Handles the file change event.
     *
     * @param {Event} event - The file change event.
     * @return {void}
     */
    const handleFileChange = event => {
        setFile(event);
    };

    /**
     * Handles the opening of the CRM data upload dialog.
     *
     * @return {void}
     */
    const handleOpenUpload = () => {
        setOpenCRMdata(true);
    };

    /**
     * Handles the file upload process.
     *
     * @return {void}
     */
    const handleFileUpload = () => {
        if (!file) {
            enqueueSnackbar("Please select a file.", {variant: "warning"});
            return;
        }
        dispatch(openImportFile());
        dispatch(
            importFile(
                secureLocalStorage.getItem("accessToken"),
                file,
                enqueueSnackbar
            )
        );
    };

    /**
     * Fetches CRM users by dispatching a getLeadOwner action with the access token and sets the isLoading state.
     *
     * @return {void} This function does not return anything.
     */
    // const fetchCrmUsers = () => {
    // dispatch(
    //     getLeadOwner(
    //         secureLocalStorage.getItem("accessToken"),
    //     )
    // );
    // };

    // /**
    //  * Handles the click event for the CRM user.
    //  * This function calls the `fetchCrmUsers` function to fetch the CRM u
    //  * @return {void} This function does not return a value.
    //  */
    // const handleCrmUserClick = () => {
    //     fetchCrmUsers();
    // };

    // useEffect(() => {
    //     fetchCrmUsers();
    // }, []);

    useEffect(() => {
        setOpenCRMdata(false);
        setFile("");
    }, [stateData?.userCreationSuccess]);

    /**
     * Opens the drawer by setting the state variable `drawerIsOpen` to `true`.
     *
     * @return {void} This function does not return a value.
     */
    const handleDrawerOpen = () => {
        setdrawerIsOpen(true);
    };

    /**
     * Closes the drawer by setting the state variable `drawerIsOpen` to `false`.
     *
     * @return {void} This function does not return a value.
     */
    const handleDrawerClose = () => {
        setdrawerIsOpen(false);
    };

    /**
     * Opens the drawer by setting the state variable `drawerOpen` to `true`.
     *
     * @return {void} This function does not return a value.
     */
    const handleOpen = () => {
        setDrawerOpen(true);
    };

    /**
     * Closes the drawer and resets the state variables.
     *
     * @return {void}
     */
    const handleClose = () => {
        setSelectDate(false);
        setDrawerOpen(false);
        setOpenCRMdata(false);
        setFile("");
        setDateRange(false);
    };

    /**
     * Handles the date change based on the name provided.
     *
     * @param {string} name - The name used to determine the date change behavior.
     * @return {void}
     */
    const handleDateChange = name => {
        switch (name) {
            case "Created":
                setKeys(values => ({
                    ...values,
                    start_date: "c_start_date",
                    end_date: "c_end_date"
                }));
                openDate(true);
                break;
            case "Interacted":
                setKeys(values => ({
                    ...values,
                    start_date: "i_start_date",
                    end_date: "i_end_date"
                }));
                openDate(true);
                break;
            default:
                return;
        }
    };

    /**
     * Handles the change event of the Interacted On date input.
     *
     * @param {Event} event - The change event object.
     * @return {void} This function does not return anything.
     */
        // const handleInteractedOnDateChange = (event) => {
        //     const selectedDate = event.target.value;
        //     setInteractedOnDate(selectedDate);
        //     onInteractedOnDate(selectedDate);
        //     setIsIntractedOnSelected(!!selectedDate); // Set Interacted On as selected
        //     setIsCreatedOnSelected(false); // Reset Created On selection
        // };

        // const handleSelectField = [
        //         {
        //             name: "Search By State Name",
        //             keyName: "state",
        //             value: state
        //         },
        //         {
        //             name: "Search By Product Name",
        //             keyName: "product",
        //             value: product
        //         },
        //         {
        //             name: " Search By Lead Status",
        //             keyName: "lead_status",
        //             value: leadStatus
        //         },
        //         {
        //             name: "Search By Pre & Post Stage",
        //             keyName: "stage",
        //             value: leadStage
        //         },
        //         {
        //             name: "Search By CRM Users",
        //             keyName: "name",
        //             value: stateData?.leadOwner
        //         },
        //         {
        //             name: "Search By Cummins Dealer",
        //             keyName: "cummins_dealer",
        //             value: cummins_dealer
        //         },
        //         {
        //             name: "Search By Lead Assign To",
        //             keyName: "lead_owner_name",
        //             value: stateData?.leadOwner
        //         },
        //         {
        //             name: "Search By Lead Source",
        //             keyName: "lead_source",
        //             value: LeadSourceChoice
        //         },
        //         {
        //             name: "Search By Pass To",
        //             keyName: "passed_to",
        //             value: stateData?.leadOwner
        //         }
        //     ]

    const handleSelectField = [
            {
                name: "Search By State Name",
                keyName: "state",
                value: state.map(singleStatus => (
                    <MenuItem value={singleStatus.value}>{singleStatus.name}</MenuItem>
                ))
            },
            {
                name: "Search By Product Name",
                keyName: "product",
                value: product.map(singleProduct => (
                    <MenuItem value={singleProduct.value}>{singleProduct.name}</MenuItem>
                ))
            },
            {
                name: " Search By Lead Status",
                keyName: "lead_status",
                value: leadStatus.map(leadStatus => (
                    <MenuItem value={leadStatus.value}>{leadStatus.name}</MenuItem>
                ))
            },
            {
                name: "Search By Pre & Post Stage",
                keyName: "stage",
                value: leadStage.map(leadStage => (
                    <MenuItem value={leadStage.key}>{leadStage.name}</MenuItem>
                ))
            },
            {
                name: "Search By CRM Users",
                keyName: "name",
                value: stateData?.leadOwner?.map(user => (
                    <MenuItem key={user.id} value={user.name}>
                        {user.name}
                    </MenuItem>
                ))
            },
            {
                name: "Search By Cummins Dealer",
                keyName: "cummins_dealer",
                value: cummins_dealer.map(dealer => (
                    <MenuItem value={dealer.name}>{dealer.name}</MenuItem>
                ))
            },
            {
                name: "Search By Lead Assign To",
                keyName: "lead_owner_name",
                value: stateData?.leadOwner?.map(user => (
                    <MenuItem key={user.id} value={user.name}>
                        {user.name}
                    </MenuItem>
                ))
            },
            {
                name: "Search By Lead Source",
                keyName: "lead_source",
                value: LeadSourceChoice?.map(user => (
                    <MenuItem value={user.keys}>{user.value}</MenuItem>
                ))
            },
            {
                name: "Search By Pass To",
                keyName: "passed_to",
                value: stateData?.leadOwner?.map(user => (
                    <MenuItem key={user.id} value={user.name}>
                        {user.name}
                    </MenuItem>
                ))
            }
        ];

    const handleCard = [
        {
            name: "Total Leads",
            value:
                stateData?.leadAnalytics?.total_count !== undefined
                    ? stateData?.leadAnalytics?.total_count
                    : "0"
        },
        {
            name: "RFS",
            value: stateData?.leadAnalytics?.product_counts
                ? stateData.leadAnalytics.product_counts.find(
                item => item.product === 1
            )?.product_count ?? "0"
                : "0"
        },
        {
            name: "RFD",
            value: stateData?.leadAnalytics?.product_counts
                ? stateData.leadAnalytics.product_counts.find(
                item => item.product === 2
            )?.product_count ?? "0"
                : "0"
        },
        {
            name: "Mobile Datum",
            value: stateData?.leadAnalytics?.product_counts
                ? stateData.leadAnalytics.product_counts.find(
                item => item.product === 3
            )?.product_count ?? "0"
                : "0"
        },
        {
            name: "Datum x",
            value: stateData?.leadAnalytics?.type_counts
                ? stateData.leadAnalytics.type_counts.find(
                item => item.datum_type === "X"
            )?.type_count ?? "0"
                : "0"
        },
        {
            name: "Datum Y",
            value: stateData?.leadAnalytics?.type_counts
                ? stateData.leadAnalytics.type_counts.find(
                item => item.datum_type === "Y"
            )?.type_count ?? "0"
                : "0"
        },
        {
            name: "Datum Z",
            value: stateData?.leadAnalytics?.type_counts
                ? stateData.leadAnalytics.type_counts.find(
                item => item.datum_type === "Z"
            )?.type_count ?? "0"
                : "0"
        },
        {
            name: "Hot",
            value: stateData?.leadAnalytics?.lead_status_counts
                ? stateData.leadAnalytics.lead_status_counts.find(
                item => item.lead_status === 1
            )?.lead_status_count ?? "0"
                : "0"
        },
        {
            name: "Warm",
            value: stateData?.leadAnalytics?.lead_status_counts
                ? stateData.leadAnalytics.lead_status_counts.find(
                item => item.lead_status === 2
            )?.lead_status_count ?? "0"
                : "0"
        },
        {
            name: "Cold",
            value: stateData?.leadAnalytics?.lead_status_counts
                ? stateData.leadAnalytics.lead_status_counts.find(
                item => item.lead_status === 3
            )?.lead_status_count ?? "0"
                : "0"
        }
    ];

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div>
                <Typography
                    className={classes.nameText}
                    variant="h1"
                    style={{
                        fontWeight: 300
                    }}
                >
                    Repos CRM
                </Typography>
            </div>
            <div className={classes.row}>
                <span className={classes.spacer}/>
            </div>

            {/**************************** Primary tools ***************************/}
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Company name / Mobile Number"
                        getsearchterm={onSearchTerm}
                        searchValue={param.search}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <div className={classes.row} style={{marginTop: "5px"}}>
                        <Box display={"flex"} flexDirection={"row"}>
                            <Button
                                size="small"
                                disabled={param?.i_start_date}
                                variant="outlined"
                                style={{marginLeft: "5px"}}
                                onClick={() => handleDateChange("Created")}
                            >
                                Created On
                            </Button>

                            <Button
                                size="small"
                                variant="outlined"
                                disabled={param?.c_start_date}
                                style={{marginLeft: "5px"}}
                                onClick={() => handleDateChange("Interacted")}
                            >
                                Interacted on
                            </Button>
                        </Box>
                    </div>
                </Grid>

                <Grid item xs={6} md={4} lg={4}>
                    {/*<Grid item xs={12} md={4} lg={4}>*/}
                        <div className={classes.row} style={{marginTop: "5px"}}>
                            <Button size="medium" variant="outlined" onClick={handleDrawerOpen}>
                                ADD Lead
                            </Button>

                            {secureLocalStorage.getItem("department") === "11" ||
                            secureLocalStorage.getItem("department") === "12" ? (
                                ""
                            ) : (
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    style={{marginLeft: "5px"}}
                                    onClick={handleOpen}
                                >
                                    View Price
                                </Button>
                            )}
                        </div>
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={4} md={2} lg={2}>
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            size="medium"
                            style={{
                                marginRight: "10px",
                                lineHeight: "2",
                                marginLeft: "5px",
                            }}
                            onClick={() => handleOpenUpload()} // Call handleFileUpload on file selection
                        >
                            Import
                            <UploadIcon/>
                        </Button>
                    </Grid>

                    <Grid item xs={4} md={2} lg={2}>
                        {stateData.isLoadingExport ? (
                            <Box sx={{display: "flex"}}>
                                <CircularProgress/>
                            </Box>
                        ) : (
                            <Button
                                variant="contained"
                                component="label"
                                size="medium"
                                style={{
                                    marginRight: "10px",
                                    lineHeight: "2",
                                    marginLeft: "5px",
                                    backgroundColor: "#298181",
                                    color: "white"
                                }}
                                onClick={() => (setSelectDate(true), setDateRange(true))} // Call handleFileUpload on file selection
                            >
                                Report
                                <FileDownloadIcon/>
                            </Button>
                        )}
                    </Grid>

                    {(param?.c_start_date || param?.c_end_date || param?.i_start_date || param?.i_end_date) &&
                        <Grid item xs={4} md={2} lg={2}>
                            {stateData.isLoadingExport ? (
                                <Box sx={{display: "flex"}}>
                                    <CircularProgress/>
                                </Box>
                            ) : (
                                <Button
                                    variant="contained"
                                    component="label"
                                    size="medium"
                                    style={{
                                        marginRight: "10px",
                                        lineHeight: "2",
                                        marginLeft: "5px",
                                        backgroundColor: "#464c4c",
                                        color: "white"
                                    }}
                                    onClick={() => handleOpenDate(true)} // Call handleFileUpload on file selection
                                >
                                    Export
                                    <FileDownloadIcon/>
                                </Button>
                            )}
                        </Grid>}
                    {(Object.keys(param).length > 2 || Object.keys(keys).length > 0) && (
                        <Grid item xs={4} md={2} lg={3}>
                            <Box
                                display={"flex"}
                                flexDirection={"row-reverse"}
                                paddingRight={2}
                            >
                                <Button
                                    size="small"
                                    style={{backgroundColor: "#ea8c8c"}}
                                    variant={"contained"}
                                    onClick={() => clearFilter()}
                                >
                                    Clear Filter
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

            {/**************************** ************ ***************************/}

            <div style={{marginTop: "1%"}}>
                <Accordion elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography variant={"h6"}>Filters & Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: "#f9f9f9"}}>
                        {/**************************** Select fields ***************************/}

                        <Typography style={{marginTop: "1%", marginBottom: "1%"}}>
                            Filters
                        </Typography>
                        <Grid container spacing={2}>
                            {handleSelectField.map((item, index) => (
                                <Grid item xs={12} md={3} index={index}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        size={"small"}
                                        fullWidth
                                        background="white"
                                    >
                                        <InputLabel style={{color: "#C8C7C7"}}>
                                            {item.name}
                                        </InputLabel>
                                        {/*{typeof(param[item?.keyName])}*/}
                                        {/*<Autocomplete*/}
                                        {/*    id={item.name}*/}
                                        {/*    // label={item.name}*/}
                                        {/*    // value={item?.value.find((option) => option?.name === item?.keyName)}*/}
                                        {/*    value={param[item?.keyName] ? param[item?.keyName] : ""}*/}
                                        {/*    options={item.value}*/}
                                        {/*    autoHighlight*/}
                                        {/*    getOptionLabel={option => option?.name}*/}
                                        {/*    // onChange={e => fliters(e.target.value, item?.keyName)}*/}
                                        {/*    onChange={(event, e) =>*/}
                                        {/*        fliters(e?.value, item?.keyName)*/}
                                        {/*    }*/}
                                        {/*    // onChange={handleInputChange}*/}
                                        {/*    renderOption={(props, option) => (*/}
                                        {/*        <Box component="li" {...props}>*/}
                                        {/*            {option?.name}*/}
                                        {/*        </Box>*/}
                                        {/*    )}*/}
                                        {/*    renderInput={params => (*/}
                                        {/*        <TextField*/}
                                        {/*            {...params}*/}
                                        {/*            // value={param[item?.keyName] ? param[item?.keyName] : ""}*/}
                                        {/*            label={item.name}*/}
                                        {/*            inputProps={{*/}
                                        {/*                ...params.inputProps*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*    )}*/}
                                        {/*/>*/}

                                        {/*<AutoComplete*/}
                                        {/*    placeholder="Autocomplete"*/}
                                        {/*    options={state}*/}
                                        {/*    value={param[item?.keyName] ? param[item?.keyName] : ""}*/}
                                        {/*    onChange={e => fliters(e?.target?.value, item?.keyName)}*/}
                                        {/*/>*/}

                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            onChange={e => fliters(e?.target?.value, item?.keyName)}
                                            value={param[item?.keyName] ? param[item?.keyName] : ""}
                                            fullWidth
                                            label="Search By Lead Assign To"
                                            style={{backgroundColor: "white"}}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {item.value}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ))}
                            {Object.keys(param).length > 2 && (
                                <Grid xs={12} md={3}>
                                    <Box style={{padding: "9px"}}>
                                        <Button
                                            variant={"outlined"}
                                            style={{backgroundColor: "#ea8c8c"}}
                                            onClick={() => clearFilter()}
                                        >
                                            Clear Filter
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>

                        {/**************************** ********** ***************************/}

                        {/******************************* cards *****************************/}
                        <Typography style={{marginTop: "2%", marginBottom: "1%"}}>
                            Cards
                        </Typography>
                        <Grid alignItems="center" container spacing={2}>
                            {handleCard.map((item, index) => (
                                <Grid item xs={4} md={2} xl={2} index={index}>
                                    <Paper
                                        elevation={1}
                                        className={clsx(classes.hoverBox, className, {})}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            style={{
                                                textDecoration: "none",
                                                color: "rgb(69, 69, 69)"
                                            }}
                                            justifyContent={"center"}
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent={"center"}
                                                style={{marginTop: "13px"}}
                                            >
                                                <Box>
                                                    <Typography
                                                        variant={"h4"}
                                                        style={{
                                                            textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                        }}
                                                    >
                                                        {item?.value}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" justifyContent={"center"}>
                                                <Typography variant="overline">{item?.name}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        {/************** ******** *************/}
                    </AccordionDetails>
                </Accordion>
            </div>

            <Dialog open={openCRMdata} onClose={() => handleClose()}>
                <div {...rest} className={clsx(classes.root, className)}>
                    <Button
                        className={classes.addNewPartnerButton}
                        variant="contained"
                        component="label"
                        color="success"
                        value={file}
                        id="purchase_order"
                        size="medium"
                    >
                        Import Excel File
                        <UploadIcon/>
                        <input
                            type="file"
                            hidden
                            onChange={e => handleFileChange(e.target.files[0])}
                        />
                    </Button>

                    <div
                        style={{
                            margin: "20px",
                            textAlign: "center",
                            backgroundColor: "#50525044"
                        }}
                    >
                        <Typography>{file.name}</Typography>
                    </div>

                    <DialogActions>
                        {stateData?.isUpload ? (
                            <Box display={"flex"} flexDirection={"column"} mt={3}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <Box sx={{width: "100%", mr: 1}}>
                                        <LinearProgress
                                            variant="determinate"
                                            style={{height: "15px"}}
                                            value={stateData?.uploadProgress}
                                        />
                                    </Box>
                                    <Box sx={{minWidth: 35}}>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >{`${Math.round(stateData?.uploadProgress)}%`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Button
                                    className={classes.addNewPartnerButton}
                                    color="primary"
                                    size="medium"
                                    variant="contained"
                                    onClick={handleFileUpload}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={classes.addNewPartnerButton}
                                    color="primary"
                                    size="medium"
                                    variant="contained"
                                    // disabled={!isFormValid() || !validateGST(formData.gst_number)}
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </div>
            </Dialog>

            <SwipeableDrawer
                anchor={"right"}
                variant="temporary"
                classes={{
                    paper: classes.drawerWidth
                }}
                open={drawerIsOpen}
                // onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.BackdropProps
                        }
                    }
                }}
            >
                <div className={classes.drawerHeading} style={{marginLeft: "10px"}}>
                    <Typography
                        variant="h2"
                        style={{marginTop: "3%", marginLeft: "16px", color: "#808080"}}
                    >
                        Add Lead
                    </Typography>
                </div>
                <AddLeadForm
                    stateData={stateData}
                    handleDrawerClose={handleDrawerClose}
                />
            </SwipeableDrawer>

            <SwipeableDrawer
                anchor={"right"}
                variant="temporary"
                classes={{
                    paper: classes.drawerWidth
                }}
                open={drawerOpen}
                onClose={handleClose}
                onOpen={handleOpen}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.BackdropProps
                        }
                    }
                }}
            >
                <div className={classes.drawerHeading} style={{marginLeft: "10px"}}>
                    <Typography
                        variant="h2"
                        style={{marginTop: "3%", marginLeft: "16px", color: "#808080"}}
                    >
                        View Price
                    </Typography>
                </div>
                <ViewPrice stateData={stateData} handleDrawerClose={handleClose}/>
            </SwipeableDrawer>

            <DialogDateRange
                open={selectDate}
                handleClose={handleClose}
                handleDate={handleExportReport}
                isRange={isDateRange}
            />
        </div>
    );
};

UserToolbar.propTypes = {
    className: PropTypes.string,
    onStateChange: PropTypes.func,
    onProductChange: PropTypes.func,
    onLeadStatusChange: PropTypes.func,
    onCrmUserChange: PropTypes.func,
    onCreatedOnDate: PropTypes.func, // PropTypes for the new functions
    onInteractedOnDate: PropTypes.func,
    setKeys: PropTypes.func,
    clearFilter: PropTypes.func,
    keys: PropTypes.object
};
export default compose(withStyles(styles))(UserToolbar);
