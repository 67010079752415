import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {Card, Grid, Typography, withStyles} from "@material-ui/core";
import compose from "recompose/compose";
import styles from "../../../AllAssets/style";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    overline: {
        marginTop: theme.spacing(1)
    }
}));

const OrdersDirectMode = props => {
    const {className, ...rest} = props;
    const nf = new Intl.NumberFormat("en-IN");
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <Grid alignItems="center" container justify="space-between">
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                    <Typography variant="h2">
                        {nf.format(props.directModeData?.direct_mode?.total_direct_count)
                            // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Total Orders
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                    <Typography variant="h2">
                        {nf.format(props.directModeData?.direct_mode?.total_direct_sum)
                            // .replace(/\B(?=(\d{3})+(?!\d))/g,",")
                            // .replace(/\B(?=(\d{2})+(?!\d))/g, ",")
                        }
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Total Quantity
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                    <Typography variant="h2">
                        {nf.format(props.directModeData?.direct_mode?.average_direct_count)
                            // ?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Average Quantity per Order
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

OrdersDirectMode.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = state => {
    return {
        directModeData: state.directModeData
    };
};

export default connect(mapStateToProps)(
    compose(withStyles(styles))(OrdersDirectMode)
);
