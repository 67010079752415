import React, {Component} from "react";
import UsersToolbar from "views/AllDatum/Components/UsersToolbar";
import {Dashboard as DashboardLayout} from "layouts";

export default class DatumAsset extends Component {
    render() {
        return (
            <div style={{padding: "24px"}}>
                <DashboardLayout title="Datum Assets">
                    <UsersToolbar/>
                </DashboardLayout>
            </div>
        );
    }
}
