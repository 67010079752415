export default {
    registrationNumber: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    capacity: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 6,
            minimum: 3
        }
    },
    selectedPartner: {
        presence: {allowEmpty: false, message: "is required"}
    },
    selectedDriver: {
        presence: {allowEmpty: false, message: "is required"}
    },
    selectedTracker: {
        presence: {allowEmpty: false, message: "is required"}
    }
};
