import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GoogleMapsReact from "../../../../components/GoogleMaps/google_maps_react";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import reposWebApi from "../../../../actions/configuration";
import {withSnackbar} from "notistack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import GoogleMaps from "../../../AllGeofences/components/PolygonMap/MapForModifyPolygon";
import {modifyGeofence, sendLogs} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import MapForPolygon from "../../../AllGeofences/components/PolygonMap/MapForPolygon";
import qs from "qs";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import secureLocalStorage from "react-secure-storage";
import fernet from "fernet";
import decryption from "encryption/Decryption";

class UsersTable extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        coords: "",
        assetInfo: "",
        right: false,
        assets: [],
        is_dialog: false,
        rppDialog: false,
        selectedOrders: [],
        is_datum: false,
        dialog_Open: false,
        tracker_id: "N/A",
        authorize: undefined,
        datum_id: "",
        geofenceId: "",
        openModifyDialog: false,
        geofenceInfo: "",
        list_Of_asset: [],
        listOfAvailableDatumTrackers: [],
        listFinservRefuelersForOrder: [],
        listFinservPartner: [],
        polygonArray: [],
        geofense_deets: [],
        openLocation: false,
        searchString: "",
        isLoading: false,
        partnerId: 0,
        asset_status: false,
        asset_id: 0,
        rpp_id: 0,
        form: "",
        datum_model: "X",
        is_dataum_active: false,
        showCapacity: false,
        capacity: "",
        error: "",
        finservOwnedDialog: false,
        installation_date: "",
        last_payment_date: "",
        upcoming_payment_date: "",
        deposit_amount: "",
        rental_amount_without_gst: "",
        subscriptions_paid: "",
        invoice_sent: "",
        remarks: "",
        is_finserv_owned_datum: "0"
    };

    constructor() {
        super();
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        this.searchTerm = searchTerm;
        if (searchTerm.length > 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm
            });
            this.getPartnersData(searchTerm);
            this.getTrackerListForBinding(searchTerm);
        } else {
            this.setState({
                searchString: ""
            });
            this.getPartnersData(this.state.searchString);
            this.getTrackerListForBinding("");
        }
    };

    encryptedData = response => {
        let secret = new fernet.Secret(
            "BbSePhsFh4eJ5U5hUkfTm_Y1R1wS4PhWnLltHUPiMW8="
        );
        let token = new fernet.Token({
            secret: secret
        });
        token.encode("string");

        let toke = new fernet.Token({
            secret: secret,
            token: response?.data,
            ttl: 0
        });

        return JSON.parse(toke.decode());
    };

    getPartnersData = (
        searchTerm = this.state.searchString,
        limit = 10,
        offset = 0,
        fis_orders = 1,
        abortController = this.abortController
    ) => {
        let queryParams = {};
        queryParams.search = searchTerm;
        queryParams.limit = limit;
        queryParams.offset = offset;
        queryParams.fis_orders = fis_orders;
        this.setState({
            isLoading: true
        });
        reposWebApi
            .get("/partner/all/", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: queryParams
            })
            .then(partners => {
                if (partners?.status === 200) {
                    this.setState({
                        listFinservPartner: this.encryptedData(partners).data.results,
                        isLoading: false
                    });
                }
            })
            .catch(error => {
                if (error?.response?.status === 500) {
                    this.props.enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                }
            });
    };

    getTrackerListForBinding = SearchTerm => {
        let queryParams = {};
        queryParams.search = SearchTerm;
        this.setState({
            isLoading: true
        });
        reposWebApi
            .get("/tracker/list_tracker_for_binding", {
                cancelToken: this.abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                // params: {
                //     tracker_type: "datum"
                // }
                params: queryParams
            })
            .then(res => {
                this.setState({
                    listOfAvailableDatumTrackers: decryption(res).data.results,
                    isLoading: false
                });
            });
    };

    handleDialogAssignRpp = (e, asset_id) => {
        this.setState({
            asset_status: e.target.checked,
            rppDialog: true,
            asset_id: asset_id,
            form: e.target.name
        });
    };

    GetFinservRefuelersForOrder = partner => {
        this.setState({
            isLoading: true
        });
        reposWebApi
            .get("/refueler/list", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    partner: partner
                }
            })
            .then(res => {
                this.setState({
                    listFinservRefuelersForOrder: decryption(res).data?.results,
                    isLoading: false
                });
            });
    };

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    handleOnSubmit = () => {
        reposWebApi
            .get("/customer/assign_refueler_partner_to_asset", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    partner_id: this.state.partnerId,
                    asset_id: this.state.asset_id,
                    refueler_id: this.state.rpp_id
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.props.enqueueSnackbar("Partner assign successfully!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/assign_refueler_partner_to_asset",
                        qs.stringify({
                            partner_id: this.state.partnerId,
                            asset_id: this.state.asset_id,
                            refueler_id: this.state.rpp_id
                        })
                    );
                    this.setState({
                        rpp_id: 0,
                        tracker_id: ""
                    });
                    if (this.state.form === "activationSwitch") {
                        this.handleFinservActivation(
                            this.state.asset_status,
                            this.state.asset_id
                        );
                        this.handleDialogClose();
                    } else {
                        this.handleDialogClose();
                    }
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    this.props.enqueueSnackbar(
                        "Please select the right Partner or RPP !",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/assign_refueler_partner_to_asset",
                        qs.stringify({
                            partner_id: this.state.partnerId,
                            asset_id: this.state.asset_id,
                            refueler_id: this.state.rpp_id
                        })
                    );
                } else {
                    this.props.enqueueSnackbar(
                        "RPP - Asset binding error, something went wrong!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/assign_refueler_partner_to_asset",
                        qs.stringify({
                            partner_id: this.state.partnerId,
                            asset_id: this.state.asset_id,
                            refueler_id: this.state.rpp_id
                        })
                    );
                }
            });
    };

    handleFinservActivation = (status, id) => {
        const enabled = status ? "Enabled" : "Disable";
        const requestBody = {
            asset_id: id,
            enabled: status ? "True" : "False"
        };
        reposWebApi
            .post("/finserv/customer_asset/enable", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.props.enqueueSnackbar(
                        "Asset Finserv " + enabled + " successfully!",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/customer_asset/enable",
                        qs.stringify(requestBody)
                    );
                    this.props.reloadAsset();
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    this.props.enqueueSnackbar("Please select the right tracker !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/customer_asset/enable",
                        qs.stringify(requestBody)
                    );
                } else {
                    this.props.enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/customer_asset/enable",
                        qs.stringify(requestBody)
                    );
                }
            });
    };

    handleDatumActivationSubmit = (e, id, tracker, authorize) => {
        reposWebApi
            .post(
                "/customer/bind_tracker_to_asset",
                {
                    tracker_id: this.state.tracker_id,
                    asset_id: id,
                    authorize: this.state.authorize,
                    datum_model: this.state.datum_model,
                    capacity: this.state.capacity,
                    bind_unbind: this.state.is_dataum_active ? 1 : 0
                },
                {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                    }
                }
            )
            .then(response => {
                if (response?.status === 201 || response?.status === 200) {
                    this.props.enqueueSnackbar(
                        response?.status === 201
                            ? "Asset successfully activated as datum!"
                            : "Asset is unbind successfully with tracker",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify()
                    );
                    this.setState({
                        tracker_id: "",
                        datum_id: "",
                        trackerType: "datum",
                        authorize: "",
                        datum_model: "",
                        capacity: ""
                    });
                    this.handleDialogClose();
                    this.props.reloadAsset();
                }
            })
            .catch(error => {
                if (error?.response?.status === 400) {
                    this.props.enqueueSnackbar("Please select a tracker and check !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify({
                            tracker_id: this.state.tracker_id,
                            asset_id: id,
                            authorize: this.state.authorize,
                            datum_model: this.state.datum_model
                        })
                    );
                }
                if (error?.response?.status === 404) {
                    this.props.enqueueSnackbar("Please select authorization !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify({
                            tracker_id: this.state.tracker_id,
                            asset_id: id,
                            authorize: this.state.authorize,
                            datum_model: this.state.datum_model
                        })
                    );
                } else {
                    this.props.enqueueSnackbar(
                        "Tracker - Asset binding error, Something went wrong!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify({
                            tracker_id: this.state.tracker_id,
                            asset_id: id,
                            authorize: this.state.authorize,
                            datum_model: this.state.datum_model
                        })
                    );
                }
                this.setState({
                    tracker_id: "",
                    datum_id: "",
                    authorize: "",
                    datum_model: "X"
                });
                this.handleDialogClose();
            });
    };

    handleDatumActivation = (e, asset) => {
        if (asset.tracker_id !== "N/A") {
            this.setState({
                list_Of_asset: asset,
                is_dataum_active: e.target.checked,
                tracker_id: "" + asset.tracker_id,
                authorize: asset.authorization ? "True" : "False",
                datum_model: asset.datum_model,
                is_datum: true
            });
            this.getTrackerListForBinding("");
        } else {
            this.setState({
                is_datum: true,
                list_Of_asset: asset,
                is_dataum_active: e.target.checked
            });
            this.getTrackerListForBinding("");
        }
    };

    openLocation = (latLon, assetInfo) => {
        this.setState({
            openLocation: true,
            coords: latLon,
            assetInfo: assetInfo
        });
    };

    openDialog(latLon, assetInfo) {
        const coords = latLon?.map(ll => {
            return {lat: ll["lat"], lng: ll["lon"]};
        });
        this.setState({
            open: true,
            coords: coords,
            assetInfo: assetInfo
        });
    }

    handleSubmit = () => {
        if (this.state.polygonArray.length === 0) {
            alert(
                "You haven't created a geofence! Please draw a polygon and click Save or close Button"
            );
            return false;
        } else
            this.props.handleModifyGeofence(
                secureLocalStorage.getItem("accessToken"),
                this.state.geofenceId,
                this.state.polygonArray
            );
        alert("Your geo-fence location has been updated");
        // this.handleCloseModifyDialog.bind()
        this.props.reloadAsset();
        this.setState({
            openModifyDialog: false,
            open: false,
            openLocation: false,
            is_dialog: false,
            dialog_Open: false,
            tracker_id: "",
            datum_model: "X"
        });
    };

    handleClose() {
        this.setState({
            open: false,
            tracker_id: "",
            datum_model: "X",
            showCapacity: false
        });
    }

    openDialogForModify(geofenceId, latLon, assetInfo) {
        if (latLon) {
            const coords = latLon?.map(ll => {
                return {lat: ll["lat"], lng: ll["lon"]};
            });
            this.setState({
                openModifyDialog: true,
                geofenceId: geofenceId,
                coords: coords,
                polygonArray: coords,
                assetInfo: assetInfo
            });
        } else {
            this.props.enqueueSnackbar("Location Not Available, Please Update The Customer Location ", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
        }
    }

    handleCloseModifyDialog() {
        this.setState({
            openModifyDialog: false,
            open: false,
            openLocation: false,
            rppDialog: false
        });
    }

    handleDialogOpen = (e, asset) => {
        e.preventDefault();
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }
        this.setState({
            ...this.state,
            is_dialog: true,
            assets: asset,
            geofense_deets: asset.geofense_deets
        });
    };
    handleDatumDialogOpen = (e, asset) => {
        e.preventDefault();
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }
        this.setState({
            ...this.state,
            assets: asset,
            dialog_Open: true
        });
    };

    getUpdatedPolygonArray = polygonArray => {
        this.setState({
            polygonArray: polygonArray
        });
    };

    handleDialogClose = () => {
        this.setState({
            authorize: undefined,
            is_dialog: false,
            is_datum: false,
            dialog_Open: false,
            open: false,
            rppDialog: false,
            tracker_id: "N/A",
            asset_id: "",
            id: "",
            rpp_id: 0,
            partnerId: 0,
            searchString: "",
            datum_model: "X",
            showCapacity: false,
            error: "",
            capacity: "",
            finservOwnedDialog: false,
            installation_date: "",
            last_payment_date: "",
            upcoming_payment_date: "",
            deposit_amount: "",
            rental_amount_without_gst: "",
            subscriptions_paid: "",
            invoice_sent: "",
            remarks: ""
        });
    };

    handleChangeSelectPartner = (e, value) => {
        this.setState({
            partnerId: value.id
        });
        this.GetFinservRefuelersForOrder(value.id);
    };

    handleSelectRpp = (e, value) => {
        this.setState({
            rpp_id: value.id
        });
    };

    handleChange = (e, value) => {
        this.setState({
            tracker_id: value.id.toString()
        });
    };

    handleChangeAuthorize = e => {
        this.setState({
            authorize: e.target.value
        });
    };
    handleChangeDatumModels = e => {
        this.setState({
            datum_model: e?.target?.value
        });
    };

    handleCapacityChange = event => {
        this.setState({
            capacity: event.target.value
        });
        // let value = event.target.value;
        // let capacity = value;
        // if (capacity > 3000) {
        //   this.setState({ error: 'Capacity should be greate than 3000' });
        // } else {
        //   this.setState({ capacity, error: '' });
        // }
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    componentDidMount() {
        this.getTrackerListForBinding();
        this.GetFinservRefuelersForOrder();
        this.getPartnersData();
    }

    handleOnChange = ({target}) => {
    };

    handleFinservOwnedDatum = (
        status,
        id,
        installation_date,
        last_payment_date,
        upcoming_payment_date,
        deposit_amount,
        rental_amount_without_gst,
        subscriptions_paid,
        invoice_sent,
        remarks
    ) => {
        const requestBody = {
            asset_id: id,
            is_finserv_owned_datum: status ? "1" : "0",
            installation_date,
            last_payment_date,
            upcoming_payment_date,
            deposit_amount,
            rental_amount_without_gst,
            subscriptions_paid,
            invoice_sent,
            remarks
        };
        reposWebApi
            .post(
                "/customer/add_finserv_owned_rental_data",
                qs.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                    }
                }
            )
            .then(response => {
                if (response?.status === 200) {
                    this.props.enqueueSnackbar(
                        "Finserv Owned Datum Disabled successfully!",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    if (status) {
                        this.setState({is_finserv_owned_datum: "1"}, () => {
                            this.handleDialogClose();
                            this.props.reloadAsset();
                        });
                    } else {
                        this.setState({is_finserv_owned_datum: "0"}, () => {
                            this.handleDialogClose();
                            this.props.reloadAsset();
                        });
                    }
                }
            })
            .catch(error => {
                if (error?.response?.status === 400) {
                    this.props.enqueueSnackbar("Please select the right tracker !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/add_finserv_owned_rental_data",
                        qs.stringify(requestBody)
                    );
                } else {
                    this.props.enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/add_finserv_owned_rental_data",
                        qs.stringify(requestBody)
                    );
                }
            });
    };

    handleInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleDialogFinservOwned = (e, asset_id) => {
        this.setState({
            asset_status: e.target.checked,
            finservOwnedDialog: true,
            asset_id: asset_id,
            form: e.target.name
        });
    };

    handleFinservOwnedSubmit = () => {
        const requestData = new URLSearchParams();
        requestData.append("asset_id", String(this.state.asset_id));
        requestData.append(
            "is_finserv_owned_datum",
            String(this.state.is_finserv_owned_datum ? "1" : "0")
        );
        requestData.append(
            "installation_date",
            String(this.state.installation_date)
        );
        requestData.append(
            "last_payment_date",
            String(this.state.last_payment_date)
        );
        requestData.append(
            "upcoming_payment_date",
            String(this.state.upcoming_payment_date)
        );
        requestData.append("deposit_amount", String(this.state.deposit_amount));
        requestData.append(
            "rental_amount_without_gst",
            String(this.state.rental_amount_without_gst)
        );
        requestData.append(
            "subscriptions_paid",
            String(this.state.subscriptions_paid)
        );
        requestData.append("invoice_sent", String(this.state.invoice_sent));
        requestData.append("remarks", String(this.state.remarks));

        reposWebApi
            .post("/customer/add_finserv_owned_rental_data", requestData.toString(), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    this.props.enqueueSnackbar(
                        "Finserv Owned Datum Added successfully!",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/add_finserv_owned_rental_data",
                        requestData.toString()
                    );

                    this.setState({is_finserv_owned_datum: "1"}, () => {
                        // This callback ensures that the state is updated before proceeding
                        if (this.state.form === "activationSwitch") {
                            this.handleDialogClose();
                            this.props.reloadAsset();
                        } else {
                            this.handleDialogClose();
                            this.props.reloadAsset();
                        }
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    this.props.enqueueSnackbar("Not Done", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/add_finserv_owned_rental_data",
                        requestData.toString()
                    );
                } else {
                    this.props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/add_finserv_owned_rental_data",
                        requestData.toString()
                    );
                }
            });
    };

    render() {
        const {classes, className} = this.props;
        const {capacity} = this.state;
        const {error} = this.state;
        const rootClassName = classNames(classes.root, className);

            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="left">Id</TableCell>
                                                <TableCell align="left">Asset Name</TableCell>
                                                <TableCell align="left">Company Name</TableCell>
                                                <TableCell align="left">Tracker Device</TableCell>
                                                <TableCell align="left">Type</TableCell>
                                                <TableCell align="left">Levels Info</TableCell>
                                                <TableCell align="left">Username</TableCell>
                                                <TableCell align="left">Password</TableCell>
                                                <TableCell align="left">Datum Activation</TableCell>
                                                <TableCell align="left">Finserv Enabled</TableCell>
                                                <TableCell align="left">Finserv Owned</TableCell>
                                                <TableCell align="center">More</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"13"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {this.props.allAssetsData?.map(asset => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={asset.id}
                                                    >
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.id}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.name}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.customer}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.tracker_device === null
                                                                ? "-"
                                                                : asset.tracker_device}
                                                        </TableCell>
                                                        {/*asset model*/}
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.asset_type === 1
                                                                ? "DATUM"
                                                                : asset.asset_type === 2
                                                                    ? "SFD"
                                                                    : asset.asset_type === 3
                                                                        ? "BARREL"
                                                                        : asset.asset_type === 4
                                                                            ? "SFC"
                                                                            : asset.asset_type === 5
                                                                                ? "DG SET"
                                                                                : asset.asset_type === 6
                                                                                    ? "INDUSTRIAL MACH."
                                                                                    : asset.asset_type === 7
                                                                                        ? "JCB"
                                                                                        : asset.asset_type === 8
                                                                                            ? "OTHERS"
                                                                                            : "null"}
                                                        </TableCell>
                                                        {/*tracker device*/}
                                                        <TableCell className={classes.tableCell}>
                                                            <FormControl className={classes.formControl}>
                                                                {asset.levels_info === null ? (
                                                                    "No data"
                                                                ) : (
                                                                    <div>
                                                                        <InputLabel id="levels_info">
                                                                            Levels
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId="levels_info"
                                                                            id="levels_info"
                                                                            style={{width: "65px"}}
                                                                        >
                                                                            <MenuItem value={10}>
                                                                                Level 1: {asset.levels_info.lvl1}
                                                                            </MenuItem>
                                                                            <MenuItem value={20}>
                                                                                Level 2: {asset.levels_info.lvl2}
                                                                            </MenuItem>
                                                                            <MenuItem value={30}>
                                                                                Level 3: {asset.levels_info.lvl3}
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </div>
                                                                )}
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.datum_cred[0]}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.datum_cred[1]}
                                                        </TableCell>
                                                        {/* Datum Activation */}
                                                        <TableCell className={classes.tableCell}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={asset.tracker_id !== "N/A"}
                                                                        // disabled={(asset.tracker_id !== 'N/A' ||  secureLocalStorage.getItem("access_type") === false)}
                                                                        name="activationSwitch"
                                                                        trackColor={{
                                                                            true: secureLocalStorage.access_type
                                                                                ? "red"
                                                                                : "grey",
                                                                            false: "grey"
                                                                        }}
                                                                        edge={"start"}
                                                                        classes={{
                                                                            root: classes.root,
                                                                            switchBase: classes.switchBase,
                                                                            thumb: classes.thumb,
                                                                            track: classes.track,
                                                                            checked: classes.checked,
                                                                            color: "primary"
                                                                        }}
                                                                        onChange={event =>
                                                                            this.handleDatumActivation(event, asset)
                                                                        }
                                                                        inputProps={{
                                                                            "aria-label": "secondary checkbox"
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.is_finserv_enabled ? (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={asset.is_finserv_enabled}
                                                                            name="activationSwitch"
                                                                            trackColor={{
                                                                                true: "red",
                                                                                false: "grey"
                                                                            }}
                                                                            edge={"start"}
                                                                            classes={{
                                                                                root: classes.root,
                                                                                switchBase: classes.switchBase,
                                                                                thumb: classes.thumb,
                                                                                track: classes.track,
                                                                                checked: classes.checked,
                                                                                color: "primary"
                                                                            }}
                                                                            onChange={event =>
                                                                                this.handleFinservActivation(
                                                                                    event.target.checked,
                                                                                    asset.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-label": "secondary checkbox"
                                                                            }}
                                                                            disabled={
                                                                                secureLocalStorage.getItem(
                                                                                    "access_type"
                                                                                ) === false
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={asset.is_finserv_enabled}
                                                                            name="activationSwitch"
                                                                            trackColor={{
                                                                                true: "red",
                                                                                false: "grey"
                                                                            }}
                                                                            edge={"start"}
                                                                            classes={{
                                                                                root: classes.root,
                                                                                switchBase: classes.switchBase,
                                                                                thumb: classes.thumb,
                                                                                track: classes.track,
                                                                                checked: classes.checked,
                                                                                color: "primary"
                                                                            }}
                                                                            onChange={event =>
                                                                                this.handleDialogAssignRpp(
                                                                                    event,
                                                                                    asset.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-label": "secondary checkbox"
                                                                            }}
                                                                            disabled={
                                                                                secureLocalStorage.getItem(
                                                                                    "access_type"
                                                                                ) === false
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>
                                                        {/* Finserv Owned Datum */}
                                                        <TableCell className={classes.tableCell}>
                                                            {asset.is_finserv_owned_datum ? (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={asset.is_finserv_owned_datum}
                                                                            name="activationSwitch"
                                                                            trackColor={{
                                                                                true: secureLocalStorage.access_type
                                                                                    ? "red"
                                                                                    : "grey",
                                                                                false: "grey"
                                                                            }}
                                                                            edge={"start"}
                                                                            classes={{
                                                                                root: classes.root,
                                                                                switchBase: classes.switchBase,
                                                                                thumb: classes.thumb,
                                                                                track: classes.track,
                                                                                checked: classes.checked,
                                                                                color: "primary"
                                                                            }}
                                                                            onChange={event =>
                                                                                this.handleFinservOwnedDatum(
                                                                                    event.target.checked,
                                                                                    asset.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-label": "secondary checkbox"
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={asset.is_finserv_owned_datum}
                                                                            name="activationSwitch"
                                                                            trackColor={{
                                                                                true: "red",
                                                                                false: "grey"
                                                                            }}
                                                                            edge={"start"}
                                                                            classes={{
                                                                                root: classes.root,
                                                                                switchBase: classes.switchBase,
                                                                                thumb: classes.thumb,
                                                                                track: classes.track,
                                                                                checked: classes.checked,
                                                                                color: "primary"
                                                                            }}
                                                                            onChange={event =>
                                                                                this.handleDialogFinservOwned(
                                                                                    event,
                                                                                    asset.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-label": "secondary checkbox"
                                                                            }}
                                                                            disabled={
                                                                                secureLocalStorage.getItem(
                                                                                    "access_type"
                                                                                ) === false
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align={"right"}
                                                        >
                                                            <Button
                                                                onClick={e => this.handleDialogOpen(e, asset)}
                                                                style={{float: "right"}}
                                                            >
                                                                <MoreIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>

                            {/*dialog section*/}
                            <Dialog
                                anchor="right"
                                open={this.state.is_dialog}
                                onClose={this.handleDialogClose}
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                    id="simple-dialog-title"
                                >
                                    <div style={{margin: "15px 0px"}}>
                                        <Typography style={{color: "#fff"}} variant="h4">
                                            Geo-fence Information
                                        </Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        style={{marginTop: 10}}
                                    >
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Geo-fence Name
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.geofense_deets.name}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Geo-fence Type
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.geofense_deets.type}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        View on Map:
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.last_latlong === null ? (
                                                            "-"
                                                        ) : (
                                                            <Button
                                                                variant={"outlined"}
                                                                color={"primary"}
                                                                onClick={() =>
                                                                    this.openLocation(
                                                                        this.state.assets.asset_lat_lon,
                                                                        this.state.assets.name
                                                                    )
                                                                }
                                                                disabled={
                                                                    secureLocalStorage.getItem("access_type") ===
                                                                    false
                                                                }
                                                            >
                                                                Locate Me
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Geo-fence area
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.last_latlong === null ? (
                                                            "-"
                                                        ) : (
                                                            <Button
                                                                variant={"outlined"}
                                                                color={"primary"}
                                                                onClick={() =>
                                                                    this.openDialog(
                                                                        this.state.geofense_deets.lat_lon_polygon,
                                                                        this.state.geofense_deets.name
                                                                    )
                                                                }
                                                                disabled={
                                                                    secureLocalStorage.getItem("access_type") ===
                                                                    false
                                                                }
                                                            >
                                                                Geo-fence
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Modify GeoFence
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Button
                                                            variant={"outlined"}
                                                            color={"primary"}
                                                            onClick={() =>
                                                                this.openDialogForModify(
                                                                    this.state.assets.geofense_deets.id,
                                                                    this.state.geofense_deets.lat_lon_polygon,
                                                                    this.state.geofense_deets.name
                                                                )
                                                            }
                                                            disabled={
                                                                secureLocalStorage.getItem("access_type") ===
                                                                false
                                                            }
                                                        >
                                                            Modify
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Capacity
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.capacity === null
                                                            ? "-"
                                                            : this.state.assets.capacity}{" "}
                                                        L
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Finserv Edit
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Button
                                                            variant={"outlined"}
                                                            color={"primary"}
                                                            name={"modify"}
                                                            onClick={event =>
                                                                this.handleDialogAssignRpp(
                                                                    event,
                                                                    this.state.assets.id
                                                                )
                                                            }
                                                            disabled={
                                                                secureLocalStorage.getItem("access_type") ===
                                                                false
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Installation Date
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.installation_date}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Last Payment Date
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.last_payment_date}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Upcoming Payment Date
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.upcoming_payment_date}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Deposit Amount
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.deposit_amount}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Rental Amount without GST
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.rental_amount_without_gst}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Subscriptions Paid
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.subscriptions_paid}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Invoice Sent
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.invoice_sent === true
                                                            ? "Yes"
                                                            : "No"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        Remarks
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.state.assets.remarks}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.handleDialogClose}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/*Datum Activation dialog*/}
                            <Dialog
                                fullWidth
                                aria-labelledby="draggable-dialog-title"
                                open={this.state.is_datum}
                                onClose={this.handleDialogClose.bind(this)}
                            >
                                {!this.state.is_dataum_active ? (
                                    <DialogTitle
                                        style={{
                                            background:
                                                "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                        }}
                                    >
                                        <div style={{margin: "15px 0px"}}>
                                            <Typography
                                                style={{color: "#fff"}}
                                                variant="h4"
                                                align={"center"}
                                            >
                                                Datum Activation And Deactivation
                                            </Typography>
                                        </div>
                                        <div style={{margin: "10px 0px"}}>
                                            <Typography
                                                variant="subtitle2"
                                                style={{marginTop: "2%", color: "#fff"}}
                                                align={"center"}
                                            >
                                                Are You Sure..!
                                            </Typography>
                                        </div>
                                        <div style={{margin: "10px 0px"}}>
                                            <Typography
                                                variant="subtitle2"
                                                align={"center"}
                                                style={{marginTop: "2%", color: "#fff"}}
                                            >
                                                You Want To Deactivate Datum and Unbind the Tracker ?
                                            </Typography>
                                        </div>
                                    </DialogTitle>
                                ) : (
                                    <DialogTitle
                                        style={{
                                            background:
                                                "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                        }}
                                    >
                                        <div style={{margin: "15px 0px"}}>
                                            <Typography style={{color: "#fff"}} variant="h4">
                                                Datum Activation And Tracker Binding
                                            </Typography>
                                        </div>
                                        <div style={{margin: "10px 0px"}}>
                                            <Typography
                                                variant="subtitle2"
                                                style={{marginTop: "2%", color: "#fff"}}
                                            >
                                                Please select the respective tracker ID to bind it with
                                                the respective Datum
                                            </Typography>
                                        </div>
                                    </DialogTitle>
                                )}
                                {this.state.is_dataum_active && (
                                    <DialogContent>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            style={{margin: "10px 0"}}
                                        >
                                            Select Tracker id
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                style={{margin: "10px 0"}}
                                            >
                                                <Autocomplete
                                                    id="combo-box-for-tracker"
                                                    onChange={this.handleChange}
                                                    options={this.state.listOfAvailableDatumTrackers}
                                                    loading={this.state.isLoading}
                                                    getOptionLabel={option => option.name}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label="Tracker_ID"
                                                            variant="outlined"
                                                            onChange={e => this.onSearchTerm(e.target.value)}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <Typography>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{margin: "10px 0"}}
                                                >
                                                    <InputLabel id="authorization">
                                                        Authorization
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        style={{margin: "5px 0"}}
                                                        labelId="authorization"
                                                        id="authorization"
                                                        label="Authorization"
                                                        value={this.state.authorize}
                                                        onChange={e => this.handleChangeAuthorize(e)}
                                                    >
                                                        <MenuItem value={"True"}>Not Required</MenuItem>
                                                        <MenuItem value={"False"}> Required</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Typography>
                                            <Typography>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{margin: "10px 0"}}
                                                >
                                                    <InputLabel id="datum_model">Datum Model</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        style={{margin: "5px 0"}}
                                                        labelId="datum_model"
                                                        id="datum_model"
                                                        label="Datum Model"
                                                        value={this.state.datum_model}
                                                        onChange={e => this.handleChangeDatumModels(e)}
                                                    >
                                                        <MenuItem value={"X"}>Model X</MenuItem>
                                                        <MenuItem value={"Y"}>Model Y</MenuItem>
                                                        <MenuItem value={"Z"}>Model Z</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        variant="outlined"
                                                        style={{margin: "10px 0"}}
                                                        labelId="capacity"
                                                        id="capacity"
                                                        label="Capacity"
                                                        value={capacity}
                                                        onChange={this.handleCapacityChange}
                                                        inputProps={{
                                                            min: 100,
                                                            max: 3000
                                                        }}
                                                    />

                                                    <Typography style={{color: "red"}}>
                                                        {error && <div>{error}</div>}
                                                    </Typography>
                                                </div>
                                            </Typography>
                                        </Typography>
                                    </DialogContent>
                                )}
                                {/*{*/}
                                {/*    !this.state.is_dataum_active ?*/}
                                {/*        <DialogActions>*/}
                                {/*            <Button autoFocus onClick={this.handleDialogClose.bind(this)}*/}
                                {/*                    color="primary">*/}
                                {/*                Cancel*/}
                                {/*            </Button>*/}
                                {/*            <Button*/}
                                {/*                onClick={e => this.handleDatumActivationSubmit(e, this.state.list_Of_asset.id, this.state.list_Of_asset.tracker_id, this.state.list_Of_asset.authorize, this.state.is_dataum_active)}*/}
                                {/*                color="primary">*/}
                                {/*                Continue*/}
                                {/*            </Button>*/}
                                {/*        </DialogActions>*/}
                                {/*        :*/}
                                <DialogActions>
                                    <Button
                                        autoFocus
                                        onClick={this.handleDialogClose.bind(this)}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    {this.state.authorize === undefined ||
                                    this.state.tracker_id === "N/A" ? (
                                        ""
                                    ) : (
                                        <Button
                                            onClick={e =>
                                                this.handleDatumActivationSubmit(
                                                    e,
                                                    this.state.list_Of_asset.id,
                                                    this.state.list_Of_asset.tracker_id,
                                                    this.state.list_Of_asset.authorize,
                                                    this.state.is_dataum_active
                                                )
                                            }
                                            color="primary"
                                        >
                                            Continue
                                        </Button>
                                    )}
                                </DialogActions>

                                {/*}*/}
                            </Dialog>

                            <Dialog
                                open={this.state.open}
                                onClose={this.handleCloseModifyDialog.bind(this)}
                                fullWidth={true}
                            >
                                <DialogContent style={{padding: 0}}>
                                    <div style={{padding: 0}}>
                                        <GoogleMapsReact
                                            google={this.props.google}
                                            height={"70%"}
                                            width={"80%"}
                                            initialCenter={{
                                                lat: this.state.coords.lat,
                                                lng: this.state.coords.lon
                                            }}
                                            marker={this.state.coords}
                                            markerInfo={this.state.assetInfo}
                                            zoom={15}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleCloseModifyDialog.bind(this)}
                                        variant={"contained"}
                                        color={"secondary"}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/*modify geoFence*/}
                            <Dialog
                                fullScreen
                                open={this.state.openModifyDialog}
                                onClose={this.handleCloseModifyDialog.bind(this)}
                                fullWidth={true}
                                // TransitionComponent={this.transition}
                            >
                                <AppBar
                                    color={"white"}
                                    style={{
                                        height: "70px"
                                    }}
                                    className={classes.appBar}
                                >
                                    <Toolbar>
                                        <Grid
                                            justify="space-between" // Add it here :)
                                            container
                                            spacing={12}
                                        >
                                            <Grid item>
                                                <div
                                                    style={{
                                                        marginTop: "7px",
                                                        padding: "19px 8px"
                                                    }}
                                                >
                                                    <Typography variant="button">
                                                        Modify Geofence
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item style={{marginTop: "10px"}}></Grid>
                                            <Grid item>
                                                <div>
                                                    {this.props.isLoading ? (
                                                        <CircularProgress color={"white"}/>
                                                    ) : (
                                                        // this.props.geofenceModificationSuccess !== null ?
                                                        //     <div>
                                                        //         {/*<Typography*/}
                                                        //         {/*    className={classes.nameText}*/}
                                                        //         {/*    variant="body1"*/}
                                                        //         {/*    style={{*/}
                                                        //         {/*        color: '#fff',*/}
                                                        //         {/*        backgroundColor: '#36a536',*/}
                                                        //         {/*        padding: '10px 15px',*/}
                                                        //         {/*        borderRadius: '5px'*/}
                                                        //         {/*    }}*/}
                                                        //         {/*>*/}
                                                        //         {/*    /!*{*!/*/}
                                                        //         {/*    /!*    this.props.geofenceModificationSuccess === "GeoFence Updated successfully" ?*!/*/}
                                                        //         {/*    /!*        // this.props.enqueueSnackbar("Geo_fence modify successfully", {*!/*/}
                                                        //         {/*    /!*        //     variant: 'success',*!/*/}
                                                        //         {/*    /!*        //     anchorOrigin: {*!/*/}
                                                        //         {/*    /!*        //         vertical: "top",*!/*/}
                                                        //         {/*    /!*        //         autoHideDuration: 5000,*!/*/}
                                                        //         {/*    /!*        //         horizontal: "center"*!/*/}
                                                        //         {/*    /!*        //     }*!/*/}
                                                        //         {/*    /!*        // }*!/*/}
                                                        //         {/*    /!*        ""*!/*/}
                                                        //         {/*    /!*        : this.props.enqueueSnackbar("Something Went Wrong.!", {*!/*/}
                                                        //         {/*    /!*            variant: 'error',*!/*/}
                                                        //         {/*    /!*            anchorOrigin: {*!/*/}
                                                        //         {/*    /!*                vertical: "top",*!/*/}
                                                        //         {/*    /!*                autoHideDuration: 5000,*!/*/}
                                                        //         {/*    /!*                horizontal: "center"*!/*/}
                                                        //         {/*    /!*            }*!/*/}
                                                        //         {/*    /!*        })}*!/*/}
                                                        //         {/*</Typography>*/}
                                                        //         <Button edge="start" color="inherit"
                                                        //                 onClick={this.handleCloseModifyDialog.bind(this)}
                                                        //                 aria-label="close"
                                                        //                 variant={'text'}
                                                        //                 color="inherit"
                                                        //         >
                                                        //             Close
                                                        //         </Button>
                                                        //     </div>
                                                        // :
                                                        <div style={{padding: "8px 8px"}}>
                                                            <Button
                                                                variant={"contained"}
                                                                color="primary"
                                                                // style={{padding: '8px 8px'}}
                                                                onClick={this.handleSubmit}
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                edge="start"
                                                                color="inherit"
                                                                onClick={this.handleCloseModifyDialog.bind(
                                                                    this
                                                                )}
                                                                aria-label="close"
                                                                variant={"text"}
                                                            >
                                                                Close
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent style={{padding: 0}}>
                                    <div style={{padding: 0}}>
                                        <GoogleMaps
                                            getUpdatedPolygon={this.getUpdatedPolygonArray}
                                            coords={this.state.coords}
                                        />
                                    </div>
                                </DialogContent>
                            </Dialog>

                            {/*map view*/}
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleClose.bind(this)}
                                fullWidth={true}
                            >
                                <DialogContent style={{padding: 0}}>
                                    <div style={{padding: 0}}>
                                        <MapForPolygon
                                            markerInfo={this.state.geofenceInfo}
                                            google={this.props.google}
                                            coords={this.state.coords}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container spacing>
                                        <Grid xs={2}>
                                            <Button
                                                onClick={this.handleCloseModifyDialog.bind(this)}
                                                variant={"contained"}
                                                color={"secondary"}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>

                            {/*located me*/}
                            <Dialog
                                open={this.state.openLocation}
                                onClose={this.handleCloseModifyDialog.bind(this)}
                                fullWidth={true}
                            >
                                <DialogContent style={{padding: 0}}>
                                    <div style={{padding: 0}}>
                                        <GoogleMapsReact
                                            google={this.props.google}
                                            height={"70%"}
                                            width={"80%"}
                                            initialCenter={{
                                                lat: this.state.coords.lat,
                                                lng: this.state.coords.lon
                                            }}
                                            marker={this.state.coords}
                                            markerInfo={this.state.assetInfo}
                                            zoom={20}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container spacing>
                                        <Grid>
                                            <Button
                                                onClick={this.handleCloseModifyDialog.bind(this)}
                                                variant={"contained"}
                                                color={"secondary"}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>

                            {/*rrp and partner assign dialog*/}
                            <Dialog
                                fullWidth
                                aria-labelledby="draggable-dialog-title"
                                open={this.state.rppDialog}
                                onClose={this.handleDialogClose.bind(this)}
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                >
                                    <div style={{margin: "15px 0px"}}>
                                        <Typography style={{color: "#fff"}} variant="h4">
                                            Finserv Enabled
                                        </Typography>
                                    </div>
                                    <div style={{margin: "10px 0px"}}>
                                        <Typography
                                            variant="subtitle2"
                                            style={{marginTop: "2%", color: "#fff"}}
                                        >
                                            Please select the respective Partner and their Rpp to bind
                                            it with the respective Customer asset
                                        </Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        style={{margin: "10px 0"}}
                                    >
                                        <Autocomplete
                                            id="combo-box-for-tracker"
                                            onChange={this.handleChangeSelectPartner}
                                            options={this.state.listFinservPartner}
                                            style={{margin: "10px 0"}}
                                            fullWidth
                                            loading={this.state.isLoading}
                                            getOptionLabel={option => option.name}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Select Partner"
                                                    variant="outlined"
                                                    onChange={e => this.onSearchTerm(e.target.value)}
                                                />
                                            )}
                                        />
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        style={{margin: "10px 0"}}
                                    >
                                        <Autocomplete
                                            id="combo-box-for-tracker"
                                            onChange={this.handleSelectRpp}
                                            options={this.state.listFinservRefuelersForOrder}
                                            style={{margin: "10px 0"}}
                                            fullWidth
                                            loading={this.state.isLoading}
                                            getOptionLabel={option =>
                                                option.vehicle_registration_number
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Select Rpp"
                                                    variant="outlined"
                                                    onChange={e => this.handleOnChange}
                                                />
                                            )}
                                        />
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        autoFocus
                                        onClick={this.handleDialogClose.bind(this)}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    {this.state.rpp_id === 0 || this.state.partnerId === 0 ? (
                                        ""
                                    ) : (
                                        <Button onClick={this.handleOnSubmit} color="primary">
                                            Continue
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>

                            {/* finserv Owned Dialog  */}
                            <Dialog
                                fullWidth
                                aria-labelledby="draggable-dialog-title"
                                open={this.state.finservOwnedDialog}
                                onClose={this.handleDialogClose.bind(this)}
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                >
                                    <div style={{margin: "15px 0px"}}>
                                        <Typography style={{color: "#fff"}} variant="h4">
                                            Add Finserv Owned Datum
                                        </Typography>
                                    </div>
                                    <div style={{margin: "10px 0px"}}>
                                        <Typography
                                            variant="subtitle2"
                                            style={{marginTop: "2%", color: "#fff"}}
                                        >
                                            Please fill the form for finserv owned datum.
                                        </Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <TextField
                                        fullWidth
                                        size={"medium"}
                                        id="installation_date"
                                        label="Installation Date"
                                        type="date"
                                        value={this.state.installation_date || ""}
                                        onChange={this.handleInputChange}
                                        style={{margin: "10px 0 "}}
                                        className={classes.textField}
                                        variant={"outlined"}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        size={"medium"}
                                        id="last_payment_date"
                                        label="Last Payment Date"
                                        type="date"
                                        value={this.state.last_payment_date || ""}
                                        onChange={this.handleInputChange}
                                        style={{margin: "10px 0"}}
                                        className={classes.textField}
                                        variant={"outlined"}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        size={"medium"}
                                        id="upcoming_payment_date"
                                        label="Upcoming Payment Date"
                                        type="date"
                                        value={this.state.upcoming_payment_date || ""}
                                        onChange={this.handleInputChange}
                                        style={{margin: "10px 0"}}
                                        className={classes.textField}
                                        variant={"outlined"}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        style={{margin: "10px 0"}}
                                        labelId="deposit_amount"
                                        id="deposit_amount"
                                        label="Deposit Amount"
                                        value={this.state.deposit_amount || ""}
                                        onChange={this.handleInputChange}
                                    />
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        style={{margin: "10px 0"}}
                                        labelId="rental_amount_without_gst"
                                        id="rental_amount_without_gst"
                                        label="Rental Amount Without GST"
                                        value={this.state.rental_amount_without_gst || ""}
                                        onChange={this.handleInputChange}
                                    />
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        style={{margin: "10px 0"}}
                                        labelId="subscriptions_paid"
                                        id="subscriptions_paid"
                                        label="Subscriptions Paid"
                                        value={this.state.subscriptions_paid || ""}
                                        onChange={this.handleInputChange}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label={"Invoice Sent"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.invoice_sent || ""}
                                        onChange={this.handleInputChange}
                                        id={"invoice_sent"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        {/* value={this.state.partner_type} */}
                                        <option key={0} value={""}></option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </TextField>
                                    <TextField
                                        label="Remarks"
                                        margin="dense"
                                        id="remarks"
                                        fullWidth
                                        value={this.state.remarks || ""}
                                        onChange={this.handleInputChange}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        autoFocus
                                        onClick={this.handleDialogClose.bind(this)}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    {this.state.installation_date === "" ||
                                    this.state.last_payment_date === "" ||
                                    this.state.upcoming_payment_date === "" ||
                                    this.state.deposit_amount === "" ||
                                    this.state.rental_amount_without_gst === "" ||
                                    this.state.subscriptions_paid === "" ||
                                    this.state.invoice_sent === "" ||
                                    this.state.remarks === "" ? (
                                        ""
                                    ) : (
                                        <Button
                                            onClick={this.handleFinservOwnedSubmit}
                                            color="primary"
                                        >
                                            Continue
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>

                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.assetDataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoading: state.isLoading,
        allPartnersData: state.allPartnersData,
        geofenceModificationSuccess: state.geofenceModificationSuccess,
        geofenceModificationFail: state.geofenceModificationFail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleModifyGeofence: (accessToken, geofenceId, polygonArray) => {
            dispatch(modifyGeofence(accessToken, geofenceId, polygonArray));
        },
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(UsersTable)));
