import React, {Component} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@material-ui/core/Button";
import {withSnackbar} from "notistack";
import compose from "recompose/compose";
import styles from "./styles";
import {colors, LinearProgress, Paper, TablePagination, withStyles} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {sendLogs} from "../../../../actions/actions";
import {connect} from "react-redux";
import Label from "../../../Dashboard/components/Label";

class Cards extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        open: false,
        imageUrl: "",
        users: [],
        right: false,
        updatedStatus: "",
        orderId: "",
        dialog_open: false,
        order_qty: 0,
        datumOrderState: []
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page, null);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0, null);
    };

    handleFieldChange = event => {
        this.setState({
            order_qty: event
        });
    };

    handleChangeStatus = () => {
        this.props.getUpdatedStatus(
            this.state.updatedStatus,
            this.state.orderId,
            this.state.order_qty
        );
        this.handleClose();
    };

    handleClose() {
        this.setState({
            ...this.state,
            dialog_open: false
        });
    }

    handleUpdateStatus = (event, orderId, status) => {
        this.setState({
            dialog_open: true,
            orderId,
            updatedStatus: event.target.value
        });
    };

    render() {
        const {classes, datumOrdersData} = this.props;

        const orderStatusColors = {
            1: colors.grey[600],
            2: colors.orange[600],
            3: colors.green[600],
            4: colors.purple[600],
            5: colors.yellow[600],
            6: colors.red["A700"]
        };

        const asstType = {
            1: "DATUM",
            2: "SFD",
            4: "SFC",
            10: "MOBILE DATUM"
        };

        const nf = new Intl.NumberFormat("en-IN");

        return (
            <>
                {this.props.isLoading ? (
                    <div className={classes.progressWrapper}>
                        <LinearProgress fullWidth className={classes.progress}/>
                    </div>
                ) : null}
                {this.props.tabClick === 0
                    ? datumOrdersData?.map(singleDatumOrder => (
                        <Box sx={{flexGrow: 2}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Paper elevation={2} className={classes.paper}>
                                            <Box display="flex" flexDirection="column">
                                                <Box
                                                    py={1}
                                                    px={2}
                                                    display="flex"
                                                    className={classes.header}
                                                    style={{
                                                        background:
                                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                    }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.textWhite}
                                                        style={{color: "primary"}}
                                                    >
                                                        #ID {singleDatumOrder.id}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.textWhite}
                                                        style={{color: "primary"}}
                                                    >
                                                        {singleDatumOrder.created_at}
                                                    </Typography>
                                                </Box>
                                                <Box p={2} spacing={2}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        align="space-around"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Box px={1} py={0} display="flex">
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Customer Name:-
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    align="center"
                                                                >
                                                                    {singleDatumOrder.customer_name}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box
                                                                px={1}
                                                                py={0}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Dispensed To
                                                                </Typography>
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="body2"
                                                                    align="center"
                                                                >
                                                                    {singleDatumOrder.dispensed_to}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box
                                                                px={1}
                                                                py={0}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Order Quantity
                                                                </Typography>
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="body2"
                                                                >
                                                                    {nf.format(singleDatumOrder.quantity)}{" "}
                                                                    Litres
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={classes.marginRightAuto}
                                                        >
                                                            <Box
                                                                px={1}
                                                                py={0}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Dispensed Quantity
                                                                </Typography>
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="caption"
                                                                >
                                                                    {nf.format(
                                                                        singleDatumOrder.dispensed_quantity
                                                                    )}{" "}
                                                                    Litres
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Box
                                                                px={0}
                                                                py={2}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Label
                                                                    color={
                                                                        singleDatumOrder.status === 1
                                                                            ? orderStatusColors[2]
                                                                            : singleDatumOrder.status === 2
                                                                                ? orderStatusColors[3]
                                                                                : singleDatumOrder.status === 3
                                                                                    ? orderStatusColors[6]
                                                                                    : singleDatumOrder.status === 4
                                                                                        ? orderStatusColors[5]
                                                                                        : singleDatumOrder.status === 5
                                                                                            ? orderStatusColors[4]
                                                                                            : orderStatusColors[1]
                                                                    }
                                                                >
                                                                    {singleDatumOrder.status === 1
                                                                        ? "On Going"
                                                                        : singleDatumOrder.is_done_by_lmd ===
                                                                        false && singleDatumOrder.status === 2
                                                                            ? "Completed"
                                                                            : singleDatumOrder.is_done_by_lmd ===
                                                                            false && singleDatumOrder.status === 3
                                                                                ? "Cancelled"
                                                                                : singleDatumOrder.status === 4
                                                                                    ? "Placed"
                                                                                    : singleDatumOrder.status === 5
                                                                                        ? "Dispensing"
                                                                                        : singleDatumOrder.is_done_by_lmd ===
                                                                                        true && singleDatumOrder.status === 2
                                                                                            ? "Completed by LMD"
                                                                                            : singleDatumOrder.is_done_by_lmd ===
                                                                                            true && singleDatumOrder.status === 3
                                                                                                ? "Cancelled by lmd"
                                                                                                : "Not Available"}
                                                                </Label>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6} align={"right"}>
                                                            <Box>
                                                                {singleDatumOrder.status === 2 ? (
                                                                    "Order is Completed"
                                                                ) : singleDatumOrder.status === 3 ? (
                                                                    "Order is Cancelled"
                                                                ) : (
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        className={classes.formControl}
                                                                        style={{minWidth: 120}}
                                                                    >
                                                                        <InputLabel id="update-input-selector">
                                                                            Update Status
                                                                        </InputLabel>
                                                                        <Select
                                                                            style={{width: "130px"}}
                                                                            labelId="update-input-selector-label"
                                                                            id="update-input-selector-select"
                                                                            label="Update Status"
                                                                            onChange={event =>
                                                                                this.handleUpdateStatus(
                                                                                    event,
                                                                                    singleDatumOrder.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            <MenuItem value={2}>Completed</MenuItem>
                                                                            <MenuItem value={3}>Cancelled</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                    : datumOrdersData?.map(singleDatumOrder => (
                        <Box sx={{flexGrow: 2}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Paper elevation={2} className={classes.paper}>
                                            <Box display="flex" flexDirection="column">
                                                <Box
                                                    py={1}
                                                    px={2}
                                                    display="flex"
                                                    className={classes.header}
                                                    style={{
                                                        background:
                                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                    }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.textWhite}
                                                        style={{color: "primary"}}
                                                    >
                                                        {singleDatumOrder.asset_name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.textWhite}
                                                        style={{color: "primary"}}
                                                    >
                                                        {singleDatumOrder.created_at}
                                                    </Typography>
                                                </Box>
                                                <Box p={2} spacing={2}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        align="space-around"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Box px={1} py={0} display="flex">
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Customer Name:-
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    align="center"
                                                                >
                                                                    {singleDatumOrder.customer_name}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box
                                                                px={1}
                                                                py={0}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    City
                                                                </Typography>
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="body2"
                                                                    align="center"
                                                                >
                                                                    {singleDatumOrder.city}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box
                                                                px={1}
                                                                py={0}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    State
                                                                </Typography>
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="body2"
                                                                >
                                                                    {singleDatumOrder.state}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={classes.marginRightAuto}
                                                        >
                                                            <Box
                                                                px={1}
                                                                py={0}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Level Info
                                                                </Typography>
                                                                <FormControl className={classes.formControl}>
                                                                    {singleDatumOrder.levels_info === null ? (
                                                                        "No data"
                                                                    ) : (
                                                                        <div>
                                                                            <InputLabel id="levels_info">
                                                                                Levels
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId="levels_info"
                                                                                id="levels_info"
                                                                                style={{width: "65px"}}
                                                                            >
                                                                                <MenuItem value={10}>
                                                                                    Level 1:{" "}
                                                                                    {
                                                                                        singleDatumOrder?.levels_info
                                                                                            ?.lvl1
                                                                                    }
                                                                                </MenuItem>
                                                                                <MenuItem value={20}>
                                                                                    Level 2:{" "}
                                                                                    {
                                                                                        singleDatumOrder?.levels_info
                                                                                            ?.lvl2
                                                                                    }
                                                                                </MenuItem>
                                                                                <MenuItem value={30}>
                                                                                    Level 3:{" "}
                                                                                    {
                                                                                        singleDatumOrder?.levels_info
                                                                                            ?.lvl3
                                                                                    }
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </div>
                                                                    )}
                                                                </FormControl>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box
                                                                px={0}
                                                                py={2}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Datum Model
                                                                </Typography>
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="body2"
                                                                >
                                                                    {singleDatumOrder.datum_models}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4} align={"right"}>
                                                            <Box
                                                                px={0}
                                                                py={2}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Asset Type
                                                                </Typography>
                                                                <Typography
                                                                    className={classes.tableCell}
                                                                    align="center"
                                                                >
                                                                    {asstType[singleDatumOrder.asset_type]}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4} align={"right"}>
                                                            <Box
                                                                px={0}
                                                                py={2}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textPrimary"
                                                                    align="center"
                                                                >
                                                                    Sub-Asset Count
                                                                </Typography>
                                                                <Typography
                                                                    className={classes.tableCell}
                                                                    align="center"
                                                                >
                                                                    {singleDatumOrder.subasset_count}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                <div>
                    {this.state.updatedStatus === 3 ? (
                        <Dialog
                            fullWidth
                            open={this.state.dialog_open}
                            onClose={this.handleClose.bind(this)}
                            aria-labelledby="simple-dialog-title"
                        >
                            {/*<DialogTitle style={{background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}} id="simple-dialog-title">*/}
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                                id="simple-dialog-title"
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        Change the order status?
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div style={{margin: "10px 0px"}}>
                                    <Typography variant={"subtitle1"}>
                                        Are you sure you want to Cancel this Order ?
                                    </Typography>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="text"
                                    color="default"
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={e => this.handleChangeStatus(e)}
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                        color: "#fff"
                                    }}
                                >
                                    Update
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <Dialog
                            fullWidth
                            open={this.state.dialog_open}
                            onClose={this.handleClose.bind(this)}
                            aria-labelledby="simple-dialog-title"
                        >
                            {/*<DialogTitle style={{background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}} id="simple-dialog-title">*/}
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                                id="simple-dialog-title"
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        Change the order status?
                                    </Typography>
                                    <Typography style={{color: "#fff"}} variant={"subtitle1"}>
                                        Are you sure you want to edit the given details ?
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div style={{margin: "10px 0px"}}>
                                    <TextField
                                        label="Order Quantity"
                                        id="outlined-size-small"
                                        variant="outlined"
                                        onChange={e => this.handleFieldChange(e.target.value)}
                                        size="medium"
                                        fullWidth
                                        type={"number"}
                                        required
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="text"
                                    color="default"
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={e => this.handleChangeStatus(e)}
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                        color: "#fff"
                                    }}
                                >
                                    Update
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </div>
                <div>
                    {this.props.tabClick === 0 ? (
                        <TablePagination
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            component="div"
                            count={this.props.dataCount}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            page={this.props.page}
                            rowsPerPage={this.props.rowsPerPage}
                            rowsPerPageOptions={[10, 100, 200]}
                        />
                    ) : (
                        <TablePagination
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            component="div"
                            count={this.props.dataCount}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            page={this.props.page}
                            rowsPerPage={this.props.rowsPerPage}
                            rowsPerPageOptions={[10, 50, 100]}
                        />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        sessionExpired: state.sessionExpired,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(Cards)));
