import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
// Material components
import {Typography, withStyles} from "@material-ui/core";

// Material icons
import {PeopleOutlined as PeopleIcon} from "@material-ui/icons";

// Shared components
import {Paper} from "components";

// Component styles
import styles from "./styles";

class Users extends Component {
    render() {
        const {classes, className, customerData, ...rest} = this.props;
        const nf = new Intl.NumberFormat("en-IN");
        const rootClassName = classNames(classes.root, className);

        return (
            <Paper {...rest} className={rootClassName}>
                <div className={classes.content}>
                    <div className={classes.details}>
                        <Typography className={classes.title} variant="body2">
                            TOTAL USERS
                        </Typography>
                        <Typography className={classes.value} variant="h3">
                            {nf.format(customerData?.all_customer_count)}
                        </Typography>
                    </div>
                    <div className={classes.iconWrapper}>
                        <PeopleIcon className={classes.icon}/>
                    </div>
                </div>
                <div className={classes.footer}>
                    {/*<Typography*/}
                    {/*  className={classes.difference}*/}
                    {/*  variant="body2"*/}
                    {/*>*/}
                    {/*  <ArrowUpwardIcon />*/}
                    {/*  16%*/}
                    {/*</Typography>*/}
                    <Typography className={classes.caption} variant="caption">
                        Count of all customers since all time
                    </Typography>
                </div>
            </Paper>
        );
    }
}

Users.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Users);
