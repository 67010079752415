import React, {Component, Fragment} from "react";
import {withRouter} from "react-router-dom";

// Externals
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";

// Material helpers
// Material components
import {
    Badge,
    IconButton,
    Popover,
    Toolbar,
    Typography,
    withStyles
} from "@material-ui/core";

// Material icons
import {
    Close as CloseIcon,
    Input as InputIcon,
    Menu as MenuIcon,
    NotificationsOutlined as NotificationsIcon
} from "@material-ui/icons";

// Shared services
import {getNotifications} from "services/notification";

// Custom components
import {NotificationList} from "./components";

// Component styles
import styles from "./styles";
import {logoutRequest} from "../../../../actions/actions";
import {connect} from "react-redux";
import secureLocalStorage from "react-secure-storage";

class Topbar extends Component {
    signal = true;

    state = {
        notifications: [],
        notificationsLimit: 4,
        notificationsCount: 0,
        notificationsEl: null
    };

    async getNotifications() {
        try {
            const {notificationsLimit} = this.state;

            const {notifications, notificationsCount} = await getNotifications(
                notificationsLimit
            );

            if (this.signal) {
                this.setState({
                    notifications,
                    notificationsCount
                });
            }
        } catch (error) {
        }
    }

    componentDidMount() {
        this.signal = true;
        this.getNotifications();
    }

    componentWillUnmount() {
        this.signal = false;
    }

    handleSignOut = () => {
        const {history} = this.props;
        secureLocalStorage.setItem("isAuthenticated", false);
        secureLocalStorage.setItem("accessToken", false);
        history.push("/sign-in");
    };

    handleShowNotifications = event => {
        this.setState({
            notificationsEl: event.currentTarget
        });
    };

    handleCloseNotifications = () => {
        this.setState({
            notificationsEl: null
        });
    };

    render() {
        const {
            classes,
            className,
            title,
            isSidebarOpen,
            onToggleSidebar
        } = this.props;
        const {notifications, notificationsCount, notificationsEl} = this.state;

        const rootClassName = classNames(classes.root, className);
        const showNotifications = Boolean(notificationsEl);

        return (
            <Fragment>
                <div className={rootClassName}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            className={classes.menuButton}
                            onClick={onToggleSidebar}
                            variant="text"
                        >
                            {isSidebarOpen ? <CloseIcon/> : <MenuIcon/>}
                        </IconButton>
                        <Typography className={classes.title} variant="h4">
                            {title}
                        </Typography>
                        <IconButton
                            className={classes.notificationsButton}
                            onClick={this.handleShowNotifications}
                        >
                            <Badge
                                badgeContent={notificationsCount}
                                color="primary"
                                variant="dot"
                            >
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                        <IconButton
                            className={classes.signOutButton}
                            // onClick={this.handleSignOut}
                            onClick={() => this.props.handleLogoutRequest(this.props.history)}
                        >
                            <InputIcon/>
                        </IconButton>
                    </Toolbar>
                </div>
                <Popover
                    anchorEl={notificationsEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    onClose={this.handleCloseNotifications}
                    open={showNotifications}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <NotificationList
                        notifications={notifications}
                        onSelect={this.handleCloseNotifications}
                    />
                </Popover>
            </Fragment>
        );
    }
}

Topbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool,
    onToggleSidebar: PropTypes.func,
    title: PropTypes.string
};

Topbar.defaultProps = {
    onToggleSidebar: () => {
    }
};

const mapStateToProps = state => {
    return {
        accessToken: state.accessToken,
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess,
        loginError: state.loginError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleLogoutRequest: history => {
            dispatch(logoutRequest(history));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withRouter, withStyles(styles))(Topbar));
