import React, {Component} from "react";
// import { Link } from 'react-router-dom';
// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    withStyles
} from "@material-ui/core";
// import Avatar from '@material-ui/core/Avatar';
// Shared helpers
// import { getInitials } from 'helpers';
// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import GoogleMaps from "../../../../components/GoogleMaps";
import GoogleMapsReact from "../../../../components/GoogleMaps/google_maps_react";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";

// import {DashboardWithoutFooter} from "../../../../layouts";

class UsersTable extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        latLon: "",
        reg_no: ""
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    openDialog(latLon, vehicle_info) {
        this.setState({
            open: true,
            latLon: latLon,
            reg_no: vehicle_info
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    render() {
        const {classes, className} = this.props;
        const rootClassName = classNames(classes.root, className);

        if (this.props.allTrackerData?.length === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table id={"refuellerTable"}>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">Serial Number</TableCell>
                                                <TableCell align="center">Name</TableCell>
                                                <TableCell align="center">Unique Address</TableCell>
                                                <TableCell align="center">Type</TableCell>
                                                <TableCell align="center">Activation Status</TableCell>
                                                <TableCell align="center">Current Status</TableCell>
                                                <TableCell align="center">Created At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"13"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {this.props.allTrackerData?.map(tracker => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={tracker.id}
                                                        id={"table_row_allRefuellers"}
                                                    >
                                                        {/*id*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.id}
                                                        </TableCell>
                                                        {/*vehicle number*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.serial_number}
                                                        </TableCell>
                                                        {/*partner*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.name}
                                                        </TableCell>
                                                        {/*partner*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.unique_address}
                                                        </TableCell>
                                                        {/*partner*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.type}
                                                        </TableCell>
                                                        {/*last stock*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.is_active ? "Active" : "De-Active"}
                                                        </TableCell>
                                                        {/*last stock*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.is_online ? "Online" : "Offline"}
                                                        </TableCell>
                                                        {/*Driver name*/}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {tracker.created_at}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>

                            <Dialog
                                open={this.state.open}
                                onClose={this.handleClose.bind(this)}
                                fullWidth={true}
                            >
                                <DialogContent style={{padding: 0}}>
                                    <div style={{padding: 0}}>
                                        <GoogleMapsReact
                                            google={this.props.google}
                                            initialCenter={{
                                                lat: this.state.latLon[1],
                                                lng: this.state.latLon[0]
                                            }}
                                            marker={this.state.latLon}
                                            markerInfo={this.state.reg_no}
                                            zoom={15}
                                        />
                                    </div>
                                </DialogContent>
                            </Dialog>
                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    // rowsPerPageOptions={[this.props.rowsPerPage, this.props.rowsPerPage*2, this.props.rowsPerPage*5]}
                                    rowsPerPageOptions={[10, 20, 50]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withStyles(styles)(UsersTable);
