export default theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    progressWrapper: {
        paddingTop: "48px",
        paddingBottom: "24px",
        display: "flex",
        justifyContent: "center"
    },
    pagination: {
        marginTop: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
});
