import React from "react";
// Material helpers
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";
// Component styles
import styles from "./style";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
// import LivePage from "./livePage";
import {
    LocalGasStationOutlined as PumpIcon,
    LocalShippingOutlined as RefuellerIcon,
    PeopleOutlined as PeopleIcon
} from "@material-ui/icons";
import {getAllMarkers, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
// Externals
import PropTypes from "prop-types";

// Material components
import GpIcon from "./Components/GpIcon";
import CustomGoogleMap from "./Components";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Skeleton} from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";

class LiveStatus2 extends React.Component {
    state = {
        value: 0,
        center: {
            lat: 20.5937,
            lon: 78.9629
        },
        zoom: 5,
        pos: null,
        checked: false
    };

    getMarkersData = (event, value) => {
        this.setState({value});

        this.props.handleGetMarkers(
            secureLocalStorage.getItem("accessToken"),
            value
        );
    };

    handleChange = event => {
        this.setState({
            checked: event.target.checked
        });
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getMarkersData(secureLocalStorage.getItem("accessToken"), 0);
    }

    render() {
        const {value} = this.state;

        const style = {
            root: {
                width: 500
            },
            bottomNav: {
                position: "absolute",
                left: 0,
                width: "100%",
                bottom: 0
            },
            progress: {
                display: "block",
                marginTop: "50%",
                marginLeft: "auto",
                marginRight: "auto"
            },
            progressDiv: {
                marginLeft: "47.5%",
                marginTop: "30%"
            }
        };

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history?.push("/sign-in");
        } else
            return (
                <div>
                    <DashboardLayout title="Live Status">
                        <div>
                            <CustomGoogleMap
                                data={this.props.allMarkersData}
                                value={value}
                                cluster={this.state.checked}
                                isLoading={this.props.isLoading}
                            />
                            {this.props.isLoading ? (
                                <div>
                                    <CircularProgress className={style.progress}/>
                                    <BottomNavigation
                                        value={value}
                                        className={style.root}
                                        style={style.bottomNav}
                                    >
                                        <BottomNavigationAction
                                            id={"refuellers"}
                                            label="Refuellers"
                                            value={0}
                                            icon={
                                                <Skeleton variant="circle" width={40} height={40}/>
                                            }
                                        />
                                        <BottomNavigationAction
                                            id={"partners"}
                                            label="Partners"
                                            value={1}
                                            icon={
                                                <Skeleton variant="circle" width={40} height={40}/>
                                            }
                                        />
                                        <BottomNavigationAction
                                            id={"customer"}
                                            label="Customers"
                                            value={2}
                                            icon={
                                                <Skeleton variant="circle" width={40} height={40}/>
                                            }
                                        />
                                        <BottomNavigationAction
                                            id={"gps"}
                                            label="Growth Partners"
                                            value={3}
                                            icon={
                                                <Skeleton variant="circle" width={40} height={40}/>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checked}
                                                    onChange={this.handleChange}
                                                    name="clusterView"
                                                />
                                            }
                                            label="Cluster View"
                                        />
                                    </BottomNavigation>
                                </div>
                            ) : (
                                <BottomNavigation
                                    value={value}
                                    onChange={this.getMarkersData}
                                    className={style.root}
                                    style={style.bottomNav}
                                >
                                    <BottomNavigationAction
                                        id={"refuellers"}
                                        label="Refuellers"
                                        value={0}
                                        icon={<RefuellerIcon/>}
                                    />
                                    <BottomNavigationAction
                                        id={"partners"}
                                        label="Partners"
                                        value={1}
                                        icon={<PumpIcon/>}
                                    />
                                    <BottomNavigationAction
                                        id={"customer"}
                                        label="Customers"
                                        value={2}
                                        icon={<PeopleIcon/>}
                                    />
                                    <BottomNavigationAction
                                        id={"gps"}
                                        label="Growth Partners"
                                        value={3}
                                        icon={<GpIcon/>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checked}
                                                onChange={this.handleChange}
                                                name="clusterView"
                                            />
                                        }
                                        label="Cluster View"
                                    />
                                </BottomNavigation>
                            )}
                        </div>
                    </DashboardLayout>
                </div>
            );
        return true;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allMarkersData: state.allMarkersData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetMarkers: (accessToken, filterValue) => {
            dispatch(getAllMarkers(accessToken, filterValue));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

LiveStatus2.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(LiveStatus2));
