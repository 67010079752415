import React, {Component} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import reposWebApi from "../../../../actions/configuration";
import {connect} from "react-redux";
import {sendLogs} from "../../../../actions/actions";
import qs from "qs";
import {withSnackbar} from "notistack";
import compose from "recompose/compose";

import styles from "./styles";
import {
    Avatar,
    InputAdornment,
    LinearProgress,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    withStyles
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import EditPump from "../EditPump";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import capitalize from "@material-ui/core/utils/capitalize";
import {
    CloseOutlined as CloseButton,
    EditOutlined as EditIcon,
    MoreHorizOutlined as MoreIcon,
    SettingsOutlined as SettingsIcon
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import RestoreIcon from "@material-ui/icons/Restore";
import DialogContentText from "@material-ui/core/DialogContentText";

import NoDatafound from "../../../AllCustomers/components/NoDataInTable";

import Drawer from "@material-ui/core/Drawer";
import Label from "../../../Dashboard/components/Label";
import secureLocalStorage from "react-secure-storage";

class Cards extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        open: false,
        imageUrl: "",
        users: [],
        right: false,
        editPartner: false,
        showMore: false,
        showMorePump: [],
        pumpDetails: [],
        selectedPump: [],
        is_pump_Active: false,
        pump_name: "",
        dialog_Open: false,
        pump_number: 0,
        is_pump_monetize: false,
        dialog_monetize_open: false,
        repos_share: "",
        agreement_date: "",
        showRegionPump: [],
        showZonePump: [],
        convenience_factor: 0
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page, null);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0, null);
    };

    handleChange = (e, field) => {
        e.preventDefault();
        if (field === "repos_share") {
            this.setState({
                [e.target.name]: (e.target.value = Math.max(
                    0,
                    parseFloat(e.target.value)
                )
                    .toString()
                    .slice(0, 6))
            });
        }
        if (field === "convenience_factor") {
            this.setState({
                [e.target.name]: (e.target.value =
                    e.target.value.indexOf(".") >= 0
                        ? e.target.value.substr(0, e.target.value.indexOf(".")) +
                        e.target.value.substr(e.target.value.indexOf("."), 3)
                        : e.target.value)
            });
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleDrawerOpen = (e, pump) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            right: true,
            selectedPump: pump
        });
    };

    handleDrawerOpenForMore = (e, pump) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            showMore: true,
            showMorePump: pump,
            pumpDetails: pump.pumps[0],
            showRegionPump: pump.region[0],
            showZonePump: pump.zone[0]
        });
    };

    handleDrawerClose = () => {
        this.setState({
            right: false,
            showMore: false,
            editPartner: false
        });
    };

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    handleEditPartnerDrawerOpen = (e, pump) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            editPartner: true,
            selectedPump: pump
        });
    };

    handleSetMinimumDeliverableQuantity = () => {
        const requestBody = {
            partner_id: this.state.selectedPump.id,
            quantity: this.state.minimumQuantity
        };

        reposWebApi
            .post("/partner/update_min_deliver_quan", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                this.props.enqueueSnackbar("Minimum Deliver Quantity Updated!", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                });
            })
            .catch(error => {
                this.props.enqueueSnackbar("Something Went Wrong!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                });
            });
    };

    partnerDialogMonetize = (event, pump, is_monetize) => {
        this.setState({
            dialog_monetize_open: true,
            pump_id: pump,
            is_pump_monetize: is_monetize
        });
    };

    partnerActivationDialog = (event, pump, is_active) => {
        event.preventDefault();
        this.setState({
            dialog_Open: true,
            pump_number: pump,
            is_pump_Active: is_active
        });
    };
    finservEnable = (event, pump, enable) => {
        const requestBody = {
            partner_id: pump,
            enabled: event.target.checked ? "True" : "False"
        };
        reposWebApi
            .post("/finserv/partner/enable", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.props.enqueueSnackbar(
                        !enable
                            ? "Partner Finserv Enable Successfully"
                            : "Partner Finserv Disable Successfully",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/partner/enable",
                        qs.stringify({
                            partner_id: pump,
                            enabled: event.target.checked ? "True" : "False"
                        })
                    );
                } else if (response.status === 400) {
                    this.props.enqueueSnackbar("Please add correct details ", {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/partner/enable",
                        qs.stringify({
                            partner_id: pump,
                            enabled: event.target.checked ? "True" : "False"
                        })
                    );
                }
                this.redirectOnSuccess();
            })
            .catch(error => {
                this.props.enqueueSnackbar("Something Went Wrong!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                });
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.getItem("mobile"),
                    "/finserv/partner/enable",
                    qs.stringify({
                        partner_id: pump,
                        enabled: event.target.checked ? "True" : "False"
                    })
                );
            });
    };

    partnerMonetization = () => {
        const requestBody = {
            monetized: this.state.is_pump_monetize ? 1 : 0,
            partner_id: this.state.pump_id,
            repos_share: this.state.repos_share,
            asd: this.state.agreement_date,
            convenience_factor: this.state.convenience_factor
        };

        reposWebApi
            .post("/partner/change_partner_monetized", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.props.enqueueSnackbar(
                        requestBody.monetized
                            ? "Partner Monetized Successfully"
                            : "Partner Demonetized Successfully",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/partner/change_partner_monetized",
                        qs.stringify({
                            monetized: this.state.is_pump_monetize ? 1 : 0,
                            partner_id: this.state.pump_id,
                            repos_share: this.state.repos_share,
                            asd: this.state.agreement_date,
                            convenience_factor: this.state.convenience_factor
                        })
                    );
                } else if (response.status === 400) {
                    this.props.enqueueSnackbar("Please add correct details ", {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/partner/change_partner_monetized",
                        qs.stringify({
                            monetized: this.state.is_pump_monetize ? 1 : 0,
                            partner_id: this.state.pump_id,
                            repos_share: this.state.repos_share,
                            asd: this.state.agreement_date,
                            convenience_factor: this.state.convenience_factor
                        })
                    );
                }
                this.redirectOnSuccess();
            })
            .catch(error => {
                this.props.enqueueSnackbar("Something Went Wrong!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                });
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.getItem("mobile"),
                    "/partner/change_partner_monetized",
                    qs.stringify({
                        monetized: this.state.is_pump_monetize ? 1 : 0,
                        partner_id: this.state.pump_id,
                        repos_share: this.state.repos_share,
                        asd: this.state.agreement_date,
                        convenience_factor: this.state.convenience_factor
                    })
                );
            });
    };

    partnerActivation = () => {
        reposWebApi
            .get("/partner/pump_activation", {
                params: {
                    pump_id: this.state.pump_number,
                    decision: this.state.is_pump_Active ? 1 : 0
                },
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.props.enqueueSnackbar(response.data.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/partner/pump_activation",
                        qs.stringify({
                            pump_id: this.state.pump_number,
                            decision: this.state.is_pump_Active ? 1 : 0
                        })
                    );
                } else if (response.status === 202) {
                    this.props.enqueueSnackbar(response.data.data, {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/partner/pump_activation",
                        qs.stringify({
                            pump_id: this.state.pump_number,
                            decision: this.state.is_pump_Active ? 1 : 0
                        })
                    );
                }
                this.redirectOnSuccess();
            })
            .catch(error => {
                this.props.enqueueSnackbar("Something Went Wrong!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                });
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.getItem("mobile"),
                    "/partner/pump_activation",
                    qs.stringify({
                        pump_id: this.state.pump_number,
                        decision: this.state.is_pump_Active ? 1 : 0
                    })
                );
            });
    };

    handleFieldChange = (field, value) => {
        this.setState({minimumQuantity: value});
    };

    openDialog(imageUrl) {
        this.setState({
            open: true,
            imageUrl: imageUrl
        });
    }

    testHandle = event => {
    };

    handleClose() {
        this.setState({
            open: false,
            dialog_Open: false,
            dialog_monetize_open: false,
            is_pump_monetize: false,
            agreement_date: "",
            repos_share: null
        });
    }

    resetPasswordBtnSubmit = (event, partnerId) => {
        event.preventDefault();
        this.props.resetPassword(partnerId.id);
    };

    redirectOnSuccess = () => {
        this.props.onModificationSuccess();
        this.handleDrawerClose();
        this.handleClose();
        this.setState({
            agreement_date: "",
            repos_share: 0
        });
    };

    render() {
        const {classes, allPartnersData} = this.props;

        if (this.props.isLoading) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            return (
                <>
                    {this.props.isLoading ? (
                        <div className={classes.progressWrapper}>
                            <LinearProgress fullWidth className={classes.progress}/>
                        </div>
                    ) : null}
                    {this.props.noDataFound ? (
                        <NoDatafound/>
                    ) : (
                        <>
                            {allPartnersData.map(partner => (
                                <Box sx={{flexGrow: 2}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Paper elevation={2} className={classes.paper}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Box
                                                            py={1}
                                                            px={2}
                                                            display="flex"
                                                            className={classes.header}
                                                            style={{
                                                                background:
                                                                    "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                            }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Avatar
                                                                className={classes.avatar}
                                                                src={
                                                                    partner.pumps[0].pump_brand === "IOCL"
                                                                        ? "/images/avatars/iocl.png"
                                                                        : partner.pumps[0].pump_brand === "BPCL"
                                                                            ? "/images/avatars/bpcl.png"
                                                                            : partner.pumps[0].pump_brand === "HPCL"
                                                                                ? "/images/avatars/hpcl.png"
                                                                                : partner.pumps[0].pump_brand === "SHELL"
                                                                                    ? "/images/avatars/shell.png"
                                                                                    : partner.pumps[0].pump_brand === "ESSAR"
                                                                                        ? "/images/avatars/essar.jpeg"
                                                                                        : partner.pumps[0].pump_brand === "REL"
                                                                                            ? "/images/avatars/rel.jpg"
                                                                                            : ""
                                                                }
                                                            />
                                                            <Typography
                                                                className={classes.textWhite}
                                                                variant="body1"
                                                            >
                                                                {capitalize(partner.pumps[0].pump_name)}
                                                            </Typography>
                                                            <Button
                                                                size={"medium"}
                                                                variant="extended"
                                                                style={{textTransform: "capitalize"}}
                                                                aria-label="delete"
                                                                className={classes.fab}
                                                                onClick={event => {
                                                                    this.handleEditPartnerDrawerOpen(
                                                                        event,
                                                                        partner
                                                                    );
                                                                }}
                                                                disabled={
                                                                    secureLocalStorage.getItem("access_type") ===
                                                                    false
                                                                }
                                                            >
                                                                <EditIcon
                                                                    style={{color: "white"}}
                                                                    className={classes.extendedIcon}
                                                                />
                                                            </Button>
                                                        </Box>
                                                        <Box p={2} spacing={2}>
                                                            <Grid
                                                                container
                                                                spacing={3}
                                                                align="space-around"
                                                                justifyContent="center"
                                                            >
                                                                {/* <Grid item xs={12}>
                                                        <Box
                                                            px={1} py={0}
                                                            display="flex"
                                                        >
                                                        <Typography variant="body2" color="textSecondary" align="center">
                                                            City
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" align="center">
                                                        {partner.city_name}
                                                        </Typography>
                                                        </Box>
                                                    </Grid> */}
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            City
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                        >
                                                                            {partner.city_name}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Mobile
                                                                        </Typography>
                                                                        <a
                                                                            style={{
                                                                                color: "black",
                                                                                margin: 3
                                                                            }}
                                                                            href={"tel:+91" + partner.mobile}
                                                                        >
                                                                            {partner.mobile}
                                                                        </a>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Manage
                                                                        </Typography>
                                                                        <Button
                                                                            size={"medium"}
                                                                            variant="extended"
                                                                            style={{textTransform: "capitalize"}}
                                                                            aria-label="delete"
                                                                            className={classes.fab}
                                                                            onClick={event => {
                                                                                this.handleDrawerOpen(event, partner);
                                                                            }}
                                                                            disabled={
                                                                                secureLocalStorage.getItem(
                                                                                    "access_type"
                                                                                ) === false
                                                                            }
                                                                        >
                                                                            <SettingsIcon
                                                                                className={classes.extendedIcon}
                                                                            />
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className={classes.marginRightAuto}
                                                                >
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Finserv Eanble
                                                                        </Typography>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={partner.is_finserv_enabled}
                                                                                    name="monetizedSwitch"
                                                                                    edge={"start"}
                                                                                    classes={{
                                                                                        root: classes.root,
                                                                                        switchBase: classes.switchBase,
                                                                                        thumb: classes.thumb,
                                                                                        track: classes.track,
                                                                                        checked: classes.checked
                                                                                    }}
                                                                                    onChange={event =>
                                                                                        this.finservEnable(
                                                                                            event,
                                                                                            partner.id,
                                                                                            partner.is_finserv_enabled
                                                                                        )
                                                                                    }
                                                                                    inputProps={{
                                                                                        "aria-label": "secondary checkbox"
                                                                                    }}
                                                                                    disabled={
                                                                                        secureLocalStorage.getItem(
                                                                                            "access_type"
                                                                                        ) === false
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Monetization Status
                                                                        </Typography>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={partner.monetized}
                                                                                    name="monetizedSwitch"
                                                                                    edge={"start"}
                                                                                    classes={{
                                                                                        root: classes.root,
                                                                                        switchBase: classes.switchBase,
                                                                                        thumb: classes.thumb,
                                                                                        track: classes.track,
                                                                                        checked: classes.checked
                                                                                    }}
                                                                                    onChange={event =>
                                                                                        this.partnerDialogMonetize(
                                                                                            event,
                                                                                            partner.id,
                                                                                            !partner.monetized
                                                                                        )
                                                                                    }
                                                                                    inputProps={{
                                                                                        "aria-label": "secondary checkbox"
                                                                                    }}
                                                                                    disabled={
                                                                                        secureLocalStorage.getItem(
                                                                                            "access_type"
                                                                                        ) === false
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Pump Status
                                                                        </Typography>
                                                                        <FormControlLabel
                                                                            onChange={event => this.testHandle(event)}
                                                                            control={
                                                                                <Switch
                                                                                    checked={
                                                                                        partner.pumps[0].is_pump_active
                                                                                    }
                                                                                    name="activationSwitch"
                                                                                    edge={"start"}
                                                                                    classes={{
                                                                                        root: classes.root,
                                                                                        switchBase: classes.switchBase,
                                                                                        thumb: classes.thumb,
                                                                                        track: classes.track,
                                                                                        checked: classes.checked
                                                                                    }}
                                                                                    onChange={event =>
                                                                                        this.partnerActivationDialog(
                                                                                            event,
                                                                                            partner.pumps[0].pump_id,
                                                                                            !partner.pumps[0].is_pump_active
                                                                                        )
                                                                                    }
                                                                                    inputProps={{
                                                                                        "aria-label": "secondary checkbox"
                                                                                    }}
                                                                                    disabled={
                                                                                        secureLocalStorage.getItem(
                                                                                            "access_type"
                                                                                        ) === false
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Button
                                                                            onClick={e =>
                                                                                this.handleDrawerOpenForMore(e, partner)
                                                                            }
                                                                            style={{float: "right"}}
                                                                        >
                                                                            <MoreIcon/>
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </>
                    )}
                    <div>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose.bind(this)}
                        >
                            <DialogTitle>License Image</DialogTitle>
                            <DialogContent>
                                <img src={this.state.imageUrl} alt=""/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            aria-labelledby="draggable-dialog-title"
                            open={this.state.dialog_Open}
                            onClose={this.handleClose.bind(this)}
                        >
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        {this.state.is_pump_Active
                                            ? "Activate Pump"
                                            : "De-activate Pump"}
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div style={{margin: "10px 0px"}}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "#808080"}}
                                        align={"center"}
                                    >
                                        Are you sure, you want to{" "}
                                        {this.state.is_pump_Active ? "Activate" : "De-activate"}{" "}
                                        this pump?
                                    </Typography>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={this.handleClose.bind(this)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button onClick={this.partnerActivation} color="primary">
                                    continue
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            aria-labelledby="draggable-dialog-title"
                            open={this.state.dialog_monetize_open}
                            onClose={this.handleClose.bind(this)}
                        >
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                                id="monetized-dialog"
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        Monetization Status!
                                    </Typography>
                                    <Typography style={{color: "#fff"}} variant={"subtitle1"}>
                                        {!this.state.is_pump_monetize
                                            ? ""
                                            : "Please fill in the details below"}
                                    </Typography>
                                </div>
                            </DialogTitle>
                            {this.state.is_pump_monetize ? (
                                <DialogContent>
                                    <div style={{margin: "10px 0px"}}>
                                        <Grid container spacing={3}>
                                            <Grid item md={12} xs={12}>
                                                <FormControl fullWidth variant="outlined" required>
                                                    <InputLabel>Repos share</InputLabel>
                                                    <OutlinedInput
                                                        label={"Repos share"}
                                                        id={"repos_share"}
                                                        name={"repos_share"}
                                                        onChange={e => this.handleChange(e, e.target.name)}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                {" "}
                                                                %{" "}
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={70}
                                                        type={"number"}
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <FormControl fullWidth variant="outlined" required>
                                                    <InputLabel>Partner Convinience Factor</InputLabel>
                                                    <OutlinedInput
                                                        label={"convenience_factor"}
                                                        id={"convenience_factor"}
                                                        name={"convenience_factor"}
                                                        value={this.state.convenience_factor}
                                                        onChange={e => this.handleChange(e, e.target.name)}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                {" "}
                                                                /L{" "}
                                                            </InputAdornment>
                                                        }
                                                        inputProps={{
                                                            step: 1
                                                        }}
                                                        type={"number"}
                                                        labelWidth={70}
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    label="Agreement Start Date"
                                                    id="date"
                                                    type="date"
                                                    variant="outlined"
                                                    name={"agreement_date"}
                                                    defaultValue="yyyy-mm-dd"
                                                    onChange={e => this.handleChange(e, e.target.name)}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </DialogContent>
                            ) : (
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure, you want to Demonetize this Partner?
                                    </DialogContentText>
                                </DialogContent>
                            )}
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={this.handleClose.bind(this)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                {(this.state.agreement_date === "" ||
                                    this.state.repos_share === "") &&
                                this.state.is_pump_monetize ? (
                                    ""
                                ) : (
                                    <Button onClick={this.partnerMonetization} color="primary">
                                        continue
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                        <Drawer
                            classes={{
                                paper: classes.width20
                            }}
                            anchor={"right"}
                            open={this.state.right}
                            onClose={this.handleDrawerClose}
                        >
                            <div className={classes.drawerHeading}>
                                <Typography
                                    className={classes.nameText}
                                    variant="h3"
                                    style={{marginTop: "2%", color: "#808080"}}
                                >
                                    <SettingsIcon
                                        style={{
                                            color: "lightgray",
                                            width: "50px",
                                            height: "50px",
                                            position: "relative",
                                            top: "15px"
                                        }}
                                    />
                                    Manage
                                </Typography>
                            </div>
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <CloseButton/>
                                </IconButton>
                            </div>
                            <div className={classes.field}>
                                <Typography
                                    variant="subtitle2"
                                    style={{marginTop: "2%", color: "#808080"}}
                                >
                                    Reset Your Password
                                </Typography>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    fullWidth
                                    style={{marginTop: "5px"}}
                                    onClick={event =>
                                        this.resetPasswordBtnSubmit(event, this.state.selectedPump)
                                    }
                                >
                                    <RestoreIcon className={classes.extendedIcon}/>
                                    Reset
                                </Button>
                            </div>
                            <div className={classes.field}>
                                <Typography
                                    variant="subtitle2"
                                    style={{marginTop: "2%", color: "#808080"}}
                                >
                                    Minimum Deliverable Quantity
                                </Typography>
                                <TextField
                                    className={classes.textField}
                                    label="Quantity"
                                    margin="dense"
                                    required
                                    type={"number"}
                                    fullWidth
                                    id={"owner_name"}
                                    onChange={event =>
                                        this.handleFieldChange(
                                            "minimumQuantity",
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                />
                                <Button
                                    fullWidth
                                    variant={"contained"}
                                    onClick={this.handleSetMinimumDeliverableQuantity}
                                >
                                    Change
                                </Button>
                            </div>
                        </Drawer>
                        <Drawer
                            anchor={"right"}
                            open={this.state.showMore}
                            onClose={this.handleDrawerClose}
                        >
                            <div className={classes.drawerHeading}>
                                <Typography
                                    className={classes.nameText}
                                    variant="overline"
                                    style={{
                                        marginTop: "2%",
                                        marginRight: "4%",
                                        marginLeft: "4%",
                                        color: "#808080"
                                    }}
                                >
                                    {this.state.showMorePump.name}
                                </Typography>
                                <Typography
                                    className={classes.nameText}
                                    variant="overline"
                                    style={{marginTop: "2%", color: "#808080"}}
                                >
                                    {this.state.showMorePump.city_name}
                                </Typography>
                            </div>
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <CloseButton/>
                                </IconButton>
                            </div>
                            <Table>
                                <TableBody>
                                    <TableRow align={"center"}>
                                        <TableCell style={{width: "100%"}}>
                                            <Typography className={classes.secondaryHeading}>
                                                <InfoTwoToneIcon
                                                    style={{
                                                        position: "relative",
                                                        bottom: -5,
                                                        marginRight: 5
                                                    }}
                                                />
                                                Basic Information
                                            </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell>{this.state.showMorePump.mobile}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Partner Status</TableCell>
                                        <TableCell>
                                            <Label
                                                variant={"outlined"}
                                                color={
                                                    this.state.showMorePump.is_active ? "green" : "red"
                                                }
                                            >
                                                {this.state.showMorePump.is_active
                                                    ? "Active"
                                                    : "Deactive"}
                                            </Label>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{this.state.showMorePump.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>City</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.city_name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Partner Type</TableCell>
                                        <TableCell align="left" style={{width: "150px"}}>
                                            {this.state.showMorePump.partner_type === "PAF"
                                                ? "Partner as fuelent"
                                                : this.state.showMorePump.partner_type === "PAR"
                                                    ? "Partner as RO"
                                                    : this.state.showMorePump.partner_type === "SAR"
                                                        ? "Supplier as RO"
                                                        : this.state.showMorePump.partner_type === "SAD"
                                                            ? "Supplier as DEPO"
                                                            : "-"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Owner</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.owned_by}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total RPP</TableCell>
                                        <TableCell align="left" style={{width: "150px"}}>
                                            {this.state.showMorePump.total_refuelers}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow align={"center"}>
                                        <TableCell style={{width: "100%"}}>
                                            <Typography className={classes.secondaryHeading}>
                                                <MonetizationOnTwoToneIcon
                                                    style={{
                                                        position: "relative",
                                                        bottom: -5,
                                                        marginRight: 5
                                                    }}
                                                />
                                                Tax Information
                                            </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>PP License Number</TableCell>
                                        <TableCell align="left">
                                            {!this.state.showMorePump.pp_license_number
                                                ? "no data"
                                                : this.state.showMorePump.pp_license_number}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={"left"}>CST Number</TableCell>
                                        <TableCell align="left">
                                            {!this.state.showMorePump.cst_tin_number
                                                ? "no data"
                                                : this.state.showMorePump.cst_tin_number}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={"left"}>Bank Account Number</TableCell>
                                        <TableCell align="left">
                                            {!this.state.showMorePump.bank_acc_no
                                                ? "no data"
                                                : this.state.showMorePump.bank_acc_no}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={"left"}>IFSC</TableCell>
                                        <TableCell align="left">
                                            {!this.state.showMorePump.ifsc
                                                ? "no data"
                                                : this.state.showMorePump.ifsc}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>GST Number</TableCell>
                                        <TableCell align="left" style={{width: "150px"}}>
                                            {!this.state.showMorePump.gst_number
                                                ? "no data"
                                                : this.state.showMorePump.gst_number}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Permanent Account Number</TableCell>
                                        <TableCell align="left" style={{width: "150px"}}>
                                            {!this.state.showMorePump.pan_card
                                                ? "no data"
                                                : this.state.showMorePump.pan_card}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total Earnings</TableCell>
                                        <TableCell align="left">
                                            &#8377;{" "}
                                            {!this.state.showMorePump.total_earnings
                                                ? "0.0"
                                                : this.state.showMorePump.total_earnings}{" "}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow align={"center"}>
                                        <TableCell style={{width: "100%"}}>
                                            <Typography className={classes.secondaryHeading}>
                                                <LocalShippingTwoToneIcon
                                                    style={{
                                                        position: "relative",
                                                        bottom: -5,
                                                        marginRight: 5
                                                    }}
                                                />
                                                Pump Details
                                            </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="left">
                                            {this.state.pumpDetails.pump_name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell align="left">
                                            {this.state.pumpDetails.pump_address}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Region</TableCell>
                                        <TableCell align="left">
                                            {this.state.showRegionPump.region}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Pincode</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.pincode}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Zone</TableCell>
                                        <TableCell align="left">
                                            {this.state.showZonePump.zone}
                                        </TableCell>
                                    </TableRow>
                                    {/*<TableRow>*/}
                                    {/*    <TableCell>Rate Last Updated</TableCell>*/}
                                    {/*    <TableCell align="left">{this.state.showMorePump.rate_last_updated}</TableCell>*/}
                                    {/*</TableRow>*/}
                                    {/*<TableRow>*/}
                                    {/*    <TableCell>Rate</TableCell>*/}
                                    {/*    <TableCell align="left">{this.state.pumpDetails.current_rate}</TableCell>*/}
                                    {/*</TableRow>*/}
                                    {/*<TableRow>*/}
                                    {/*    <TableCell>Density Last Updated</TableCell>*/}
                                    {/*    <TableCell align="left">{this.state.showMorePump.density_last_updated}</TableCell>*/}
                                    {/*</TableRow>*/}
                                    {/*<TableRow>*/}
                                    {/*    <TableCell>Density</TableCell>*/}
                                    {/*    <TableCell align="left">{this.state.pumpDetails.current_density}</TableCell>*/}
                                    {/*</TableRow>*/}
                                    <TableRow align={"center"}>
                                        <TableCell style={{width: "100%"}}>
                                            <Typography className={classes.secondaryHeading}>
                                                <CreditCardIcon
                                                    style={{
                                                        position: "relative",
                                                        bottom: -5,
                                                        marginRight: 5
                                                    }}
                                                />
                                                Monetization Details
                                            </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Monetized</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.monetized ? "Yes" : "No"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Repos Share</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.repos_share === null
                                                ? "0"
                                                : this.state.showMorePump.repos_share}
                                            %
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Convinience Factor</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.convenience_factor === null
                                                ? "0"
                                                : this.state.showMorePump.convenience_factor}
                                            /L
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Agreement Start Date</TableCell>
                                        <TableCell align="left">
                                            {this.state.showMorePump.asd === null
                                                ? "-"
                                                : this.state.showMorePump.asd}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Drawer>
                        <Drawer
                            classes={{
                                paper: classes.width20
                            }}
                            anchor={"right"}
                            open={this.state.editPartner}
                            onClose={this.handleDrawerClose}
                        >
                            <div className={classes.drawerHeading}>
                                <Typography
                                    className={classes.nameText}
                                    variant="h3"
                                    style={{
                                        fontWeight: 300,
                                        color: "gray",
                                        marginTop: "5%",
                                        marginLeft: "2%"
                                    }}
                                >
                                    Edit Partner
                                </Typography>
                            </div>
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <CloseButton onClick={this.handleDrawerClose}/>
                                </IconButton>
                            </div>
                            <EditPump
                                pump={this.state.selectedPump}
                                onModificationSuccess={this.redirectOnSuccess}
                            />
                        </Drawer>

                        {this.props.noDataFound ? (
                            ""
                        ) : (
                            <TablePagination
                                style={{padding: "0%"}}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                component="div"
                                count={this.props.dataCount}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                page={this.props.page}
                                rowsPerPage={this.props.rowsPerPage}
                                rowsPerPageOptions={[10, 100, 500]}
                            />
                        )}
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(Cards)));
