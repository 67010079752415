import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
// Material components
import {
    colors,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";

import NoDatafound from "../NoDataInTable/index";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import Label from "../../../Dashboard/components/Label";
import Autocomplete from "@material-ui/lab/Autocomplete";
import secureLocalStorage from "react-secure-storage";

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: [],
            right: false,
            requestStatus: 0
        };
    }

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    handleDrawerOpen = (e, side, open, customer) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            right: open,
            customerData: customer
        });
    };

    resetPasswordBtnSubmit = (event, customerId) => {
        event.preventDefault();
        this.props.resetPassword(customerId);
    };

    handleChange = (e, value, type) => {
        // this.setState({
        //     requestStatus: e.target.value
        // })
        this.props.handleChangeEdit(e, value, type);
    };

    onSearchTerm = searchTerm => {
    };

    handleDrawerClose = () => {
        this.setState({right: false});
    };

    render() {
        const {classes, allCustomerData} = this.props;

        const orderStatusColors = {
            1: colors.orange[600],
            2: colors.green[600],
            3: colors.red[600],
            4: colors.lightBlue[800]
        };

        if (this.props.noDataFound === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else {
                return (
                    <>
                        {this.props.isLoading ? (
                            <div className={classes.progressWrapper}>
                                <LinearProgress fullWidth className={classes.progress}/>
                            </div>
                        ) : null}
                        {this.props.noDataFound ? (
                            <NoDatafound/>
                        ) : (
                            <>
                                {allCustomerData.map(customer => (
                                    <Box sx={{flexGrow: 2}} style={{margin: "4%"}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Paper elevation={2} className={classes.paper}>
                                                        <Box display="flex" flexDirection="column">
                                                            <Box
                                                                py={1}
                                                                px={2}
                                                                display="flex"
                                                                className={classes.header}
                                                                style={{
                                                                    background:
                                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                                }}
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    className={classes.textWhite}
                                                                    style={{color: "primary"}}
                                                                >
                                                                    {customer?.service_number}
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    className={classes.textWhite}
                                                                >
                                                                    {customer?.created_at}
                                                                </Typography>
                                                            </Box>
                                                            <Box p={2} spacing={2}>
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                    align="space-around"
                                                                    justifyContent="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Box px={1} py={0} display="flex">
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textSecondary"
                                                                                align="center"
                                                                            >
                                                                                <b>Contact Number:- </b>
                                                                                {customer?.mobile}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Concern Person
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                                align="center"
                                                                                style={{wordBreak: "break-word"}}
                                                                            >
                                                                                {customer?.contact_person_name}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                RPP
                                                                            </Typography>
                                                                            <Typography
                                                                                align={"center"}
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                            >
                                                                                {customer?.refueler_reg_number}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className={classes.marginRightAuto}
                                                                    >
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Assigned Engineer
                                                                            </Typography>
                                                                            {customer?.assigned_service_eng ===
                                                                            null ||
                                                                            customer?.assigned_service_eng === "" ? (
                                                                                <Typography
                                                                                    align={"center"}
                                                                                    color="textSecondary"
                                                                                    variant="body2"
                                                                                >
                                                                                    Not Assigned
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography
                                                                                    align={"center"}
                                                                                    color="textPrimary"
                                                                                    variant="body2"
                                                                                >
                                                                                    {customer?.assigned_service_eng}
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Issue Category
                                                                            </Typography>
                                                                            <Typography
                                                                                align={"center"}
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                {customer?.issue_category}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Issue Subcategory
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                {customer?.issue_subcategory}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Issue Status
                                                                            </Typography>
                                                                            <Label
                                                                                color={
                                                                                    customer.status === "OPN"
                                                                                        ? orderStatusColors["1"]
                                                                                        : customer.status === "CAN"
                                                                                            ? orderStatusColors["3"]
                                                                                            : customer.status === "COM"
                                                                                                ? orderStatusColors["2"]
                                                                                                : customer.status === "INP"
                                                                                                    ? orderStatusColors["4"]
                                                                                                    : ""
                                                                                }
                                                                            >
                                                                                {customer.status === "OPN"
                                                                                    ? "OPEN"
                                                                                    : customer.status === "CAN"
                                                                                        ? "CANCEL"
                                                                                        : customer.status === "COM"
                                                                                            ? "COMPLETE"
                                                                                            : customer.status === "INP"
                                                                                                ? "IN-PROCESS"
                                                                                                : ""}
                                                                            </Label>
                                                                            {/*<Typography*/}
                                                                            {/*    color="textSecondary"*/}
                                                                            {/*    variant="caption">*/}
                                                                            {/*    {customer?.status}*/}
                                                                            {/*</Typography>*/}
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Select Engineer
                                                                            </Typography>
                                                                            <Autocomplete
                                                                                fullWidth
                                                                                id="combo-box-for-tracker"
                                                                                onChange={e =>
                                                                                    this.handleChange(
                                                                                        e,
                                                                                        customer,
                                                                                        "engineer"
                                                                                    )
                                                                                }
                                                                                options={this.props.EngineerData}
                                                                                disabled={
                                                                                    secureLocalStorage.getItem(
                                                                                        "access_type"
                                                                                    ) === false
                                                                                }
                                                                                renderInput={params => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        fullWidth
                                                                                        // label="Engineer"
                                                                                        variant="standard"
                                                                                        onChange={e =>
                                                                                            this.onSearchTerm(e.target.value)
                                                                                        }
                                                                                        disabled={
                                                                                            secureLocalStorage.getItem(
                                                                                                "access_type"
                                                                                            ) === false
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Change Status
                                                                            </Typography>
                                                                            <FormControl variant="outlined" fullWidth>
                                                                                {/*<InputLabel id="Request-mode-selector" fullWidth>Status</InputLabel>*/}
                                                                                <Select
                                                                                    fullWidth
                                                                                    variant={"standard"}
                                                                                    labelId="Request-mode-selector"
                                                                                    id="Request-mode-selector"
                                                                                    value={this.state.requestStatus}
                                                                                    onChange={e =>
                                                                                        this.handleChange(
                                                                                            e,
                                                                                            customer,
                                                                                            "status"
                                                                                        )
                                                                                    }
                                                                                    label="Request Status"
                                                                                >
                                                                                    {/*<MenuItem value={0}>Change Status</MenuItem>*/}
                                                                                    <MenuItem value={"OPN"}>
                                                                                        OPEN
                                                                                    </MenuItem>
                                                                                    <MenuItem value={"CAN"}>
                                                                                        CANCEL
                                                                                    </MenuItem>
                                                                                    <MenuItem value={"COM"}>
                                                                                        COMPLETE
                                                                                    </MenuItem>
                                                                                    <MenuItem value={"INP"}>
                                                                                        IN-PROGRESS
                                                                                    </MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={4}>
                                                                        <Box>
                                                                            <Button size="small">
                                                                                <MoreIcon
                                                                                    onClick={e =>
                                                                                        this.handleDrawerOpen(
                                                                                            e,
                                                                                            "right",
                                                                                            true,
                                                                                            customer
                                                                                        )
                                                                                    }
                                                                                    style={{float: "right"}}
                                                                                />
                                                                            </Button>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </>
                        )}

                        <Drawer
                            anchor="right"
                            open={this.state.right}
                            onClose={this.handleDrawerClose}
                        >
                            <div className={classes.root}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <Button onClick={this.handleDrawerClose}>X</Button>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight: "bold"}}>
                                                Pump Name
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state?.customerData?.partner_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight: "bold"}}>
                                                OMC Name
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state?.customerData?.omc_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight: "bold"}}>Zone</TableCell>
                                            <TableCell>
                                                {!this.state?.customerData?.zone
                                                    ? "NA"
                                                    : this.state?.customerData?.zone}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight: "bold"}}>
                                                Region
                                            </TableCell>
                                            <TableCell>
                                                {!this.state.customerData?.Region
                                                    ? "No data"
                                                    : this.state.customerData?.Region}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight: "bold"}}>
                                                Issue Description
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.customerData?.issue_description === "N/A"
                                                    ? "No data"
                                                    : this.state.customerData?.issue_description}
                                            </TableCell>
                                        </TableRow>
                                        {/*<TableRow>*/}
                                        {/*    <TableCell style={{fontWeight: 'bold'}}>Total Diesel Consumption</TableCell>*/}
                                        {/*    <TableCell align="left">{!this.state.customerData?.avg_quantity_ordered ? 'No data' : (this.state.customerData?.avg_quantity_ordered)?.toFixed(2)}</TableCell>*/}
                                        {/*</TableRow>*/}
                                    </TableBody>
                                </Table>
                            </div>
                        </Drawer>
                        <div>
                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    style={{padding: "0%"}}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100, 500]}
                                />
                            )}
                        </div>
                    </>
                );
            }
        }
    }
}

Cards.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

Cards.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withStyles(styles)(Cards);
