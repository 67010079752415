import {green, red} from "@material-ui/core/colors";

export default theme => ({
    root: {},
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        // backgroundColor: theme.palette.primary.main,
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: 500,
        height: "36px",
        width: "36px"
    },
    nameText: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20
    },
    details: {
        alignItems: "center"
    },
    backgroundMarker: {
        opacity: 0.1,
        position: "absolute",
        right: 0,
        top: 0
    },
    column: {
        flexBasis: "33.333333%"
        // flexBasis: '24.55%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2)
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    panelActions: {
        // textAlign:'center',
        padding: "10px 22px"
    },
    fab: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "3.5%"
    },
    list: {
        width: "550px"
    },
    fullList: {
        width: "auto"
    },
    width20: {
        width: "310px"
    },
    iconCustomizer: {
        width: "60px",
        height: "40px",
        position: "relative",
        top: "10px",
        color: "#8a2be2"
    },
    drawerHeading: {
        float: "left",
        marginBottom: "10px"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    switchBase: {
        color: red[300],
        "&$checked": {
            color: green[500]
        },
        "&$checked + $track": {
            backgroundColor: green[500]
        }
    },
    checked: {},
    track: {},
    field: {
        margin: theme.spacing(3)
    }
});
