import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {withStyles} from "@material-ui/core";

// Material components
// import { CircularProgress, Typography } from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Shared services
// import { getUsers } from '../../services/user';
// Custom components
import {UsersTable, UsersToolbar} from "./components";

// Component styles
import styles from "./style";
import {getAllGeofences, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import secureLocalStorage from "react-secure-storage";

class AllGeofences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0
        };
    }

    onSearchTerm = searchTerm => {
        if (searchTerm !== "") {
            this.setState({
                searchString: searchTerm
            });
            this.getGeofenceData(searchTerm);
        } else {
            this.setState({
                searchString: ""
            });
            this.getGeofenceData();
        }
    };

    // onSelectedCustomer =(value) => {
    //   if (value === ""){
    //     this.getGeofenceData("", this.state.rowsPerPage, 0, null)
    //   }
    //   else this.getGeofenceData("", this.state.rowsPerPage, 0, value)
    // };

    onPagination = (limit, page) => {
        let offset = limit * page;

        this.setState({rowsPerPage: limit, page});

        this.getGeofenceData(this.state.searchString, limit, offset);
    };

    // getGeofenceData = (searchTerm="", limit=this.state.rowsPerPage, offset=0, customer) => {
    //   this.props.handleGetAllGeofences( secureLocalStorage.getItem("accessToken"), searchTerm, limit, offset, customer);
    // };

    getGeofenceData = (
        searchTerm = "",
        limit = this.state.rowsPerPage,
        offset = 0
    ) => {
        this.props.handleGetAllGeofences(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset
        );
    };

    versionControl = () => {
        this.versionControl();
        this.props.version();
    };

    componentDidMount() {
        this.getGeofenceData();
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="Geofences">
                    <div className={classes.root}>
                        {/*{*/}
                        {/*  this.props.allGeofencesData.length === 0*/}
                        {/*      ?*/}
                        {/*      <div className={classes.progressWrapper}>*/}
                        {/*        <CircularProgress className={classes.progress}/>*/}
                        {/*      </div>*/}
                        {/*      :*/}
                        <div>
                            <UsersToolbar
                                allGeofencesData={this.props.allGeofencesData}
                                searchedTerm={this.onSearchTerm}
                                allCustomerData={this.props.allCustomerData}
                                onSelectedCustomer={this.onSelectedCustomer}
                            />
                            <UsersTable
                                allGeofencesData={this.props.allGeofencesData}
                                rowsPerPage={this.state.rowsPerPage}
                                dataCount={this.props.dataCount}
                                page={this.state.page}
                                onPagination={this.onPagination}
                                noDataFound={this.props.noDataFound}
                                isLoading={this.props.isLoading}
                            />
                        </div>
                        {/*}*/}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllGeofences.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allGeofencesData: state.allGeofencesData,
        allCustomerData: state.allCustomerData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllGeofences: (accessToken, searchString, limit, offset) => {
            dispatch(getAllGeofences(accessToken, searchString, limit, offset));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(AllGeofences));
