import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Divider, Typography, withStyles} from "@material-ui/core";

// Material icons
import {AccessTime as AccessTimeIcon, GetApp as GetAppIcon} from "@material-ui/icons";

// Shared components
import {Paper} from "components";

// Component styles
import styles from "./styles";

class ProductCard extends Component {
    render() {
        const {classes, className, product} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <Paper className={rootClassName}>
                <div className={classes.imageWrapper}>
                    <img alt="Product" className={classes.image} src={product.imageUrl}/>
                </div>
                <div className={classes.details}>
                    <Typography className={classes.title} variant="h4">
                        {product.title}
                    </Typography>
                    <Typography className={classes.description} variant="body1">
                        {product.description}
                    </Typography>
                </div>
                <Divider/>
                <div className={classes.stats}>
                    <AccessTimeIcon className={classes.updateIcon}/>
                    <Typography className={classes.updateText} variant="body2">
                        Updated 2hr ago
                    </Typography>
                    <GetAppIcon className={classes.downloadsIcon}/>
                    <Typography className={classes.downloadsText} variant="body2">
                        {product.totalDownloads} Downloads
                    </Typography>
                </div>
            </Paper>
        );
    }
}

ProductCard.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard);
