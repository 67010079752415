export default theme => ({
    root: {
        marginTop: "16px"
    },
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    deleteButton: {
        color: theme.palette.danger.main,
        marginRight: theme.spacing(1)
    },
    editButton: {
        color: theme.palette.info.main,
        marginRight: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    importIcon: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        // marginRight: theme.spacing(1)
    },
    exportIcon: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    drawerWidth: {
        zIndex: 1200,
        width: "340px"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "1.5%"
    },
    drawerHeading: {
        float: "left",
        marginBottom: "10px"
    },
    filterDrawer: {
        width: "25%"
    },
    nameOne: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer",
        color: "gray"
    },
    mybtn: {
        color: "#12161B",
        padding: " 6px 16px",
        fontSize: "12px",
        minWidth: "84px",
        boxSizing: "border-box",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        transition:
            " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontWeight: 500,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: "0px",
        borderRadius: "4px",
        letterSpacing: "0.02857em",
        textTransform: "uppercase"
    }
});
