import React, {Component} from "react";

// Material helpers
import {withStyles} from "@material-ui/core";

// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Component styles
import styles from "./styles";
import {getFleetCardPayments, sendLogs, versionControl} from "../../actions/actions";
import {UsersTable, UsersToolbar} from "./Components";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {withSnackbar} from "notistack";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

// import Cards from './components/Cards/Cards';

class AllFleetCardPayments extends Component {
    state = {
        searchString: "",
        rowsPerPage: 10,
        page: 0,
        open: true,
        fromDateFilter: null,
        toDate: null
    };

    constructor(props) {
        super(props);
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getFleetCardPayments(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getFleetCardPayments("");
        }
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page});
        this.getFleetCardPayments(this.state.searchString, limit, offset);
    };

    getFleetCardPayments = (
        searchTerm = this.state.searchString,
        limit = this.state.rowsPerPage,
        offset = 0,
        fromDate = this.state.fromDateFilter,
        toDate = this.state.toDate,
        abortController = this.abortController
    ) => {
        this.props.handleGetFleetCardPayments(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            fromDate,
            toDate,
            abortController
        );
    };

    onSelectFromDate = fromDate => {
        this.setState({
            fromDateFilter: fromDate
        });

        if (fromDate === "") {
            this.getFleetCardPayments("", 10, 0, (fromDate = null));
        } else {
            this.getFleetCardPayments("", 10, 0, fromDate);
        }
    };

    onSelectToDate = toDate => {
        this.setState({
            toDate: toDate
        });

        if (toDate === "") {
            this.getFleetCardPayments(
                "",
                10,
                0,
                this.state.fromDateFilter,
                (toDate = null)
            );
        } else {
            this.getFleetCardPayments("", 10, 0, this.state.fromDateFilter, toDate);
        }
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getFleetCardPayments();
        const handler = e => this.setState({mobileView: e.matches});
        window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <div>
                    <DashboardLayout title="All Fleet Card Payments">
                        <div className={classes.root}>
                            <UsersToolbar
                                FleetCardData={this.props.FleetCardData}
                                searchedTerm={this.onSearchTerm}
                                selectFromDate={this.onSelectFromDate}
                                selectToDate={this.onSelectToDate}
                            />
                            {/* <Breakpoint medium up> */}
                            <div className={classes.content}>
                                <UsersTable
                                    FleetCardData={this.props.FleetCardData}
                                    dataCount={this.props.dataCount}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                    mobileView={this.state.mobileView}
                                />
                            </div>
                            {/* </Breakpoint> */}
                        </div>
                        {/*<Breakpoint small down>*/}
                        {/*    <Divider style={{marginBottom: "4%", marginTop: "10%", color: "#aeafb0"}}/>*/}
                        {/*     <Cards*/}
                        {/*        getServiceRequestData={this.getServiceRequestData}*/}
                        {/*        EngineerData={this.state.EngineerData}*/}
                        {/*        allCustomerData={this.props.allServiceData}*/}
                        {/*        resetPassword={this.resetPassword}*/}
                        {/*        dataCount={this.props.dataCount}*/}
                        {/*        page={this.state.page}*/}
                        {/*        rowsPerPage={this.state.rowsPerPage}*/}
                        {/*        onPagination={this.onPagination}*/}
                        {/*        noDataFound={this.props.noDataFound}*/}
                        {/*        isLoading={this.props.isLoading}*/}
                        {/*        mobileView={this.state.mobileView}*/}
                        {/*        handleChangeEdit={this.handleChangeEdit}*/}

                        {/*    /> */}
                        {/*</Breakpoint>*/}
                    </DashboardLayout>
                </div>
            );
        return true;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        FleetCardData: state.FleetCardData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        sessionExpired: state.sessionExpired,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetFleetCardPayments: (
            accessToken,
            searchString,
            limit,
            offset,
            fromDate,
            toDate,
            abortController
        ) => {
            dispatch(
                getFleetCardPayments(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    fromDate,
                    toDate,
                    abortController
                )
            );
        },
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllFleetCardPayments)));
