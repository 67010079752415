import React, {Component} from "react";

// Externals
// Material helpers
import {CircularProgress, withStyles} from "@material-ui/core";
import {Breakpoint} from "react-socks";
// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";
// Component styles
import styles from "./style";
import reposWebApi from "../../actions/configuration";
import UsersTable from "./Components/UsersTable";
import UsersToolbar from "./Components/UsersToolbar";
import {withSnackbar} from "notistack";
import qs from "qs";
import axios from "axios";
import Cards from "./Components/Cards/Cards";
import {getDatumDetails, sendLogs, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";

class AllDatum extends Component {
    state = {
        allDatumOrders: [],
        rowsPerPage: 10,
        page: 0,
        dataCount: 0,
        searchString: "",
        noDataFound: false,
        dialog_open: false,
        autoRefresh: false,
        datumOrderState: [],
        progressbar: false,
        refreshCount: false,
        isSecondTab: false,
        fiterState: "",
        isLoading: false,
        tabClick: 0,
        onFilter: "",
        fromDateFilter: null,
        toDate: null,
        datumStatus: "",
        status: "",
        refresh: false
    };

    constructor(props) {
        super(props);
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm !== "") {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.state.tabClick === 0
                ? this.getAllDatumOrders(
                    searchTerm,
                    10,
                    0,
                    this.state.fiter,
                    this.state.fromDate,
                    this.state.toDate,
                    this.state.status
                )
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken"),
                    this?.state?.rowsPerPage,
                    this?.state?.page,
                    searchTerm,
                    this.state.onFilter,
                    this.state.fromDateFilter,
                    this.state.toDate,
                    this.state.datumStatus,
                    this.abortController
                );
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.state.tabClick === 0
                ? this.getAllDatumOrders()
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken"),
                    this?.state?.rowsPerPage,
                    this?.state?.page,
                    searchTerm,
                    this.state.onFilter,
                    this.state.fromDateFilter,
                    this.state.toDate,
                    this.state.datumStatus,
                    this.abortController
                );
        }
    };

    onSearch(searchTerm) {
        this.setState({
            selectedValue: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    filterByState = fiter => {
        this.setState({
            rowsPerPage: 10,
            page: 0,
            fiter
        });
        this?.state?.tabClick === 0
            ? this.getAllDatumOrders(
                this.state.searchString,
                10,
                0,
                fiter,
                this.state.fromDate,
                this.state.toDate,
                this.state.status
            )
            : this.props.handleDatumDeatails(
                secureLocalStorage.getItem("accessToken"),
                this?.state?.rowsPerPage,
                this?.state?.page,
                this?.state?.searchString,
                fiter
            );
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page}, () => {
            this.state.tabClick === 0
                ? this.getAllDatumOrders(
                    this.state.searchString,
                    limit,
                    offset,
                    this.state.fiter,
                    undefined,
                    undefined,
                    this.state.status
                )
                : this.setState({rowsPerPage: limit, page}, () => {
                    this.props.handleDatumDeatails(
                        secureLocalStorage.getItem("accessToken"),
                        limit,
                        offset,
                        undefined,
                        this.state.fiter,
                        undefined,
                        undefined,
                        this.state.datumStatus
                    );
                });
        });
    };

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    updateOrderStatus = (order_status, orderId, order_qty) => {
        const requestBody = {
            order_id: orderId,
            order_status,
            lmd_dispensed_quantity: order_qty
        };

        reposWebApi
            .post("order/datum_order_change_status", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                this.getAllDatumOrders();
                this.props.enqueueSnackbar("Status updated successfully!", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
                this.setState({
                    closeDialog: false
                });
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.getItem("mobile"),
                    "order/datum_order_change_status",
                    qs.stringify(requestBody)
                );
            })
            .catch(err => {
                this.props.enqueueSnackbar("Status did not get updated", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
            })
            .finally(resObj => {
                this.setState({
                    closeDialog: true
                });
            });
    };

    refreshData = () => {
        this.setState({
            refresh: true
        });
        this.state.tabClick === 0
            ? this.getAllDatumOrders()
            : this.props.handleDatumDeatails(
                secureLocalStorage.getItem("accessToken")
            );
    };

    getAllDatumOrders = (
        search,
        limit,
        offset,
        filter,
        fromDate,
        toDate,
        status
    ) => {
        this.setState({
            isLoading: true
        });

        let queryParams = {};

        if (search !== "") {
            queryParams.search = search;
        }

        if (filter !== "") {
            queryParams.state = filter;
        }

        if (limit !== null && offset !== null) {
            queryParams.limit = limit;
            queryParams.offset = offset;
        }

        if (
            fromDate !== null ||
            (fromDate !== "" && toDate !== null) ||
            toDate !== ""
        ) {
            queryParams.start_date = fromDate;
            queryParams.end_date = toDate;
        }

        if (status !== "") {
            queryParams.status = status;
        }

        if (this.abortController) {
            this.abortController.cancel("cancel request");
        }

        this.abortController = axios.CancelToken.source();

        reposWebApi
            .get("order/get_all_datum_orders", {
                cancelToken: this.abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: queryParams
            })
            .then(response => {
                if (response.data.data?.count === 0) {
                    this.setState({
                        dataCount: decryption(response).data.count,
                        noDataFound: true,
                        allDatumOrders: decryption(response).data.results,
                        progressbar: true,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        noDataFound: false,
                        dataCount: decryption(response).data.count,
                        allDatumOrders: decryption(response).data.results,
                        progressbar: false,
                        isLoading: false
                    });
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                }
                if (error?.response?.status === 400) {
                    this.props.enqueueSnackbar("Bad Request!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                } else if (error?.response?.status === 500) {
                    this.props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            });
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getAllDatumOrders("", this.state.rowsPerPage, 0);
    }

    handelContinue = () => {
        this.handleAutorefresh(this.state.refreshCount);
    };

    handleAutorefresh = (autorefresh, validate) => {
        if (validate === "checkBox" || validate === "componentWillUnmount") {
            secureLocalStorage.setItem("autorefresh", autorefresh);
        }
        if (secureLocalStorage.autorefresh === "true") {
            this.state.tabClick === 0
                ? this.getAllDatumOrders()
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken")
                );
            setTimeout(this.handelContinue, 10000);
        }
    };

    handleChangeTab = (event, value) => {
        this.setState({
            tabClick: value,
            onFilter: "",
            searchString: "",
            allDatumOrders: [],
            fromDateFilter: null,
            toDate: null,
            rowsPerPage: 10
        });
        if (value === 1) {
            this.props.handleDatumDeatails(
                secureLocalStorage.getItem("accessToken"),
                10,
                0,
                undefined,
                undefined
            );
        } else {
            this.getAllDatumOrders(
                undefined,
                this.state.rowsPerPage,
                this.state.page
            );
        }
    };

    handleSetFilter = value => {
        this.setState({
            onFilter: value
        });
    };

    handleSearchFilter = value => {
        this.setState({
            onSeacrh: value
        });
    };

    onSelectFromDate = fromDate => {
        this.setState({
            fromDateFilter: fromDate
        });
        if (fromDate === "") {
            this.state.tabClick === 0
                ? this.getAllDatumOrders(
                    this.state.searchString,
                    this.state.rowsPerPage,
                    this.state.page,
                    this.state.onFilter,
                    (fromDate = null)
                )
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken"),
                    this?.state?.rowsPerPage,
                    this?.state?.page,
                    this?.state?.searchString,
                    this.state.onFilter,
                    (fromDate = null)
                );
        } else {
            this.state.tabClick === 0
                ? this.getAllDatumOrders(
                    this.state.searchString,
                    this.state.rowsPerPage,
                    this.state.page,
                    this.state.onFilter,
                    fromDate
                )
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken"),
                    this?.state?.rowsPerPage,
                    this?.state?.page,
                    this?.state?.searchString,
                    this.state.onFilter,
                    fromDate
                );
        }
    };

    onSelectToDate = toDate => {
        this.setState({
            toDate: toDate
        });

        if (toDate === "") {
            this.state.tabClick === 0
                ? this.getAllDatumOrders(
                    this.state.searchString,
                    this.state.rowsPerPage,
                    this.state.page,
                    this.state.onFilter,
                    this.state.fromDateFilter,
                    (toDate = null)
                )
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken"),
                    this?.state?.rowsPerPage,
                    this?.state?.page,
                    this?.state?.searchString,
                    this.state.onFilter,
                    this.state.fromDateFilter,
                    (toDate = null)
                );
        } else {
            this.state.tabClick === 0
                ? this.getAllDatumOrders(
                    this.state.searchString,
                    this.state.rowsPerPage,
                    this.state.page,
                    this.state.onFilter,
                    this.state.fromDateFilter,
                    toDate
                )
                : this.props.handleDatumDeatails(
                    secureLocalStorage.getItem("accessToken"),
                    this?.state?.rowsPerPage,
                    this?.state?.page,
                    this?.state?.searchString,
                    this.state.onFilter,
                    this.state.fromDateFilter,
                    toDate
                );
        }
    };

    handelDatumStatus = datumStatus => {
        if (this.abortController) {
            this.abortController.cancel("cancel request");
        }
        this.abortController = axios.CancelToken.source();

        this.setState({
            datumStatus: datumStatus
        });
        this.props.handleDatumDeatails(
            secureLocalStorage.getItem("accessToken"),
            this?.state?.rowsPerPage,
            this?.state?.page,
            this?.state?.searchString,
            this.state.onFilter,
            this.state.fromDateFilter,
            this.state.toDate,
            datumStatus,
            this.abortController
        );
    };

    handelOrderStatus = status => {
        if (this.abortController) {
            this.abortController.cancel("cancel request");
        }
        this.abortController = axios.CancelToken.source();
        this.setState({
            status: status
        });
        this.getAllDatumOrders(
            this.state.searchString,
            this.state.rowsPerPage,
            this.state.page,
            this.state.onFilter,
            this.state.fromDateFilter,
            this.state.toDate,
            status
        );
    };

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="Datum Dashboard">
                    <div className={classes.root}>
                        {this.props.allDatumOrders?.length === 0 ? (
                            <div className={classes.progressWrapper}>
                                <CircularProgress className={classes.progress}/>
                            </div>
                        ) : (
                            <div>
                                <UsersToolbar
                                    AllDatumOrders={this.props.allDatumOrders}
                                    searchedTerm={this.onSearchTerm}
                                    progressBar={this.state.progressbar}
                                    handleAutorefresh={this.handleAutorefresh}
                                    refreshData={this.refreshData}
                                    getAllDatumOrders={this.getAllDatumOrders}
                                    handelContinue={this.handelContinue}
                                    handleChangeTab={this.handleChangeTab}
                                    filterByState={this.filterByState}
                                    tabClick={this.state.tabClick}
                                    onSearch={this.state.onSearch}
                                    onFilter={this.state.onFilter}
                                    handleSetFilter={this.handleSetFilter}
                                    handleSearchFilter={this.handleSearchFilter}
                                    fromDateFilter={this.state.fromDateFilter}
                                    toDate={this.state.toDate}
                                    selectFromDate={this.onSelectFromDate}
                                    selectToDate={this.onSelectToDate}
                                    searchString={this.state.searchString}
                                    datumStatus={this.state.datumStatus}
                                    DatumStatus={this.handelDatumStatus}
                                    status={this.state.status}
                                    OrderStatus={this.handelOrderStatus}
                                />
                                <>
                                    <Breakpoint medium up>
                                        <UsersTable
                                            refreshData={this.getAllDatumOrders}
                                            dataCount={
                                                this.state?.tabClick === 1
                                                    ? this.props?.datumDetails?.count
                                                    : this.state.dataCount
                                            }
                                            datumOrdersData={
                                                this.state?.tabClick === 1
                                                    ? this.props?.datumDetails?.results
                                                    : this.state?.allDatumOrders
                                            }
                                            page={this.state.page}
                                            rowsPerPage={this.state.rowsPerPage}
                                            onPagination={this.onPagination}
                                            getUpdatedStatus={this.updateOrderStatus}
                                            closeDialog={this.state.dialog_open}
                                            noDataFound={this.state.noDataFound}
                                            datumOrderState={this.state.allDatumOrders}
                                            progressBar={this.state.progressbar}
                                            tabClick={this.state.tabClick}
                                            isLoading={this.state.isLoading}
                                        />
                                    </Breakpoint>
                                    <Breakpoint small down>
                                        <Cards
                                            refreshData={this.getAllDatumOrders}
                                            dataCount={
                                                this.state?.tabClick === 1
                                                    ? this.props?.datumDetails?.count
                                                    : this.state?.dataCount
                                            }
                                            datumOrdersData={
                                                this.state.tabClick === 1
                                                    ? this.props?.datumDetails?.results
                                                    : this.state?.allDatumOrders
                                            }
                                            page={this.state.page}
                                            rowsPerPage={this.state.rowsPerPage}
                                            onPagination={this.onPagination}
                                            getUpdatedStatus={this.updateOrderStatus}
                                            closeDialog={this.state.dialog_open}
                                            noDataFound={this.state.noDataFound}
                                            datumOrderState={this.state.allDatumOrders}
                                            progressBar={this.state.progressbar}
                                            tabClick={this.state.tabClick}
                                            isLoading={this.state.isLoading}
                                        />
                                    </Breakpoint>
                                </>
                            </div>
                        )}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        sessionExpired: state.sessionExpired,
        isLoading: state.isLoading,
        datumDetails: state.datumDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        },
        handleDatumDeatails: (
            accessTokenn,
            limit,
            offset,
            searchTerm,
            fiter,
            fromDate,
            toDate,
            datumStatus,
            abortController
        ) => {
            dispatch(
                getDatumDetails(
                    accessTokenn,
                    limit,
                    offset,
                    searchTerm,
                    fiter,
                    fromDate,
                    toDate,
                    datumStatus,
                    abortController
                )
            );
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllDatum)));
