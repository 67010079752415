import {Grid, TextField} from "@material-ui/core";
import React, {useEffect} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {getCategory, getCompanyType, getSubCategory} from "actions/actions.js";
import secureLocalStorage from "react-secure-storage";
import {Asset_Owned, consumption, Employee_Size, Turn_Over, usage} from "../../../../constants/constants";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const DatumQuestions = props => {
    const {stateData, listLeads, handleDrawerClose, param, isOpenDrawer, isFormValid, setFormDataForQus, formDataForQus, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(
        param => {
            dispatch(getCategory(secureLocalStorage.getItem("accessToken"), param));
        },
        [dispatch]
    );

    const handleInputChange = event => {
        const {id, value} = event.target;
        setFormDataForQus({
            ...formDataForQus,
            [id]: value
        });
        if (event.target.id === "category") {
            const param = {
                category: value
            };
            dispatch(
                getSubCategory(
                    secureLocalStorage.getItem("accessToken"),
                    enqueueSnackbar,
                    param
                )
            );
        } else if (event.target.id === "sub_category") {
            const param = {
                sub_category: value
            };
            dispatch(
                getCompanyType(
                    secureLocalStorage.getItem("accessToken"),
                    enqueueSnackbar,
                    param
                )
            );
        }
    };

    // const getLeadList = () => {
    //     dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    // };

    // const handleSubmit = () => {
    //     dispatch(
    //         CompanyDetails(
    //             secureLocalStorage.getItem("accessToken"),
    //             formDataForQus,
    //             enqueueSnackbar,
    //             handleDrawerClose,
    //             getLeadList
    //         )
    //     );
    // };


    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Employee Size"}
                            margin="dense"
                            fullWidth
                            value={formDataForQus.employee_size}
                            onChange={handleInputChange}
                            // required
                            id={"employee_size"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option key={0} value={""}></option>

                            {Employee_Size.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Turn Over"}
                            margin="dense"
                            fullWidth
                            value={formDataForQus.turn_over}
                            onChange={handleInputChange}
                            // required
                            id={"turn_over"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option key={0} value={""}></option>

                            {Turn_Over.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Asset Owned"}
                            margin="dense"
                            fullWidth
                            value={formDataForQus.asset_owned}
                            onChange={handleInputChange}
                            // required
                            id={"asset_owned"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option key={0} value={""}></option>

                            {Asset_Owned.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Monthly Consumption"}
                            margin="dense"
                            required
                            fullWidth
                            value={formDataForQus.monthly_diesel_consumption}
                            onChange={handleInputChange}
                            id={"monthly_diesel_consumption"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option key={0} value={""}></option>

                            {consumption.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            required
                            className={classes.textField}
                            label={"Industry"}
                            id={"category"}
                            margin="dense"
                            onChange={handleInputChange}
                            fullWidth
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                            value={formDataForQus.category}
                        >
                            <option key={0} value={""}></option>
                            {stateData?.categories?.map(option => (
                                <option key={option} id={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Category"}
                            id={"sub_category"}
                            margin="dense"
                            onChange={handleInputChange}
                            disabled={!formDataForQus.category}
                            fullWidth
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                            value={formDataForQus.sub_category}
                        >
                            <option key={0} value={""}></option>
                            {stateData?.subCategories?.map(option => (
                                <option key={option} id={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Company Type"}
                            margin="dense"
                            fullWidth
                            value={formDataForQus.company_type}
                            onChange={handleInputChange}
                            disabled={!formDataForQus.sub_category}
                            // required
                            id={"company_type"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option key={0} value={""}></option>
                            {stateData?.companyType?.map(option => (
                                <option key={option} id={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={2} xs={6}>
                        <TextField
                            className={classes.textField}
                            label={"Usage"}
                            margin="dense"
                            fullWidth
                            value={formDataForQus.usage}
                            onChange={handleInputChange}
                            // required
                            id={"usage"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option key={0} value={""}></option>

                            {usage.map(option => (
                                <option key={option.name} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                {/*<Grid container spacing={2}>*/}
                {/*    <Grid item lg={2} md={2} xs={6}>*/}
                {/*        <Button*/}
                {/*            className={classes.addNewPartnerButton}*/}
                {/*            color="primary"*/}
                {/*            size="medium"*/}
                {/*            variant="contained"*/}
                {/*            onClick={handleSubmit}*/}
                {/*            disabled={!isFormValid()}*/}
                {/*        >*/}
                {/*            Save*/}
                {/*        </Button>*/}
                {/*    </Grid>*/}
                {/*    {!isOpenDrawer && <Grid item lg={2} md={2} xs={6}>*/}
                {/*        <Button*/}
                {/*            className={classes.addNewPartnerButton}*/}
                {/*            color="primary"*/}
                {/*            size="medium"*/}
                {/*            variant="contained"*/}
                {/*            // disabled={!isFormValid()}*/}
                {/*            onClick={handleDrawerClose}*/}
                {/*        >*/}
                {/*            Cancel*/}
                {/*        </Button>*/}
                {/*    </Grid>}*/}
                {/*</Grid>*/}
            </>

        </div>
    );
};
export default compose(withStyles(styles))(DatumQuestions);
