import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
    const {setAlertDialogSlide, alertDialogSlide, handleAction} = props;

    function handleClickOpen() {
        handleAction();
    }

    function handleClose() {
        setAlertDialogSlide(false);
    }

    return (
        <div>
            {/*<Fab aria-label="delete" size={'small'} onClick={handleClickOpen}>*/}
            {/*    <DeleteIcon/>*/}
            {/*</Fab>*/}
            {/*<Fab color="secondary" size={'small'}  onClick={handleEditOpen} aria-label="edit">*/}
            {/*  <EditIcon />*/}
            {/*</Fab>*/}
            <Dialog
                open={alertDialogSlide}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Delete "} {props?.data} ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{color: "#985555", fontWeight: 500}}
                    >
                        Are you sure you want to delete {props?.data}? this action cannot be
                        undone!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleClickOpen} color="primary">
                        Yes! Delete {props?.data}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
