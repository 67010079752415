import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Divider, Typography, withStyles} from "@material-ui/core";

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
});

class Footer extends Component {
    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <Divider/>
                <Typography className={classes.company} variant="body1">
                    &copy; Repos Energy. 2022
                </Typography>
                <Typography variant="caption">
                    Created with love for the environment. By designers and developers who
                    love to work together!
                </Typography>
            </div>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
