export default {
    mobile: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 10,
            minimum: 10
        }
    },
    email: {
        presence: {allowEmpty: false, message: "is required"},
        email: true,
        length: {
            maximum: 128
        }
    },
    user_name: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    zoneSelected: {
        presence: {allowEmpty: false, message: "is required"}
    },
    departmentSelected: {
        presence: {allowEmpty: false, message: "is required"}
    },
    regionSelected: {
        presence: {allowEmpty: false, message: "is required"}
    },
    accessTypeSelected: {
        presence: {allowEmpty: false, message: "is required"}
    }
};
