import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
// Material helpers
// Material components
import {withStyles} from "@material-ui/core";
// Shared layouts
import {Dashboard as DashboardLayout} from "layouts";
// Custom components
import {UsersTable, UsersToolbar} from "./components";
// Component styles
import styles from "./style";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {getAllPartners, resetPartnerPassword, versionControl} from "../../actions/actions";
import axios from "axios";
import {Breakpoint} from "react-socks";
import Cards from "views/AllPumps/components/Cards/Cards";
import secureLocalStorage from "react-secure-storage";

class AllPumps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            searchPinCode: undefined,
            rowsPerPage: 10,
            offset: 0,
            page: 0,
            passResetSuccess: false
        };
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getPartnersData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getPartnersData("");
        }
    };

    onSearchPinCode = pinCode => {
        if (pinCode.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchPinCode: pinCode,
                rowsPerPage: 10,
                page: 0
            });
            this.getPartnersData(
                "",
                10,
                0,
                undefined,
                undefined,
                this.abortController,
                0,
                pinCode
            );
        } else {
            this.setState({
                searchPinCode: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getPartnersData(
                "",
                10,
                0,
                undefined,
                undefined,
                this.abortController,
                0,
                undefined
            );
        }
    };

    onPagination = (limit, page) => {
        let offsetChange = limit * page;
        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page, offset: offsetChange});

        this.getPartnersData(
            this.state.searchString,
            limit,
            offsetChange,
            undefined,
            undefined,
            undefined,
            undefined,
            this.state.searchPinCode
        );
    };

    onSelectedRefueller = refueller => {
        if (refueller !== "") {
            this.getPartnersData("", 10, 0, refueller);
        } else {
            this.getPartnersData();
        }
    };

    onSelectedCity = city => {
        if (city !== "") {
            this.getPartnersData("", 10, 0, null, city);
        } else {
            this.getPartnersData();
        }
    };

    getPartnersData = (
        searchTerm = this.state.searchString,
        limit = this.state.rowsPerPage,
        offset = this.state.offset,
        refueller = null,
        city = null,
        abortController = this.abortController,
        fis_orders = 0,
        pinCode = this.state.pinCode
    ) => {
        this.props.handleGetAllPartners(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            refueller,
            city,
            abortController,
            fis_orders,
            pinCode
        );
    };

    resetPassword = selectedPartner => {
        this.props.handleResetPassword(
            secureLocalStorage.getItem("accessToken"),
            selectedPartner
        );
    };

    reloadTable = () => {
        this.getPartnersData();
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getPartnersData();
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="All Partners">
                    <div className={classes.root}>
                        {/*{*/}
                        {/*    this.props.allPartnersData === []*/}
                        {/*        ?*/}
                        {/*        <div className={classes.progressWrapper}>*/}
                        {/*            <CircularProgress className={classes.progress}/>*/}
                        {/*        </div>*/}
                        {/*        :*/}
                        <div>
                            <UsersToolbar
                                onSearchPinCode={this.onSearchPinCode}
                                allPartners={this.props.allPartnersData}
                                searchedTerm={this.onSearchTerm}
                                selectedRefueller={this.onSelectedRefueller}
                                selectedCity={this.onSelectedCity}
                                history={this.props.history}
                                partnerCreationSuccessReloadRequest={this.reloadTable}
                            />
                            <Breakpoint medium up>
                                <UsersTable
                                    dataCount={this.props.dataCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    allPartnersData={this.props.allPartnersData}
                                    page={this.state.page}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                    resetPassword={this.resetPassword}
                                    passwordResetSuccess={this.props.passwordResetSuccess}
                                    onModificationSuccess={this.reloadTable}
                                />
                            </Breakpoint>
                            <Breakpoint small down>
                                <Cards
                                    dataCount={this.props.dataCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    allPartnersData={this.props.allPartnersData}
                                    page={this.state.page}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                    resetPassword={this.resetPassword}
                                    passwordResetSuccess={this.props.passwordResetSuccess}
                                    onModificationSuccess={this.reloadTable}
                                />
                            </Breakpoint>
                        </div>
                        {/*}*/}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllPumps.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allPartnersData: state.allPartnersData,
        isLoading: state.isLoading,
        passwordResetSuccess: state.passwordResetSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllPartners: (
            accessToken,
            searchString,
            limit,
            offset,
            refueller,
            city,
            abortController,
            fis_orders,
            pinCode
        ) => {
            dispatch(
                getAllPartners(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    refueller,
                    city,
                    abortController,
                    fis_orders,
                    pinCode
                )
            );
        },
        handleResetPassword: (accessToken, partnerId) => {
            dispatch(resetPartnerPassword(accessToken, partnerId));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(AllPumps));
