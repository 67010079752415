import fernet from "fernet";

const decryption = response => {
    let secret = new fernet.Secret(
        "BbSePhsFh4eJ5U5hUkfTm_Y1R1wS4PhWnLltHUPiMW8="
    );
    let token = new fernet.Token({
        secret: secret
    });
    token.encode("string");

    let toke = new fernet.Token({
        secret: secret,
        token: response?.data,
        ttl: 0
    });

    return JSON.parse(toke.decode());
};

export default decryption;