import React, {Component} from "react";
import {Breakpoint} from "react-socks";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {withStyles} from "@material-ui/core";

// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Custom components
import {UsersTable, UsersToolbar} from "./components";

// Component styles
import styles from "./style";
import {getAllAssets, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Cards from "views/AllAssets/components/Cards/Cards";
import secureLocalStorage from "react-secure-storage";

class AllAssets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0,
            asset: "0",
            customer: ""
        };
        this.abortController = "";
    }

    selectedAssetType = asset => {
        if (asset !== "") {
            this.setState({asset});
            this.getAssetData(
                this.state.searchString,
                10,
                this.state.offset,
                (this.state.customer = null),
                asset,
                this.state.fromDateFilter,
                this.state.toDate
            );
        } else {
            this.getAssetData();
        }
    };

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getAssetData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getAssetData("");
        }
    };

    onSelectedCustomer = value => {
        if (value === "") {
            this.getAssetData("", this.state.rowsPerPage, 0);
        } else this.getAssetData("", this.state.rowsPerPage, 0, value);
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        this.setState({rowsPerPage: limit, page});

        this.getAssetData(this.state.searchString, limit, offset);
    };

    getAssetData = (
        searchTerm = "",
        limit = this.state.rowsPerPage,
        offset = this.state.offset,
        customer = null,
        asset = this.state.asset,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllAssets(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            customer,
            asset,
            abortController
        );
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getAssetData();
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="Assets">
                    <div className={classes.root}>
                        {this.props.allCustomerData?.length === 0 ? (
                            <div className={classes.progressWrapper}>
                                <CircularProgress className={classes.progress}/>
                            </div>
                        ) : (
                            <div>
                                <UsersToolbar
                                    AllAssetsData={this.props.allAssetsData}
                                    searchedTerm={this.onSearchTerm}
                                    allCustomerData={this.props.allCustomerData}
                                    onSelectedCustomer={this.onSelectedCustomer}
                                    selectedAssetType={this.selectedAssetType}
                                />
                                <Breakpoint medium up>
                                    <UsersTable
                                        assetDataCount={this.props.dataCount}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        allAssetsData={this.props.allAssetsData}
                                        onPagination={this.onPagination}
                                        noDataFound={this.props.noDataFound}
                                        isPartnerLoading={this.props.isLoading}
                                        reloadAsset={this.getAssetData}
                                    />
                                </Breakpoint>
                                <Breakpoint small down>
                                    <Cards
                                        assetDataCount={this.props.dataCount}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        allAssetsData={this.props.allAssetsData}
                                        onPagination={this.onPagination}
                                        noDataFound={this.props.noDataFound}
                                        isPartnerLoading={this.props.isLoading}
                                        reloadAsset={this.getAssetData}
                                    />
                                </Breakpoint>
                            </div>
                        )}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllAssets.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allAssetsData: state.allAssetsData,
        allCustomerData: state.allCustomerData,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllAssets: (
            accessToken,
            searchString,
            limit,
            offset,
            customer,
            asset,
            abortController
        ) => {
            dispatch(
                getAllAssets(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    customer,
                    asset,
                    abortController
                )
            );
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(AllAssets));
