import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
// Material components
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    withStyles
} from "@material-ui/core";

// Material icons
import {
    AssignmentOutlined as OrderIcon,
    AssignmentTurnedInOutlined as AssignmentIcon,
    BallotOutlined as AssetIcon,
    DashboardOutlined as DashboardIcon,
    DonutSmallOutlined as SteeringIcon,
    LocalGasStationOutlined as PumpIcon,
    LocalShippingOutlined as RefuellerIcon,
    MemoryOutlined as MemoryIcon,
    PeopleOutlined as PeopleIcon,
    WifiTetheringOutlined as LiveIcon
} from "@material-ui/icons";
import StorageIcon from "@material-ui/icons/Storage";
import SettingsIcon from "@mui/icons-material/Settings";
import Person3Icon from "@mui/icons-material/Person3";

// Component styles
import styles from "./styles";
import {connect} from "react-redux";
import compose from "recompose/compose";
import secureLocalStorage from "react-secure-storage";

class Sidebar extends Component {
    render() {
        const {classes, className} = this.props;
        const rootClassName = classNames(classes.root, className);

        return (
            <nav className={rootClassName}>
                <div className={classes.logoWrapper}>
                    <Link className={classes.logoLink} to="/">
                        {/*<img  alt="logo" className="brand-logo"/>*/}
                        <img
                            alt="Brainalytica logo"
                            className={classes.logoImage}
                            // src="/images/logos/logo_tans_small.png"
                            style={{width: "100px"}}
                            src={require("../../../../assets/img/Repos_Black_A.png")}
                        />
                    </Link>
                </div>
                <Divider className={classes.logoDivider}/>
                <div className={classes.profile}>
                    <Link to="#">
                        <Avatar
                            alt="default image"
                            className={classes.avatar}
                            src="/images/avatars/default_avatar.png"
                        />
                    </Link>
                    <Typography className={classes.nameText} variant="h6">
                        Administrator
                        {/*{this.props.userDetails.first_name} { this.props.userDetails.last_name}*/}
                    </Typography>
                    <Typography className={classes.bioText} variant="caption">
                        {/*+91 {this.props.userDetails.mobile}*/}
                        Repos
                    </Typography>
                </div>
                <Divider className={classes.profileDivider}/>
                <List component="div" disablePadding>
                    {secureLocalStorage.getItem("department") === 1 ? (
                        <>
                            {/*<ListItem*/}
                            {/*    activeClassName={classes.activeListItem}*/}
                            {/*    className={classes.listItem}*/}
                            {/*    component={NavLink}*/}
                            {/*    to="/dashboard"*/}
                            {/*>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <DashboardIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText*/}
                            {/*        classes={{primary: classes.listItemText}}*/}
                            {/*        id={"dashboard"}*/}
                            {/*        primary="Dashboard"*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/all-assets"
                                id={"all_assets"}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssetIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" Customer Assets"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_drivers"}
                                to="/all-drivers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div
                                        style={{
                                            transform: "rotate(90deg)",
                                            marginLeft: "-5px",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        <SteeringIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Operators"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_order_assignments"}
                                to="/all-order-assignments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Assignments"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/fleetcardpayments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"fleetcard"}
                                    primary="fleetcard"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 2 ? (
                        <>
                            {/*<ListItem*/}
                            {/*    activeClassName={classes.activeListItem}*/}
                            {/*    className={classes.listItem}*/}
                            {/*    component={NavLink}*/}
                            {/*    to="/dashboard"*/}
                            {/*>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <DashboardIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText*/}
                            {/*        classes={{primary: classes.listItemText}}*/}
                            {/*        id={"dashboard"}*/}
                            {/*        primary="Dashboard"*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/all-assets"
                                id={"all_assets"}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssetIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" Customer Assets"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_order_assignments"}
                                to="/all-order-assignments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Assignments"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/fleetcardpayments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"fleetcard"}
                                    primary="fleetcard"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 3 ? (
                        <>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/dashboard"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"dashboard"}
                                    primary="Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/all-assets"
                                id={"all_assets"}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssetIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" Customer Assets"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_drivers"}
                                to="/all-drivers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div
                                        style={{
                                            transform: "rotate(90deg)",
                                            marginLeft: "-5px",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        <SteeringIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Operators"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_order_assignments"}
                                to="/all-order-assignments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Assignments"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/fleetcardpayments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"fleetcard"}
                                    primary="fleetcard"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 4 ? (
                        <>
                            {/*<ListItem*/}
                            {/*    activeClassName={classes.activeListItem}*/}
                            {/*    className={classes.listItem}*/}
                            {/*    component={NavLink}*/}
                            {/*    to="/dashboard"*/}
                            {/*>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <DashboardIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText*/}
                            {/*        classes={{primary: classes.listItemText}}*/}
                            {/*        id={"dashboard"}*/}
                            {/*        primary="Dashboard"*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 5 ? (
                        <>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/dashboard"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"dashboard"}
                                    primary="Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/admin-dashboard"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Manage Access"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/all-assets"
                                id={"all_assets"}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssetIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" Customer Assets"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_drivers"}
                                to="/all-drivers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div
                                        style={{
                                            transform: "rotate(90deg)",
                                            marginLeft: "-5px",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        <SteeringIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Operators"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_order_assignments"}
                                to="/all-order-assignments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Assignments"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_trackers"}
                                to="/all-trackers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="IOT Trackers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_service"}
                                to="/service_request"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Service Request"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/fleetcardpayments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"fleetcard"}
                                    primary="fleetcard"
                                />
                            </ListItem>

                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 6 ? (
                        <>
                            {/*<ListItem*/}
                            {/*    activeClassName={classes.activeListItem}*/}
                            {/*    className={classes.listItem}*/}
                            {/*    component={NavLink}*/}
                            {/*    to="/dashboard"*/}
                            {/*>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <DashboardIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText*/}
                            {/*        classes={{primary: classes.listItemText}}*/}
                            {/*        id={"dashboard"}*/}
                            {/*        primary="Dashboard"*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 7 ? (
                        <>
                            {/*<ListItem*/}
                            {/*    activeClassName={classes.activeListItem}*/}
                            {/*    className={classes.listItem}*/}
                            {/*    component={NavLink}*/}
                            {/*    to="/dashboard"*/}
                            {/*>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <DashboardIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText*/}
                            {/*        classes={{primary: classes.listItemText}}*/}
                            {/*        id={"dashboard"}*/}
                            {/*        primary="Dashboard"*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 8 ||
                    secureLocalStorage.getItem("department") === "" ? (
                        <>
                            {/*<ListItem*/}
                            {/*    activeClassName={classes.activeListItem}*/}
                            {/*    className={classes.listItem}*/}
                            {/*    component={NavLink}*/}
                            {/*    to="/dashboard"*/}
                            {/*>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <DashboardIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText*/}
                            {/*        classes={{primary: classes.listItemText}}*/}
                            {/*        id={"dashboard"}*/}
                            {/*        primary="Dashboard"*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/all-assets"
                                id={"all_assets"}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssetIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" Customer Assets"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_drivers"}
                                to="/all-drivers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div
                                        style={{
                                            transform: "rotate(90deg)",
                                            marginLeft: "-5px",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        <SteeringIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Operators"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_order_assignments"}
                                to="/all-order-assignments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Assignments"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_trackers"}
                                to="/all-trackers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="IOT Trackers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_service"}
                                to="/service_request"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Service Request"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 9 ? (
                        <>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_service"}
                                to="/service_request"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Service Request"
                                />
                            </ListItem>

                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : secureLocalStorage.getItem("department") === 10 ? (
                        <>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/dashboard"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"dashboard"}
                                    primary="Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"live_status"}
                                to="/live-status"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LiveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Live Status"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_customers"}
                                to="/all-customers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Customers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/all-assets"
                                id={"all_assets"}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssetIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" Customer Assets"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_pumps"}
                                to="/all-pumps"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PumpIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Partners"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_drivers"}
                                to="/all-drivers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div
                                        style={{
                                            transform: "rotate(90deg)",
                                            marginLeft: "-5px",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        <SteeringIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Operators"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_refuellers"}
                                to="/all-refuellers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RefuellerIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Repos Petrol Pumps"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_orders"}
                                to="/all-orders"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <OrderIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary=" RPP Orders"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_datums"}
                                to="/all-datums"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <div>
                                        <StorageIcon/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Datum Dashboard"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_order_assignments"}
                                to="/all-order-assignments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Assignments"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_trackers"}
                                to="/all-trackers"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="IOT Trackers"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                id={"all_service"}
                                to="/service_request"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MemoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    primary="Service Request"
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLink}
                                to="/fleetcardpayments"
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{primary: classes.listItemText}}
                                    id={"fleetcard"}
                                    primary="fleetcard"
                                />
                            </ListItem>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    ) : (
                        <>
                            {/************************************ crm user ********************************/}
                            {secureLocalStorage.getItem("is_crm") === true && (
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLink}
                                    to="/ReposCRM"
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Person3Icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{primary: classes.listItemText}}
                                        id={"ReposCRM"}
                                        primary="Repos CRM"
                                    />
                                </ListItem>
                            )}
                            {/************************************ *****************************************/}
                        </>
                    )}
                </List>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        accessToken: state.accessToken,
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess,
        loginError: state.loginError,
        userDetails: state.userDetails
    };
};
export default connect(mapStateToProps)(compose(withStyles(styles))(Sidebar));
// export default withStyles(styles)(Sidebar);
