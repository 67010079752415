import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Label from "../../../Dashboard/components/Label";
import {CloseOutlined as CloseButton} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
// Shared components
import {Portlet, PortletContent} from "components";
import {connect} from "react-redux";
// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import {withSnackbar} from "notistack";
import compose from "recompose/compose";
import {deleteUser} from "../../../../actions/actions";
import PropTypes from "prop-types";
import AddUser from "../AddUser";
import secureLocalStorage from "react-secure-storage";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.abortController = "";
        this.state = {
            editUser: false,
            selectedUser: []
        };
    }

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    handleDelete = (e, id) => {
        this.props.handleDeleteUser(
            secureLocalStorage.getItem("accessToken"),
            id,
            this.props
        );
    };

    handleEdit = () => {
    };

    handleEditUserDrawerOpen = (e, user) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            editUser: true,
            selectedUser: user
        });
    };

    handleDrawerClose = () => {
        this.setState({
            right: false,
            showMore: false,
            editUser: false
        });
    };

    redirectOnSuccess = () => {
        this.props.onModificationSuccess();
        this.handleDrawerClose();
    };

    render() {
        const {classes, className, allUserData} = this.props;
        const rootClassName = classNames(classes.root, className);

        // const indianStates = {
        //     AN: "ANDAMAN AND NICOBAR ISLANDS",
        //     AP: "ANDHRA PRADESH",
        //     AR: "ARUNACHAL PRADESH",
        //     AS: "ASSAM",
        //     BR: "BIHAR",
        //     CT: "CHHATTISGARH",
        //     CH: "CHANDIGARH",
        //     DD: "DAMN AND DIU",
        //     DL: "DELHI",
        //     DN: "DADRA AND NAGAR HAVELI",
        //     GA: "GOA",
        //     GJ: "GUJARAT",
        //     HR: "HARYANA",
        //     HP: "HIMACHAL PRADESH",
        //     JH: "JHARKHAND",
        //     JK: "JAMMU AND KASHMIR",
        //     KA: "KARNATAKA",
        //     KL: "KERALA",
        //     LD: "LAKSHADWEEP",
        //     MH: "MAHARASHTRA",
        //     MP: "MADHYA PRADESH",
        //     MN: "MANIPUR",
        //     MZ: "MIZORAM",
        //     NL: "NAGALAND",
        //     OD: "ODISHA",
        //     PB: "PUNJAB",
        //     PY: "PONDICHERRY",
        //     RJ: "RAJASTHAN",
        //     SK: "SIKKIM",
        //     TN: "TAMIL NADU",
        //     TR: "TRIPURA",
        //     TS: "TELANGANA",
        //     UK: "UTTARAKHAND",
        //     UP: "UTTAR PRADESH",
        //     WB: "WEST BENGAL",
        //     ML: "MEGHALAYA",
        //     LA: "LADAKH"
        // };

        const department = {
            1: "OPERATIONAL EXCELLENCE",
            2: "OE PARTNER ONBOARDING",
            3: "OE ORDER FULFILLMENT",
            4: "BUSINESS ASSOCIATES",
            5: "ADMIN",
            6: "BUSINESS DEVELOPMENT",
            7: "FINANCE TEAM",
            8: "TECH TEAM",
            9: "Service Engineer",
            10: "Founders Office",
            11: "Cummins Ho",
            12: "Cummins Dealer"
        };

        if (this.props.userDataCount === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isUserLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="center">Edit</TableCell>
                                                <TableCell align="center">Mobile</TableCell>
                                                <TableCell align="center">Name</TableCell>
                                                <TableCell align="center">Department</TableCell>
                                                <TableCell align="center">Company Email</TableCell>
                                                {/* <TableCell align="center">Region</TableCell> */}
                                                <TableCell align="center">State</TableCell>
                                                {/* <TableCell align="center">Territory</TableCell> */}
                                                <TableCell align="center">User Type</TableCell>
                                                <TableCell align="center">Access Type</TableCell>
                                                <TableCell align="center">Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"13"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {allUserData?.map(users => (
                                                    <TableRow className={classes.tableRow} hover>
                                                        <TableCell className={classes.tableCell}>
                                                            <Button
                                                                size={"medium"}
                                                                variant="extended"
                                                                style={{textTransform: "capitalize"}}
                                                                aria-label="delete"
                                                                className={classes.fab}
                                                                onClick={event => {
                                                                    this.handleEditUserDrawerOpen(event, users);
                                                                }}
                                                            >
                                                                <EditIcon className={classes.extendedIcon}/>
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {users.mobile}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {users.name}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            <b>
                                                                {users.department === null ? (
                                                                    <Typography
                                                                        variant={"overline"}
                                                                        color={"error"}
                                                                        style={{textTransform: "none"}}
                                                                    >
                                                                        No Data
                                                                    </Typography>
                                                                ) : (
                                                                    department[users.department]
                                                                )}
                                                            </b>
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {users.email}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {users.state_list[0]}
                                                        </TableCell>
                                                        {/* <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {users.state === null ?
                                                                <Typography variant={"overline"} color={'error'}
                                                                            style={{textTransform: 'none'}}>
                                                                    No Data
                                                                </Typography> : indianStates[users.state]}
                                                        </TableCell> */}
                                                        {/* <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {users.territory === null ?
                                                                <Typography variant={"overline"} color={'error'}
                                                                            style={{textTransform: 'none'}}>
                                                                    No Data
                                                                </Typography> : users.territory
                                                            }
                                                        </TableCell> */}
                                                        <TableCell
                                                            align="center"
                                                            className={classes.tableCell}
                                                        >
                                                            {users.is_crm === true
                                                                ? "CRM"
                                                                : "SuperAdmin User"}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Label
                                                                color={
                                                                    users.access_type ? "#F7A4A4" : "#B9E0FF"
                                                                }
                                                            >
                                                                <p
                                                                    style={{
                                                                        color: users.access_type
                                                                            ? "#DC3535"
                                                                            : "blue",
                                                                        fontWeight: "700",
                                                                        width: "90px",
                                                                        textAlign: "center"
                                                                    }}
                                                                >
                                                                    {users.access_type ? "Read & Write " : "Read"}
                                                                </p>
                                                            </Label>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Button
                                                                onClick={e => this.handleDelete(e, users.id)}
                                                            >
                                                                <DeleteIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>
                            <Drawer
                                classes={{
                                    paper: classes.width10
                                }}
                                anchor={"left"}
                                open={this.state.editUser}
                                onClose={this.handleDrawerClose}
                            >
                                <div className={classes.drawerHeading}>
                                    <Typography
                                        className={classes.nameText}
                                        variant="h1"
                                        style={{
                                            fontWeight: 100,
                                            color: "gray",
                                            marginTop: "5%"
                                        }}
                                    >
                                        Edit User
                                    </Typography>
                                </div>
                                <div className={classes.drawerHeader}>
                                    <IconButton>
                                        <CloseButton onClick={this.handleDrawerClose}/>
                                    </IconButton>
                                </div>
                                <AddUser
                                    onClose={this.handleDrawerClose}
                                    user={this.state.selectedUser}
                                    isUpdate={true}
                                    userCreationSucess={this.redirectOnSuccess}
                                    drawerClose={this.handleDrawerClose}
                                />
                                {/*<EditUser*/}
                                {/*    user={this.state.selectedUser}*/}
                                {/*    onModificationSuccess={this.redirectOnSuccess}*/}
                                {/*/>*/}
                            </Drawer>
                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.allUserDataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100, 500, 5000]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
    }

    // }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
        deleteUserData: state.deleteUserData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleDeleteUser: (accessToken, id, snackBar) => {
            dispatch(deleteUser(accessToken, id, snackBar));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(UsersTable)));
