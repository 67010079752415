import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Typography, withStyles} from "@material-ui/core";

// Material icons
// Shared components
import {SearchInput} from "components";
// import SelectDropdown from '../../../../components/SelectDropdown'
// Component styles
import styles from "./styles";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: ""
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            selectedValue: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    onSelect(value) {
        const {onSelectedCustomer} = this.props;
        onSelectedCustomer(value);
    }

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        // const exportCSV = () => {
        //   var csvRow =[];
        //   var A = [['Vehicle NNumber','Last Location', 'Last Stock', 'Last Updated At', 'Partner', 'Driver', 'Refueler Model', 'Tracker Device Id', 'Model Type', ' CarIQ', 'CarIQ Device ID', 'Current Rate', 'Current Density']];
        //   var allRefuellerData = this.props.allRefuellerData;
        //
        //   var firstNameForFileName = '';
        //   var lastNameForFileName = '';
        //
        //   for (var item =0; item < allRefuellerData.length; item++){
        //     A.push([item, allRefuellerData[item].vehicle_registration_number, allRefuellerData[item].last_stock,allRefuellerData[item].last_feed_at,allRefuellerData[item].partner_name,allRefuellerData[item].driver,allRefuellerData[item].refueler_model,allRefuellerData[item].tracker_device, allRefuellerData[item].model_type, allRefuellerData[item].is_cariq_activated, allRefuellerData[item].cariq, allRefuellerData[item].current_rate, allRefuellerData[item].current_density])
        //     firstNameForFileName = allRefuellerData[0].vehicle_registration_number;
        //     lastNameForFileName  = allRefuellerData[allRefuellerData.length-1].vehicle_registration_number;
        //   }
        //
        //   for (var i=0; i< A.length; ++i){
        //     csvRow.push(A[i].join(","))
        //   }
        //
        //   var today = new Date();
        //   var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        //   var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //   var dateTime = date+'_'+time;
        //
        //   var csvString = csvRow.join("%0A");
        //
        //   var a = document.createElement("a");
        //
        //   a.href='data:attachement/csv,' + csvString;
        //
        //   a.target ="_Blank";
        //
        //   a.download= "Refueller List" + firstNameForFileName + "_to_" + lastNameForFileName+ "at"+ dateTime + ".csv";
        //
        //   document.body.appendChild(a);
        //
        //   a.click();
        //
        // };

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        All Geofences
                    </Typography>
                </div>
                <div className={classes.row}>
                    <span className={classes.spacer}/>
                </div>
                <div className={classes.row}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search Geofence"
                        getsearchterm={this.onSearch}
                    />
                    {/*<SelectDropdown label={"Select Customer"} id={"select_customer"} data={this.props.allCustomerData} onSelect={this.onSelect}/>*/}
                    <span className={classes.spacer}/>
                </div>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
