import React, {Component} from "react";
import "./modify.css";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        marginTop: "150px",
        textAlign: "center"
    },
    image: {
        display: "inline-block",
        marginTop: "50px",
        maxWidth: "100%",
        width: "554px"
    },
    progressWrapper: {
        paddingTop: "48px",
        paddingBottom: "24px",
        display: "flex",
        justifyContent: "center"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
});

class MapContainerForDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            polygonArray: []
        };
    }

    initMap = () => {
        const location = this.props.coords;
        const passNewArray = geoArray => {
            this.props.getUpdatedPolygon(geoArray);
        };
        let markers = [];
        // navigator.geolocation.getCurrentPosition(function(position) {

        const map = new window.google.maps.Map(
            document.getElementById("map-canvas"),
            {
                center: {
                    lat: this.props.coords[0].lat,
                    lng: this.props.coords[1].lng
                },
                zoom: 13,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true
            }
        );

        // Create the search box and link it to the UI element.
        const input = /** @type {HTMLInputElement} */ (document.getElementById(
            "pac-input"
        ));
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        const searchBox = new window.google.maps.places.SearchBox(
            /** @type {HTMLInputElement} */ (input)
        );

        // [START region_getplaces]
        // Listen for the event fired when the user selects an item from the
        // pick list. Retrieve the matching places for that item.
        window.google.maps.event.addListener(
            searchBox,
            "places_changed",
            function () {
                const places = searchBox.getPlaces();

                // eslint-disable-next-line
                if (places.length == 0) {
                    return;
                }

                // eslint-disable-next-line
                for (var i = 0, marker; (marker = markers[i]); i++) {
                    marker.setMap(null);
                }

                // For each place, get the icon, place name, and location.
                markers = [];
                const bounds = new window.google.maps.LatLngBounds();

                // eslint-disable-next-line
                for (var i = 0, place; (place = places[i]); i++) {
                    const image = {
                        url: place.icon,
                        size: new window.google.maps.Size(71, 71),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(25, 25)
                    };

                    // Create a marker for each place.

                    // eslint-disable-next-line
                    var marker = new window.google.maps.Marker({
                        map: map,
                        icon: image,
                        title: place.name,
                        position: place.geometry.location
                    });

                    markers.push(marker);

                    bounds.extend(place.geometry.location);
                }

                map.fitBounds(bounds);
            }
        );
        // [END region_getplaces]

        // Bias the SearchBox results towards places that are within the bounds of the
        // current map's viewport.
        window.google.maps.event.addListener(map, "bounds_changed", function () {
            const bounds = map.getBounds();
            searchBox.setBounds(bounds);
        });

        let all_overlays = [];
        let selectedShape;
        const drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_RIGHT,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
                fillColor: "rgba(152,152,152,0.51)",
                fillOpacity: 1
            }
        });
        let polygonArray = [];

        function clearSelection() {
            if (selectedShape) {
                selectedShape.setEditable(false);
                selectedShape = null;
            }
        }

        function setSelection(shape) {
            polygonArray = location;
            deleteSelectedShape();
            clearSelection();
            selectedShape = shape;
            shape.setEditable(false);
            const polygonBounds = shape.getPath();
            const bounds = [];
            for (let i = 0; i < polygonBounds.length; i++) {
                const point = {
                    lat: polygonBounds.getAt(i).lat(),
                    lng: polygonBounds.getAt(i).lng()
                };
                bounds.push(point);
                polygonArray.push(point);
            }
            passNewArray(polygonArray);
        }

        function deleteSelectedShape() {
            polygonArray = [];

            if (selectedShape) {
                selectedShape.setMap(null);
            }
        }

        // eslint-disable-next-line
        function deleteAllShape() {
            for (let i = 0; i < all_overlays.length; i++) {
                all_overlays[i].overlay.setMap(null);
            }
            all_overlays = [];
            polygonArray = [];
        }

        function CenterControl(controlDiv, map) {
            // Set CSS for the control border.
            const controlUI = document.createElement("div");
            controlUI.style.backgroundColor = "#fff";
            controlUI.style.border = "2px solid #fff";
            controlUI.style.borderRadius = "3px";
            controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
            controlUI.style.cursor = "pointer";
            controlUI.style.marginBottom = "22px";
            controlUI.style.textAlign = "center";
            controlUI.title = "Select to delete the shape";
            controlDiv.appendChild(controlUI);

            // Set CSS for the control interior.
            const controlText = document.createElement("div");
            controlText.style.color = "rgb(25,25,25)";
            controlText.style.fontFamily = "Roboto,Arial,sans-serif";
            controlText.style.fontSize = "16px";
            controlText.style.lineHeight = "38px";
            controlText.style.paddingLeft = "5px";
            controlText.style.paddingRight = "5px";
            controlText.innerHTML = "Delete Selected Area";
            controlUI.appendChild(controlText);

            // Setup the click event listeners: simply set the map to Chicago.
            controlUI.addEventListener("click", function () {
                deleteSelectedShape();
            });
        }

        drawingManager.setMap(map);
        // eslint-disable-next-line
        const getPolygonCoords = function (newShape) {
            const len = newShape.getPath().getLength();
            for (let i = 0; i < len; i++) {
            }
        };

        window.google.maps.event.addListener(
            drawingManager,
            "polygoncomplete",
            function (polygon) {
                for (let i = 0; i < polygon.getPath().getLength(); i++) {
                    polygon
                        .getPath()
                        .getAt(i)
                        .toUrlValue(6);
                }
            }
        );

        window.google.maps.event.addListener(
            drawingManager,
            "overlaycomplete",
            function (event) {
                all_overlays.push(event);
                if (event.type !== window.google.maps.drawing.OverlayType.MARKER) {
                    drawingManager.setDrawingMode(null);
                    //Write code to select the newly selected object.

                    const newShape = event.overlay;
                    newShape.type = event.type;
                    window.google.maps.event.addListener(newShape, "click", function () {
                        setSelection(newShape);
                    });

                    setSelection(newShape);
                }
            }
        );

        const centerControlDiv = document.createElement("div");
        // eslint-disable-next-line
        const centerControl = new CenterControl(centerControlDiv, map);

        centerControlDiv.index = 1;
        map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
            centerControlDiv
        );
        // });
    };

    componentDidMount() {
        loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyC5-RRQjjGOPGIAFt-tuy6ClXrjjFZFvxw&libraries=drawing,places&callback=initMap"
        );
        window.initMap = this.initMap;
    }

    render() {
        return (
            <div>
                {/*<div id="map">*/}
                {/*    <input id="pac-input" className="controls" type="text" placeholder="Search Box"/>*/}
                {/*</div>*/}

                <input
                    id="pac-input"
                    className="controls"
                    type="text"
                    placeholder="Search Box"
                />
                <div id="map-canvas" style={{overflow: "initial", top: "74px"}}></div>
            </div>
        );
    }
}

function loadScript(url) {
    const index = window.document.getElementsByTagName("script")[0];
    const index2 = window.document.getElementsByTagName("script")[1];
    const script = window.document.createElement("script");
    const script2 = window.document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script2.type = "text/javascript";
    script2.src =
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
    index2.parentNode.insertBefore(script2, index2);
}

export default withStyles(styles)(MapContainerForDrawing);
