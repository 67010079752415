import React, {Component} from "react";
import UsersTable from "./components/UsersTable";
import UsersToolbar from "./components/UsersToolbar";
import {Dashboard as DashboardLayout} from "layouts";
import styles from "./styles";
import {withStyles} from "@material-ui/core";
import {withSnackbar} from "notistack";
import compose from "recompose/compose";
import {getAllUsers, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import secureLocalStorage from "react-secure-storage";

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0
        };
    }

    getAllUsers = () => {
        this.props.handleGetAllUsers(
            secureLocalStorage.getItem("accessToken"),
            this.state.rowsPerPage,
            this.state.page
        );
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getAllUsers();
    }

    reloadTable = () => {
        this.getAllUsers();
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page});
        this.props.handleGetAllUsers(
            secureLocalStorage.getItem("accessToken"),
            limit,
            offset
        );
    };

    render() {
        const {classes} = this.props;
        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        }
        return (
            <DashboardLayout title="Manage Access">
                <div className={classes.root}>
                    <div>
                        <UsersToolbar userCreationSuccessReloadRequest={this.reloadTable}/>
                        <UsersTable
                            allUserDataCount={this.props.dataCount}
                            rowsPerPage={this.state?.rowsPerPage}
                            page={this.state.page}
                            onPagination={this.onPagination}
                            noDataFound={this.props.noDataFound}
                            isUserDataLoading={this.props.isLoading}
                            allUserData={this.props.allUserData}
                            onModificationSuccess={this.reloadTable}
                        />
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allUserData: state.allUserData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        isLoading: state.isLoading
    };
};

AdminDashboard.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllUsers: (accessToken, limit, offset) => {
            dispatch(getAllUsers(accessToken, limit, offset));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AdminDashboard)));
