import React from "react";
import {Box, Container} from "@mui/material";
import LoginForm from "./LoginForm";
import {connect} from "react-redux";
import {mapStateToProps} from "../FetchDataFromReducer/mapStateToProps";

const Login = () => {
    return (
        <>
            {
                <div
                    style={{
                        backgroundImage: 'url("/images/loginPage/loginImage.jpeg")',
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "100vh",
                        backgroundAttachment: "fixed",
                        backgroundSize: "100% 100%"
                    }}
                    title="Login"
                >
                    <Box display={"flex"} flexDirection={"row"}>
                        <Container
                            maxWidth={false}
                            style={{display: "flex", justifyContent: "flex-end"}}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                style={{width: "420px", borderRadius: "20%"}}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    style={{marginTop: "20%"}}
                                >
                                    <LoginForm classes={''}/>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </div>
            }
        </>
    );
};

export default connect(mapStateToProps)(Login);
