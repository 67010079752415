import {Button, TextField} from "@material-ui/core";
import React, {useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {AddRppInfo, getAllLeads} from "actions/actions.js";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const RPP_Chassis = [
    {
        key: 1,
        name: "AL_ECOMET "
    },
    {
        key: 2,
        name: "EICHER_2055XP_PRO "
    },
    {
        key: 3,
        name: "EICHER_2075XP_PRO "
    },
    {
        key: 4,
        name: "EICHER_2095_PRO "
    },
    {
        key: 5,
        name: "MAHINDRA_FURIO_10_MH "
    },
    {
        key: 6,
        name: "MAHINDRA_FURIO_10_ROI "
    },
    {
        key: 7,
        name: "MAHINDRA_FURIO_11_MH "
    },
    {
        key: 8,
        name: "MAHINDRA_FURIO_11_ROI "
    },
    {
        key: 9,
        name: "MAHINDRA_FURIO_7_MH "
    },
    {
        key: 10,
        name: "MAHINDRA_FURIO_7_ROI "
    },
    {
        key: 11,
        name: "T_7_ULTRA "
    },
    {
        key: 12,
        name: "TATA_1112 "
    },
    {
        key: 13,
        name: "TATA_T_6 "
    },
    {
        key: 14,
        name: "TATA_T_11 "
    },
    {
        key: 15,
        name: "TATA_T_19_ULTRA "
    },
    {
        key: 16,
        name: "TATA_T_14_ULTRA "
    },
    {
        key: 17,
        name: "BB_1117R "
    }
];

const OMC = [
    {
        key: 1,
        name: "HPCL "
    },
    {
        key: 2,
        name: "BPCL "
    },
    {
        key: 3,
        name: "IOCL"
    },
    {
        key: 4,
        name: "Shell"
    },
    {
        key: 5,
        name: "NYARA"
    },
    {
        key: 6,
        name: "Others"
    }
];

const AddProductDetailForm = props => {
    const {stateData, listLeads, handleDrawerClose, param, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [saveButton, setSaveButton] = useState(true);

    const [formData, setFormData] = useState({
        id: listLeads?.id,
        rpp_invoice_type: listLeads?.rpp_invoice_type
            ? listLeads?.rpp_invoice_type
            : "SI",
        rpp_loading_type: listLeads?.rpp_loading_type
            ? listLeads?.rpp_loading_type
            : "TL",
        rpp_rbml_type: listLeads?.rpp_rbml_type ? 1 : 0,
        rpp_dispensing_unit: listLeads?.rpp_dispensing_unit
            ? listLeads?.rpp_dispensing_unit
            : "GVR",
        rpp_peso_term: listLeads?.rpp_peso_term,
        rpp_pump_type: listLeads?.rpp_pump_type,
        rpp_abs: listLeads?.rpp_abs,
        requirement_comment: listLeads?.requirement_comment,
        rpp_chassis: listLeads?.rpp_chassis,
        rpp_variant: listLeads?.rpp_variant,
        rpp_omc: listLeads?.rpp_omc ? listLeads?.rpp_omc : 1
    });

    const handleInputChange = event => {
        setSaveButton(false);
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleSubmit = () => {
        dispatch(
            AddRppInfo(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar,
                handleDrawerClose,
                getLeadList
            )
        );
    };

    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForProduct = [
            "requirement_comment",
            "rpp_chassis",
            "rpp_variant",
            "rpp_omc",
            "rpp_abs"
        ];

        return requiredFieldsForProduct.every(field => formData[field]);
    };

    /************************* ************ ****************************/

    const RPP_varient = [
        {
            key: 2,
            name: "RE_X "
        },
        {
            key: 3,
            name: "RE_Y "
        },
        {
            key: 4,
            name: "RE_Z "
        },
        {
            key: 6,
            name: "VO_X "
        },
        {
            key: 7,
            name: "VO_Y "
        },
        {
            key: 8,
            name: "VO_Z "
        },
        {
            key: 9,
            name: "PROJECT "
        }
    ];

    // const RPP_varient_RBML = [
    //     {
    //         key: 1,
    //         name: "RE ",
    //     },
    //     {
    //         key: 5,
    //         name: "VO ",
    //     },
    // ];

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <TextField
                className={classes.textField}
                label={"Invoice Type"}
                margin="dense"
                fullWidth
                value={formData.rpp_invoice_type}
                onChange={handleInputChange}
                // required
                id={"rpp_invoice_type"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type}*/}
                {/* <option key={0} value={""}></option>  */}
                <option value={"SI"}>Single Invoice</option>
                <option value={"DI"}>Double Invoice</option>
            </TextField>
            <TextField
                className={classes.textField}
                label={"Loading Type"}
                margin="dense"
                fullWidth
                value={formData.rpp_loading_type}
                onChange={handleInputChange}
                // required
                id={"rpp_loading_type"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                {/* <option key={0} value={""}></option> */}
                <option value={"TL"}> Top Load</option>
                <option value={"BL"}> Bottom Load</option>
            </TextField>

            <TextField
                className={classes.textField}
                label={"RBML Type"}
                margin="dense"
                fullWidth
                value={formData.rpp_rbml_type}
                onChange={handleInputChange}
                // required
                id={"rpp_rbml_type"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                {/* <option key={0} value={""}></option> */}
                <option value={"0"}>No</option>
                <option value={"1"}>Yes</option>
            </TextField>

            <TextField
                className={classes.textField}
                label={"RPP Chassis"}
                margin="dense"
                fullWidth
                value={formData.rpp_chassis}
                onChange={handleInputChange}
                // required
                id={"rpp_chassis"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option key={0} value={""}></option>

                {RPP_Chassis.map(option => (
                    <option key={option.key} value={option.key}>
                        {option.name}
                    </option>
                ))}
            </TextField>
            {formData.rpp_rbml_type === "1" ? (
                <TextField
                    className={classes.textField}
                    label={"RPP Variant"}
                    margin="dense"
                    fullWidth
                    value={formData.rpp_variant}
                    onChange={handleInputChange}
                    // required
                    id={"rpp_variant"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    <option key={0} value={""}></option>
                    <option key={1} value={1}>
                        RE
                    </option>
                    <option key={2} value={2}>
                        VO
                    </option>
                </TextField>
            ) : (
                <TextField
                    className={classes.textField}
                    label={"RPP Variant"}
                    margin="dense"
                    fullWidth
                    value={formData.rpp_variant}
                    onChange={handleInputChange}
                    // required
                    id={"rpp_variant"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    <option key={0} value={""}></option>

                    {RPP_varient.map(option => (
                        <option key={option.key} value={option.key}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
            )}
            <TextField
                className={classes.textField}
                label={"Dispensing Unit"}
                margin="dense"
                fullWidth
                value={formData.rpp_dispensing_unit}
                onChange={handleInputChange}
                // required
                id={"rpp_dispensing_unit"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                {/* <option key={0} value={""}></option> */}
                <option value={"GVR"}>GVR</option>
                <option value={"Tokheim"}>Tokheim</option>
                <option value={"Tokheim"}>ISOL FM</option>
                <option value={"Tokheim"}>GVR FM</option>
            </TextField>
            <TextField
                className={classes.textField}
                label="Peso Terms 10 Years"
                margin="dense"
                type="text"
                disabled
                placeholder="in years"
                value={formData.rpp_peso_term}
                id={"rpp_peso_term"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            <TextField
                className={classes.textField}
                label={"OMC"}
                margin="dense"
                fullWidth
                value={formData.rpp_omc}
                onChange={handleInputChange}
                // required
                id={"rpp_omc"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option key={0} value={""}></option>

                {OMC.map(option => (
                    <option key={option.key} value={option.key}>
                        {option.name}
                    </option>
                ))}
            </TextField>
            <TextField
                className={classes.textField}
                label={"RPP abs"}
                margin="dense"
                fullWidth
                value={formData.rpp_abs}
                onChange={handleInputChange}
                // required
                id={"rpp_abs"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                <option key={0} value={""}></option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
            </TextField>
            <TextField
                label="Comment"
                margin="dense"
                id="requirement_comment"
                fullWidth
                value={formData?.requirement_comment}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={3}
            />
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleSubmit}
                disabled={!isFormValid() || saveButton}
            >
                Save
            </Button>
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleDrawerClose}
                // disabled={!isFormValid()}
            >
                Cancel
            </Button>
        </div>
    );
};
export default compose(withStyles(styles))(AddProductDetailForm);
