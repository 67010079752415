import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Box,
    Card,
    CardContent,
    Grid,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

// Shared components
import {Portlet, PortletContent} from "components";
import {withSnackbar} from "notistack";
import {connect} from "react-redux";
import compose from "recompose/compose";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {getCategory, getCompanyType, getSubCategory, sendLogs} from "actions/actions";
import qs from "qs";
import secureLocalStorage from "react-secure-storage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {Asset_Owned, consumption, Employee_Size, Turn_Over, usage} from "../../../../constants/constants";


class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: [],
            right: false,
            customerData: [],
            is_dialog: false,
            amount: 0,
            date_of_transaction: "",
            transition_reference_id: "",
            customer_id: "",
            customerDialog: false,
            subAssetDialog: false,
            customerId: "",
            fromDate: "",
            toDate: "",
            showTable: false,
            showSubTable: false,
            mCapital: false,
            questionDrawer: false,
            categories: [],
            subCategories: [],
            companyTypes: [],
            selectedCategory: "",
            selectedSubCategory: "",
            selectedCompanyType: "",
            isLoading: false
        };
    }

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    handleDrawerOpen = (e, side, open, customer) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            right: open,
            customerData: customer
        });
    };

    resetPasswordBtnSubmit = (event, customerId) => {
        event.preventDefault();
        this.props.resetPassword(customerId);
    };
    handleDrawerClose = () => {
        this.setState({right: false});
    };

    handleDialogOpen = (e, customerId) => {
        e.preventDefault();

        this.setState({
            is_dialog: true
        });
    };

    handleDialogClose = () => {
        this.setState({
            is_dialog: false
        });
    };

    handleCustomerDialogOpen = (e, customerId) => {
        e.preventDefault();

        this.setState({
            customerDialog: true
        });
    };

    handleCustomerDialogClose = () => {
        this.setState({
            customerDialog: false,
            fromDate: "",
            toDate: "",
            showTable: false
        });
    };

    handleSubAssetDialogOpen = (e, customerId) => {
        e.preventDefault();

        this.setState({
            subAssetDialog: true
        });
    };

    handleSubAssetDialogClose = () => {
        this.setState({
            subAssetDialog: false,
            fromDate: "",
            toDate: "",
            showSubTable: false
        });
    };

    handleFieldChange = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    };

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    handleFleetCardSubmit = () => {
        this.props.addFleetCard(
            secureLocalStorage.getItem("accessToken"),
            this.state.amount,
            this.state.date_of_transaction,
            this.state.transition_reference_id,
            this.state.customerData.id
        );
        this.setState({
            is_dialog: false
        });
        const requestBody = {
            amount: this.state.amount,
            date_of_transaction: this.state.date_of_transaction,
            transactoion_ref_id: this.state.transition_reference_id,
            customer_id: this.state.customerData.id
        };
        this.getLogs(
            secureLocalStorage.getItem("accessToken"),
            secureLocalStorage.getItem("mobile"),
            "/payment/fleet_card_payment/",
            qs.stringify(requestBody)
        );
    };

    handelCustomerAnalytics = () => {
        this.setState({
            showTable: true
        });
        this.props.customerAnalytics(
            secureLocalStorage.getItem("accessToken"),
            this.state.customerData.id,
            this.state.fromDate,
            this.state.toDate
        );
    };

    handelSubAssetAnalytics = () => {
        this.setState({
            showSubTable: true
        });
        this.props.subAssetAnalytics(
            secureLocalStorage.getItem("accessToken"),
            this.state.customerData.id,
            this.state.fromDate,
            this.state.toDate
        );
    };

    onFromDate = e => {
        this.setState({
            fromDate: e.target.value
        });
    };

    onToDate = e => {
        this.setState({
            toDate: e.target.value
        });
    };

    handleSwitchChange = () => {
        // Toggle the current state
        const updatedCreditLandingState = !this.state.customerData.credit_landing;

        // Make API call to update backend with new credit_landing state
        this.props.mCapital(
            secureLocalStorage.getItem("accessToken"),
            updatedCreditLandingState,
            this.state.customerData.id,
            this.props.enqueueSnackbar
        );

        // Update the state with the new credit_landing value
        this.setState(
            prevState => ({
                customerData: {
                    ...prevState.customerData,
                    credit_landing: updatedCreditLandingState
                }
            }),
            () => {
                // Reload the page after 10 seconds
                setTimeout(() => {
                    window.location.reload();
                }, 500); // 10 seconds delay
            }
        );
    };

    componentDidMount() {
        this.props.getCategory(
            secureLocalStorage.getItem("accessToken"),
            {},
            this.setIsLoading
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCategory !== this.state.selectedCategory) {
            const param = {category: this.state.selectedCategory};
            this.props.getSubCategory(
                secureLocalStorage.getItem("accessToken"),
                this.props.enqueueSnackbar,
                param
            );
        }
        if (prevState.selectedSubCategory !== this.state.selectedSubCategory) {
            const param = {sub_category: this.state.selectedSubCategory};
            this.props.getCompanyType(
                secureLocalStorage.getItem("accessToken"),
                this.props.enqueueSnackbar,
                param
            );
        }
    }

    setIsLoading = isLoading => {
        this.setState({isLoading});
    };

    handleQuestionDrawerOpen = (e, side, open, customer) => {
        this.setState({
            questionDrawer: open,
            customerData: customer
        });
    };

    handleQuestionDrawerClose = () => {
        this.setState({questionDrawer: false});
    };

    handleInputChange = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    };

    render() {
        const {
            classes,
            className,
            categories,
            subCategories,
            companyTypes
        } = this.props;
        const {customer} = this.props;
        const creditLandingEnabled =
            this.state.customerData?.credit_landing || false;

        const rootClassName = classNames(classes.root, className);

        if (this.props.noDataFound === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="left">Company Name</TableCell>
                                                <TableCell align="left">Created at</TableCell>
                                                <TableCell align="left">Phone</TableCell>
                                                <TableCell align="left">Email</TableCell>
                                                <TableCell align="left">Industry Type</TableCell>
                                                <TableCell align="left">Delivered Orders</TableCell>
                                                <TableCell align="left">Reset Password</TableCell>
                                                <TableCell align="center">More</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"8"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {this.props.allCustomerData?.map(customer => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={customer?.id}
                                                    >
                                                        <TableCell
                                                            align="left"
                                                            className={classes.tableCell}
                                                        >
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {customer?.name}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.created_at}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.contact_number}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.email}
                                                        </TableCell>
                                                        {/*<TableCell className={classes.tableCell}>*/}
                                                        {/*    {!customer.address[0] ? 'No data' : customer.address[0]}*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.industry_type}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {!customer?.all_order_count
                                                                ? "No data"
                                                                : customer?.all_order_count}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Tooltip
                                                                title="Reset Password"
                                                                aria-label="reset"
                                                            >
                                                                <IconButton
                                                                    onClick={event =>
                                                                        this.resetPasswordBtnSubmit(
                                                                            event,
                                                                            customer?.id
                                                                        )
                                                                    }
                                                                    aria-label="delete"
                                                                    className={classes.margin}
                                                                    size="small"
                                                                    disabled={
                                                                        localStorage.access_type === "false"
                                                                    }
                                                                >
                                                                    <RotateLeftIcon
                                                                        color={"primary"}
                                                                        className={classes.extendedIcon}
                                                                    >
                                                                        Reset
                                                                    </RotateLeftIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Questionnaire" aria-label="reset">
                                                                <IconButton>
                                                                    <Button
                                                                        onClick={e =>
                                                                            this.handleQuestionDrawerOpen(
                                                                                e,
                                                                                "right",
                                                                                true,
                                                                                customer
                                                                            )
                                                                        }
                                                                        style={{float: "right"}}
                                                                    >
                                                                        <InfoOutlinedIcon/>
                                                                    </Button>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align={"left"}
                                                        >
                                                            <Button
                                                                onClick={e =>
                                                                    this.handleDrawerOpen(
                                                                        e,
                                                                        "right",
                                                                        true,
                                                                        customer
                                                                    )
                                                                }
                                                                style={{float: "right"}}
                                                            >
                                                                <MoreIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>
                            {/*drawer for more details*/}

                            <Drawer
                                anchor="right"
                                open={this.state.right}
                                onClose={this.handleDrawerClose}
                            >
                                <div className={classes.root}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    IFSC
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.customerData?.ifsc}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    VAN
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.customerData?.van}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Last Ordered
                                                </TableCell>
                                                <TableCell>
                                                    {!this.state.customerData?.last_order_date
                                                        ? "NA"
                                                        : this.state.customerData?.last_order_date}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    GST Number
                                                </TableCell>
                                                <TableCell>
                                                    {this.state.customerData?.gst_number
                                                        ? this.state.customerData?.gst_number
                                                        : "NA"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Last Month Consumption
                                                </TableCell>
                                                <TableCell>
                                                    {!this.state.customerData?.last_month_fuel_used
                                                        ? "No data"
                                                        : this.state.customerData?.last_month_fuel_used}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Credit With Repos
                                                </TableCell>
                                                <TableCell align="left">
                                                    {!this.state.customerData?.credit_with_repos
                                                        ? "No data"
                                                        : this.state.customerData?.credit_with_repos?.toFixed(
                                                            2
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    M-Capital
                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        checked={creditLandingEnabled}
                                                        onChange={this.handleSwitchChange}
                                                        color="primary"
                                                        // disabled={this.state.customerData?.credit_landing === false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={e =>
                                                        this.handleDialogOpen(
                                                            e,
                                                            this.state?.customerData?.id
                                                        )
                                                    }
                                                    id={"add_partner_btn"}
                                                    style={{
                                                        margin: "10px",
                                                        lineHeight: "2",
                                                        textAlign: "center"
                                                    }}
                                                    disabled={
                                                        !(
                                                            secureLocalStorage.getItem("access_type") ===
                                                            true ||
                                                            secureLocalStorage.getItem("department") === 1 ||
                                                            secureLocalStorage.getItem("department") === 2 ||
                                                            secureLocalStorage.getItem("department") === 3 ||
                                                            secureLocalStorage.getItem("department") === 5
                                                        )
                                                    }
                                                >
                                                    Fleet Card Payment
                                                </Button>
                                            </TableRow>
                                            <TableRow>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={e =>
                                                        this.handleCustomerDialogOpen(
                                                            e,
                                                            this.state.customerData.id
                                                        )
                                                    }
                                                    id={"add_partner_btn"}
                                                    style={{
                                                        margin: "10px",
                                                        lineHeight: "2",
                                                        textAlign: "center"
                                                    }}
                                                    disabled={
                                                        !(
                                                            secureLocalStorage.getItem("access_type") ===
                                                            true ||
                                                            secureLocalStorage.getItem("department") === 1 ||
                                                            secureLocalStorage.getItem("department") === 2 ||
                                                            secureLocalStorage.getItem("department") === 3 ||
                                                            secureLocalStorage.getItem("department") === 5
                                                        )
                                                    }
                                                >
                                                    Customer Analytics
                                                </Button>
                                            </TableRow>
                                            <TableRow>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={e =>
                                                        this.handleSubAssetDialogOpen(
                                                            e,
                                                            this.state.customerData.id
                                                        )
                                                    }
                                                    id={"add_partner_btn"}
                                                    style={{
                                                        margin: "10px",
                                                        lineHeight: "2",
                                                        textAlign: "center"
                                                    }}
                                                    disabled={
                                                        !(
                                                            secureLocalStorage.getItem("access_type") ===
                                                            true ||
                                                            secureLocalStorage.getItem("department") === 1 ||
                                                            secureLocalStorage.getItem("department") === 2 ||
                                                            secureLocalStorage.getItem("department") === 3 ||
                                                            secureLocalStorage.getItem("department") === 5
                                                        )
                                                    }
                                                >
                                                    Sub-Asset Analytics
                                                </Button>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Drawer>

                            {/* ********* Questions *********** */}
                            <Drawer
                                anchor="right"
                                open={this.state.questionDrawer}
                                onClose={this.handleQuestionDrawerClose}
                                className={classes.drawerWidth}
                            >
                                <div className={classes.drawerWidth}>
                                    <Typography variant="h3" style={{color: "#898585"}}>
                                        Questionnaire
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        label={"Employee Size"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.employee_size}
                                        onChange={event =>
                                            this.handleInputChange(
                                                "employee_size",
                                                event.target.value
                                            )
                                        }
                                        // required
                                        id={"employee_size"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        <option key={0} value={""}></option>

                                        {Employee_Size.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>

                                    <TextField
                                        className={classes.textField}
                                        label={"Turn Over"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.turn_over}
                                        onChange={event =>
                                            this.handleInputChange("turn_over", event.target.value)
                                        }
                                        // required
                                        id={"turn_over"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        <option key={0} value={""}></option>

                                        {Turn_Over.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>

                                    <TextField
                                        className={classes.textField}
                                        label={"Asset Owned"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.asset_owned}
                                        onChange={event =>
                                            this.handleInputChange("asset_owned", event.target.value)
                                        }
                                        // required
                                        id={"asset_owned"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        <option key={0} value={""}></option>

                                        {Asset_Owned.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>

                                    <TextField
                                        className={classes.textField}
                                        label={"Monthly Diesel Consumption"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.monthly_diesel_consumption}
                                        onChange={event =>
                                            this.handleInputChange(
                                                "monthly_diesel_consumption",
                                                event.target.value
                                            )
                                        }
                                        // required
                                        id={"monthly_diesel_consumption"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        <option key={0} value={""}></option>

                                        {consumption.map(option => (
                                            <option key={option.key} value={option.key}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>

                                    <TextField
                                        className={classes.textField}
                                        label={"Industry"}
                                        id={"category"}
                                        margin="dense"
                                        fullWidth
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                        value={this.state.selectedCategory}
                                        onChange={event =>
                                            this.handleInputChange("category", event.target.value)
                                        }
                                    >
                                        <option key={0} value={""}></option>
                                        {categories?.map(option => (
                                            <option key={option} id={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                    <TextField
                                        className={classes.textField}
                                        label={"Category"}
                                        id={"sub_category"}
                                        margin="dense"
                                        disabled={!this.state.selectedCategory}
                                        fullWidth
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                        value={this.state.selectedSubCategory}
                                        onChange={event =>
                                            this.handleInputChange("sub_category", event.target.value)
                                        }
                                    >
                                        <option key={0} value={""}></option>
                                        {subCategories?.map(option => (
                                            <option key={option} id={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>

                                    <TextField
                                        className={classes.textField}
                                        label={"Company Type"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.selectedCompanyType}
                                        onChange={event =>
                                            this.handleInputChange("company_type", event.target.value)
                                        }
                                        disabled={!this.state.selectedSubCategory}
                                        // required
                                        id={"company_type"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        <option key={0} value={""}></option>
                                        {companyTypes?.map(option => (
                                            <option key={option} id={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>

                                    <TextField
                                        className={classes.textField}
                                        label={"Usage"}
                                        margin="dense"
                                        fullWidth
                                        value={this.state.usage}
                                        onChange={event =>
                                            this.handleInputChange("usage", event.target.value)
                                        }
                                        // required
                                        id={"usage"}
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                    >
                                        <option key={0} value={""}></option>

                                        {usage.map(option => (
                                            <option key={option.name} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>

                                    <Button
                                        className={classes.addNewPartnerButton}
                                        color="primary"
                                        size="medium"
                                        variant="contained"
                                        // onClick={this.handleSave}
                                        // disabled={!isFormValid()}
                                    >
                                        Save
                                    </Button>

                                    <Button
                                        className={classes.addNewPartnerButton}
                                        color="primary"
                                        size="medium"
                                        variant="contained"
                                        // disabled={!isFormValid()}
                                        onClick={this.handleQuestionDrawerClose}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Drawer>

                            <Dialog
                                fullWidth
                                aria-labelledby="draggable-dialog-title"
                                open={this.state.is_dialog}
                                onClose={this.handleDialogClose}
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                >
                                    <div style={{margin: "15px 0px"}}>
                                        <Typography
                                            style={{color: "#fff"}}
                                            variant="h4"
                                            align={"center"}
                                        >
                                            Add Fleet Card Payment
                                        </Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        style={{margin: "10px 0"}}
                                    >
                                        <TextField
                                            className={classes.textField}
                                            label="Amount"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            id={"amount"}
                                            onChange={event =>
                                                this.handleFieldChange("amount", event.target.value)
                                            }
                                            variant="outlined"
                                        />
                                        <Typography style={{marginTop: "4%"}}>
                                            <TextField
                                                fullWidth
                                                size={"small"}
                                                id="date"
                                                label="Date of Transaction"
                                                type="date"
                                                onChange={event =>
                                                    this.handleFieldChange(
                                                        "date_of_transaction",
                                                        event.target.value
                                                    )
                                                }
                                                style={{marginRight: "10px"}}
                                                variant={"outlined"}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Typography>
                                        <Typography>
                                            <TextField
                                                className={classes.textField}
                                                label="Transaction Reference ID"
                                                margin="dense"
                                                fullWidth
                                                id={"transition_reference_id"}
                                                onChange={event =>
                                                    this.handleFieldChange(
                                                        "transition_reference_id",
                                                        event.target.value
                                                    )
                                                }
                                                variant="outlined"
                                            />
                                        </Typography>
                                        {/* <Typography>
                                            <TextField
                                                className={classes.textField}
                                                label="Customer ID"
                                                margin="dense"
                                                fullWidth
                                                defaultValue={this.state.customerData.id}
                                                type={"number"}
                                                id={"customer_id"}
                                                onChange={event =>
                                                    this.handleFieldChange('customer_id', event.target.value)
                                                }
                                                variant="outlined"
                                            />
                                            </Typography> */}
                                    </Typography>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        autoFocus
                                        onClick={this.handleDialogClose}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    {this.state.amount === "" ||
                                    this.state.date_of_transaction === "" ||
                                    this.state.transition_reference_id === "" ? (
                                        ""
                                    ) : (
                                        <Button
                                            onClick={e =>
                                                this.handleFleetCardSubmit(
                                                    e,
                                                    this.state.amount,
                                                    this.state.date_of_transaction,
                                                    this.state.transition_reference_id,
                                                    this.state.customerData.id
                                                )
                                            }
                                            color="primary"
                                        >
                                            Continue
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                fullWidth
                                aria-labelledby="draggable-dialog-title"
                                open={this.state.customerDialog}
                                onClose={this.handleCustomerDialogClose}
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                >
                                    <div style={{margin: "15px 0px"}}>
                                        <Typography
                                            style={{color: "#fff"}}
                                            variant="h4"
                                            align={"center"}
                                        >
                                            Customer Data Analytics
                                        </Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    {this.state.fromDate === "" || this.state.toDate === "" ? (
                                        <Typography
                                            align="center"
                                            style={{
                                                fontWeight: "500",
                                                margin: "5%",
                                                fontSize: "16px"
                                            }}
                                        >
                                            Please Select Date Range For Customer Analytics
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        style={{marginTop: "1%"}}
                                        align="center"
                                    >
                                        <div className={classes.row}>
                                            <TextField
                                                size={"small"}
                                                id="date"
                                                label="From"
                                                type="date"
                                                onChange={event => this.onFromDate(event)}
                                                style={{marginRight: "10px"}}
                                                className={classes.textField}
                                                variant={"outlined"}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                            <TextField
                                                size={"small"}
                                                id="date"
                                                label="To"
                                                type="date"
                                                className={classes.textField}
                                                onChange={event => this.onToDate(event)}
                                                variant={"outlined"}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    {this.props.noDataFound ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={"8"}>
                                                    <NoDatafound/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : this.state.showTable === true ? (
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Average Dispensed Quantity
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.average_dispened_quantity} Lt
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Average Order Quantity
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.average_order_quantity} Lt
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Average Rate
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.average_rate} ₹
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Number of SubAssets
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.no_of_sub_asset}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Total Amount
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.total_amount} ₹
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Total Dispensed Orders
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.total_dispened_orders}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Total Dispensed Quantity
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.total_dispesed_quantity} Lt
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Total Refill Orders
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.total_refill_orders}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontWeight: "bold", fontSize: "16px"}}
                                                    >
                                                        Total Refill Quantity
                                                    </TableCell>
                                                    <TableCell align="left" style={{fontSize: "16px"}}>
                                                        {customer.total_refill_quantity} Lt
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ) : (
                                        ""
                                    )}
                                </DialogContent>

                                <DialogActions>
                                    {this.state.fromDate === "" || this.state.toDate === "" ? (
                                        ""
                                    ) : (
                                        <Button
                                            onClick={e =>
                                                this.handelCustomerAnalytics(e, this.state.customerId)
                                            }
                                            color="primary"
                                        >
                                            Continue
                                        </Button>
                                    )}
                                    <Button
                                        autoFocus
                                        onClick={this.handleCustomerDialogClose}
                                        color="primary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                fullWidth
                                aria-labelledby="draggable-dialog-title"
                                open={this.state.subAssetDialog}
                                onClose={this.handleSubAssetDialogClose}
                            >
                                <DialogTitle
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                    }}
                                >
                                    <div style={{margin: "15px 0px"}}>
                                        <Typography
                                            style={{color: "#fff"}}
                                            variant="h4"
                                            align={"center"}
                                        >
                                            Top 5 Sub-Asset Data Analytics
                                        </Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    {this.state.fromDate === "" || this.state.toDate === "" ? (
                                        <Typography
                                            align="center"
                                            style={{
                                                fontWeight: "500",
                                                margin: "5%",
                                                fontSize: "16px"
                                            }}
                                        >
                                            Please Select Date Range For Customer Analytics
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        align="center"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "3%",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div className={classes.row}>
                                            <TextField
                                                size={"small"}
                                                id="date"
                                                label="From"
                                                type="date"
                                                onChange={event => this.onFromDate(event)}
                                                style={{marginRight: "10px"}}
                                                className={classes.textField}
                                                variant={"outlined"}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                            <TextField
                                                size={"small"}
                                                id="date"
                                                label="To"
                                                type="date"
                                                className={classes.textField}
                                                onChange={event => this.onToDate(event)}
                                                variant={"outlined"}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    {this.props.subasset?.length === 0 ? (
                                        <>
                                            {this.state?.showSubTable === true ? (
                                                <Typography
                                                    align="center"
                                                    style={{fontWeight: "700"}}
                                                >
                                                    No Data Found
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ) : this.state?.showSubTable === true ? (
                                        this.props?.subasset?.map(subasset => (
                                            <Card variant="outlined" style={{marginBottom: "2%"}}>
                                                <CardContent>
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        align="center"
                                                        sx={{fontWeight: "bold"}}
                                                    >
                                                        SubAsset Name
                                                    </Typography>
                                                    <Typography variant="body1" align="center">
                                                        {subasset.dispensed_by}
                                                    </Typography>
                                                </CardContent>
                                                <CardContent>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        justifyContent={"space-between"}
                                                    >
                                                        <Box display={"flex"} flexDirection={"column"}>
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                sx={{fontWeight: "bold"}}
                                                            >
                                                                Average Dispensed Quantity
                                                            </Typography>
                                                            <Typography variant="body1" align="center">
                                                                {subasset.average_dispensed_quantity} Lt
                                                            </Typography>
                                                        </Box>
                                                        <Box display={"flex"} flexDirection={"column"}>
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                sx={{fontWeight: "bold"}}
                                                            >
                                                                Order Count
                                                            </Typography>
                                                            <Typography variant="body1" align="center">
                                                                {subasset.order_count}
                                                            </Typography>
                                                        </Box>
                                                        <Box display={"flex"} flexDirection={"column"}>
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                sx={{fontWeight: "bold"}}
                                                            >
                                                                Total Dispensed Quantity
                                                            </Typography>
                                                            <Typography variant="body1" align="center">
                                                                {subasset.total_dispensed_quantity} Lt
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        ))
                                    ) : (
                                        ""
                                    )}
                                </DialogContent>

                                <DialogActions>
                                    {this.state.fromDate === "" || this.state.toDate === "" ? (
                                        ""
                                    ) : (
                                        <Button
                                            onClick={e =>
                                                this.handelSubAssetAnalytics(e, this.state.customerId)
                                            }
                                            color="primary"
                                        >
                                            Continue
                                        </Button>
                                    )}
                                    <Button
                                        autoFocus
                                        onClick={this.handleSubAssetDialogClose}
                                        color="primary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        customer: state.customer,
        subasset: state.subasset,
        categories: state.categories,
        subCategories: state.subCategories,
        companyTypes: state.companyTypes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        },
        getCategory: (accessToken, params, setIsLoading) =>
            dispatch(getCategory(accessToken, params, setIsLoading)),
        getSubCategory: (accessToken, params) =>
            dispatch(getSubCategory(accessToken, params)),
        getCompanyType: (accessToken, params) =>
            dispatch(getCompanyType(accessToken, params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(UsersTable)));
