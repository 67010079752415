import {Box, Button, LinearProgress, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import UploadIcon from "@mui/icons-material/Upload";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {getAllLeads, PurchaseOrder} from "actions/actions.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const PurchaseOrderForm = props => {
    const {stateData, listLeads, handleDrawerClose, param, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [imageName, setImageName] = useState("");
    const [open, setOpen] = useState(false);

    const [formData, setFormData] = useState({
        id: listLeads?.id,
        gst_number: listLeads?.gst_number,
        purchase_order: ""
    });

    const handleInputChange = event => {
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleSetImage = value => {
        setImageName(value.name);
        setFormData({
            ...formData,
            purchase_order: value
        });
    };

    const handleSubmit = () => {
        dispatch(
            PurchaseOrder(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar,
                handleDrawerClose,
                getLeadList
            )
        );
    };

    const openImageScreen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForOrder = ["gst_number"];

        return requiredFieldsForOrder.every(field => formData[field]);
    };

    const validateGST = gst_no => {
        // eslint-disable-next-line
        var re = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

        return re.test(gst_no);
    };
    /************************* ************ ****************************/

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <TextField
                className={classes.textField}
                label="GST Number"
                margin="dense"
                type="text"
                error={formData.gst_number !== "" && !validateGST(formData.gst_number)}
                value={formData.gst_number}
                placeholder="07AAAAA1234A1Z1"
                id={"gst_number"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />
            <Button
                className={classes.addNewPartnerButton}
                variant="contained"
                component="label"
                color="success"
                value={formData.purchase_order}
                id="purchase_order"
                // onChange={handleInputChange}
                size="medium"
            >
                Upload PO
                <UploadIcon/>
                <input
                    type="file"
                    hidden
                    onChange={e => handleSetImage(e.target.files[0])}
                />
            </Button>
            <Typography>{imageName}</Typography>
            {stateData?.isUpload ? (
                <Box display={"flex"} flexDirection={"column"} mt={3}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Box sx={{width: "100%", mr: 1}}>
                            <LinearProgress
                                variant="determinate"
                                style={{height: "15px"}}
                                value={stateData?.uploadProgress}
                            />
                        </Box>
                        <Box sx={{minWidth: 35}}>
                            <Typography variant="body2" color="text.secondary">{`${Math.round(
                                stateData?.uploadProgress
                            )}%`}</Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    <Button
                        className={classes.addNewPartnerButton}
                        color="primary"
                        size="medium"
                        variant="contained"
                        disabled={
                            !isFormValid() ||
                            !validateGST(formData.gst_number) ||
                            listLeads?.po_filename !== ""
                        }
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        className={classes.addNewPartnerButton}
                        color="primary"
                        size="medium"
                        variant="contained"
                        // disabled={!isFormValid() || !validateGST(formData.gst_number)}
                        onClick={handleDrawerClose}
                    >
                        Cancel
                    </Button>
                </>
            )}
            {listLeads?.po_filename && (
                <Box display={"flex"} flexDirection={"column"} mt={3}>
                    <Box
                        sx={{display: "flex", alignItems: "center"}}
                        onClick={openImageScreen}
                    >
                        <img src={listLeads?.po_filename} width={"100%"} alt={"PO file"}/>
                    </Box>
                </Box>
            )}
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogContent>
                    <img src={listLeads?.po_filename} width={"100%"} alt={"PO file"}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default compose(withStyles(styles))(PurchaseOrderForm);
