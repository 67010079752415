import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Typography, withStyles} from "@material-ui/core";

// Material icons
// Shared components
import {SearchInput} from "components";

// Component styles
import styles from "./styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: "",
            selectedValue: "",
            assetType: "0"
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onAssetType = event => {
        const {selectedAssetType} = this.props;
        this.setState({
            assetType: event.target.value
        });
        selectedAssetType(event.target.value);
    };

    onSearch(searchTerm) {
        this.setState({
            selectedValue: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    onSelect(value) {
        const {onSelectedCustomer} = this.props;
        onSelectedCustomer(value);
    }

    handleDialogOpen = (e, asset) => {
        e.preventDefault();
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }
        this.setState({
            ...this.state,
            is_dialog: true,
            assets: asset
        });
    };

    handleDialogClose = () => {
        this.setState({is_dialog: false});
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        let AssetType = [
            {
                id: "0",
                value: "0",
                name: "All"
            },
            {
                id: "1",
                value: "1",
                name: "Datum"
            },
            {
                id: "2",
                value: "2",
                name: "SFD"
            },
            {
                id: "5",
                value: "5",
                name: "DG SET"
            },
            {
                id: "6",
                value: "6",
                name: "Industrial Machine"
            },
            {
                id: "7",
                value: "7",
                name: "JCB"
            },
            {
                id: "8",
                value: "8",
                name: "Others"
            }
        ];

        // const exportCSV = () => {
        //   var csvRow =[];
        //   var A = [['Vehicle NNumber','Last Location', 'Last Stock', 'Last Updated At', 'Partner', 'Driver', 'Refueler Model', 'Tracker Device Id', 'Model Type', ' CarIQ', 'CarIQ Device ID', 'Current Rate', 'Current Density']];
        //   var allRefuellerData = this.props.allRefuellerData;
        //
        //   var firstNameForFileName = '';
        //   var lastNameForFileName = '';
        //
        //   for (var item =0; item < allRefuellerData.length; item++){
        //     A.push([item, allRefuellerData[item].vehicle_registration_number, allRefuellerData[item].last_stock,allRefuellerData[item].last_feed_at,allRefuellerData[item].partner_name,allRefuellerData[item].driver,allRefuellerData[item].refueler_model,allRefuellerData[item].tracker_device, allRefuellerData[item].model_type, allRefuellerData[item].is_cariq_activated, allRefuellerData[item].cariq, allRefuellerData[item].current_rate, allRefuellerData[item].current_density])
        //     firstNameForFileName = allRefuellerData[0].vehicle_registration_number;
        //     lastNameForFileName  = allRefuellerData[allRefuellerData.length-1].vehicle_registration_number;
        //   }
        //
        //   for (var i=0; i< A.length; ++i){
        //     csvRow.push(A[i].join(","))
        //   }
        //
        //   var today = new Date();
        //   var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        //   var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //   var dateTime = date+'_'+time;
        //
        //   var csvString = csvRow.join("%0A");
        //
        //   var a = document.createElement("a");
        //
        //   a.href='data:attachement/csv,' + csvString;
        //
        //   a.target ="_Blank";
        //
        //   a.download= "Refueller List" + firstNameForFileName + "_to_" + lastNameForFileName+ "at"+ dateTime + ".csv";
        //
        //   document.body.appendChild(a);
        //
        //   a.click();
        //
        // };

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        All Assets
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div>
                            <SearchInput
                                className={classes.searchInput}
                                placeholder="Search by asset name or company name or ID"
                                getsearchterm={this.onSearch}
                            />
                            {/*<SelectDropdown label={"Select Customer"} id={"select_customer"} data={this.props.allCustomerData} onSelect={this.onSelect}/>*/}
                            <span className={classes.spacer}/>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size={"small"}
                                fullWidth
                            >
                                <InputLabel id="asset_Type">Asset Type</InputLabel>
                                <Select
                                    labelId="asset_Type"
                                    id="asset_Type"
                                    value={this.state.assetType}
                                    onChange={this.onAssetType}
                                    fullWidth
                                    label="Asset Type"
                                >
                                    {AssetType.map(singleStatus => (
                                        <MenuItem value={singleStatus.value}>
                                            {singleStatus.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
