import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import {Button, Grid, Typography, withStyles} from "@material-ui/core";

// Shared components
import {SearchInput} from "components";

// Component styles
import styles from "./style";
import {ArrowUpward as ArrowUpwardIcon} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: ""
        };

        this.onSearch = this.onSearch.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            searchString: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    onFromDate = e => {
        const {selectFromDate} = this.props;
        selectFromDate(e.target.value);

        this.setState({
            state: e.target.value
        });
    };

    onToDate = e => {
        const {selectToDate} = this.props;
        selectToDate(e.target.value);
        this.setState({
            state: e.target.value
        });
    };

    render() {
        const exportCSV = () => {
            const csvRow = [];
            const A = [
                [
                    "Sr. no",
                    "Name",
                    "Phone",
                    "Email",
                    "Address",
                    "Industry-Type",
                    "Last Ordered Date",
                    "Last Month Usage",
                    "Avg. Order Quantity"
                ]
            ];
            const allCustomerData = this.props.allCustomerData;

            let firstNameForFileName = "";
            let lastNameForFileName = "";

            for (let item = 0; item < allCustomerData.length; item++) {
                A.push([
                    item,
                    allCustomerData[item].name,
                    allCustomerData[item].contact_number,
                    allCustomerData[item].email,
                    allCustomerData[item].address[0],
                    allCustomerData[item].industry_type,
                    allCustomerData[item].last_order_date,
                    allCustomerData[item].last_month_fuel_used,
                    allCustomerData[item].avg_quantity_ordered
                ]);

                firstNameForFileName = allCustomerData[0].name;
                lastNameForFileName = allCustomerData[allCustomerData.length - 1].name;
            }

            for (let i = 0; i < A.length; ++i) {
                csvRow.push(A[i].join(","));
            }

            const today = new Date();
            const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            const time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + "_" + time;

            const csvString = csvRow.join("%0A");

            const a = document.createElement("a");

            a.href = "data:attachement/csv," + csvString;

            a.target = "_Blank";

            a.download =
                "customer_list_" +
                firstNameForFileName +
                "_to_" +
                lastNameForFileName +
                "at" +
                dateTime +
                ".csv";

            document.body.appendChild(a);

            a.click();
        };

        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{
                            fontWeight: 300
                        }}
                    >
                        All Customers
                    </Typography>
                </div>
                <div className={classes.row}>
                    <span className={classes.spacer}/>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search by Company Name or Mobile Number or VAN"
                            getsearchterm={this.onSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.row} style={{marginTop: "0px"}}>
                            <TextField
                                size={"small"}
                                id="date"
                                label="From"
                                type="date"
                                onChange={event => this.onFromDate(event)}
                                style={{marginRight: "10px"}}
                                variant={"outlined"}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <TextField
                                size={"small"}
                                id="date"
                                label="To"
                                type="date"
                                className={classes.textField}
                                variant={"outlined"}
                                onChange={event => this.onToDate(event)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} align="right">
                        <div>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={exportCSV}
                                style={{marginRight: 12}}
                                disabled={
                                    !(
                                        (secureLocalStorage.getItem("department") === 7 ||
                                            secureLocalStorage.getItem("department") === 5 ||
                                            secureLocalStorage.getItem("department") === 8) &&
                                        secureLocalStorage.getItem("access_type") === true
                                    )
                                }
                            >
                                <ArrowUpwardIcon/>
                                Export
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                {/*<div className={classes.row}>*/}
                {/*    <span className={classes.spacer} />*/}
                {/*    <Button*/}
                {/*        className={classes.exportButton}*/}
                {/*        size="small"*/}
                {/*        variant="outlined"*/}
                {/*        onClick={exportCSV}*/}
                {/*        style={{marginRight:0}}*/}
                {/*    >*/}
                {/*        <ArrowUpwardIcon className={classes.exportIcon} />*/}
                {/*        Export*/}
                {/*    </Button>*/}
                {/*</div>*/}
                {/*<div className={classes.row} style={{marginTop:'18px'}}>*/}
                {/*    <TextField*/}
                {/*        id="date"*/}
                {/*        label="From"*/}
                {/*        type="date"*/}
                {/*        onChange={event =>this.onFromDate(event)}*/}
                {/*        style={{marginRight:'10px'}}*/}
                {/*        variant={"outlined"}*/}
                {/*        className={classes.textField}*/}
                {/*        InputLabelProps={{*/}
                {/*            shrink: true,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <TextField*/}
                {/*        id="date"*/}
                {/*        label="To"*/}
                {/*        type="date"*/}
                {/*        className={classes.textField}*/}
                {/*        variant={"outlined"}*/}
                {/*        onChange={event =>this.onToDate(event)}*/}
                {/*        InputLabelProps={{*/}
                {/*            shrink: true,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
