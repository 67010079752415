import React, {Component} from "react";
import "./viewGeofence.css";
import {withStyles} from "@material-ui/core";
import MarkerClusterer from "@google/markerclusterer";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        marginTop: "150px",
        textAlign: "center"
    },
    image: {
        display: "inline-block",
        marginTop: "50px",
        maxWidth: "100%",
        width: "554px"
    },
    progressWrapper: {
        paddingTop: "48px",
        paddingBottom: "24px",
        display: "flex",
        justifyContent: "center"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
});

class CustomGoogleMap extends Component {
    map;

    constructor(props) {
        super(props);

        this.state = {
            allMarkers: [],
            markerCluster: null
        };
    }

    initMap = () => {
        this.map = new window.google.maps.Map(document.getElementById("map"), {
            center: {lat: 18.0, lng: 73.0},
            zoom: 5
        });
    };

    componentDidMount() {
        loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyC5-RRQjjGOPGIAFt-tuy6ClXrjjFZFvxw&callback=initMap"
        );
        window.initMap = this.initMap;
    }

    render() {
        // clearing previous markers
        if (this.state.allMarkers.length > 0) {
            this.state.allMarkers.forEach(val => {
                val.setMap(null);
            });
            this.setState({
                allMarkers: []
            });
        }
        // creating array of marker coordinates from the API response data
        let markerCoordinates = this.props.data?.map(ll => {
            return {
                position: {
                    lat: parseFloat(ll.markerdata.lat),
                    lng: parseFloat(ll.markerdata.lon)
                },
                name: ll.name
            };
        });

        if (markerCoordinates?.length === 0) {
        } else {
            let marker;
            let markerIcon;
            // marker icon and size according to its type i.e. refuellers, pumps, customers
            if (this.props.value === 0 && markerCoordinates?.length !== 0) {
                markerIcon = {
                    url: "/images/oil-tanker2.png",
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 40)
                };
            } else if (this.props.value === 1 || this.props.value === 3) {
                markerIcon = {
                    url: "/images/partner.png",
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 40)
                };
            }
                // else if(this.props.value === 3) {
                //     markerIcon = {
                //         url: '/images/partner.png',
                //         scaledSize: new window.google.maps.Size(40, 40),
                //         origin: new window.google.maps.Point(0, 0),
                //         anchor: new window.google.maps.Point(20, 40)
                //     };
            // }
            else {
                markerIcon = {
                    url: "/images/customers.png",
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 40)
                };
            }

            for (let i = 0; i < markerCoordinates?.length; i++) {
                // create marker
                marker = new window.google.maps.Marker({
                    position: markerCoordinates[i].position,
                    map: this.map,
                    animation: window.google.maps.Animation?.DROP,
                    title: markerCoordinates[i].name,
                    icon: markerIcon
                });

                // create marker content
                marker.content = markerCoordinates[i].name;

                // create infowindow
                const infoWindow = new window.google.maps.InfoWindow();

                // event listener
                // eslint-disable-next-line
                window.google.maps.event.addListener(marker, "click", function () {
                    infoWindow.setContent(this.content);
                    infoWindow.open(this.getMap(), this);
                });

                this.state.allMarkers.push(marker);
            }

            if (this.props.cluster) {
                if (this.state.markerCluster === null) {
                    this.setState({
                        markerCluster: new MarkerClusterer(
                            this.map,
                            this.state.allMarkers,
                            {
                                imagePath:
                                    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                            }
                        )
                    });
                    // this.state.markerCluster = new MarkerClusterer(
                    //     this.map, this.state.allMarkers,
                    //     {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'}
                    // );
                } else {
                    // clearing previous markers from cluster
                    this.state.markerCluster.clearMarkers();
                    this.state.markerCluster.addMarkers(this.state.allMarkers);
                }
            } else if (!this.props.cluster && this.state.markerCluster !== null) {
                window.location.reload();
            }
        }
        return (
            <div>
                <div id="map"></div>
            </div>
        );
    }
}

function loadScript(url) {
    const index = window.document.getElementsByTagName("script")[0];
    const index2 = window.document.getElementsByTagName("script")[1];
    const script = window.document.createElement("script");
    const script2 = window.document.createElement("script");
    script.src = url;
    script2.src =
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
    index2.parentNode.insertBefore(script2, index2);
}

CustomGoogleMap.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomGoogleMap);
