import {green, red} from "@material-ui/core/colors";

export default theme => ({
    root: {
        marginTop: "16px"
    },
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: 500,
        height: "36px",
        width: "36px"
    },
    nameText: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    switchBase: {
        color: red[300],
        "&$checked": {
            color: green[500]
        },
        "&$checked + $track": {
            backgroundColor: green[500]
        }
    },
    checked: {},
    track: {}
});
