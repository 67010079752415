import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Button, TextField, withStyles} from "@material-ui/core";

// Shared components
import {Portlet, PortletContent, PortletFooter, PortletHeader, PortletLabel} from "components";

// Component styles
import styles from "./styles";

class Password extends Component {
    state = {
        values: {
            password: "",
            confirm: ""
        }
    };

    handleFieldChange = (field, value) => {
        const newState = {...this.state};

        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    render() {
        const {classes, className, ...rest} = this.props;
        const {values} = this.state;

        const rootClassName = classNames(classes.root, className);

        return (
            <Portlet {...rest} className={rootClassName}>
                <PortletHeader>
                    <PortletLabel subtitle="Update password" title="Password"/>
                </PortletHeader>
                <PortletContent>
                    <form className={classes.form}>
                        <TextField
                            className={classes.textField}
                            label="Password"
                            name="password"
                            onChange={event =>
                                this.handleFieldChange("password", event.target.value)
                            }
                            type="password"
                            value={values.password}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="Confirm password"
                            name="confirm"
                            onChange={event =>
                                this.handleFieldChange("confirm", event.target.value)
                            }
                            type="password"
                            value={values.confirm}
                            variant="outlined"
                        />
                    </form>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    <Button color="primary" variant="outlined">
                        Update
                    </Button>
                </PortletFooter>
            </Portlet>
        );
    }
}

Password.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Password);
