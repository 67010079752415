import {Button, TextField} from "@material-ui/core";
import React, {useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {BookingInfo, getAllLeads} from "actions/actions.js";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const BookingAmountForm = props => {
    const {stateData, listLeads, handleDrawerClose, param, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [formData, setFormData] = useState({
        id: listLeads?.id,
        booking_date: listLeads?.booking_date,
        booking_amount: listLeads?.booking_amount,
        utr_number: listLeads?.utr_number
    });

    const handleInputChange = event => {
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleSubmit = () => {
        dispatch(
            BookingInfo(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar,
                handleDrawerClose,
                getLeadList
            )
        );
    };

    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForProduct = [
            "booking_date",
            "booking_amount",
            "utr_number"
        ];

        return requiredFieldsForProduct.every(field => formData[field]);
    };

    /************************* ************ ****************************/

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <TextField
                size={"small"}
                id="booking_date"
                label="Booking Date"
                type="date"
                fullWidth
                value={formData?.booking_date}
                onChange={handleInputChange}
                style={{marginRight: "10px"}}
                variant={"outlined"}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                className={classes.textField}
                label="Booking Amount"
                margin="dense"
                type="number"
                value={formData.booking_amount}
                id={"booking_amount"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            <TextField
                className={classes.textField}
                label="UTR Number"
                margin="dense"
                type="text"
                value={formData.utr_number}
                id={"utr_number"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                disabled={!isFormValid()}
                onClick={handleSubmit}
            >
                Save
            </Button>
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                // disabled={!isFormValid()}
                onClick={handleDrawerClose}
            >
                Cancel
            </Button>
        </div>
    );
};
export default compose(withStyles(styles))(BookingAmountForm);
