import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Divider, Typography, withStyles} from "@material-ui/core";
import {version} from "../../../../actions/configuration";

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
});

class Footer extends Component {
    render() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = dd + "/" + mm + "/" + yyyy;

        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <Divider/>
                <Typography className={classes.company} variant="body1">
                    &copy; Repos Energy. {formattedToday} | V{version}
                    <span>
            <img
                draggable="false"
                style={{position: "absolute", marginLeft: 10}}
                width={20}
                className="twemoji"
                alt="🇮🇳"
                src="https://em-content.zobj.net/thumbs/160/facebook/355/flag-india_1f1ee-1f1f3.png"
            />
          </span>
                </Typography>

                <Typography variant="caption">
                    Created with love for the environment. By designers and developers who
                    love to work together
                </Typography>
            </div>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
