export default {
    ownerName: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    pumpName: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    mobile: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 10,
            minimum: 10
        }
    },
    email: {
        presence: {allowEmpty: false, message: "is required"},
        email: true,
        length: {
            maximum: 128
        }
    },
    password: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    dealersOf: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    licenseNumber: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    address: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    latitude: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    longitude: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    city: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    state: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    pinCode: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 6
        }
    }
};
