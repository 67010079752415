import React from "react";
import PropTypes from "prop-types";
import {
    Divider, LinearProgress,
    Paper, TableBody, TableCell, TableContainer,
    TablePagination, TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Component styles
import styles from "./styles";
import Button from "@material-ui/core/Button";
import {EditOutlined as EditIcon} from "@material-ui/icons";
import secureLocalStorage from "react-secure-storage";
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";
import NoDatafound from "../../views/AllCustomers/components/NoDataInTable";
import {PortletContent} from "../index";
import PerfectScrollbar from "react-perfect-scrollbar";

const Cards = ({
                   stateData,
                   onPagination,
                   rowsPerPage,
                   page,
                   handleCall,
                   selected,
                   setSelected,
                   param,
                   cardData,
                   HandleDrawer,
                   buttonCondition,
                   isLoading,
                   noDataFound,
                   isLeadTableLoading,
                   isDescriptionLoading,
                   handleDescription,
                   count,
                   isEdit,
                   classes,
                   className,
                   ...rest
               }) => {

    const handleChangeRowsPerPage = event => {
        onPagination(event.target.value, 0, null);
    };

    const handleChangePage = (event, page) => {
        onPagination(rowsPerPage, page, null);
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            {!noDataFound &&
                (isLoading ? (
                    <LinearProgress fullWidth className={classes.progress}/>
                ) : (
                    <TablePagination
                        backIconButtonProps={{
                            "aria-label": "Previous Page"
                        }}
                        component="div"
                        count={count}
                        nextIconButtonProps={{
                            "aria-label": "Next Page"
                        }}
                        // onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 100, 500]}
                        onPageChange={handleChangePage}
                    />
                ))}
            <Divider/>
            <PortletContent noPadding>
                <PerfectScrollbar>
                    <TableContainer style={{maxHeight: 640}}>
                        <Grid container spacing={2} padding={4}>
                            {noDataFound ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={"16"}>
                                            <NoDatafound/>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : isLeadTableLoading ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={"16"}>
                                                <LinearProgress
                                                    fullWidth
                                                    className={classes.progress}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                stateData?.map(item => (
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Paper elevation={2} className={classes.paper}>
                                            <Box display="flex" flexDirection="column">
                                                <Box
                                                    py={1}
                                                    px={2}
                                                    display="flex"
                                                    className={classes.header}
                                                    style={{
                                                        background:
                                                            "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                    }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.textWhite}
                                                        style={{color: "primary"}}
                                                    >
                                                        {item?.driver_name === ""
                                                            ? "Repos Operator"
                                                            : item?.driver_name}
                                                    </Typography>
                                                    {!isEdit && <Button
                                                        onClick={() =>
                                                            HandleDrawer("Edit", item)
                                                        }
                                                        disabled={
                                                            secureLocalStorage.getItem("access_type") ===
                                                            false
                                                        }
                                                    >
                                                        <EditIcon
                                                            driver={item}
                                                            style={{color: "white"}}
                                                        />
                                                    </Button>}
                                                </Box>
                                                <Box p={2} spacing={2}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        align="space-around"
                                                        justifyContent="center"
                                                    >
                                                        {cardData?.map(tableInfo => (
                                                            tableInfo?.name !== 'Actionable' ? (<Grid item xs={4}>
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                {tableInfo?.name}
                                                                            </Typography>
                                                                            {tableInfo?.isDescriptionLoading ? <>

                                                                                    <Typography
                                                                                        className={classes.nameText}
                                                                                        variant="body2"
                                                                                        style={{
                                                                                            color: item[tableInfo?.dataKey]
                                                                                                ? "#7b88ca"
                                                                                                : "#d55c5c",
                                                                                            alignSelf: 'center'
                                                                                        }}
                                                                                    >
                                                                                        {item[tableInfo?.dataKey]?.length <= 18 ?
                                                                                            item[tableInfo?.dataKey] :
                                                                                            (item[tableInfo?.dataKey]?.substr(0, 18) + "...")}
                                                                                        <Button
                                                                                            variant={"contained"}
                                                                                            style={{backgroundColor: '#123865'}}
                                                                                            onClick={() => handleDescription(item[tableInfo?.dataKey])}>
                                                                                            <Typography style={{
                                                                                                fontSize: '0.6rem',
                                                                                                textTransform: "none",
                                                                                                lineHeight: 'normal',
                                                                                                color: '#ffffff',
                                                                                                fontWeight: 500
                                                                                            }}>
                                                                                                Know More
                                                                                            </Typography>
                                                                                        </Button>
                                                                                    </Typography>
                                                                                </> :
                                                                                <Typography
                                                                                    className={classes.nameText}
                                                                                    variant="body2"
                                                                                    align="center"
                                                                                    style={{
                                                                                        color: item[tableInfo?.dataKey]
                                                                                            ? "#7b88ca"
                                                                                            : "#d55c5c",
                                                                                        alignSelf: 'center'
                                                                                    }}
                                                                                >
                                                                                    {item[tableInfo?.dataKey]
                                                                                        ? item[tableInfo?.dataKey]
                                                                                        : "no data"}
                                                                                </Typography>
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                ) :
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Divider/>
                                                                    </Grid>
                                                                    <Grid item xs={12} container spacing={1}
                                                                          align={"right"}>
                                                                        {tableInfo?.actionButtons?.map((actions) => (
                                                                            (buttonCondition(actions?.iconName, item) &&
                                                                                <Grid item xs={2}>
                                                                                    {stateData?.isLoading ?
                                                                                        <Box mr={2}>
                                                                                            <Skeleton
                                                                                                color="inherit"
                                                                                                style={{
                                                                                                    height: "30px",
                                                                                                    width: "38px"
                                                                                                }}
                                                                                            />
                                                                                        </Box> :
                                                                                        <Button onClick={() =>
                                                                                            actions?.fun(actions?.iconName, item)}>
                                                                                            {actions?.icon}
                                                                                        </Button>}
                                                                                </Grid>)
                                                                        ))}
                                                                    </Grid>
                                                                </>
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                        </Grid>
                    </TableContainer>
                </PerfectScrollbar>
            </PortletContent>
            <Divider/>
            {!noDataFound &&
                (isLoading ? (
                    <LinearProgress fullWidth className={classes.progress}/>
                ) : (
                    <TablePagination
                        backIconButtonProps={{
                            "aria-label": "Previous Page"
                        }}
                        component="div"
                        count={count}
                        nextIconButtonProps={{
                            "aria-label": "Next Page"
                        }}
                        // onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 100, 500]}
                        onPageChange={handleChangePage}
                    />
                ))}
        </div>
    );
}

Cards.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};


export default withStyles(styles)(Cards)
