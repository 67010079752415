// Palette
import palette from "../palette";

export default {
    root: {
        backgroundColor: palette.common.white,
        color: palette.text.secondary,
        "&:hover": {
            backgroundColor: palette.common.neutral
        }
    }
};
