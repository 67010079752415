import React, {Component} from "react";

// Externals ToolBar
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import {Checkbox, FormControlLabel, Tooltip, Typography, withStyles} from "@material-ui/core";
// Shared components
import {SearchInput} from "components";
import RefreshIcon from "@mui/icons-material/Refresh";
// Component styles
import styles from "./styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {withSnackbar} from "notistack";
import DownloadIcon from "@mui/icons-material/Download";

class UsersToolbar extends Component {
    ordersArray = [];

    constructor(props) {
        super(props);

        this.state = {
            searchString: ""
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onOrderType = this.onOrderType.bind(this);
        this.onDeliveryMode = this.onDeliveryMode.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            searchString: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    onSelect(value) {
        const {selectedCustomer} = this.props;
        selectedCustomer(value);
    }

    onSelectedStatus = event => {
        const {selectedStatus} = this.props;
        this.setState({
            orderStatus: event.target.value
        });
        selectedStatus(event.target.value);
    };

    onOrderType = event => {
        const {orderType} = this.props;
        this.setState({
            orderType: event.target.value
        });
        orderType(event.target.value);
    };

    onDeliveryMode = event => {
        const {deliveryModes} = this.props;
        this.setState({
            deliveryModes: event.target.value
        });
        deliveryModes(event.target.value);
    };

    onFromDate = e => {
        const {selectFromDate} = this.props;
        selectFromDate(e.target.value);

        this.setState({
            state: e.target.value
        });
    };

    onToDate = e => {
        const {selectToDate} = this.props;
        selectToDate(e.target.value);
        this.setState({
            state: e.target.value
        });
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({
            open: false,
            actionSelector: null,
            subActionSelect: null,
            deliveryMode: "DIR"
        });
    };

    timeoutPromise(time) {
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve(Date.now());
            }, time);
        });
    }

    refreshData = () => {
        this.setState({
            open: false,
            actionSelector: null,
            subActionSelect: null
        });
        const {refreshData} = this.props;
        refreshData();
    };

    handlecheckbox = event => {
        const {handleAutorefresh} = this.props;

        handleAutorefresh(event.target.checked, "checkBox");
    };

    handleDownloadCcv = () => {
        this.props.sendReport();
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        // const nf = new Intl.NumberFormat("en-IN");

        // var subst = /\s/g;

        // const exportCSV = () => {
        //     const csvRow = [];
        //     const A = [['Order_Id', 'created_at', 'delivered_on', 'Quantity', 'rate', 'Delivery_date', 'Delivery_charges', 'status',
        //         'proforma_invoice_number', 'grand_total_amount', 'sub_total_amount', 'delivery_charges', 'tax_amount', 'Customer_Name', 'assigned_refueller', 'asset_name',
        //         'driver_name', 'gst_number', 'payment_method', 'grand_total_amount_inr', 'customer_address', 'partner_name', 'is_done_by_lmd', 'payment_status',
        //         'is_done_by_lmd_payment', 'agreement_id', 'time_slot_alloted', 'delivered_quantity', 'credit_amount', 'credit_days', 'due_date',
        //         'advance_amount', 'collected_amount', 'balance_amount', 'order_settle', 'partner_settle', 'repos_balance', 'partner_payment', 'bank_acc_no', 'ifsc', 'repos_share',
        //         'is_finserv_enabled', 'order_placed_by', 'm_capital', 'self_pickup', 'discount_amt', 'credit_limit', 'available_credit']];
        //     const allOrdersData = this?.props?.allOrdersData;
        //
        //     let firstNameForFileName = '';
        //     let lastNameForFileName = '';
        //
        //     for (let item = 0; item < allOrdersData?.length; item++) {
        //         A.push([allOrdersData[item]?.order_id,
        //             allOrdersData[item]?.created_at,
        //             allOrdersData[item]?.delivered_on,
        //             allOrdersData[item]?.quantity ? allOrdersData[item]?.quantity : "0",
        //             allOrdersData[item].rate,
        //             allOrdersData[item]?.delivery_date,
        //             allOrdersData[item]?.delivery_charges,
        //             allOrdersData[item]?.status_code_int === 1 ? "Driver Assignment Pending" :
        //                 allOrdersData[item]?.status_code_int === 2 ? "Assigned" :
        //                     allOrdersData[item]?.status_code_int === 3 ? "Delivered" :
        //                         allOrdersData[item]?.status_code_int === 4 ? "Cancelled" :
        //                             allOrdersData[item]?.status_code_int === 5 ? "Dispensing" :
        //                                 allOrdersData[item]?.status_code_int === 6 ? "Dispatched" :
        //                                     allOrdersData[item]?.status_code_int === 7 ? "Completion Pending" :
        //                                         allOrdersData[item]?.status_code_int === 8 ? "Pending" :
        //                                             allOrdersData[item]?.status_code_int === 10 ? "Aborted" :
        //                                                 allOrdersData[item]?.status_code_int === 13 ? "Order Expired" :
        //                                                     allOrdersData[item]?.status_code_int === 14 ? "Order Rejected" :
        //                                                         allOrdersData[item]?.status_code_int === 15 ? "Finserv Pending" : '',
        //             allOrdersData[item]?.proforma_invoice_number,
        //             allOrdersData[item]?.grand_total_amount ?
        //                 allOrdersData[item].grand_total_amount : '0',
        //             allOrdersData[item]?.sub_total_amount ? allOrdersData[item]?.sub_total_amount : '0',
        //             allOrdersData[item]?.delivery_charges ? allOrdersData[item]?.delivery_charges : '0',
        //             allOrdersData[item]?.tax_amount ? allOrdersData[item]?.tax_amount : '0',
        //             allOrdersData[item]?.customer_name?.replace(" ", '\t'),
        //             allOrdersData[item]?.assigned_refueler, '\"' + allOrdersData[item]?.asset_name?.replace(" ", '\t') + '\"',
        //             allOrdersData[item]?.driver_name?.replace(" ", '\t'),
        //             allOrdersData[item]?.gst_number,
        //             allOrdersData[item]?.payment_method,
        //             allOrdersData[item]?.grand_total_amount_inr,
        //             '\"' + allOrdersData[item]?.customer_address?.replace(/[" "]/g, '\t') + '\"',
        //             allOrdersData[item]?.partner_name?.replace(" ", '\t'),
        //             allOrdersData[item]?.is_done_by_lmd,
        //             allOrdersData[item]?.payment_status === 'ITD' ? "Initiated" :
        //                 allOrdersData[item]?.payment_status === 'PND' ? "Pending" :
        //                     allOrdersData[item]?.payment_status === 'CLD' ? "Cancelled" :
        //                         allOrdersData[item]?.payment_status === 'SCS' ? "Success" :
        //                             allOrdersData[item]?.payment_status === 'FLD' ? "Failed" :
        //                                 allOrdersData[item]?.payment_status === 'SBL' ? "Success by LMD" : "Other",
        //             allOrdersData[item]?.is_done_by_lmd_payment, allOrdersData[item]?.agreement_id, allOrdersData[item]?.time_slot_alloted ? allOrdersData[item]?.time_slot_alloted : "-",
        //             allOrdersData[item]?.status === 'DVD' ? parseFloat(allOrdersData[item]?.delivered_quantity)?.toFixed(2) + " Litres" : 'NA', allOrdersData[item]?.credit_amount ? '\"' + allOrdersData[item]?.credit_amount?.replace("₹", 'Rs. ') + '\"' : '0', allOrdersData[item].credit_days, allOrdersData[item].due_date,
        //             allOrdersData[item]?.advance_amount ? '\"' + allOrdersData[item]?.advance_amount?.replace('₹', 'Rs. ') + '\"' : 0, allOrdersData[item]?.collected_amount ? '\"' + allOrdersData[item]?.collected_amount?.replace('₹', 'Rs. ') + '\"' : '0',
        //             allOrdersData[item]?.balance_amount ? '\"' + allOrdersData[item]?.balance_amount?.replace('₹', 'Rs. ') + '\"' : '0',
        //             allOrdersData[item]?.order_settle, allOrdersData[item]?.partner_settle, allOrdersData[item]?.repos_balance, allOrdersData[item]?.partner_payment,
        //             allOrdersData[item]?.bank_acc_no, allOrdersData[item]?.ifsc, allOrdersData[item]?.repos_share, allOrdersData[item]?.is_finserv_enabled, allOrdersData[item]?.order_placed_by,
        //             allOrdersData[item]?.m_capital, allOrdersData[item]?.self_pickup, allOrdersData[item]?.discount_amt, allOrdersData[item]?.credit_limit, allOrdersData[item]?.available_credit,
        //         ]);
        //
        //         firstNameForFileName = allOrdersData[0]?.order_id;
        //         lastNameForFileName = allOrdersData[allOrdersData?.length - 1]?.order_id;
        //     }
        //
        //     for (let i = 0; i < A?.length; ++i) {
        //         csvRow.push(A[i].join(","))
        //     }
        //
        //     const today = new Date();
        //     const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        //     const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //     const dateTime = date + '_' + time;
        //
        //     const csvString = csvRow.join("%0A");
        //
        //     const a = document.createElement("a");
        //
        //     a.href = 'data:attachement/csv,' + csvString;
        //
        //     a.target = "_Blank";
        //
        //     a.download = "Orders List" + firstNameForFileName + "_to_" + lastNameForFileName + "at" + dateTime + ".csv";
        //
        //     document.body.appendChild(a);
        //
        //     a.click();
        //
        // };

        let status = [
            {
                id: "DVD",
                value: "DVD",
                name: "Delivered"
            },
            {
                id: "COP",
                value: "COP",
                name: "Confirmation Pending"
            },
            {
                id: "DRA",
                value: "DRA",
                name: "Driver Assigned"
            },
            {
                id: "ODS",
                value: "ODS",
                name: "Dispensing"
            },
            {
                id: "ABT",
                value: "ABT",
                name: "Aborted"
            },
            {
                id: "VDP",
                value: "VDP",
                name: "Vehicle Dispatched"
            },
            {
                id: "CAN",
                value: "CAN",
                name: "Cancelled"
            },
            {
                id: "EXP",
                value: "EXP",
                name: "Expired"
            },
            {
                id: "FAP",
                value: "FAP",
                name: "Finserv Approval pending"
            }
        ];

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        All Orders
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <div className={classes.row}>
                            <SearchInput
                                isDatum={false}
                                className={classes.searchInput}
                                placeholder="Search by Order Id or Company Name"
                                getsearchterm={this.onSearch}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <div className={classes.row}>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size={"small"}
                                fullWidth
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.orderStatus}
                                    onChange={this.onSelectedStatus}
                                    fullWidth
                                    label="Status"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {status.map(singleStatus => (
                                        <MenuItem value={singleStatus.value}>
                                            {singleStatus.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    {/* Order Type */}
                    <Grid item xs={6} md={2}>
                        <div className={classes.row}>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size={"small"}
                                fullWidth
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Order Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.orderType}
                                    onChange={this.onOrderType}
                                    fullWidth
                                    label="Status"
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value={1}>Postpaid</MenuItem>
                                    <MenuItem value={0}>Prepaid</MenuItem>
                                    <MenuItem value={2}>Door to Door delivery</MenuItem>
                                    <MenuItem value={3}>Self Pickup</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div className={classes.row}>
                            <TextField
                                size={"small"}
                                id="date"
                                label="From"
                                type="date"
                                onChange={event => this.onFromDate(event)}
                                style={{marginRight: "10px"}}
                                className={classes.textField}
                                variant={"outlined"}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <TextField
                                size={"small"}
                                id="date"
                                label="To"
                                type="date"
                                className={classes.textField}
                                onChange={event => this.onToDate(event)}
                                variant={"outlined"}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} alignContent="left">
                        <Grid item xs={4} style={{marginTop: "3%"}}>
                            <FormControlLabel
                                control={<Checkbox onChange={this.handlecheckbox}/>}
                                labelPlacement="start"
                                label="Auto Refresh"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={6} container spacing={2}>
                        <Grid item xs={8} align="right">
                            <Tooltip title="Page Refresh" aria-label="page_refresh">
                                <Button>
                                    <RefreshIcon
                                        style={{
                                            width: "1.5em",
                                            height: "1.5em",
                                            color: "#00a7bc"
                                        }}
                                        onClick={this.refreshData}
                                    />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Tooltip
                            title={
                                this.props.fromDateFilter === null || this.props.toDate === null
                                    ? "Please select date range"
                                    : "Download CSV"
                            }
                            aria-label="download_csv"
                        >
                            <Grid item xs={4} align="center">
                                <Button
                                    disabled={
                                        this.props.fromDateFilter === null ||
                                        this.props.toDate === null
                                    }
                                    onClick={() => this.handleDownloadCcv()}
                                >
                                    <DownloadIcon/>
                                    Download
                                </Button>
                            </Grid>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoadingOrderChange: state.isLoadingOrderChange,
        statusChanged: state.statusChanged,
        isLoading: state.isLoading
    };
};

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default connect(mapStateToProps)(
    compose(withStyles(styles))(withSnackbar(UsersToolbar))
);
