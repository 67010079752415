import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
// Material components
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
    withStyles
} from "@material-ui/core";

// Material icons
import {
    DashboardOutlined as DashboardIcon,
    DonutSmallOutlined as SteeringIcon,
    InfoOutlined as InfoIcon,
    LocalGasStationOutlined as PumpIcon,
    LocalShippingOutlined as RefuellerIcon,
    PeopleOutlined as PeopleIcon,
    SettingsOutlined as SettingsIcon,
    WifiTetheringOutlined as LiveIcon
} from "@material-ui/icons";

// Component styles
import styles from "./styles";

class Sidebar extends Component {
    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <nav className={rootClassName}>
                <div className={classes.logoWrapper}>
                    <Link className={classes.logoLink} to="/">
                        {/*<img  alt="logo" className="brand-logo"/>*/}
                        <img
                            alt="Brainalytica logo"
                            className={classes.logoImage}
                            // src="/images/logos/logo_tans_small.png"
                            style={{width: "100px"}}
                            src={require("../../../../assets/img/Repos_Black_A.png")}
                        />
                    </Link>
                </div>
                <Divider className={classes.logoDivider}/>
                <div className={classes.profile}>
                    <Link to="/account">
                        <Avatar
                            alt="Roman Kutepov"
                            className={classes.avatar}
                            src="/images/avatars/avatar_1.png"
                        />
                    </Link>
                    <Typography className={classes.nameText} variant="h6">
                        Administrator
                    </Typography>
                    <Typography className={classes.bioText} variant="caption">
                        Repos
                    </Typography>
                </div>
                <Divider className={classes.profileDivider}/>
                <List component="div" disablePadding>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/dashboard"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Dashboard"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/live-status"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <LiveIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Live Status"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/all-pumps"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PumpIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Partners"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/all-refuellers"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <RefuellerIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Refuellers"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/all-customers"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Customers"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/all-customers"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Customers"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/all-geofences"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Geofences"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/all-drivers"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <div
                                style={{
                                    transform: "rotate(90deg)",
                                    marginLeft: "-5px",
                                    marginBottom: "5px"
                                }}
                            >
                                <SteeringIcon/>
                            </div>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Drivers"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/settings"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Settings"
                        />
                    </ListItem>
                </List>
                <Divider className={classes.listDivider}/>
                <List
                    component="div"
                    disablePadding
                    subheader={
                        <ListSubheader className={classes.listSubheader}>
                            Support
                        </ListSubheader>
                    }
                >
                    <ListItem
                        className={classes.listItem}
                        component="a"
                        href="#"
                        target="_blank"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Customer support"
                        />
                    </ListItem>
                </List>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
