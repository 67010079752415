export default theme => ({
    root: {
        marginTop: "16px"
    },
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: 500,
        height: "36px",
        width: "36px"
    },
    nameText: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: "pointer"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    BackdropProps: {
        background: "#00000045"
    },
    progressWrapper: {
        marginTop: theme.spacing(2)
    },
    cards: {
        margin: theme.spacing(2)
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end"
    },
    textWhite: {
        color: "white"
    },
    paper: {
        borderRadius: "20% 20% 4% 4%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        marginTop: "10%",
        "&:hover": {
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;",
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out"
        }
    },
    header: {
        borderRadius: "20px 20px 0% 0%",
        height: "60px;"
    },
    checkbox: {
        color: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        "&:hover": {
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;",
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out"
        }
    }
});
