import React, {Component} from "react";
import {Link as RouterLink} from "react-router-dom";

// Externals
import PropTypes from "prop-types";

// Material helpers
// Material components
import {Button, Grid, Typography, withStyles} from "@material-ui/core";

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        marginTop: "150px",
        textAlign: "center"
    },
    contentButton: {
        marginTop: "40px",
        textAlign: "center"
    },
    image: {
        display: "inline-block",
        marginTop: "50px",
        maxWidth: "100%",
        width: "400px"
    }
});

class NotFound extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Grid container justify="center" spacing={4}>
                    <Grid item lg={6} xs={12}>
                        <div className={classes.content}>
                            <Typography variant="h1">
                                404: The page you are looking for isn’t here
                            </Typography>
                            <Typography variant="subtitle2">
                                You either tried some shady route or you came here by mistake.
                                Whichever it is, try using the navigation
                            </Typography>
                            <img
                                alt="Under development"
                                className={classes.image}
                                src="/images/not_found.png"
                            />
                        </div>
                        <div className={classes.contentButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={RouterLink}
                                to={{
                                    pathname: "/sign-in"
                                }}
                            >
                                <Typography style={{color: "#fff"}}>
                                    Redirect to the Login
                                </Typography>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
