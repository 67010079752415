import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Button, withStyles} from "@material-ui/core";

// Shared components
import {DisplayMode, SearchInput} from "components";

// Component styles
import styles from "./styles";

class ProductsToolbar extends Component {
    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div className={classes.row}>
                    <span className={classes.spacer}/>
                    <Button color="primary" size="small" variant="outlined">
                        Add
                    </Button>
                </div>
                <div className={classes.row}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search product"
                    />
                    <span className={classes.spacer}/>
                    <DisplayMode mode="grid"/>
                </div>
            </div>
        );
    }
}

ProductsToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductsToolbar);
