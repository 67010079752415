import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";

// Views
import Dashboard from "./views/Dashboard";
import AdminDashboard from "./views/AdminDashboard";
import ProductList from "./views/ProductList";
import Typography from "./views/Typography";
import Icons from "./views/Icons";
import Account from "./views/Account";
import Settings from "./views/Settings";
import SignIn from "./views/SignIn";
import NotFound from "./views/NotFound";
import AllTrackers from "./views/AllTrackers";
import AllPumps from "./views/AllPumps";
import AllRefuellers from "./views/AllRefuellers";
import AllCustomers from "./views/AllCustomers";
import AllDrivers from "./views/AllDrivers";
import AllOrders from "./views/AllOrders";
import AllGeofences from "./views/AllGeofences";
import Assets from "./views/AllAssets";
import LiveStatus2 from "./views/LiveStatus2";
import AllOrderAssignments from "./views/AllOrderAssignments/";
import CreatePartnerVehicleOperator from "./views/CreatePartnerVehicleOperator";
import DatumActivation from "./views/DatumActivation";
import AllDatum from "./views/AllDatum";
import AllServiceRequest from "./views/AllServiceRequest";
import AllFleetCardPayments from "views/AllFleetCardPayments";
import OrderInsights from "views/DashboardViewMore/components/OrderInsights";
import CustomerOverview from "views/DashboardViewMore/components/CustomerOverview";
import DatumOverview from "views/DashboardViewMore/components/DatumOverview";
import PartnerOverview from "views/DashboardViewMore/components/PartnerOverview";
import DatumAsset from "views/DashboardViewMore/components/DatumAsset";
import ReposCRM from "./views/ReposCRM";
import Login from "./views/SignIn/Login";
import WelcomePage from "./views/WelcomePage";
import secureLocalStorage from "react-secure-storage";
import Decryption from "./views/decryption";

export class Routes extends Component {
    render() {
        switch (
        parseInt(this.props.department) ||
        secureLocalStorage.getItem("department")
            ) {
            case 1:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllCustomers} exact path="/all-customers"/>
                        <Route component={Assets} exact path="/all-assets"/>
                        <Route component={AllDrivers} exact path="/all-drivers"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route
                            component={AllFleetCardPayments}
                            exact
                            path="/fleetcardpayments"
                        />
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 2:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route component={AllCustomers} exact path="/all-customers"/>
                        <Route component={Assets} exact path="/all-assets"/>
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route
                            component={AllFleetCardPayments}
                            exact
                            path="/fleetcardpayments"
                        />
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 3:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={AllDrivers} exact path="/all-drivers"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route component={AllCustomers} exact path="/all-customers"/>

                        <Route component={AllTrackers} exact path="/all-trackers"/>
                        <Route component={Assets} exact path="/all-assets"/>
                        <Route component={AllGeofences} exact path="/all-geofences"/>
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        <Route component={DatumActivation} exact path="/activate-datum"/>
                        <Route
                            component={CreatePartnerVehicleOperator}
                            exact
                            path="/create-partner-vehicle-operator"
                        />
                        {/*<Route component={AdminDashboard} exact path="/admin-dashboard"/>*/}
                        <Route component={NotFound} exact path="/not-found"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route
                            component={AllServiceRequest}
                            exact
                            path="/service_request"
                        />
                        <Route
                            component={AllFleetCardPayments}
                            exact
                            path="/fleetcardpayments"
                        />
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 4:
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 5:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route component={AdminDashboard} exact path="/admin-dashboard"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={AllDrivers} exact path="/all-drivers"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route component={AllCustomers} exact path="/all-customers"/>
                        <Route component={Decryption} exact path="/api-testing"/>
                        <Route component={AllTrackers} exact path="/all-trackers"/>
                        <Route component={Assets} exact path="/all-assets"/>
                        <Route component={AllGeofences} exact path="/all-geofences"/>
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        <Route component={DatumActivation} exact path="/activate-datum"/>
                        <Route
                            component={CreatePartnerVehicleOperator}
                            exactx
                            path="/create-partner-vehicle-operator"
                        />
                        {/*<Route component={ProductList} exact path="/products"/>*/}
                        {/*<Route component={Typography} exact path="/typography"/>*/}
                        {/*<Route component={Icons} exact path="/icons"/>*/}
                        {/*<Route component={Account} exact path="/account"/>*/}
                        {/*<Route component={Settings} exact path="/settings"/>*/}
                        <Route component={AdminDashboard} exact path="/admin-dashboard"/>
                        {/*<Route component={NotFound} exact path="/not-found"/>*/}
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route
                            component={AllServiceRequest}
                            exact
                            path="/service_request"
                        />
                        <Route
                            component={AllFleetCardPayments}
                            exact
                            path="/fleetcardpayments"
                        />
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 6:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Route component={SignIn} exact path="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        <Route component={NotFound} exact path="/not-found"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 7:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route component={AllCustomers} exact path="/all-customers"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 8:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllCustomers} exact path="/all-customers"/>
                        <Route component={Assets} exact path="/all-assets"/>
                        <Route component={AllDrivers} exact path="/all-drivers"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route
                            component={AllFleetCardPayments}
                            exact
                            path="/fleetcardpayments"
                        />
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 9:
                return (
                    <Switch>
                        <Route
                            component={AllServiceRequest}
                            exact
                            path="/service_request"
                        />
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 10:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={Dashboard} exact path="/dashboard"/>
                        <Route component={OrderInsights} exact path="/OrderInsights"/>
                        {/* <Route component={AdminDashboard} exact path="/admin-dashboard"/> */}
                        <Route
                            component={CustomerOverview}
                            exact
                            path="/CustomerOverview"
                        />
                        <Route component={DatumOverview} exact path="/DatumOverview"/>
                        <Route component={PartnerOverview} exact path="/PartnerOverview"/>
                        <Route component={AllDrivers} exact path="/all-drivers"/>
                        <Route component={LiveStatus2} exact path="/live-status"/>
                        <Route component={AllPumps} exact path="/all-pumps"/>
                        <Route component={AllOrders} exact path="/all-orders"/>
                        <Route component={AllRefuellers} exact path="/all-refuellers"/>
                        <Route
                            component={AllOrderAssignments}
                            exact
                            path="/all-order-assignments"
                        />
                        <Route component={AllCustomers} exact path="/all-customers"/>

                        <Route component={AllTrackers} exact path="/all-trackers"/>
                        <Route component={Assets} exact path="/all-assets"/>
                        <Route component={AllGeofences} exact path="/all-geofences"/>
                        <Route component={AllDatum} exact path="/all-datums"/>
                        <Route component={DatumAsset} exact path="/datumasset"/>
                        <Route component={DatumActivation} exact path="/activate-datum"/>
                        <Route
                            component={CreatePartnerVehicleOperator}
                            exact
                            path="/create-partner-vehicle-operator"
                        />
                        <Route component={ProductList} exact path="/products"/>
                        <Route component={Typography} exact path="/typography"/>
                        <Route component={Icons} exact path="/icons"/>
                        <Route component={Account} exact path="/account"/>
                        <Route component={Settings} exact path="/settings"/>
                        {/*<Route component={AdminDashboard} exact path="/admin-dashboard"/>*/}
                        <Route component={NotFound} exact path="/not-found"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                        <Route
                            component={AllServiceRequest}
                            exact
                            path="/service_request"
                        />
                        <Route
                            component={AllFleetCardPayments}
                            exact
                            path="/fleetcardpayments"
                        />
                        <Redirect to="/not-found"/>
                    </Switch>
                );
            case 11:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                    </Switch>
                );
            case 12:
                // eslint-disable-next-line
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        {secureLocalStorage.getItem("is_crm") === true && (
                            <Route component={ReposCRM} exact path="/ReposCRM"/>
                        )}
                    </Switch>
                );
            default:
                return (
                    <Switch>
                        <Route component={Login} exact path="/sign-in"/>
                        {secureLocalStorage.getItem("accessToken") ? (
                            <Redirect exact from="/" to="/welcome"/>
                        ) : (
                            <Redirect exact from="/" to="/sign-in"/>
                        )}
                        <Route component={WelcomePage} exact path="/welcome"/>
                        <Route component={NotFound} exact path="/not-found"/>
                        <Redirect to="/not-found"/>
                    </Switch>
                );
        }
    }
}

Routes.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        department: state.department,
        userDetails: state.userDetails
    };
};

export default connect(mapStateToProps, "")(compose(withStyles())(Routes));
