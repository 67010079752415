import React, {Component} from "react";
import {Breakpoint} from "react-socks";
// Externals
import PropTypes from "prop-types";
// Material components
import {CircularProgress, Divider, withStyles} from "@material-ui/core";
// Shared layouts
import {Dashboard as DashboardLayout} from "layouts";
// Custom components
import {UsersTable, UsersToolbar} from "./components";
// Component styles
import styles from "./style";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {changeOrderStatus, getAllOrders, sendReportToMail, versionControl} from "../../actions/actions";
import Cards from "./components/Cards/Cards";
import {withSnackbar} from "notistack";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

class AllOrders extends Component {
    constructor(props) {
        super(props);
        this.abortController = "";
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0,
            status: null,
            offset: 0,
            fromDateFilter: null,
            toDate: null,
            refueller: null,
            selectedOrders: [],
            setSnackOpen: false,
            setSelectedCount: 0,
            setLmdOrders: false,
            orderState: [],
            progressbar: false,
            orderType: "",
            deliveryModes: "",
            refreshCount: false
        };
        //this.getOrdersData = this.getOrdersData.bind(this);
    }

    // onSearchTerm = (searchTerm) => {
    //     if (searchTerm !== "") {
    //         this.setState({
    //             searchString: searchTerm,
    //         });
    //         this.getOrdersData(searchTerm, this.state.limit, this.state.offset, null, null, null, this.state.status);
    //     } else {
    //         this.setState({
    //             searchString: "",
    //         });
    //         this.getOrdersData(null, this.state.limit, this.state.offset, null, null, null, this.state.status);
    //     }
    // };

    onSearchTerm = searchTerm => {
        if (searchTerm?.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getOrdersData(searchTerm);
        } else {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getOrdersData("");
        }
    };

    onPagination = (limit, page) => {
        let offset = limit * page;
        this.setState({
            rowsPerPage: limit,
            page,
            offset
        });
        this.getOrdersData(
            this.state.searchString,
            limit,
            offset,
            this.state.status,
            this.state.fromDateFilter,
            this.state.toDate,
            this.state.orderType,
            this.state.deliveryModes
        );
    };
    onSelectedStatus = status => {
        // if (status !== "") {
        this.setState({status});
        this.getOrdersData(
            this.state.searchString,
            10,
            this.state.offset,
            status,
            this.state.fromDateFilter,
            this.state.toDate
        );
    };

    onOrderType = orderType => {
        if (orderType !== "") {
            this.setState({orderType});
            this.getOrdersData(
                this.state.searchString,
                10,
                this.state.offset,
                this.state.status,
                this.state.fromDateFilter,
                this.state.toDate,
                orderType
            );
        } else {
            this.setState({orderType: ""});
            this.getOrdersData(
                this.state.searchString,
                10,
                this.state.offset,
                this.state.status,
                this.state.fromDateFilter,
                this.state.toDate,
                orderType,
                this.state.deliveryModes
            );
        }
    };

    onDeliveryMode = deliveryModes => {
        if (deliveryModes !== "") {
            this.setState({deliveryModes});
            this.getOrdersData(
                this.state.searchString,
                10,
                this.state.offset,
                this.state.status,
                this.state.fromDateFilter,
                this.state.toDate,
                this.state.orderType,
                deliveryModes
            );
        } else {
            this.setState({deliveryModes: ""});
            this.getOrdersData(
                this.state.searchString,
                10,
                this.state.offset,
                this.state.status,
                this.state.fromDateFilter,
                this.state.toDate,
                this.state.orderType,
                deliveryModes
            );
        }
    };

    getOrdersData = (
        searchTerm = this.state.searchString,
        limit = this.state.rowsPerPage,
        offset = this.state.offset,
        status = this.state.status,
        fromDate = this.state.fromDateFilter,
        toDate = this.state.toDate,
        orderType = this.state.orderType,
        deliveryModes = this.state.deliveryModes,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllOrders(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            status,
            fromDate,
            toDate,
            orderType,
            deliveryModes,
            abortController,
            this.props
        );
    };

    sendReport = () => {
        let value = {
            flag: 1,
            start_date: this.state.fromDateFilter,
            end_date: this.state.toDate,
            search: this.state.searchString,
            status: this.state.status
        };
        this.props.handleSendReport(
            secureLocalStorage.getItem("accessToken"),
            value,
            this.props
        );
    };
    changeStatus = (order, value) => {
        this.props.handleChangeOrderStatus(
            secureLocalStorage.getItem("accessToken"),
            order,
            value
        );
    };

    changePayStatus = (order, status) => {
        this.props.handleChangePaymentStatus(
            secureLocalStorage.getItem("accessToken"),
            order,
            status
        );
    };

    onSelectFromDate = fromDate => {
        this.setState({
            fromDateFilter: fromDate
        });

        if (fromDate === "") {
            this.getOrdersData(
                this.state.searchString,
                this.state.rowsPerPage,
                0,
                this.state.status,
                (fromDate = null)
            );
        } else {
            this.getOrdersData(
                this.state.searchString,
                this.state.rowsPerPage,
                0,
                this.state.status,
                fromDate
            );
        }
    };

    onSelectToDate = toDate => {
        this.setState({
            toDate: toDate
        });

        if (toDate === "") {
            this.getOrdersData(
                this.state.searchString,
                this.state.rowsPerPage,
                0,
                this.state.status,
                this.state.fromDateFilter,
                (toDate = null)
            );
        } else {
            this.getOrdersData(
                this.state.searchString,
                this.state.rowsPerPage,
                0,
                this.state.status,
                this.state.fromDateFilter,
                toDate
            );
        }
    };

    onSelected = (setOrders, is_done_by_lmd) => {
        this.setState({
            selectedOrders: setOrders,
            setSelectedCount: setOrders?.length,
            setLmdOrders: is_done_by_lmd
        });
    };

    // onChangeBulkOrders = (selectedOrders, action, status, deliveredQuantity, deliveryMode) => {
    //     if (action === "1") {
    //         if (selectedOrders.length === 1) {
    //             this.props.handleChangeOrderStatusSingle( secureLocalStorage.getItem("accessToken"), selectedOrders, status, action, deliveredQuantity, deliveryMode, this.props)
    //         } else this.props.handleChangeOrderStatus( secureLocalStorage.getItem("accessToken"), selectedOrders, status, action)
    //     } else if (action === "2") {
    //         this.props.handleChangePaymentStatus( secureLocalStorage.getItem("accessToken"), selectedOrders, status, action, this.props)
    //     }
    // }

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        secureLocalStorage.setItem("autorefresh", "false");
        this.getOrdersData();
    }

    handelContinue = () => {
        this.handleAutorefresh(this.state.refreshCount);
    };

    handleAutorefresh = (autorefresh, validate) => {
        if (validate === "checkBox" || validate === "componentWillUnmount") {
            secureLocalStorage.setItem("autorefresh", autorefresh);
        }
        if (secureLocalStorage.autorefresh === "true") {
            this.getOrdersData();
            setTimeout(this.handelContinue, 10000);
        }
    };

    componentWillUnmount() {
        secureLocalStorage.setItem("autorefresh", "false");
        this.handleAutorefresh(false, "componentWillUnmount");
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="All Orders">
                    <div className={classes.root}>
                        {this.props.allOrdersData?.length === 0 ? (
                            <div className={classes.progressWrapper}>
                                <CircularProgress className={classes.progress}/>
                            </div>
                        ) : (
                            <div>
                                <UsersToolbar
                                    isLoading={this.props.isLoading}
                                    refreshData={this.getOrdersData}
                                    searchedTerm={this.onSearchTerm}
                                    allOrdersData={this.props.allOrdersData}
                                    selectedStatus={this.onSelectedStatus}
                                    orderType={this.onOrderType}
                                    deliveryModes={this.onDeliveryMode}
                                    selectFromDate={this.onSelectFromDate}
                                    selectToDate={this.onSelectToDate}
                                    // onSelectedOrders={this.onChangeBulkOrders}
                                    ordersArray={this.state.selectedOrders}
                                    selectedOrdersCount={this.state.setSelectedCount}
                                    handleAutorefresh={this.handleAutorefresh}
                                    handelContinue={this.handelContinue}
                                    sendReport={this.sendReport}
                                    fromDateFilter={this.state.fromDateFilter}
                                    toDate={this.state.toDate}
                                />
                                <Breakpoint medium up>
                                    <UsersTable
                                        refreshData={this.getOrdersData}
                                        allOrdersData={this.props.allOrdersData}
                                        rowsPerPage={this.state.rowsPerPage}
                                        dataCount={this.props.dataCount}
                                        page={this.state.page}
                                        onPagination={this.onPagination}
                                        noDataFound={this.props.noDataFound}
                                        isLoading={this.props.isLoading}
                                        changeStatus={this.changeStatus}
                                        changePaymentStatus={this.changePayStatus}
                                        statusChanged={this.props.statusChanged}
                                        paymentStatusChanged={this.props.paymentStatusChanged}
                                        changingStatus={this.props.isLoadingOrderChange}
                                        changingPaymentStatus={
                                            this.props.isLoadingPaymentStatusChange
                                        }
                                        onSelected={this.onSelected}
                                        hasError={this.props.error}
                                    />
                                </Breakpoint>
                                <Breakpoint small down>
                                    <Divider
                                        style={{
                                            marginBottom: "4%",
                                            marginTop: "10%",
                                            color: "#aeafb0"
                                        }}
                                    />
                                    <Cards
                                        refreshData={this.getOrdersData}
                                        allOrdersData={this.props.allOrdersData}
                                        rowsPerPage={this.state.rowsPerPage}
                                        dataCount={this.props.dataCount}
                                        page={this.state.page}
                                        onPagination={this.onPagination}
                                        noDataFound={this.props.noDataFound}
                                        isLoading={this.props.isLoading}
                                        changeStatus={this.changeStatus}
                                        changePaymentStatus={this.changePayStatus}
                                        statusChanged={this.props.statusChanged}
                                        paymentStatusChanged={this.props.paymentStatusChanged}
                                        changingStatus={this.props.isLoadingOrderChange}
                                        changingPaymentStatus={
                                            this.props.isLoadingPaymentStatusChange
                                        }
                                        onSelected={this.onSelected}
                                        hasError={this.props.error}
                                    />
                                </Breakpoint>
                            </div>
                        )}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllOrders.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allOrdersData: state.allOrdersData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        isLoading: state.isLoading,
        statusChanged: state.statusChanged,
        paymentStatusChanged: state.paymentStatusChanged,
        isLoadingOrderChange: state.isLoadingOrderChange,
        isLoadingPaymentStatusChange: state.isLoadingPaymentStatusChange,
        error: state.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllOrders: (
            accessToken,
            searchString,
            limit,
            offset,
            status,
            fromDate,
            toDate,
            orderType,
            deliveryMode,
            abortController,
            snackBar
        ) => {
            dispatch(
                getAllOrders(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    status,
                    fromDate,
                    toDate,
                    orderType,
                    deliveryMode,
                    abortController,
                    snackBar
                )
            );
        },
        handleChangeOrderStatus: (accessToken, order, value, action) => {
            dispatch(changeOrderStatus(accessToken, order, value, action));
        },
        handleChangeOrderStatusSingle: (
            accessToken,
            order,
            value,
            action,
            deliveredQuantity,
            deliveryMode,
            snackBar
        ) => {
            dispatch(
                changeOrderStatus(
                    accessToken,
                    order,
                    value,
                    action,
                    deliveredQuantity,
                    deliveryMode,
                    snackBar
                )
            );
        },
        handleSendReport: (accessToken, value, snackBar) => {
            dispatch(sendReportToMail(accessToken, value, snackBar));
        },
        version: () => {
            dispatch(versionControl());
        }
        // handleChangePaymentStatus: (accessToken, order, status, action, snackBar) => {
        //     dispatch(changePaymentStatus(accessToken, order, status, action, snackBar))
        // },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllOrders)));
