import axios from "axios";

const reposWebApi = axios.create({
  /****************** live ****************/
  // baseURL: 'https://drf.reposenergy.com/api/web/v1'
  /****************** ******** ****************/

  /****************** staging ****************/
  baseURL: "https://staging.reposenergy.com/api/web/v1"
  // baseURL:'https://develop.reposenergy.com/api/web/v1'
  // baseURL:'https://new-server.reposenergy.com/api/web/v1'
  /****************** ******** ****************/

  /****************** New staging ****************/
  // baseURL:'https://new-server.reposenergy.com/api/web/v1'
  /****************** ******** ****************/

});


export const reposNodeApi = axios.create({
  /****************** staging ****************/
  // baseURL: 'https://dpu-stage.reposenergy.com/api/node/'
  /****************** **** ****************/

  /****************** live ****************/
  baseURL: "https://dpu-prod.reposenergy.com/api/node/"
  /****************** **** ****************/
});

export default reposWebApi;
export let version = "5.2.1";
