import React, {Component} from "react";
// import { Link } from 'react-router-dom';
// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";

// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GoogleMapsReact from "../../../../components/GoogleMaps/google_maps_react";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import TimeLine from "../TimeLine/TimeLine";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

class UsersTable extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        reg_no: "",
        latLon: []
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    openDialog(latLon, vehicle_info) {
        this.setState({
            open: true,
            latLon: latLon,
            reg_no: vehicle_info
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleChange = e => {
        this.setState({
            value: e.target.value
        });
    };

    handleGetAssignment = (refuelerId, orderId) => {
        this.props.getRefuelerOrderAssignment(refuelerId, orderId);
    };

    orderUnassignmentData = orderId => {
        this.props.getRefuelerOrderUnssignment(orderId);
    };

    handleChangeOrderSequence = (order, operation) => {
        this.props.changeOrderSequence(order, operation);
    };

    render() {
        const {classes, className} = this.props;
        const rootClassName = classNames(classes.root, className);

        if (this.props.dataCount === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="left">RPP Number</TableCell>
                                                <TableCell align="left">Assign Orders</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {this.props.allRefuellerData?.map(refueller => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={refueller.vehicle_registration_number}
                                                        id={"table_row_allRefuellers"}
                                                    >
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            style={{textAlign: "center"}}
                                                        >
                                                            {/* {refueller.orders_data.map((ordersData) => {
                                            return(
                                              <Typography
                                              className={classes.partner}
                                              variant={"string"}>
                                                {ordersData.partner_name}
                                              </Typography>
                                            )
                                            })} */}
                                                            <Typography
                                                                variant="h5"
                                                                style={{
                                                                    marginBottom: "3%",
                                                                    fontSize: "1.3em"
                                                                }}
                                                            >
                                                                {refueller.pump_name}
                                                            </Typography>
                                                            <Typography
                                                                className={classes.subtitle}
                                                                variant={"body1"}
                                                            >
                                                                {refueller.vehicle_registration_number}
                                                            </Typography>

                                                            <Button
                                                                id={"view_refueller_btn"}
                                                                onClick={() =>
                                                                    this.openDialog(
                                                                        refueller.last_latlong,
                                                                        refueller.vehicle_registration_number
                                                                    )
                                                                }
                                                            >
                                                                <VisibilityOutlinedIcon/> View
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <TimeLine
                                                                ordersData={this.props.allOrdersData}
                                                                assignedRefuelerOrders={refueller.orders_data}
                                                                refueler={refueller}
                                                                getAssignmentData={this.handleGetAssignment}
                                                                moveOrder={this.handleChangeOrderSequence}
                                                                orderUnassignmentData={
                                                                    this.orderUnassignmentData
                                                                }
                                                                orderUnassignedSuccessfully={
                                                                    this.props.orderUnassignedSuccessfully
                                                                }
                                                                getRefuellerData={this?.props?.getRefuellerData}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleClose.bind(this)}
                                fullWidth={true}
                            >
                                <DialogContent style={{padding: 0}}>
                                    <div style={{padding: 0}}>
                                        <GoogleMapsReact
                                            google={this.props.google}
                                            marker={this.state.latLon}
                                            markerInfo={this.state.reg_no}
                                            zoom={15}
                                        />
                                    </div>
                                </DialogContent>
                            </Dialog>
                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    // rowsPerPageOptions={[this.props.rowsPerPage, this.props.rowsPerPage*2, this.props.rowsPerPage*5]}
                                    rowsPerPageOptions={[10, 20, 50]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withStyles(styles)(UsersTable);
