import React, {Component} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button, TextField, withStyles} from "@material-ui/core";
import {activateRpp, getAllRefuellers} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import styles from "../../../AllRefuellers/style";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import secureLocalStorage from "react-secure-storage";

class ActivateRpp extends Component {
    state = {
        selectedRpp: 0
    };

    componentDidMount() {
        this.props.handleGetAllRefuellers(
            secureLocalStorage.getItem("accessToken"),
            "",
            200,
            0,
            ""
        );
    }

    onTagsChangeDriver = (event, values) => {
        event.preventDefault();
        this.setState({
            selectedRpp: values.id
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        this.props.handleChangeActivationStatus(
            secureLocalStorage.getItem("accessToken"),
            this.state.selectedRpp,
            1
        );
        return false;
    };

    render() {
        const {classes} = this.props;

        return (
            <div style={{textAlign: "center"}}>
                <div className={classes.root}>
                    <form autoComplete="off" noValidate method={"POST"} action={"#"}>
                        {this.props.isLoading ? (
                            <CircularProgress/>
                        ) : (
                            <Autocomplete
                                id="combo-box-for-driver"
                                onChange={this.onTagsChangeDriver}
                                options={this.props.allRefuellerData}
                                fullWidth
                                getOptionLabel={option => option.vehicle_registration_number}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="Select RPP"
                                        variant="outlined"
                                        onChange={this.handleOnChange}
                                    />
                                )}
                            />
                        )}
                        {this.state.selectedRpp !== 0 ? (
                            this.props.isLoadingActivatingRefueler ? (
                                <CircularProgress/>
                            ) : (
                                <Button
                                    className={classes.addNewPartnerButton}
                                    color="primary"
                                    fullWidth
                                    name="create_partner_submit"
                                    id="create_partner_submit"
                                    style={{margin: "10px 0"}}
                                    onClick={e => this.handleSubmit(e)}
                                    size="large"
                                    variant="contained"
                                >
                                    Activate Rpp
                                </Button>
                            )
                        ) : (
                            ""
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

ActivateRpp.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allRefuellerData: state.allRefuellerData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allPartnersMinimalData: state.allPartnersMinimalData,
        isLoading: state.isLoading,
        isLoadingActivatingRefueler: state.isLoadingActivatingRefueler,
        activatingRefuelerFailed: state.activatingRefuelerFailed
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllRefuellers: (
            accessToken,
            searchString,
            limit,
            offset,
            partner
        ) => {
            dispatch(
                getAllRefuellers(accessToken, searchString, limit, offset, partner)
            );
        },
        handleChangeActivationStatus: (accessToken, id, status) => {
            dispatch(activateRpp(accessToken, id, status));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(ActivateRpp));
