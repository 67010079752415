import React, {Component} from "react";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import InfoWindowEx from "./InfoWindowEx";

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {}
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props.place_,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    render() {
        const style = {
            width: "100%",
            height: "50vh"
        };

        return (
            <div style={style}>
                <Map
                    google={this.props.google}
                    initialCenter={{
                        lat: this.props.marker.lat,
                        lng: this.props.marker.lon
                    }}
                    style={{width: "100%", height: "100%", position: "relative"}}
                    className={"map"}
                    zoom={8}
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        key={"place.id"}
                        place_={"place"}
                        position={{
                            lat: this.props.marker.lat,
                            lng: this.props.marker.lon
                        }}
                    />
                    <InfoWindowEx
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                    >
                        <div>
                            <h3>{this.props.markerInfo}</h3>
                        </div>
                    </InfoWindowEx>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyC5-RRQjjGOPGIAFt-tuy6ClXrjjFZFvxw"
})(MapContainer);
