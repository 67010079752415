import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
// Material components
import {
    LinearProgress,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import reposWebApi from "../../../../actions/configuration";
import GoogleMaps from "../../../AllGeofences/components/PolygonMap/MapForModifyPolygon";
import {modifyGeofence, sendLogs} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import MapForPolygon from "../../../AllGeofences/components/PolygonMap/MapForPolygon";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import qs from "qs";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {withSnackbar} from "notistack";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import GoogleMapsReact from "../../../../components/GoogleMaps/google_maps_react";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";

class Cards extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        coords: "",
        assetInfo: "",
        right: false,
        assets: [],
        is_dialog: false,
        rppDialog: false,
        selectedOrders: [],
        is_datum: false,
        dialog_Open: false,
        tracker_id: "",
        authorize: "",
        datum_id: "",
        geofenceId: "",
        openModifyDialog: false,
        geofenceInfo: "",
        list_Of_asset: [],
        listOfAvailableDatumTrackers: [],
        listFinservRefuelersForOrder: [],
        listFinservPartner: [],
        polygonArray: [],
        geofense_deets: [],
        openLocation: false,
        searchString: "",
        isLoading: false,
        partnerId: 0,
        asset_status: false,
        asset_id: 0,
        rpp_id: 0,
        form: "",
        datum_model: "X",
        is_dataum_active: false,
        showCapacity: false,
        error: "",
        capacity: ""
    };

    constructor() {
        super();
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        this.searchTerm = searchTerm;
        if (searchTerm.length > 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm
            });
            this.getPartnersData(searchTerm);
            this.getTrackerListForBinding(searchTerm);
        } else {
            this.setState({
                searchString: ""
            });
            this.getPartnersData(this.state.searchString);
            this.getTrackerListForBinding("");
        }
    };

    getPartnersData = (
        searchTerm = this.state.searchString,
        limit = 10,
        offset = 0,
        fis_orders = 1,
        abortController = this.abortController
    ) => {
        let queryParams = {};
        queryParams.search = searchTerm;
        queryParams.limit = limit;
        queryParams.offset = offset;
        queryParams.fis_orders = fis_orders;
        this.setState({
            isLoading: true
        });
        reposWebApi
            .get("/partner/all/", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: queryParams
            })
            .then(partners => {
                if (partners?.status === 200) {
                    this.setState({
                        listFinservPartner: partners.data.data.results,
                        isLoading: false
                    });
                }
            })
            .catch(error => {
                if (error?.response?.status === 500) {
                    this.props.enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                }
            });
    };

    getTrackerListForBinding = SearchTerm => {
        let queryParams = {};
        queryParams.search = SearchTerm;
        this.setState({
            isLoading: true
        });
        reposWebApi
            .get("/tracker/list_tracker_for_binding", {
                cancelToken: this.abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                // params: {
                //     tracker_type: "datum"
                // }
                params: queryParams
            })
            .then(res => {
                this.setState({
                    listOfAvailableDatumTrackers: decryption(res)?.data?.results,
                    isLoading: false
                });
            });
    };

    handleDialogAssignRpp = (e, asset_id) => {
        this.setState({
            asset_status: e.target.checked,
            rppDialog: true,
            asset_id: asset_id,
            form: e.target.name
        });
    };

    GetFinservRefuelersForOrder = partner => {
        this.setState({
            isLoading: true
        });
        reposWebApi
            .get("/refueler/list", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    partner: partner
                }
            })
            .then(res => {
                this.setState({
                    listFinservRefuelersForOrder: res?.data?.data?.results,
                    isLoading: false
                });
            });
    };

    handleOnSubmit = () => {
        reposWebApi
            .get("/customer/assign_refueler_partner_to_asset", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    partner_id: this.state.partnerId,
                    asset_id: this.state.asset_id,
                    refueler_id: this.state.rpp_id
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    this.props.enqueueSnackbar("Partner assign successfully!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/assign_refueler_partner_to_asset",
                        qs.stringify({
                            partner_id: this?.state?.partnerId,
                            asset_id: this?.state?.asset_id,
                            refueler_id: this?.state?.rpp_id
                        })
                    );
                    this.setState({
                        rpp_id: 0,
                        tracker_id: ""
                    });
                    if (this.state.form === "activationSwitch") {
                        this.handleFinservActivation(
                            this.state.asset_status,
                            this.state.asset_id
                        );
                        this.handleDialogClose();
                    } else {
                        this.handleDialogClose();
                    }
                }
            })
            .catch(error => {
                if (error?.response?.status === 400) {
                    this.props.enqueueSnackbar(
                        "Please select the right Partner or RPP !",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/assign_refueler_partner_to_asset",
                        qs.stringify({
                            partner_id: this.state.partnerId,
                            asset_id: this.state.asset_id,
                            refueler_id: this.state.rpp_id
                        })
                    );
                } else {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/assign_refueler_partner_to_asset",
                        qs.stringify({
                            partner_id: this.state.partnerId,
                            asset_id: this.state.asset_id,
                            refueler_id: this.state.rpp_id
                        })
                    );
                    this.props.enqueueSnackbar(
                        "RPP - Asset binding error, something went wrong!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                }
            });
    };

    handleFinservActivation = (status, id) => {
        const enabled = status ? "Enabled" : "Disable";
        const requestBody = {
            asset_id: id,
            enabled: status ? "True" : "False"
        };
        reposWebApi
            .post("/finserv/customer_asset/enable", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/customer_asset/enable",
                        qs.stringify(requestBody)
                    );
                    this.props?.enqueueSnackbar(
                        "Asset Finserv " + enabled + " successfully!",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.props.reloadAsset();
                }
            })
            .catch(error => {
                if (error?.response?.status === 400) {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/customer_asset/enable",
                        qs.stringify(requestBody)
                    );
                    this.props.enqueueSnackbar("Please select the right tracker !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                } else {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/finserv/customer_asset/enable",
                        qs.stringify(requestBody)
                    );
                    this.props.enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                }
            });
    };
    handleDatumActivationSubmit = (e, id, tracker, authorize) => {
        reposWebApi
            .post(
                "/customer/bind_tracker_to_asset",
                {
                    tracker_id: this.state.tracker_id,
                    asset_id: id,
                    authorize: this.state.authorize,
                    datum_model: this.state.datum_model,
                    capacity: this.state.capacity,
                    bind_unbind: this.state.is_dataum_active ? 1 : 0
                },
                {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                    }
                }
            )
            .then(response => {
                if (response?.status === 201 || response?.status === 200) {
                    this.props.enqueueSnackbar(
                        response?.status === 201
                            ? "Asset successfully activated as datum!"
                            : "Asset is unbind successfully with tracker",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify()
                    );
                    this.setState({
                        tracker_id: "",
                        datum_id: "",
                        trackerType: "datum",
                        authorize: "",
                        datum_model: "",
                        capacity: ""
                    });
                    this.handleDialogClose();
                    this.props.reloadAsset();
                }
            })
            .catch(error => {
                if (error?.response?.status === 400) {
                    this.props.enqueueSnackbar("Please select a tracker and check !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify({
                            tracker_id: this.state.tracker_id,
                            asset_id: id,
                            authorize: this.state.authorize,
                            datum_model: this.state.datum_model
                        })
                    );
                }
                if (error?.response?.status === 404) {
                    this.props.enqueueSnackbar("Please select authorization !", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify({
                            tracker_id: this.state.tracker_id,
                            asset_id: id,
                            authorize: this.state.authorize,
                            datum_model: this.state.datum_model
                        })
                    );
                } else {
                    this.props.enqueueSnackbar(
                        "Tracker - Asset binding error, Something went wrong!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/customer/bind_tracker_to_asset",
                        qs.stringify({
                            tracker_id: this.state.tracker_id,
                            asset_id: id,
                            authorize: this.state.authorize,
                            datum_model: this.state.datum_model
                        })
                    );
                }
                this.setState({
                    tracker_id: "",
                    datum_id: "",
                    authorize: "",
                    datum_model: "X"
                });
                this.handleDialogClose();
            });
    };

    handleDatumActivation = (e, asset) => {
        if (asset.tracker_id !== "N/A") {
            this.setState({
                list_Of_asset: asset,
                is_dataum_active: e.target.checked,
                tracker_id: "" + asset.tracker_id,
                authorize: asset.authorization ? "True" : "False",
                datum_model: asset.datum_model,
                is_datum: true
            });
            this.getTrackerListForBinding("");
        } else {
            this.setState({
                is_datum: true,
                list_Of_asset: asset,
                is_dataum_active: e.target.checked
            });
            this.getTrackerListForBinding("");
        }
    };

    openLocation = (latLon, assetInfo) => {
        this.setState({
            openLocation: true,
            coords: latLon,
            assetInfo: assetInfo
        });
    };

    openDialog(latLon, assetInfo) {
        const coords = latLon?.map(ll => {
            return {lat: ll["lat"], lng: ll["lon"]};
        });
        this.setState({
            open: true,
            coords: coords,
            assetInfo: assetInfo
        });
    }

    handleSubmit = () => {
        if (this.state.polygonArray.length === 0) {
            alert(
                "You haven't created a geofence! Please draw a polygon and click Save or close Button"
            );
            return false;
        } else
            this?.props?.handleModifyGeofence(
                secureLocalStorage.getItem("accessToken"),
                this.state.geofenceId,
                this.state.polygonArray
            );
        alert("Your geo-fence location has been updated");
        // this.handleCloseModifyDialog.bind()
        this.props.reloadAsset();
        this.setState({
            openModifyDialog: false,
            open: false,
            openLocation: false,
            is_dialog: false,
            dialog_Open: false,
            tracker_id: "",
            datum_model: "X"
        });
    };

    handleClose() {
        this.setState({
            open: false,
            tracker_id: "",
            datum_model: "X",
            showCapacity: false
        });
    }

    openDialogForModify(geofenceId, latLon, assetInfo) {
        const coords = latLon?.map(ll => {
            return {lat: ll["lat"], lng: ll["lon"]};
        });
        this.setState({
            openModifyDialog: true,
            geofenceId: geofenceId,
            coords: coords,
            polygonArray: coords,
            assetInfo: assetInfo
        });
    }

    handleCloseModifyDialog() {
        this.setState({
            openModifyDialog: false,
            open: false,
            openLocation: false,
            rppDialog: false
        });
    }

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    handleDialogOpen = (e, asset) => {
        e.preventDefault();
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }
        this.setState({
            ...this.state,
            is_dialog: true,
            assets: asset,
            geofense_deets: asset.geofense_deets
        });
    };
    // handleDatumDialogOpen = (e, asset) => {
    //     e.preventDefault();
    //     if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
    //         return;
    //     }
    //     this.setState({
    //         ...this.state,
    //         assets: asset,
    //         dialog_Open: true
    //     });
    // };

    getUpdatedPolygonArray = polygonArray => {
        this.setState({
            polygonArray: polygonArray
        });
    };

    handleDialogClose = () => {
        this.setState({
            authorize: "",
            is_dialog: false,
            is_datum: false,
            dialog_Open: false,
            open: false,
            rppDialog: false,
            tracker_id: "",
            asset_id: "",
            id: "",
            rpp_id: 0,
            partnerId: 0,
            searchString: "",
            datum_model: "X",
            showCapacity: false,
            error: "",
            capacity: ""
        });
    };

    handleChangeSelectPartner = (e, value) => {
        this.setState({
            partnerId: value.id
        });
        this.GetFinservRefuelersForOrder(value.id);
    };

    handleSelectRpp = (e, value) => {
        this.setState({
            rpp_id: value.id
        });
    };

    handleChange = (e, value) => {
        this.setState({
            tracker_id: value.id.toString()
        });
    };

    handleChangeAuthorize = e => {
        this.setState({
            authorize: e.target.value
        });
    };
    handleChangeDatumModels = e => {
        this.setState({
            datum_model: e.target.value
        });
    };

    handleCapacityChange = event => {
        this.setState({
            capacity: event.target.value
        });
        // let value = event.target.value;
        // let capacity = value;
        // if (capacity > 3000) {
        //   this.setState({ error: 'Capacity should be greate than 3000' });
        // } else {
        //   this.setState({ capacity, error: '' });
        // }
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    componentDidMount() {
        this.getTrackerListForBinding();
        this.GetFinservRefuelersForOrder();
        this.getPartnersData();
    }

    render() {
        const {classes} = this.props;
        const {capacity} = this.state;
        const {error} = this.state;
        if (this.props.partnerDataCount === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isPartnerLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <>
                        {this.props.isLoading ? (
                            <div className={classes.progressWrapper}>
                                <LinearProgress fullWidth className={classes.progress}/>
                            </div>
                        ) : null}
                        {this.props.noDataFound ? (
                            "hii"
                        ) : (
                            <>
                                {this.props.allAssetsData.map(asset => (
                                    <Box sx={{flexGrow: 2}} style={{margin: "4%"}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Paper elevation={2} className={classes.paper}>
                                                        <Box display="flex" flexDirection="column">
                                                            <Box
                                                                py={1}
                                                                px={2}
                                                                display="flex"
                                                                className={classes.header}
                                                                style={{
                                                                    background:
                                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                                }}
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    className={classes.textWhite}
                                                                    style={{color: "primary"}}
                                                                >
                                                                    #ID {asset?.id}
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    className={classes.textWhite}
                                                                >
                                                                    {asset?.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box p={2} spacing={2}>
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                    align="space-around"
                                                                    justifyContent="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Box px={1} py={0} display="flex">
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textSecondary"
                                                                                align="center"
                                                                            >
                                                                                <b>Company Name:- </b>
                                                                                {asset.customer}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Tracker Device
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                                align="center"
                                                                                style={{wordBreak: "break-word"}}
                                                                            >
                                                                                {asset.tracker_device === null
                                                                                    ? "-"
                                                                                    : asset.tracker_device}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Type
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                            >
                                                                                {asset.asset_type === 1
                                                                                    ? "DATUM"
                                                                                    : asset.asset_type === 2
                                                                                        ? "SFD"
                                                                                        : asset.asset_type === 3
                                                                                            ? "BARREL"
                                                                                            : asset.asset_type === 4
                                                                                                ? "SFC"
                                                                                                : asset.asset_type === 5
                                                                                                    ? "DG SET"
                                                                                                    : asset.asset_type === 6
                                                                                                        ? "INDUSTRIAL MACH."
                                                                                                        : asset.asset_type === 7
                                                                                                            ? "JCB"
                                                                                                            : asset.asset_type === 8
                                                                                                                ? "OTHERS"
                                                                                                                : "null"}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className={classes.marginRightAuto}
                                                                    >
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Datum Activation
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                <FormControlLabel
                                                                                    onChange={event =>
                                                                                        this.handleDatumActivation(
                                                                                            event,
                                                                                            asset
                                                                                        )
                                                                                    }
                                                                                    control={
                                                                                        <Switch
                                                                                            checked={
                                                                                                asset.tracker_id !== "N/A"
                                                                                            }
                                                                                            disabled={
                                                                                                asset.tracker_id !== "N/A" ||
                                                                                                secureLocalStorage.getItem(
                                                                                                    "access_type"
                                                                                                ) === "false"
                                                                                            }
                                                                                            name="activationSwitch"
                                                                                            trackColor={{
                                                                                                true: secureLocalStorage.access_type
                                                                                                    ? "red"
                                                                                                    : "grey",
                                                                                                false: "grey"
                                                                                            }}
                                                                                            edge={"start"}
                                                                                            disableRipple={true}
                                                                                            classes={{
                                                                                                root: classes.root,
                                                                                                switchBase: classes.switchBase,
                                                                                                thumb: classes.thumb,
                                                                                                track: classes.track,
                                                                                                checked: classes.checked,
                                                                                                color: "primary"
                                                                                            }}
                                                                                            onChange={event =>
                                                                                                this.handleDatumActivation(
                                                                                                    event,
                                                                                                    asset
                                                                                                )
                                                                                            }
                                                                                            inputProps={{
                                                                                                "aria-label":
                                                                                                    "secondary checkbox"
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Username
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                {asset.datum_cred[0]}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Password
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                {asset.datum_cred[1]}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Is_Finserv Enabled
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                {asset.is_finserv_enabled ? (
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Switch
                                                                                                checked={
                                                                                                    asset.is_finserv_enabled
                                                                                                }
                                                                                                name="activationSwitch"
                                                                                                trackColor={{
                                                                                                    true: "red",
                                                                                                    false: "grey"
                                                                                                }}
                                                                                                edge={"start"}
                                                                                                classes={{
                                                                                                    root: classes.root,
                                                                                                    switchBase:
                                                                                                    classes.switchBase,
                                                                                                    thumb: classes.thumb,
                                                                                                    track: classes.track,
                                                                                                    checked: classes.checked,
                                                                                                    color: "primary"
                                                                                                }}
                                                                                                onChange={event =>
                                                                                                    this.handleFinservActivation(
                                                                                                        event.target.checked,
                                                                                                        asset.id
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "secondary checkbox"
                                                                                                }}
                                                                                                disabled={
                                                                                                    secureLocalStorage.getItem(
                                                                                                        "access_type"
                                                                                                    ) === "false"
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Switch
                                                                                                checked={
                                                                                                    asset.is_finserv_enabled
                                                                                                }
                                                                                                name="activationSwitch"
                                                                                                trackColor={{
                                                                                                    true: "red",
                                                                                                    false: "grey"
                                                                                                }}
                                                                                                edge={"start"}
                                                                                                classes={{
                                                                                                    root: classes.root,
                                                                                                    switchBase:
                                                                                                    classes.switchBase,
                                                                                                    thumb: classes.thumb,
                                                                                                    track: classes.track,
                                                                                                    checked: classes.checked,
                                                                                                    color: "primary"
                                                                                                }}
                                                                                                onChange={event =>
                                                                                                    this.handleDialogAssignRpp(
                                                                                                        event,
                                                                                                        asset.id
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "secondary checkbox"
                                                                                                }}
                                                                                                disabled={
                                                                                                    secureLocalStorage.getItem(
                                                                                                        "access_type"
                                                                                                    ) === "false"
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4} align="left">
                                                                        <Box>
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Levels Info
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                <FormControl
                                                                                    className={classes.formControl}
                                                                                >
                                                                                    {asset.levels_info === null ? (
                                                                                        "No data"
                                                                                    ) : (
                                                                                        <div>
                                                                                            <InputLabel
                                                                                                id="levels_info">
                                                                                                Levels
                                                                                            </InputLabel>
                                                                                            <Select
                                                                                                labelId="levels_info"
                                                                                                id="levels_info"
                                                                                                style={{width: "65px"}}
                                                                                            >
                                                                                                <MenuItem value={10}>
                                                                                                    Level 1:{" "}
                                                                                                    {asset.levels_info.lvl1}
                                                                                                </MenuItem>
                                                                                                <MenuItem value={20}>
                                                                                                    Level 2:{" "}
                                                                                                    {asset.levels_info.lvl2}
                                                                                                </MenuItem>
                                                                                                <MenuItem value={30}>
                                                                                                    Level 3:{" "}
                                                                                                    {asset.levels_info.lvl3}
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </div>
                                                                                    )}
                                                                                </FormControl>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}></Grid>
                                                                    <Grid item xs={4} align="right">
                                                                        <Box>
                                                                            <Button size="small">
                                                                                <MoreIcon
                                                                                    onClick={e =>
                                                                                        this.handleDialogOpen(e, asset)
                                                                                    }
                                                                                    style={{float: "right"}}
                                                                                />
                                                                            </Button>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </>
                        )}
                        <Dialog
                            anchor="right"
                            open={this.state.is_dialog}
                            onClose={this.handleDialogClose}
                        >
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                                id="simple-dialog-title"
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        Geo-fence Information
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    style={{marginTop: 10}}
                                >
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Geo-fence Name
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {this.state.geofense_deets.name}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Geo-fence Type
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {this.state.geofense_deets.type}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    View on Map:
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {this.state.assets.last_latlong === null ? (
                                                        "-"
                                                    ) : (
                                                        <Button
                                                            variant={"outlined"}
                                                            color={"primary"}
                                                            onClick={() =>
                                                                this.openLocation(
                                                                    this.state.assets.asset_lat_lon,
                                                                    this.state.assets.name
                                                                )
                                                            }
                                                            disabled={
                                                                secureLocalStorage.getItem("access_type") ===
                                                                false
                                                            }
                                                        >
                                                            Locate Me
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Geo-fence area
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {this.state.assets.last_latlong === null ? (
                                                        "-"
                                                    ) : (
                                                        <Button
                                                            variant={"outlined"}
                                                            color={"primary"}
                                                            onClick={() =>
                                                                this.openDialog(
                                                                    this.state.geofense_deets.lat_lon_polygon,
                                                                    this.state.geofense_deets.name
                                                                )
                                                            }
                                                        >
                                                            Geo-fence
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Modify GeoFence
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <Button
                                                        variant={"outlined"}
                                                        color={"primary"}
                                                        onClick={() =>
                                                            this.openDialogForModify(
                                                                this.state.assets.geofense_deets.id,
                                                                this.state.geofense_deets.lat_lon_polygon,
                                                                this.state.geofense_deets.name
                                                            )
                                                        }
                                                    >
                                                        Modify
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Capacity
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {this.state.assets.capacity === null
                                                        ? "-"
                                                        : this.state.assets.capacity}{" "}
                                                    L
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align={"left"}
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Finserv Edit
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <Button
                                                        variant={"outlined"}
                                                        color={"primary"}
                                                        name={"modify"}
                                                        onClick={event =>
                                                            this.handleDialogAssignRpp(
                                                                event,
                                                                this.state.assets.id
                                                            )
                                                        }
                                                        disabled={
                                                            secureLocalStorage.getItem("access_type") ===
                                                            false
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleDialogClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/*Datum Activation dialog*/}
                        <Dialog
                            fullWidth
                            aria-labelledby="draggable-dialog-title"
                            open={this.state.is_datum}
                            onClose={this.handleDialogClose.bind(this)}
                        >
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        Datum Activation
                                    </Typography>
                                </div>
                                <div style={{margin: "10px 0px"}}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "#fff"}}
                                    >
                                        Please select the respective tracker ID to bind it with the
                                        respective Datum
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    style={{margin: "10px 0"}}
                                >
                                    Select Tracker id
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        style={{margin: "10px 0"}}
                                    >
                                        {/* <Select
                                                fullWidth
                                                style={{margin: '5px 0'}}
                                                labelId="tracker_id"
                                                id="tracker_id"
                                                value={this.state.tracker_id}
                                                onChange={e => this.handleChange(e)}
                                                label="tracker_id"
                                                color={"secondary"}
                                            >
                                                {
                                                    this.state.listOfAvailableDatumTrackers.length === 0 ?
                                                        <MenuItem>
                                                            No Trackers Available!
                                                        </MenuItem> : ''
                                                }
                                                {
                                                    this.state.listOfAvailableDatumTrackers.map((singleDatumTracker) => (
                                                        <MenuItem value={singleDatumTracker.id}>
                                                            {
                                                                singleDatumTracker.name
                                                            }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select> */}
                                        <Autocomplete
                                            id="combo-box-for-tracker"
                                            onChange={this.handleChange}
                                            options={this.state.listOfAvailableDatumTrackers}
                                            loading={this.state.isLoading}
                                            getOptionLabel={option => option.name}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Tracker_ID"
                                                    variant="outlined"
                                                    onChange={e => this.onSearchTerm(e.target.value)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <Typography>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            style={{margin: "10px 0"}}
                                        >
                                            <InputLabel id="authorization">Authorization</InputLabel>
                                            <Select
                                                fullWidth
                                                style={{margin: "5px 0"}}
                                                labelId="authorization"
                                                id="authorization"
                                                label="Authorization"
                                                onChange={e => this.handleChangeAuthorize(e)}
                                            >
                                                <MenuItem value={"True"}>Not Required</MenuItem>
                                                <MenuItem value={"False"}>Required</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Typography>
                                    <Typography>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            style={{margin: "10px 0"}}
                                        >
                                            <InputLabel id="datum_model">Datum Model</InputLabel>
                                            <Select
                                                fullWidth
                                                style={{margin: "5px 0"}}
                                                labelId="datum_model"
                                                id="datum_model"
                                                label="Datum Model"
                                                value={this.state.datum_model}
                                                onChange={e => this.handleChangeDatumModels(e)}
                                            >
                                                <MenuItem value={"X"}>Model X</MenuItem>
                                                <MenuItem value={"Y"}>Model Y</MenuItem>
                                                <MenuItem value={"Z"}>Model Z</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                variant="outlined"
                                                style={{margin: "10px 0"}}
                                                labelId="capacity"
                                                id="capacity"
                                                label="Capacity"
                                                value={capacity}
                                                onChange={this.handleCapacityChange}
                                                inputProps={{
                                                    min: 100,
                                                    max: 3000
                                                }}
                                            />

                                            <Typography style={{color: "red"}}>
                                                {error && <div>{error}</div>}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={this.handleDialogClose.bind(this)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                {this.state.authorize === "" || this.state.tracker_id === "" ? (
                                    ""
                                ) : (
                                    <Button
                                        onClick={e =>
                                            this.handleDatumActivationSubmit(
                                                e,
                                                this.state.list_Of_asset.id,
                                                this.state.list_Of_asset.tracker_id,
                                                this.state.list_Of_asset.authorize
                                            )
                                        }
                                        color="primary"
                                    >
                                        Continue
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.open}
                            onClose={this.handleCloseModifyDialog.bind(this)}
                            fullWidth={true}
                        >
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <GoogleMapsReact
                                        google={this.props.google}
                                        height={"70%"}
                                        width={"80%"}
                                        initialCenter={{
                                            lat: this.state.coords.lat,
                                            lng: this.state.coords.lon
                                        }}
                                        marker={this.state.coords}
                                        markerInfo={this.state.assetInfo}
                                        zoom={15}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleCloseModifyDialog.bind(this)}
                                    variant={"contained"}
                                    color={"secondary"}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/*modify geoFence*/}
                        <Dialog
                            fullScreen
                            open={this.state.openModifyDialog}
                            onClose={this.handleCloseModifyDialog.bind(this)}
                            fullWidth={true}
                            // TransitionComponent={this.transition}
                        >
                            <AppBar
                                color={"white"}
                                style={{
                                    height: "70px"
                                }}
                                className={classes.appBar}
                            >
                                <Toolbar>
                                    <Grid
                                        justify="space-between" // Add it here :)
                                        container
                                        spacing={12}
                                    >
                                        <Grid item>
                                            <div
                                                style={{
                                                    marginTop: "7px",
                                                    padding: "19px 8px"
                                                }}
                                            >
                                                <Typography variant="button">
                                                    Modify Geofence
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item style={{marginTop: "10px"}}></Grid>
                                        <Grid item>
                                            <div>
                                                {this.props.isLoading ? (
                                                    <CircularProgress color={"white"}/>
                                                ) : (
                                                    // this.props.geofenceModificationSuccess !== null ?
                                                    //     <div>
                                                    //         {/*<Typography*/}
                                                    //         {/*    className={classes.nameText}*/}
                                                    //         {/*    variant="body1"*/}
                                                    //         {/*    style={{*/}
                                                    //         {/*        color: '#fff',*/}
                                                    //         {/*        backgroundColor: '#36a536',*/}
                                                    //         {/*        padding: '10px 15px',*/}
                                                    //         {/*        borderRadius: '5px'*/}
                                                    //         {/*    }}*/}
                                                    //         {/*>*/}
                                                    //         {/*    /!*{*!/*/}
                                                    //         {/*    /!*    this.props.geofenceModificationSuccess === "GeoFence Updated successfully" ?*!/*/}
                                                    //         {/*    /!*        // this.props.enqueueSnackbar("Geo_fence modify successfully", {*!/*/}
                                                    //         {/*    /!*        //     variant: 'success',*!/*/}
                                                    //         {/*    /!*        //     anchorOrigin: {*!/*/}
                                                    //         {/*    /!*        //         vertical: "top",*!/*/}
                                                    //         {/*    /!*        //         autoHideDuration: 5000,*!/*/}
                                                    //         {/*    /!*        //         horizontal: "center"*!/*/}
                                                    //         {/*    /!*        //     }*!/*/}
                                                    //         {/*    /!*        // }*!/*/}
                                                    //         {/*    /!*        ""*!/*/}
                                                    //         {/*    /!*        : this.props.enqueueSnackbar("Something Went Wrong.!", {*!/*/}
                                                    //         {/*    /!*            variant: 'error',*!/*/}
                                                    //         {/*    /!*            anchorOrigin: {*!/*/}
                                                    //         {/*    /!*                vertical: "top",*!/*/}
                                                    //         {/*    /!*                autoHideDuration: 5000,*!/*/}
                                                    //         {/*    /!*                horizontal: "center"*!/*/}
                                                    //         {/*    /!*            }*!/*/}
                                                    //         {/*    /!*        })}*!/*/}
                                                    //         {/*</Typography>*/}
                                                    //         <Button edge="start" color="inherit"
                                                    //                 onClick={this.handleCloseModifyDialog.bind(this)}
                                                    //                 aria-label="close"
                                                    //                 variant={'text'}
                                                    //                 color="inherit"
                                                    //         >
                                                    //             Close
                                                    //         </Button>
                                                    //     </div>
                                                    // :
                                                    <div style={{padding: "8px 8px"}}>
                                                        <Button
                                                            variant={"contained"}
                                                            color="primary"
                                                            // style={{padding: '8px 8px'}}
                                                            onClick={this.handleSubmit}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={this.handleCloseModifyDialog.bind(this)}
                                                            aria-label="close"
                                                            variant={"text"}
                                                        >
                                                            Close
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <GoogleMaps
                                        getUpdatedPolygon={this.getUpdatedPolygonArray}
                                        coords={this.state.coords}
                                    />
                                </div>
                            </DialogContent>
                        </Dialog>

                        {/*map view*/}
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose.bind(this)}
                            fullWidth={true}
                        >
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <MapForPolygon
                                        markerInfo={this.state.geofenceInfo}
                                        google={this.props.google}
                                        coords={this.state.coords}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Grid container spacing>
                                    <Grid xs={2}>
                                        <Button
                                            onClick={this.handleCloseModifyDialog.bind(this)}
                                            variant={"contained"}
                                            color={"secondary"}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>

                        {/*located me*/}
                        <Dialog
                            open={this.state.openLocation}
                            onClose={this.handleCloseModifyDialog.bind(this)}
                            fullWidth={true}
                        >
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <GoogleMapsReact
                                        google={this.props.google}
                                        height={"70%"}
                                        width={"80%"}
                                        initialCenter={{
                                            lat: this.state.coords.lat,
                                            lng: this.state.coords.lon
                                        }}
                                        marker={this.state.coords}
                                        markerInfo={this.state.assetInfo}
                                        zoom={20}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Grid container spacing>
                                    <Grid>
                                        <Button
                                            onClick={this.handleCloseModifyDialog.bind(this)}
                                            variant={"contained"}
                                            color={"secondary"}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>

                        {/*rrp and partner assign dialog*/}
                        <Dialog
                            fullWidth
                            aria-labelledby="draggable-dialog-title"
                            open={this.state.rppDialog}
                            onClose={this.handleDialogClose.bind(this)}
                        >
                            <DialogTitle
                                style={{
                                    background: "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                }}
                            >
                                <div style={{margin: "15px 0px"}}>
                                    <Typography style={{color: "#fff"}} variant="h4">
                                        Finserv Enabled
                                    </Typography>
                                </div>
                                <div style={{margin: "10px 0px"}}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{marginTop: "2%", color: "#fff"}}
                                    >
                                        Please select the respective Partner and their Rpp to bind
                                        it with the respective Customer asset
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    style={{margin: "10px 0"}}
                                >
                                    <Autocomplete
                                        id="combo-box-for-tracker"
                                        onChange={this.handleChangeSelectPartner}
                                        options={this.state.listFinservPartner}
                                        style={{margin: "10px 0"}}
                                        fullWidth
                                        loading={this.state.isLoading}
                                        getOptionLabel={option => option.name}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Select Partner"
                                                variant="outlined"
                                                onChange={e => this.onSearchTerm(e.target.value)}
                                            />
                                        )}
                                    />
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    style={{margin: "10px 0"}}
                                >
                                    <Autocomplete
                                        id="combo-box-for-tracker"
                                        onChange={this.handleSelectRpp}
                                        options={this.state.listFinservRefuelersForOrder}
                                        style={{margin: "10px 0"}}
                                        fullWidth
                                        loading={this.state.isLoading}
                                        getOptionLabel={option => option.name}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Select Rpp"
                                                variant="outlined"
                                                onChange={e => this.handleOnChange}
                                            />
                                        )}
                                    />
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={this.handleDialogClose.bind(this)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                {this.state.rpp_id === 0 || this.state.partnerId === 0 ? (
                                    ""
                                ) : (
                                    <Button onClick={this.handleOnSubmit} color="primary">
                                        Continue
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>

                        {this.props.noDataFound ? (
                            ""
                        ) : (
                            <TablePagination
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                component="div"
                                count={this.props.assetDataCount}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                page={this.props.page}
                                rowsPerPage={this.props.rowsPerPage}
                                rowsPerPageOptions={[10, 20, 50, 100, 500, 5000]}
                            />
                        )}
                    </>
                );
        }
    }
}

Cards.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};
const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoading: state.isLoading,
        allPartnersData: state.allPartnersData,
        geofenceModificationSuccess: state.geofenceModificationSuccess,
        geofenceModificationFail: state.geofenceModificationFail
    };
};
Cards.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};
const mapDispatchToProps = dispatch => {
    return {
        handleModifyGeofence: (accessToken, geofenceId, polygonArray) => {
            dispatch(modifyGeofence(accessToken, geofenceId, polygonArray));
        },
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(Cards)));
