import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {withStyles} from "@material-ui/core";

// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Component styles
import styles from "./style";
import {connect} from "react-redux";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CreateDriver from "../AllDrivers/components/CreateDriver/CreateDriver";
import CreateRefueler from "../AllRefuellers/components/CreateRefueler/CreateRefueler";
import Cariq from "./components/CariqBindingModule/index.js";
import ActivateRpp from "./components/ActivateRPP/ActivateRpp";
import CreatePumpSingleSignOn from "../AllPumps/components/CreatePumpSingleSignOn/CreatePumpSingleSignOn";
import secureLocalStorage from "react-secure-storage";

class CreatePartnerVehicleOperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0
        };
    }

    pageNext = () => {
        this.setState({
            page: this.state.page + 1
        });
    };

    pagePrev = () => {
        this.setState({
            page: this.state.page - 1
        });
    };

    redirectOnSuccess = () => {
        this.props.history.push("/all-pumps");
    };

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="Partner-Operator-Vehicle Binding">
                    <div className={classes.root}>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={0} md={1} lg={2}></Grid>
                            <Grid item xs={12} md={10} lg={8}>
                                <div>
                                    <Card className={classes.root}>
                                        <div>
                                            <Typography
                                                variant="h1"
                                                style={{
                                                    fontWeight: 300,
                                                    color: "gray"
                                                }}
                                            >
                                                {this.state.page === 0 && "Create Partner"}
                                                {this.state.page === 1 && "Create Driver"}
                                                {this.state.page === 2 && "Create RPP"}
                                                {this.state.page === 3 && "Assign Cariq"}
                                                {this.state.page === 4 && "Activate RPP"}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    fontWeight: 300,
                                                    color: "gray"
                                                }}
                                            >
                                                {this.state.page === 0 &&
                                                    "Create Partner now or Click next to select an existing partner"}
                                                {this.state.page === 1 &&
                                                    "Create Driver now or Click next to select an existing Driver"}
                                                {this.state.page === 2 &&
                                                    "Create RPP now or Click next to select an existing RPP"}
                                                {this.state.page === 3 &&
                                                    "Select TrackerId and Assign Cariq"}
                                                {this.state.page === 4 &&
                                                    "Select RPP and Click Activate Now!"}
                                            </Typography>
                                        </div>
                                        <CardContent>
                                            {this.state.page === 0 && (
                                                <CreatePumpSingleSignOn
                                                    pumpCreationSuccessCallback={this.redirectOnSuccess}
                                                />
                                            )}
                                            {this.state.page === 1 && <CreateDriver/>}
                                            {this.state.page === 2 && <CreateRefueler/>}
                                            {this.state.page === 3 && <Cariq/>}
                                            {this.state.page === 4 && <ActivateRpp/>}
                                        </CardContent>
                                        <CardActions>
                                            <div style={{width: "100%"}}>
                                                {this.state.page === 0 ? (
                                                    ""
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        style={{float: "left"}}
                                                        onClick={this.pagePrev}
                                                    >
                                                        Back
                                                    </Button>
                                                )}
                                                {this.state.page === 4 ? (
                                                    ""
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        style={{float: "right"}}
                                                        onClick={this.pageNext}
                                                    >
                                                        Next
                                                    </Button>
                                                )}
                                            </div>
                                        </CardActions>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid item xs={0} md={1} lg={2}></Grid>
                        </Grid>
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

CreatePartnerVehicleOperator.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allAssetsData: state.allAssetsData,
        allCustomerData: state.allCustomerData,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllAssets: (
            accessToken,
            searchString,
            limit,
            offset,
            customer
        ) => {
            // dispatch(getAllAssets(accessToken, searchString, limit, offset, customer))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(CreatePartnerVehicleOperator));
