import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import validate from "validate.js";
import _ from "underscore";

// Material components
import {Button, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, withStyles} from "@material-ui/core";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";
import {editOperator} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import CircularProgress from "@material-ui/core/CircularProgress";
import reposWebApi from "../../../../actions/configuration";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import secureLocalStorage from "react-secure-storage";

class EditDriver extends Component {
    state = {
        values: {
            fullName: this.props.driver.driver_name,
            mobile: this.props.driver.mobile,
            email: this.props.driver.email,
            password: "",
            confirmPassword: "",
            user_name: this.props.driver.user_name,
            licenseNumber: this.props.driver.license_number,
            insurance: this.props.driver.insurance,
            viewCnfPass: false,
            viewPass: false,
            partner: this.props.driver.partner,
            id: this.props.driver.id
        },
        touched: {
            fullName: false,
            mobile: false,
            email: false,
            password: false,
            confirmPassword: false,
            user_name: false,
            licenseNumber: false,
            insurance: false
        },
        errors: {
            fullName: null,
            mobile: null,
            email: null,
            password: null,
            confirmPassword: null,
            user_name: null,
            licenseNumber: null,
            insurance: null
        },
        viewPass: false,
        isValid: true,
        isLoading: false,
        submitError: false,
        allPartnersData: [],
        selectedPartner: null
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = !errors;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
    };

    handleSubmit = event => {
        event.preventDefault();
        this.editOperator(this.state.values);
        return false;
    };

    onTagsChange = (event, values) => {
        event.preventDefault();
        this.setState({
            selectedPartner: values.id
        });
    };

    editOperator = operatorFormData => {
        this.props.handleEditOperator(
            secureLocalStorage.getItem("accessToken"),
            operatorFormData,
            this.state.selectedPartner
        );
        return false;
    };

    redirectOnSuccess = () => {
        this.props.modifyOperatorSuccess();
    };

    handleOnChange = ({target}) => {
    };

    handleClickShowPassword = () => {
        this.setState({
            viewPass: !this.state.viewPass
        });
    };

    componentDidMount() {
        reposWebApi
            .get("/partner/minimal", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    limit: 500
                }
            })
            .then(response => {
                this.setState({
                    allPartnersData: response.data.data
                });
            });
    }

    render() {
        const {classes} = this.props;
        const {values} = this.state;

        return (
            <div className={classes.root}>
                <form autoComplete="off" noValidate method={"POST"} action={"#"}>
                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Basic Information
                        </Typography>

                        <TextField
                            className={classes.textField}
                            label="Full Name"
                            margin="dense"
                            required
                            value={values.fullName}
                            id={"driver_name"}
                            fullWidth
                            // defaultValue={this.props.driver.driver_name}
                            onChange={event =>
                                this.handleFieldChange("fullName", event.target.value)
                            }
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="Mobile"
                            margin="dense"
                            type="number"
                            value={values.mobile}
                            fullWidth
                            id={"mobile_number"}
                            onChange={event =>
                                this.handleFieldChange("mobile", event.target.value)
                            }
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="Email"
                            margin="dense"
                            type="email"
                            fullWidth
                            id={"email"}
                            value={values.email}
                            onChange={event =>
                                this.handleFieldChange("email", event.target.value)
                            }
                            variant="outlined"
                        />
                        <FormControl className={classes.textField} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={this.state.viewPass ? "text" : "password"}
                                value={values.password}
                                onChange={event =>
                                    this.handleFieldChange("password", event.target.value)
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            edge="end"
                                        >
                                            {this.state.viewPass ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </div>

                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Legal Details
                        </Typography>
                        <TextField
                            className={classes.textField}
                            // helperText="Please specify the Pump Name"
                            label="User name"
                            margin="dense"
                            required
                            value={values.user_name}
                            fullWidth
                            id={"user_name"}
                            onChange={event =>
                                this.handleFieldChange("user_name", event.target.value)
                            }
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="License Number"
                            onChange={event =>
                                this.handleFieldChange("licenseNumber", event.target.value)
                            }
                            margin="dense"
                            id={"license_number"}
                            value={values.licenseNumber}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="Insurance"
                            margin="dense"
                            type="text"
                            id={"insurance"}
                            inputProps={{maxLength: 30}}
                            value={values.insurance}
                            onChange={event =>
                                this.handleFieldChange("insurance", event.target.value)
                            }
                            variant="outlined"
                        />
                    </div>

                    <div className={classes.field}>
                        {this.props.operatorModificationIsLoading ? (
                            <CircularProgress className={classes.progress}/>
                        ) : this.props.operatorModificationSuccess ? (
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_partner_submit"
                                id="create_partner_submit"
                                // disabled={!this.state.isValid}
                                onClick={e => this.redirectOnSuccess(e)}
                                size="large"
                                variant="contained"
                            >
                                GO TO ALL OPERATORS
                            </Button>
                        ) : (
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_partner_submit"
                                id="create_partner_submit"
                                // disabled={!this.state.isValid}
                                onClick={e => this.handleSubmit(e)}
                                size="large"
                                variant="contained"
                            >
                                Update Operator
                            </Button>
                        )}
                        {this.props.operatorModificationSuccess ? (
                            <Typography className={classes.fieldPassed} variant="body2">
                                {this.props.operatorModificationSuccessMessage}
                            </Typography>
                        ) : (
                            ""
                        )}
                        {this.props.operatorModificationError ? (
                            this.props.operatorModificationSuccess ? (
                                ""
                            ) : (
                                <Typography className={classes.fieldError} variant="body2">
                                    Email or Mobile already Exists! please choose another email or
                                    mobile.
                                </Typography>
                            )
                        ) : (
                            ""
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

EditDriver.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingPartner: state.isLoadingPartner,
        operatorModificationIsLoading: state.operatorModificationIsLoading,
        operatorModificationError: state.operatorModificationError,
        operatorModificationSuccess: state.operatorModificationSuccess,
        operatorModificationSuccessMessage: state.operatorModificationSuccessMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleEditOperator: (accessToken, operatorForm, partnerId) => {
            dispatch(editOperator(accessToken, operatorForm, partnerId));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(EditDriver));
