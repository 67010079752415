import {Button, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {AddDatumInfo, getAllLeads} from "actions/actions.js";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const AddDatumDetails = props => {
    const {stateData, listLeads, handleDrawerClose, param, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [formData, setFormData] = useState({
        id: listLeads?.id,
        // product:listLeads?.product,
        datum_type: listLeads?.datum_type ? listLeads?.datum_type : "X",
        datum_variant: listLeads?.datum_variant,
        datum_capacity: listLeads?.datum_capacity,
        reason_for_buying: listLeads?.reason_for_buying
            ? listLeads?.reason_for_buying
            : "Diesel Purchase Data",
        requirement_comment: listLeads?.requirement_comment
    });

    const handleInputChange = event => {
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value,
            datum_variant:
                value === "Z" ? "Z-3" : value === "Y" ? "NA" : formData?.datum_variant
        });
    };

    const handleInputVarient = event => {
        setFormData({
            ...formData,
            datum_variant: event.target.value
        });
    };

    const getDatumVariantOptions = () => {
        switch (formData.datum_type) {
            case "X":
                return [
                    <option key="1" value="300">
                        300 Lt
                    </option>,
                    <option key="2" value="500">
                        500 Lt
                    </option>,
                    <option key="3" value="1000">
                        1000 Lt
                    </option>,
                    <option key="4" value="2000">
                        2000 Lt
                    </option>
                ];
            case "F":
                return [
                    <option key="5" value="FM">
                        FM
                    </option>,
                    <option key="6" value="DU">
                        DU
                    </option>
                ];
            case "Z":
                return [
                    <option key="7" value="Z1">
                        Z-1
                    </option>,
                    <option key="8" value="Z2">
                        Z-2
                    </option>,
                    <option key="9" value="Z3">
                        Z-3
                    </option>
                ];
            default:
                return [
                    <option key="5" value="FM">
                        FM
                    </option>,
                    <option key="6" value="DU">
                        DU
                    </option>
                ];
        }
    };

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleSubmit = () => {
        dispatch(
            AddDatumInfo(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar,
                handleDrawerClose,
                getLeadList
            )
        );
    };

    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForDatumX = [
            "requirement_comment",
            "datum_type",
            "datum_variant",
            "reason_for_buying"
        ];

        const requiredFieldsForDatum = [
            "requirement_comment",
            "datum_type",
            "datum_capacity",
            "reason_for_buying"
        ];
        if (formData.datum_type === "F" || formData.datum_type === "X") {
            return requiredFieldsForDatumX.every(field => formData[field]);
        } else {
            return requiredFieldsForDatum.every(field => formData[field]);
        }
    };

    /************************* ************ ****************************/

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            {listLeads.product !== 4 ? (
                <>
                    {secureLocalStorage.getItem("department") === "11" ||
                    secureLocalStorage.getItem("department") === "12" ? (
                        <TextField
                            className={classes.textField}
                            label={"Datum Type"}
                            margin="dense"
                            fullWidth
                            value={formData.datum_type}
                            onChange={handleInputChange}
                            // required
                            id={"datum_type"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option value={"X"}>Datum X</option>
                            <option value={"Y"}>Datum Y</option>
                            <option value={"Z"}>Datum Z</option>
                        </TextField>
                    ) : (
                        <TextField
                            className={classes.textField}
                            label={"Datum Type"}
                            margin="dense"
                            fullWidth
                            value={formData.datum_type}
                            onChange={handleInputChange}
                            // required
                            id={"datum_type"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            {/* value={this.state.partner_type}*/}
                            {/* <option key={0} value={""}></option>  */}
                            {/* <option value="">Select Datum Type</option> */}
                            <option value={"F"}>Datum F</option>
                            <option value={"X"}>Datum X</option>
                            <option value={"Y"}>Datum Y</option>
                            <option value={"Z"}>Datum Z</option>
                        </TextField>
                    )}
                    {(formData.datum_type === "F" || formData.datum_type === "X") && (
                        <>
                            <Typography>Datum Variant</Typography>
                            <TextField
                                className={classes.textField}
                                // label={"Datum Variant"}
                                margin="dense"
                                fullWidth
                                value={formData.datum_variant}
                                onChange={handleInputVarient}
                                // required
                                id={"datum_variant"}
                                select
                                SelectProps={{native: true}}
                                variant="outlined"
                            >
                                {/* value={this.state.partner_type} */}
                                {/* <option key={0} value={""}></option> */}
                                <option value="">Select Datum Variant</option>
                                {getDatumVariantOptions()}
                            </TextField>{" "}
                        </>
                    )}
                    <TextField
                        className={classes.textField}
                        label="Datum Capacity"
                        margin="dense"
                        fullWidth
                        value={formData.datum_capacity}
                        onChange={handleInputChange}
                        id="datum_capacity"
                        select
                        SelectProps={{native: true}}
                        variant="outlined"
                        style={{
                            display:
                                formData.datum_type === "Z" || formData.datum_type === "Y"
                                    ? "block"
                                    : "none"
                        }}
                    >
                        {formData.datum_type === "Z" || formData.datum_type === "Y" ? (
                            <>
                                <option key="10" value="0">
                                    Select Capacity
                                </option>
                                <option key="10" value="1">
                                    1 Kl
                                </option>
                                <option key="11" value="2">
                                    2 Kl
                                </option>
                            </>
                        ) : null}
                    </TextField>
                    {listLeads.product === 2 && (
                        <TextField
                            className={classes.textField}
                            label={"Reason For Buying"}
                            margin="dense"
                            fullWidth
                            value={formData.reason_for_buying}
                            onChange={handleInputChange}
                            // required
                            id={"reason_for_buying"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option value={"Diesel Purchase Data"}>
                                Diesel Purchase Data
                            </option>
                            <option value={"Storage"}>Storage</option>
                            <option value={"Theft"}>Theft</option>
                            <option value={"Safeguard Customer"}>Safeguard Customer</option>
                            <option value={"Authorised Dispensing"}>
                                Authorised Dispensing
                            </option>
                            <option value={"Live Traking Of Datum"}>
                                Live Traking Of Datum
                            </option>
                            <option value={"Sub-Asset Visiblity"}>Sub-Asset Visiblity</option>
                        </TextField>
                    )}
                </>
            ) : (
                <TextField
                    label="Capacity"
                    margin="dense"
                    id="datum_capacity"
                    fullWidth
                    value={formData.datum_capacity}
                    onChange={handleInputChange}
                    variant="outlined"
                    type={"number"}
                ></TextField>
            )}
            <TextField
                label="Comment"
                margin="dense"
                id="requirement_comment"
                fullWidth
                value={formData?.requirement_comment}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={3}
            />
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleSubmit}
                disabled={!isFormValid()}
            >
                Save
            </Button>
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleDrawerClose}
                // disabled={!isFormValid()}
            >
                Cancel
            </Button>
        </div>
    );
};
export default compose(withStyles(styles))(AddDatumDetails);
