import React, {Component} from "react";

// Externals
import validate from "validate.js";
import _ from "underscore";

// Material components
import {Button, FormControlLabel, Switch, TextField, Typography, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";
import {createUser, editUser, getRegionsAndState, getStateByTerritory} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import reposWebApi from "../../../../actions/configuration";
import Grid from "@material-ui/core/Grid";
import {withSnackbar} from "notistack";
import Tooltip from "@material-ui/core/Tooltip";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";
import {Autocomplete} from "@mui/material";

class AddUser extends Component {
    state = {
        success: false,
        values: {
            id: this.props?.user?.id,
            email: this.props?.user?.email,
            user_name: this.props?.user?.name,
            accessTypeSelected: this.props?.user?.access_type ? 1 : 0,
            departmentSelected: this.props?.user?.department,
            mobile: this.props?.user?.mobile,
            zoneSelected: this.props?.user?.zone,
            regionSelected: this.props?.user?.region,
            department: this.props?.user?.department,
            access_type: this.props?.user?.access_type,
            region: [],
            crmToggled: this.props?.user?.is_crm ? 1 : 0,
            state_area: this.props?.user?.state_name,
            territory: this.props?.user?.territory,
            rank: this.props?.user?.rank,
            reporting_to: this.props?.user?.reporting_to,
            repos_id: this.props?.user?.repos_id,
            stateList : this?.props?.user?.state_list
        },
        touched: {
            mobile: false,
            email: false,
            user_name: false,
            accessTypeSelected: false,
            zoneSelected: false,
            regionSelected: false,
            departmentSelected: false,
            crmToggled: false,
            repos_id: false,
            rank: false,
            state_area: false,
            territory: false,
            reporting_to: false
        },
        errors: {
            mobile: null,
            email: null,
            user_name: null,
            accessTypeSelected: null,
            regionSelected: null,
            departmentSelected: null,
            repos_id: null,
            rank: null,
            state_area: null,
            territory: null,
            reporting_to: null
        },
        isValid: false,
        isLoading: false,
        submitError: false,
        selectedUser: null,
        zones: [],
        stateArea: [],
        territory: [],
        reportingTo: []
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;
        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = !errors;

        this.setState(newState);
    }, 300);

    isFormValid = () => {
        const {values} = this.state;

        const requiredFieldsForCRM = [
            "user_name",
            "mobile",
            "email",
            // "repos_id",
            "rank",
            // "state_area",
            // "territory",
            "reporting_to"
        ];

        const nonCrmUsers = [
            "user_name",
            "mobile",
            "email"
            // "state_area",
            // "territory",
        ];

        if (values.crmToggled === 1) {
            return requiredFieldsForCRM.every(field => values[field]);
        } else {
            return nonCrmUsers.every(field => values[field]);
        }
    };

    /***************** this function for get data from users ******************/
    handleFieldChange = (field, value) => {
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
        // if (field === "regionSelected" && value !== "") {
        //     const params = {
        //         region: value,
        //     }
        //     this.props.getStateData(secureLocalStorage.getItem("accessToken"), this.props.enqueueSnackbar, params);
        // }
        // if (field === "state_area" && value !== "") {
        //     const params = {
        //         state_name: value,
        //     }
        //     this.props.getStateByTerritoryData(secureLocalStorage.getItem("accessToken"), this.props.enqueueSnackbar, params);
        //     newState.values[field] = value;
        // }
        if (field === "state_list") {
            const selectedStates = value.map(state => state.key).join(",");
            newState.values[field] = selectedStates;
        } else {
            newState.values[field] = value;
        }
        if (field === "crmToggled") {
            newState.crmToggled = value; // Update CRM status
        }

        this.setState(newState, this.validateForm);

        if (field === "rank" && value !== "") {
            this.getReportingByRank(value);
        }
        if (field === "departmentSelected") {
            newState.submitError = null;
            newState.touched.department = true;
            newState.values.department = value;
            this.setState(newState, this.validateForm);
        }
    };

    /****************************************************************/

    handleSubmit = event => {
        event.preventDefault();
        this.createUser(this.state.values);
        this.handleClose();
        return false;
    };

    handleSubmitEdit = event => {
        event.preventDefault();
        this.editUser();
        return false;
    };

    /**************** get reporting list after selecting rank **************/
    getReportingByRank = rank => {
        reposWebApi
            .get("/customer/crm_user_reporting", {
                params: {
                    rank_id: rank
                },
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                this.setState({
                    reportingTo: decryption(response).data
                });
            })
            .catch(error => {
                if (error.response?.status === 400) {
                } else if (error.response?.status === 406) {
                    alert("Invalid Credentials provided");
                }
                if (error?.response?.status === 500) {
                }
            });
    };

    onTagsChange = (event, values) => {
        event.preventDefault();
        this.setState({
            selectedUser: values.id
        });
    };

    createUser = userFormData => {
        this.props.handlecreateUser(
            secureLocalStorage.getItem("accessToken"),
            userFormData,
            this.props,
            this.state.selectedUser
        );
        return false;
    };

    handleClose = () => {
        this.props.onClose("right", false);
        this.state = {
            success: false
        };
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    editUser = userFormData => {
        this.props.handleEditUser(
            secureLocalStorage.getItem("accessToken"),
            this.state.values,
            this.props
        );
    };

    componentDidMount() {
        // if (this.props.isUpdate) {
        //     this.getRegionsAndState(this.props?.user?.region);
        //     this.getStateByTerritory(this.props?.user?.state);
        // }
    }

    render() {
        const {classes} = this.props;
        const {values, touched, errors} = this.state;


        const departmentList = [
            {
                key: 1,
                name: "Operational Excellence"
            },
            {
                key: 2,
                name: "OE Partner Onboarding"
            },
            {
                key: 3,
                name: "OE Order Fullfillment"
            },
            {
                key: 4,
                name: "Bussiness Associates"
            },
            {
                key: 5,
                name: "Admin"
            },
            {
                key: 6,
                name: "Business Development"
            },
            {
                key: 7,
                name: "Finance Team"
            },
            {
                key: 8,
                name: "Tech Team"
            },
            {
                key: 9,
                name: "Service Engineer"
            },
            {
                key: 10,
                name: "Founders Office"
            },
            {
                key: 11,
                name: "Cummins Ho"
            },
            {
                key: 12,
                name: "Cummins Dealer"
            }
        ];

        const ranking = [
            {
                key: 1,
                name: "Admin"
            },
            {
                key: 2,
                name: "CEO"
            },
            {
                key: 3,
                name: "Founders Office"
            },
            {
                key: 14,
                name: "BA Lead"
            },
            {
                key: 4,
                name: "State Head "
            },
            {
                key: 5,
                name: "State Lead"
            },
            {
                key: 6,
                name: "Bussiness Associates"
            },
            {
                key: 7,
                name: "Territory Head"
            },
            {
                key: 8,
                name: "Territory Lead"
            },
            {
                key: 9,
                name: "Territory Associate"
            },
            {
                key: 10,
                name: "Area Sales Associate"
            },
            {
                key: 11,
                name: "Sr. General Manager-Cummins"
            },
            {
                key: 12,
                name: "Business Development Manager-Cummins"
            },
            {
                key: 13,
                name: "Cummins Dealer"
            }
        ];

        const indianStates = [
            {key: "AN", name: "ANDAMAN AND NICOBAR ISLANDS"},
            {key: "AP", name: "ANDHRA PRADESH"},
            {key: "AR", name: "ARUNACHAL PRADESH"},
            {key: "AS", name: "ASSAM"},
            {key: "BR", name: "BIHAR"},
            {key: "CT", name: "CHHATTISGARH"},
            {key: "CH", name: "CHANDIGARH"},
            {key: "DD", name: "DAMN AND DIU"},
            {key: "DL", name: "DELHI"},
            {key: "DN", name: "DADRA AND NAGAR HAVELI"},
            {key: "GA", name: "GOA"},
            {key: "GJ", name: "GUJARAT"},
            {key: "HR", name: "HARYANA"},
            {key: "HP", name: "HIMACHAL PRADESH"},
            {key: "JH", name: "JHARKHAND"},
            {key: "JK", name: "JAMMU AND KASHMIR"},
            {key: "KA", name: "KARNATAKA"},
            {key: "KL", name: "KERALA"},
            {key: "LD", name: "LAKSHADWEEP"},
            {key: "MH", name: "MAHARASHTRA"},
            {key: "MP", name: "MADHYA PRADESH"},
            {key: "MN", name: "MANIPUR"},
            {key: "MZ", name: "MIZORAM"},
            {key: "NL", name: "NAGALAND"},
            {key: "OD", name: "ODISHA"},
            {key: "PB", name: "PUNJAB"},
            {key: "PY", name: "PONDICHERRY"},
            {key: "RJ", name: "RAJASTHAN"},
            {key: "SK", name: "SIKKIM"},
            {key: "TN", name: "TAMIL NADU"},
            {key: "TR", name: "TRIPURA"},
            {key: "TS", name: "TELANGANA"},
            {key: "UK", name: "UTTARAKHAND"},
            {key: "UP", name: "UTTAR PRADESH"},
            {key: "WB", name: "WEST BENGAL"},
            {key: "ML", name: "MEGHALAYA"},
            {key: "LA", name: "LADAKH"}
        ];

        const showMobileError = touched.mobile && errors.mobile;
        const showEmailError = touched.email && errors.email;
        const showUserNameError = touched.user_name && errors.user_name;
        const showZoneError = touched.zone && errors.zone;
        const showRegionError = touched.region && errors.region;
        const showDepartmentError = touched.department && errors.department;
        const showReposIdError = touched.repos_id && errors.repos_id;
        const showRankError = touched.rank && errors.rank;
        const showStateAreaError = touched.state_area && errors.state_area;
        const showTerritoryError = touched.territory && errors.territory;
        const showReportingToError = touched.reporting_to && errors.reporting_to;

        return (
            <div className={classes.root}>
                <form autoComplete="off" noValidate method={"POST"} action={"#"}>
                    <div className={classes.field}>
                        <TextField
                            disabled={this.props.isUpdate}
                            className={classes.textField}
                            label="Name"
                            margin="dense"
                            fullWidth
                            type="user_name"
                            id={"user_name"}
                            value={values.user_name}
                            onChange={event =>
                                this.handleFieldChange("user_name", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showUserNameError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.user_name[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            disabled={this.props.isUpdate}
                            className={classes.textField}
                            inputProps={{
                                className: classes.textField,
                                maxlength: 10
                            }}
                            label="Mobile"
                            margin="dense"
                            type="number"
                            fullWidth
                            value={values.mobile}
                            id={"mobile_number"}
                            onChange={event =>
                                this.handleFieldChange(
                                    "mobile",
                                    (event.target.value = Math.max(
                                        0,
                                        parseInt(event.target.value)
                                    )
                                        .toString()
                                        .slice(0, 10))
                                )
                            }
                            variant="outlined"
                        />
                        <div>
                            {showMobileError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.mobile[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            disabled={this.props.isUpdate}
                            className={classes.textField}
                            label="Company Email"
                            margin="dense"
                            fullWidth
                            type="email"
                            id={"email"}
                            value={values.email}
                            onChange={event =>
                                this.handleFieldChange("email", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showEmailError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.email[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label={"Department List"}
                            margin="dense"
                            fullWidth
                            onChange={event =>
                                this.handleFieldChange("departmentSelected", event.target.value)
                            }
                            // required
                            id={"select_department"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                            value={values.departmentSelected}
                        >
                            <option key={0} value={""}></option>
                            {/* ))} */}
                            {departmentList.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                        <div>
                            {showDepartmentError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.department[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label={"Access type"}
                            margin="dense"
                            fullWidth
                            value={values.accessTypeSelected}
                            onChange={event =>
                                this.handleFieldChange("accessTypeSelected", event.target.value)
                            }
                            // required
                            id={"access_type"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            <option value={0}> Read Only</option>
                            <option value={1}>Read & Write</option>
                        </TextField>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values.crmToggled === 1}
                                    onChange={event =>
                                        this.handleFieldChange(
                                            "crmToggled",
                                            event?.target?.checked ? 1 : 0
                                        )
                                    }
                                    // Add any other switch props you need
                                />
                            }
                            label="CRM"
                        />
                        {values.crmToggled && (
                            <>
                                {values.departmentSelected !== 11 &&
                                values.departmentSelected !== 12 ? (
                                    <>
                                        <TextField
                                            className={classes.textField}
                                            label="Repos ID"
                                            margin="dense"
                                            fullWidth
                                            required
                                            //   type="user_name"
                                            id={"repos_id"}
                                            value={values.repos_id}
                                            onChange={event =>
                                                this.handleFieldChange("repos_id", event.target.value)
                                            }
                                            variant="outlined"
                                        />
                                        <div>
                                            {showReposIdError && (
                                                <Typography
                                                    className={classes.fieldError}
                                                    variant="body2"
                                                >
                                                    {errors.repos_id[0]}
                                                </Typography>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                                <TextField
                                    className={classes.textField}
                                    label={"Select Rank"}
                                    id={"rank"}
                                    margin="dense"
                                    onChange={event =>
                                        this.handleFieldChange("rank", event.target.value)
                                    }
                                    required
                                    fullWidth
                                    select
                                    SelectProps={{native: true}}
                                    variant="outlined"
                                    value={values.rank}
                                >
                                    <option key={0} value={""}></option>
                                    {ranking?.map(option => (
                                        <option key={option.key} value={option.key}>
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                                <div>
                                    {showRankError && (
                                        <Typography className={classes.fieldError} variant="body2">
                                            {errors.rank[0]}
                                        </Typography>
                                    )}
                                </div>
                                {this.state.touched.rank ? (
                                    <TextField
                                        className={classes.textField}
                                        label={"Reporting To"}
                                        id={"reporting_to"}
                                        margin="dense"
                                        onChange={event =>
                                            this.handleFieldChange("reporting_to", event.target.value)
                                        }
                                        // required
                                        fullWidth
                                        select
                                        SelectProps={{native: true}}
                                        variant="outlined"
                                        value={values.reporting_to}
                                    >
                                        <option key={0} value={""}></option>
                                        {this.state?.reportingTo?.map(option => (
                                            <option key={option.name} id={option} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                ) : (
                                    <Tooltip title="Please Select Rank ">
                                        <TextField
                                            className={classes.textField}
                                            label={"Reporting To"}
                                            id={"reporting_to"}
                                            margin="dense"
                                            fullWidth
                                            disabled={true}
                                            variant="outlined"
                                            value={values.reporting_to}
                                        />
                                    </Tooltip>
                                )}
                                <div>
                                    {showReportingToError && (
                                        <Typography className={classes.fieldError} variant="body2">
                                            {errors.reporting_to[0]}
                                        </Typography>
                                    )}
                                </div>
                            </>
                        )}

                        {/*<TextField*/}
                        {/*    className={classes.textField}*/}
                        {/*    label={"Select Region"}*/}
                        {/*    id={"select_region"}*/}
                        {/*    margin="dense"*/}
                        {/*    onChange={(event) =>*/}
                        {/*        this.handleFieldChange("regionSelected", event.target.value)*/}
                        {/*    }*/}
                        {/*    required*/}
                        {/*    fullWidth*/}
                        {/*    select*/}
                        {/*    SelectProps={{native: true}}*/}
                        {/*    variant="outlined"*/}
                        {/*    value={values.regionSelected}*/}
                        {/*>*/}

                        {/*    <option key={0} value={""}></option>*/}
                        {/*    {region.map((option) => (*/}
                        {/*        <option key={option} id={option} value={option}>*/}
                        {/*            {option}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</TextField>*/}
                        {/*<div>*/}
                        {/*    {showRegionError && (*/}
                        {/*        <Typography className={classes.fieldError} variant="body2">*/}
                        {/*            {errors.regionSelected[0]}*/}
                        {/*        </Typography>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*{this.state.touched?.regionSelected ?*/}

                        <Autocomplete
                            multiple
                            id="state_list"
                            options={indianStates}
                            getOptionLabel={option => option.name}
                            value={values.state_list}
                            onChange={(event, newValue) =>
                                this.handleFieldChange("state_list", newValue)
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select State"
                                    placeholder="Select State"
                                    margin="dense"
                                    required
                                    fullWidth
                                />
                            )}
                        />
                        {/*:*/}
                        {/*<Tooltip title="Please Select Region">*/}
                        {/*    <TextField*/}
                        {/*        className={classes.textField}*/}
                        {/*        label={"Select State"}*/}
                        {/*        id={"reporting_to"}*/}
                        {/*        margin="dense"*/}
                        {/*        fullWidth*/}
                        {/*        disabled={true}*/}
                        {/*        variant="outlined"*/}
                        {/*        value={values.state_area}*/}
                        {/*    />*/}
                        {/*</Tooltip>*/}
                        {/*}*/}
                        <div>
                            {showStateAreaError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.state_area[0]}
                                </Typography>
                            )}
                        </div>
                        {/*{this.state.touched.state_area ?*/}
                        {/*    <TextField*/}
                        {/*        className={classes.textField}*/}
                        {/*        label={"Select Territory"}*/}
                        {/*        id={"territory"}*/}
                        {/*        margin="dense"*/}
                        {/*        onChange={(event) =>*/}
                        {/*            this.handleFieldChange("territory", event.target.value)*/}
                        {/*        }*/}
                        {/*        required*/}
                        {/*        fullWidth*/}
                        {/*        select*/}
                        {/*        SelectProps={{native: true}}*/}
                        {/*        variant="outlined"*/}
                        {/*        value={values.territory}*/}
                        {/*    >*/}
                        {/*        <option key={0} value={""}></option>*/}
                        {/*        {this.props.getTerritories.map((option) => (*/}
                        {/*            <option key={option} id={option} value={option}>*/}
                        {/*                {option}*/}
                        {/*            </option>*/}
                        {/*        ))}*/}
                        {/*    </TextField> :*/}
                        {/*    <Tooltip title="Please Select Territory">*/}
                        {/*        <TextField*/}
                        {/*            className={classes.textField}*/}
                        {/*            label={"Select Territory"}*/}
                        {/*            id={"reporting_to"}*/}
                        {/*            margin="dense"*/}
                        {/*            fullWidth*/}
                        {/*            disabled={true}*/}
                        {/*            variant="outlined"*/}
                        {/*            value={values.territory}*/}
                        {/*        />*/}
                        {/*    </Tooltip>*/}
                        {/*}*/}
                        {/*<div>*/}
                        {/*    {showTerritoryError && (*/}
                        {/*        <Typography className={classes.fieldError} variant="body2">*/}
                        {/*            {errors.territory[0]}*/}
                        {/*        </Typography>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                    {this.props.isUpdate ? (
                        <div className={classes.field}>
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_user_submit"
                                id="create_user_submit"
                                onClick={e => this.handleSubmitEdit(e)}
                                size="large"
                                variant="contained"
                            >
                                Modify User
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.field}>
                            {/* {this.props.userCreationIsLoading ? (
                                    <CircularProgress className={classes.progress}/>
                                ) : ( */}
                            <Grid container>
                                <Grid item xs={5}>
                                    <Button
                                        className={classes.addNewPartnerButton}
                                        color="primary"
                                        name="create_user_submit"
                                        id="create_newUser_submit"
                                        disabled={!this.isFormValid()}
                                        onClick={e => this.handleSubmit(e)}
                                        size="large"
                                        variant="contained"
                                    >
                                        Add New
                                    </Button>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                    <Button
                                        className={classes.addNewPartnerButton}
                                        color="inherit"
                                        name="create_user_submit"
                                        id="close_submit"
                                        // disabled={!this.state.isValid}
                                        onClick={e => this.handleClose(e)}
                                        size="large"
                                        variant="contained"
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                            {/* )} */}
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

AddUser.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingUser: state.isLoadingUser,
        getTerritories: state.getTerritories,
        getStateOfIndia: state.getStateOfIndia,
        userCreationIsLoading: state.userCreationIsLoading,
        userCreationError: state.userCreationError,
        userCreationSuccess: state.userCreationSuccess,
        userCreationSuccessMessage: state.userCreationSuccessMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleEditUser: (accessToken, userForm, snackbar) => {
            dispatch(editUser(accessToken, userForm, snackbar));
        },
        handlecreateUser: (accessToken, userForm, snackbar) => {
            dispatch(createUser(accessToken, userForm, snackbar));
        },
        getStateByTerritoryData: (accessToken, enqueueSnackbar, param) => {
            dispatch(getStateByTerritory(accessToken, enqueueSnackbar, param));
        },
        getStateData: (accessToken, enqueueSnackbar, param) => {
            dispatch(getRegionsAndState(accessToken, enqueueSnackbar, param));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AddUser)));
