import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
import classNames from "classnames";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";

// Material icons
import RefreshIcon from "@mui/icons-material/Refresh";
// Shared components
import {SearchInput} from "components";
import Grid from "@material-ui/core/Grid";

// Component styles
import styles from "./styles";
import {getDatumAnalytics, getOrderStatus} from "actions/actions";
import {connect} from "react-redux";
import {compose} from "underscore";
import clsx from "clsx";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: "",
            value: 0,
            isHovered: false,
            state: "",
            selectedButtonIndex: null
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onSearch(value) {
        this.setState({
            selectedValue: value
        });
        value.replace(" ", "");
        if (value.length >= 3 || value.length === 0) {
            // if (value.length >= 3){
            this.props.searchedTerm(value);
        }
    }

    onFilter = event => {
        this.props.handleSetFilter(event.target.value);
        this?.props?.filterByState(event.target.value);
        this.props.tabClick === 0
            ? this.getAllOrderStatus(event.target.value)
            : this.getAllDatumAnalytics(event.target.value);
    };

    onSelect(value) {
        const {onSelectedCustomer} = this.props;
        onSelectedCustomer(value);
    }

    handlecheckbox = event => {
        const {handleAutorefresh} = this.props;

        handleAutorefresh(event?.target?.checked, "checkBox");
    };

    refreshData = refresh => {
        const {refreshData} = this.props;
        refreshData(refresh);
    };

    getAllOrderStatus = state => {
        this.props.handlegetOrderStatus(
            secureLocalStorage.getItem("accessToken"),
            state
        );
    };

    getAllDatumAnalytics = state => {
        this.props.handlegetDatumAnalytics(
            secureLocalStorage.getItem("accessToken"),
            state
        );
    };

    componentDidMount() {
        this.getAllOrderStatus();
        this.getAllDatumAnalytics();
    }

    onFromDate = e => {
        const {selectFromDate} = this.props;
        selectFromDate(e.target.value);

        this.setState({
            state: e.target.value
        });
    };

    onToDate = e => {
        const {selectToDate} = this.props;
        selectToDate(e.target.value);
        this.setState({
            state: e.target.value
        });
    };

    onDatumStatus = (e, datumStatus) => {
        const {DatumStatus} = this.props;
        DatumStatus(datumStatus);
        this.setState({
            DatumStatus: datumStatus
        });
        this.setState({selectedButtonIndex: datumStatus});
    };

    onOrderStatus = (e, status) => {
        const {OrderStatus} = this.props;
        OrderStatus(status);
        this.setState({
            OrderStatus: status
        });
    };

    showinfo = () => {
    };

    render() {
        const {classes, className} = this.props;
        const {selectedButtonIndex} = this.state;
        const {orderStatus} = this.props;
        const {datumAnalytics} = this.props;
        const {isHovered} = this.state;
        const rootClassName = classNames(classes.root, className);

        // const exportCSV = () => {
        //   var csvRow =[];
        //   var A = [['Vehicle NNumber','Last Location', 'Last Stock', 'Last Updated At', 'Partner', 'Driver', 'Refueler Model', 'Tracker Device Id', 'Model Type', ' CarIQ', 'CarIQ Device ID', 'Current Rate', 'Current Density']];
        //   var allRefuellerData = this.props.allRefuellerData;
        //
        //   var firstNameForFileName = '';
        //   var lastNameForFileName = '';
        //
        //   for (var item =0; item < allRefuellerData.length; item++){
        //     A.push([item, allRefuellerData[item].vehicle_registration_number, allRefuellerData[item].last_stock,allRefuellerData[item].last_feed_at,allRefuellerData[item].partner_name,allRefuellerData[item].driver,allRefuellerData[item].refueler_model,allRefuellerData[item].tracker_device, allRefuellerData[item].model_type, allRefuellerData[item].is_cariq_activated, allRefuellerData[item].cariq, allRefuellerData[item].current_rate, allRefuellerData[item].current_density])
        //     firstNameForFileName = allRefuellerData[0].vehicle_registration_number;
        //     lastNameForFileName  = allRefuellerData[allRefuellerData.length-1].vehicle_registration_number;
        //   }
        //
        //   for (var i=0; i< A.length; ++i){
        //     csvRow.push(A[i].join(","))
        //   }
        //
        //   var today = new Date();
        //   var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        //   var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //   var dateTime = date+'_'+time;
        //
        //   var csvString = csvRow.join("%0A");
        //
        //   var a = document.createElement("a");
        //
        //   a.href='data:attachement/csv,' + csvString;
        //
        //   a.target ="_Blank";
        //
        //   a.download= "Refueller List" + firstNameForFileName + "_to_" + lastNameForFileName+ "at"+ dateTime + ".csv";
        //
        //   document.body.appendChild(a);
        //
        //   a.click();
        //
        // };

        const state = [
            {
                value: "AN",
                name: "ANDAMAN AND NICOBAR ISLANDS"
            },
            {
                value: "AP",
                name: "ANDHRA PRADESH"
            },
            {
                value: "AR",
                name: "ARUNACHAL PRADESH"
            },
            {
                value: "AS",
                name: "ASSAM"
            },
            {
                value: "BR",
                name: "BIHAR"
            },
            {
                value: "CT",
                name: "CHHATTISGARH"
            },
            {
                value: "CH",
                name: "CHANDIGARH"
            },
            {
                value: "DD",
                name: "DAMAN AND DIU"
            },
            {
                value: "DL",
                name: "DELHI"
            },
            {
                value: "DN",
                name: "DADRA AND NAGAR HAVELI"
            },
            {
                value: "GA",
                name: "GOA"
            },
            {
                value: "GJ",
                name: "GUJARAT"
            },
            {
                value: "HR",
                name: "HARYANA"
            },
            {
                value: "HP",
                name: "HIMACHAL PRADESH"
            },
            {
                value: "JH",
                name: "JHARKHAND"
            },
            {
                value: "JK",
                name: "JAMMU AND KASHMIR"
            },
            {
                value: "KA",
                name: "KARNATAKA"
            },
            {
                value: "KL",
                name: "KERALA"
            },
            {
                value: "LD",
                name: "LAKSHADWEEP"
            },
            {
                value: "LA",
                name: "LADAKH"
            },
            {
                value: "MH",
                name: "MAHARASHTRA"
            },
            {
                value: "MP",
                name: "MADHYA PRADESH"
            },
            {
                value: "MN",
                name: "MANIPUR"
            },
            {
                value: "ML",
                name: "MEGHLAYA"
            },
            {
                value: "MZ",
                name: "MIZORAM"
            },
            {
                value: "NL",
                name: "NAGALAND"
            },
            {
                value: "OD",
                name: "ODISHA"
            },
            {
                value: "PB",
                name: "PUNJAB"
            },
            {
                value: "PY",
                name: "PUDUCHERRY"
            },
            {
                value: "RJ",
                name: "RAJASTHAN"
            },
            {
                value: "SK",
                name: "SIKKIM"
            },
            {
                value: "TN",
                name: "TAMIL NADU"
            },
            {
                value: "TR",
                name: "TRIPURA"
            },
            {
                value: "TS",
                name: "TELANGANA"
            },
            {
                value: "UK",
                name: "UTTARAKHAND"
            },
            {
                value: "UP",
                name: "UTTAR PRADESH"
            },
            {
                value: "WB",
                name: "WEST BENGAL"
            }
        ];
        return (
            <div className={rootClassName}>
                <div>
                    <Box>
                        <Tabs
                            value={this.props.tabClick}
                            onChange={this.props.handleChangeTab}
                        >
                            <Tab label="All Datum orders" value={0}/>
                            <Tab label="Datum assets" value={1}/>
                        </Tabs>
                    </Box>
                </div>
                <Grid container spacing={3} style={{marginTop: "1%"}}>
                    <Grid item xs={12} md={6} sx={4} spacing={3}>
                        <Grid item xs={12} md={12}>
                            <div className={classes.row}>
                                <SearchInput
                                    isDatum={true}
                                    className={classes.searchInput}
                                    placeholder={
                                        this.props.tabClick === 0
                                            ? "Search by Customer name"
                                            : "Search by Customer or asset name"
                                    }
                                    getsearchterm={this.props?.searchedTerm}
                                    searchValue={this?.props?.searchString}
                                />

                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    size={"small"}
                                    fullWidth
                                    background="white"
                                >
                                    <InputLabel style={{color: "#C8C7C7"}}>
                                        Search By State Name
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.props.onFilter}
                                        onChange={this.onFilter}
                                        fullWidth
                                        label="Search By State Name"
                                        style={{backgroundColor: "white"}}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {state.map(singleStatus => (
                                            <MenuItem value={singleStatus.value}>
                                                {singleStatus.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} style={{marginTop: "1%"}}>
                            <div className={classes.row}>
                                <TextField
                                    size={"small"}
                                    id="date"
                                    label="From"
                                    type="date"
                                    onChange={event => this.onFromDate(event)}
                                    style={{marginRight: "10px"}}
                                    className={classes.textField}
                                    variant={"outlined"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    size={"small"}
                                    id="date"
                                    label="To"
                                    type="date"
                                    className={classes.textField}
                                    onChange={event => this.onToDate(event)}
                                    variant={"outlined"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.row} style={{alignItems: "right"}}>
                            <span className={classes.spacer1}/>
                            <FormControlLabel
                                control={<Checkbox onChange={this.handlecheckbox}/>}
                                labelPlacement="start"
                                label="Auto Refresh"
                                variant="outlined"
                            />
                            <span className={classes.spacer2}/>
                            <Tooltip title="Page Refresh" aria-label="page_refresh">
                                <Button>
                                    <RefreshIcon
                                        style={{
                                            width: "1.5em",
                                            height: "1.5em",
                                            color: "#00a7bc"
                                        }}
                                        onClick={this.refreshData}
                                    />
                                </Button>
                            </Tooltip>
                            <span className={classes.spacer}/>
                        </div>
                    </Grid>
                </Grid>
                {this.props?.tabClick === 0 ? (
                    <Grid
                        alignItems="center"
                        container
                        spacing={2}
                        style={{marginTop: "1%"}}
                    >
                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className)}
                                onClick={event => this.onOrderStatus(event)}
                            >
                                <div justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color: "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {orderStatus?.total_orders}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline" align="center">
                                                Total Orders
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className)}
                                onClick={event => this.onOrderStatus(event, 1)}
                            >
                                <div justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color: "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {orderStatus?.on_going}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline" align="center">
                                                Ongoing Orders
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className)}
                                onClick={event => this.onOrderStatus(event, 5)}
                            >
                                <div justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color: "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {orderStatus?.dispensing}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline" align="center">
                                                Dispensing Orders{" "}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className)}
                                onClick={event => this.onOrderStatus(event, 2)}
                            >
                                <div justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color: "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {orderStatus?.completed}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline" align="center">
                                                Completed Orders{" "}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className)}
                                onClick={event => this.onOrderStatus(event, 3)}
                            >
                                <div justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box display="flex" justifyContent={"center"}>
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color: "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {orderStatus?.cancled}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline" align="center">
                                                Cancelled Orders{" "}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        alignItems="center"
                        container
                        spacing={2}
                        style={{marginTop: "1%"}}
                    >
                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className, {
                                    [classes.clicked]: selectedButtonIndex === 1
                                })}
                                onClick={event => this.onDatumStatus(event, 1)}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent={"center"}
                                        style={{marginTop: "13px"}}
                                    >
                                        <Box>
                                            <Typography
                                                variant={"h4"}
                                                style={{
                                                    color: selectedButtonIndex === 1 ? "red" : "#122E91",
                                                    textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                }}
                                            >
                                                {datumAnalytics?.total_datum_count}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent={"center"}>
                                        <Typography variant="overline" align="center">
                                            Total Datum`s
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className, {
                                    [classes.clicked]: selectedButtonIndex === 2
                                })}
                                onClick={event => this.onDatumStatus(event, 2)}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent={"center"}
                                        style={{marginTop: "13px"}}
                                    >
                                        <Box>
                                            <Typography
                                                variant={"h4"}
                                                style={{
                                                    color: selectedButtonIndex === 2 ? "red" : "#122E91",
                                                    textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                }}
                                            >
                                                {datumAnalytics?.datum_x}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent={"center"}>
                                        <Typography variant="overline" align="center">
                                            Datum X
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className, {
                                    [classes.clicked]: selectedButtonIndex === 3
                                })}
                                onClick={event => this.onDatumStatus(event, 3)}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent={"center"}
                                        style={{marginTop: "13px"}}
                                    >
                                        <Box>
                                            <Typography
                                                variant={"h4"}
                                                style={{
                                                    color: selectedButtonIndex === 3 ? "red" : "#122E91",
                                                    textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                }}
                                            >
                                                {datumAnalytics?.datum_y}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent={"center"}>
                                        <Typography variant="overline" align="center">
                                            Datum Y
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className, {
                                    [classes.clicked]: selectedButtonIndex === 4
                                })}
                                onClick={event => this.onDatumStatus(event, 4)}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent={"center"}
                                        style={{marginTop: "13px"}}
                                    >
                                        <Box>
                                            <Typography
                                                variant={"h4"}
                                                style={{
                                                    color: selectedButtonIndex === 4 ? "red" : "#122E91",
                                                    textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                }}
                                            >
                                                {datumAnalytics?.datum_z}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent={"center"}>
                                        <Typography variant="overline" align="center">
                                            DATUM Z
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} md={2} xl={2}>
                            <Paper
                                elevation={1}
                                className={clsx(classes.hoverBox, className, {
                                    [classes.clicked]: selectedButtonIndex === 10
                                })}
                                onClick={event => this.onDatumStatus(event, 10)}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent={"center"}
                                        style={{marginTop: "13px"}}
                                    >
                                        <Box>
                                            <Typography
                                                variant={"h4"}
                                                style={{
                                                    color: selectedButtonIndex === 10 ? "red" : "#122E91",
                                                    textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                }}
                                            >
                                                {datumAnalytics?.sfd_sfc_count}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent={"center"}>
                                        <Typography variant="overline">SFD</Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid
                            alignItems="center"
                            container
                            spacing={2}
                            style={{marginTop: "1%"}}
                        >
                            <Grid item xs={4} md={2} xl={2}>
                                <Paper
                                    elevation={1}
                                    className={clsx(classes.hoverBox, className, {
                                        [classes.clicked]: selectedButtonIndex === 5
                                    })}
                                    onClick={event => this.onDatumStatus(event, 5)}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color:
                                                            selectedButtonIndex === 5 ? "red" : "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {datumAnalytics?.active_datum_count}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline">Active Datum</Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} md={2} xl={2}>
                                <Paper
                                    elevation={1}
                                    className={clsx(classes.hoverBox, className, {
                                        [classes.clicked]: selectedButtonIndex === 8
                                    })}
                                    onClick={event => this.onDatumStatus(event, 8)}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color:
                                                            selectedButtonIndex === 8 ? "red" : "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {datumAnalytics?.inactive_datum_count_7_days}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline">In-Active</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"} mt={-2}>
                                            <Typography variant="overline">Datum</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"} mt={-2}>
                                            <Typography variant="overline">{"(0-7 days)"}</Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} md={2} xl={2}>
                                <Paper
                                    elevation={1}
                                    className={clsx(classes.hoverBox, className, {
                                        [classes.clicked]: selectedButtonIndex === 7
                                    })}
                                    onClick={event => this.onDatumStatus(event, 7)}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color:
                                                            selectedButtonIndex === 7 ? "red" : "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {datumAnalytics?.inactive_7_to_15_datum_count}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline">In-Active</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"} mt={-2}>
                                            <Typography variant="overline">Datum</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"} mt={-2}>
                                            <Typography variant="overline">
                                                {"(7-15 days)"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} md={2} xl={2}>
                                <Paper
                                    elevation={1}
                                    className={clsx(classes.hoverBox, className, {
                                        [classes.clicked]: selectedButtonIndex === 6
                                    })}
                                    onClick={event => this.onDatumStatus(event, 6)}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        style={{textDecoration: "none", color: "rgb(69, 69, 69)"}}
                                        justifyContent={"center"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            style={{marginTop: "13px"}}
                                        >
                                            <Box>
                                                <Typography
                                                    variant={"h4"}
                                                    style={{
                                                        color:
                                                            selectedButtonIndex === 6 ? "red" : "#122E91",
                                                        textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                    }}
                                                >
                                                    {datumAnalytics?.inactive_datum_count_15_to_30_days}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"}>
                                            <Typography variant="overline">In-Active</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"} mt={-2}>
                                            <Typography variant="overline">Datum</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent={"center"} mt={-2}>
                                            <Typography variant="overline">
                                                {"(15-30 days)"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} md={2} xl={2}>
                                <Tooltip
                                    title="DATUM not in use  from more than 30  days"
                                    aria-label="Datum not in use from more than 30 days"
                                >
                                    <Paper
                                        elevation={1}
                                        className={clsx(classes.hoverBox, className, {
                                            [classes.clicked]: selectedButtonIndex === 9
                                        })}
                                        onClick={event => this.onDatumStatus(event, 9)}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            style={{
                                                textDecoration: "none",
                                                color: "rgb(69, 69, 69)"
                                            }}
                                            justifyContent={"center"}
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent={"center"}
                                                style={{marginTop: "13px"}}
                                            >
                                                <Box>
                                                    <Typography
                                                        variant={"h4"}
                                                        style={{
                                                            color:
                                                                selectedButtonIndex === 9 ? "red" : "#122E91",
                                                            textShadow: "0px 0px, 0px 0px, 0px 0px"
                                                        }}
                                                    >
                                                        {datumAnalytics?.manual_datum_count}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" justifyContent={"center"}>
                                                <Typography variant="overline">Manual</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

const mapStateToProps = state => {
    return {
        orderStatus: state.orderStatus,
        datumAnalytics: state.datumAnalytics
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handlegetOrderStatus: (accessToken, state) => {
            dispatch(getOrderStatus(accessToken, state));
        },
        handlegetDatumAnalytics: (accessToken, state) => {
            dispatch(getDatumAnalytics(accessToken, state));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(UsersToolbar));
