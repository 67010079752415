import {green, red} from "@material-ui/core/colors";

export default theme => ({
    root: {},
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    spacer1: {
        flexGrow: 10
    },
    spacer2: {
        flexGrow: 2
    },

    deleteButton: {
        color: theme.palette.danger.main,
        marginRight: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    importIcon: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    exportIcon: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    nameText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        color: "gray"
    },
    mybtn: {
        textIndent: -9999,
        color: "#12161B",
        padding: " 6px 16px",
        backgroundImage: 'url("/images/directDl.png")',
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    switchBase: {
        color: red[300],
        "&$checked": {
            color: green[500]
        },
        "&$checked + $track": {
            backgroundColor: green[500]
        }
    }
});
