import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
// Material components
import {LinearProgress, Paper, TablePagination, Typography, withStyles} from "@material-ui/core";
import GoogleMapsReact from "../../../../components/GoogleMaps/google_maps_react";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@material-ui/core/Dialog";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";

class Cards extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        latLon: "",
        reg_no: ""
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    openDialog(latLon, vehicle_info) {
        this.setState({
            open: true,
            latLon: latLon,
            reg_no: vehicle_info
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    render() {
        const {classes} = this.props;

        if (this.props.allTrackerData?.length === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else {
                return (
                    <>
                        {this.props.isLoading ? (
                            <div className={classes.progressWrapper}>
                                <LinearProgress fullWidth className={classes.progress}/>
                            </div>
                        ) : null}
                        {this.props.noDataFound ? (
                            <NoDatafound/>
                        ) : (
                            <>
                                {this.props.allTrackerData.map(tracker => (
                                    <Box sx={{flexGrow: 2}} style={{margin: "4%"}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Paper elevation={2} className={classes.paper}>
                                                        <Box display="flex" flexDirection="column">
                                                            <Box
                                                                py={1}
                                                                px={2}
                                                                display="flex"
                                                                className={classes.header}
                                                                style={{
                                                                    background:
                                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                                }}
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    className={classes.textWhite}
                                                                    style={{color: "primary"}}
                                                                >
                                                                    #ID {tracker.id}
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    className={classes.textWhite}
                                                                >
                                                                    {tracker.created_at}
                                                                </Typography>
                                                            </Box>
                                                            <Box p={2} spacing={2}>
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                    align="space-around"
                                                                    justifyContent="center"
                                                                >
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Name
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                                align="center"
                                                                            >
                                                                                {tracker.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Unique Address
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                                align="center"
                                                                                style={{wordBreak: "break-word"}}
                                                                            >
                                                                                {tracker.unique_address}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Serial Number
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                            >
                                                                                {tracker.serial_number}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className={classes.marginRightAuto}
                                                                    >
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Type
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                            >
                                                                                {tracker.type}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Activation Status
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                                align="center"
                                                                            >
                                                                                {tracker.is_active
                                                                                    ? "Active"
                                                                                    : "De-Active"}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            px={1}
                                                                            py={0}
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color="textPrimary"
                                                                                align="center"
                                                                            >
                                                                                Current Status
                                                                            </Typography>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="caption"
                                                                                align="center"
                                                                            >
                                                                                {tracker.is_online
                                                                                    ? "Online"
                                                                                    : "Offline"}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </>
                        )}
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose.bind(this)}
                            fullWidth={true}
                        >
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <GoogleMapsReact
                                        google={this.props.google}
                                        initialCenter={{
                                            lat: this.state.latLon[1],
                                            lng: this.state.latLon[0]
                                        }}
                                        marker={this.state.latLon}
                                        markerInfo={this.state.reg_no}
                                        zoom={15}
                                    />
                                </div>
                            </DialogContent>
                        </Dialog>
                        <div>
                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    style={{padding: "0%"}}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100, 500]}
                                />
                            )}
                        </div>
                    </>
                );
            }
        }
    }
}

Cards.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

Cards.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withStyles(styles)(Cards);
