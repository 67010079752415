import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";

import NoDatafound from "../NoDataInTable/index";

// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {withSnackbar} from "notistack";
import compose from "recompose/compose";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import secureLocalStorage from "react-secure-storage";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: [],
            right: false,
            requestStatus: 0
        };
    }

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    handleDrawerOpen = (e, side, open, customer) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            right: open,
            customerData: customer
        });
    };
    handleDrawerClose = () => {
        this.setState({right: false});
    };

    handleChange = (e, value, type) => {
        this.setState({
            requestStatus: e.target.value
        });
        this.props.handleChangeEdit(e, value, type);
    };

    onSearchTerm = searchTerm => {
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        if (this.props.noDataFound === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="left">Complaint ID</TableCell>
                                                <TableCell align="left">
                                                    Complaint Logging Date
                                                </TableCell>
                                                <TableCell align="left">Concern Person</TableCell>
                                                <TableCell align="left">Mobile</TableCell>
                                                <TableCell align="left">RPP</TableCell>
                                                <TableCell align="left">Issue Category</TableCell>
                                                <TableCell align="left">Issue Subcategory</TableCell>
                                                <TableCell align="left">Assigned</TableCell>
                                                <TableCell align="left">Select Engineer</TableCell>
                                                <TableCell align="left">Change Status</TableCell>
                                                <TableCell align="left">More</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"8"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {this.props.allCustomerData?.map(customer => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={customer?.id}
                                                    >
                                                        <TableCell
                                                            align="left"
                                                            className={classes.tableCell}
                                                        >
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {customer?.service_number}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.created_at}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.contact_person_name}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.mobile}
                                                        </TableCell>
                                                        {/*<TableCell className={classes.tableCell}>*/}
                                                        {/*    {!customer.address[0] ? 'No data' : customer.address[0]}*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.refueler_reg_number}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.issue_category}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.issue_subcategory}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {customer?.assigned_service_eng === null ||
                                                            customer?.assigned_service_eng === "" ? (
                                                                "Not Assigned"
                                                            ) : (
                                                                <Typography variant={"h5"}>
                                                                    {customer?.assigned_service_eng}
                                                                </Typography>
                                                            )}
                                                        </TableCell>

                                                        {/*** select Service Engineer ***/}
                                                        <TableCell className={classes.tableCell}>
                                                            <Autocomplete
                                                                id="combo-box-for-tracker"
                                                                onChange={e =>
                                                                    this.handleChange(e, customer, "engineer")
                                                                }
                                                                options={this.props.EngineerData}
                                                                style={{margin: "10px 0", width: 150}}
                                                                disabled={
                                                                    secureLocalStorage.getItem("access_type") ===
                                                                    false
                                                                }
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        fullWidth
                                                                        label="Select Engineer"
                                                                        variant="outlined"
                                                                        onChange={e =>
                                                                            this.onSearchTerm(e.target.value)
                                                                        }
                                                                        disabled={
                                                                            secureLocalStorage.getItem(
                                                                                "access_type"
                                                                            ) === false
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell className={classes.tableCell}>
                                                            <Label
                                                                color={
                                                                    customer.status === "OPN" ? orderStatusColors["1"] :
                                                                        customer.status === "CAN" ? orderStatusColors["3"] :
                                                                            customer.status === "COM" ? orderStatusColors["2"] :
                                                                                customer.status === "INP" ? orderStatusColors["4"] : ''}
                                                            >
                                                            {customer.status === "OPN" ? "OPEN" :
                                                                customer.status === "CAN" ? "CANCEL" :
                                                                    customer.status === "COM" ? "COMPLETE" :
                                                                        customer.status === "INP" ? "IN-PROCESS" : ''}
                                                            </Label>
                                                        </TableCell> */}

                                                        {/*** Change Status Service status ***/}
                                                        <TableCell className={classes.tableCell}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <InputLabel
                                                                    id="Request-mode-selector"
                                                                    fullWidth
                                                                >
                                                                    Change Status
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    variant={"outlined"}
                                                                    labelId="Request-mode-selector"
                                                                    id="Request-mode-selector"
                                                                    value={customer.status}
                                                                    disabled={
                                                                        secureLocalStorage.getItem(
                                                                            "access_type"
                                                                        ) === false
                                                                    }
                                                                    onChange={e =>
                                                                        this.handleChange(e, customer, "status")
                                                                    }
                                                                    label="Request Status"
                                                                    style={
                                                                        customer.status === "OPN"
                                                                            ? {
                                                                                color: "#FAB57A",
                                                                                fontWeight: 900
                                                                            }
                                                                            : customer.status === "CAN"
                                                                                ? {
                                                                                    color: "#EC4646",
                                                                                    fontWeight: 900
                                                                                }
                                                                                : customer.status === "COM"
                                                                                    ? {
                                                                                        color: "#A1DE93",
                                                                                        fontWeight: 900
                                                                                    }
                                                                                    : customer.status === "INP"
                                                                                        ? {
                                                                                            color: "#2E94B9",
                                                                                            fontWeight: 900
                                                                                        }
                                                                                        : ""
                                                                    }
                                                                >
                                                                    {/* <MenuItem value={0}>Change Status</MenuItem> */}
                                                                    <MenuItem value={"OPN"}>OPEN</MenuItem>
                                                                    <MenuItem value={"CAN"}>CANCEL</MenuItem>
                                                                    <MenuItem value={"COM"}>COMPLETE</MenuItem>
                                                                    <MenuItem value={"INP"}>IN-PROGRESS</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>

                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align={"left"}
                                                        >
                                                            <Button
                                                                onClick={e =>
                                                                    this.handleDrawerOpen(
                                                                        e,
                                                                        "right",
                                                                        true,
                                                                        customer
                                                                    )
                                                                }
                                                                style={{float: "right"}}
                                                            >
                                                                <MoreIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>
                            {/*drawer for more details*/}

                            <Drawer
                                anchor="right"
                                open={this.state.right}
                                onClose={this.handleDrawerClose}
                            >
                                <div className={classes.root}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Pump Name
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.customerData?.partner_name}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    OMC Name
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.customerData?.omc_name}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Zone
                                                </TableCell>
                                                <TableCell>
                                                    {!this.state.customerData?.zone
                                                        ? "NA"
                                                        : this.state.customerData?.zone}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    GST Number
                                                </TableCell>
                                                <TableCell>
                                                    {this.state.customerData?.region
                                                        ? this.state.customerData?.region
                                                        : "NA"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Last Month Consumption
                                                </TableCell>
                                                <TableCell>
                                                    {!this.state.customerData?.last_month_fuel_used
                                                        ? "No data"
                                                        : this.state.customerData?.last_month_fuel_used}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Credit With Repos
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.customerData?.issue_description === "N/A"
                                                        ? "No data"
                                                        : this.state.customerData?.issue_description}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Drawer>

                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withSnackbar(compose(withStyles(styles))(UsersTable));
