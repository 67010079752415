import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from "validate.js";
import _ from "underscore";

// Material helpers
// Material components
import {Button, Grid, TextField, Typography, withStyles} from "@material-ui/core";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";
import {connect} from "react-redux";
import {loginRequest} from "../../actions/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withSnackbar} from "notistack";
import secureLocalStorage from "react-secure-storage";

class SignIn extends Component {
    state = {
        values: {
            userName: "",
            password: ""
        },
        touched: {
            userName: false,
            password: false
        },
        errors: {
            userName: null,
            password: null
        },
        isValid: false,
        isLoading: false,
        submitError: false,
        department: secureLocalStorage.getItem("department")
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = !errors;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = {...this.state};

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    setIsLoading = () => {
    };

    handleSignIn = () => {
        try {
            this.setState({isLoading: true});
            this.props.handleSignInRequest(
                this.state.values,
                this.props.history,
                this.props,
                this.setIsLoading
            );
        } catch (error) {
            this.setState({
                isLoading: false,
                serviceError: error
            });
        }
    };

    handleLoginFailed = () => {
        if (this.props.loginError) {
            this.setState({isLoading: false});
        }
        return <Typography variant="body2">{"submitError"}</Typography>;
    };

    render() {
        const {classes} = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError
            // isLoading
        } = this.state;

        const showuserNameError = touched.userName && errors.userName;
        const showPasswordError = touched.password && errors.password;

        if (secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/dashboard");
        } else if (
            secureLocalStorage.getItem("accessToken") &&
            this.state.department === 9
        ) {
            this.props.history.push("/service_request");
        }
        return (
            <div className={classes.root}>
                <Grid className={classes.grid} container>
                    <Grid className={classes.quoteWrapper} item lg={5}>
                        <div className={classes.quote}>
                            <div className={classes.quoteInner}>
                                <Typography className={classes.quoteText} variant="h1">
                                    {/*Take care of your business,*/}
                                    {/*we have your diesel needs covered.*/}
                                    {/*Order and Relax*/}
                                </Typography>
                                <div className={classes.person}>
                                    <Typography className={classes.name} variant="body1">
                                        {/*Repos Super Administrator*/}
                                    </Typography>
                                    <Typography
                                        className={classes.bio}
                                        variant="body2"
                                    ></Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid className={classes.content} item lg={7} xs={12}>
                        <div className={classes.content}>
                            <div className={classes.contentHeader}></div>
                            <div className={classes.contentBody}>
                                <form className={classes.form}>
                                    <img
                                        alt="Brainalytica logo"
                                        style={{width: "100px"}}
                                        src={require("../../assets/img/Repos_Black_A.png")}
                                    />
                                    <Typography className={classes.title} variant="h2">
                                        Sign in
                                    </Typography>
                                    <Typography className={classes.subtitle} variant="body1">
                                        login with your username and password
                                    </Typography>
                                    <div className={classes.fields}>
                                        <TextField
                                            className={classes.textField}
                                            label="Username"
                                            name="userName"
                                            onChange={event =>
                                                this.handleFieldChange("userName", event.target.value)
                                            }
                                            type="text"
                                            value={values.userName}
                                            variant="outlined"
                                        />
                                        {showuserNameError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.userName[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="Password"
                                            name="password"
                                            onChange={event =>
                                                this.handleFieldChange("password", event.target.value)
                                            }
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                        />
                                        {showPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.password[0]}
                                            </Typography>
                                        )}
                                    </div>
                                    {this.props.loginError && (
                                        <Typography className={classes.submitError} variant="body2">
                                            {submitError}
                                        </Typography>
                                    )}

                                    {this.props.isLoading ? (
                                        <CircularProgress className={classes.progress}/>
                                    ) : (
                                        <Button
                                            className={classes.signInButton}
                                            color="primary"
                                            name="login"
                                            id="login"
                                            disabled={!isValid}
                                            onClick={this.handleSignIn}
                                            size="large"
                                            variant="contained"
                                        >
                                            Sign in now
                                        </Button>
                                    )}

                                    {this.props.loginError && (
                                        <Typography className={classes.submitError} variant="body2">
                                            Please Check your Username or Password
                                        </Typography>
                                    )}
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

SignIn.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess,
        loginError: state.loginError,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleSignInRequest: (userData, history, props, setIsLoading) => {
            dispatch(
                loginRequest(userData, history, props.enqueueSnackbar, setIsLoading)
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(SignIn)));
