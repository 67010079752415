// Palette
import palette from "../palette";

export default {
    root: {
        borderRadius: "3px",
        overflow: "hidden"
    },
    colorPrimary: {
        backgroundColor: palette.common.neutral
    }
};
