import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import validate from "validate.js";
import _ from "underscore";

// Material components
import {Button, TextField, Typography, withStyles} from "@material-ui/core";

// Component styles
import styles from "../CreatePumpSingleSignOn/styles";

// Form validation schema
import schema from "./schema";
import {editPartner, reloadPartner} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import reposWebApi from "../../../../actions/configuration";
import qs from "qs";
import InputLabel from "@material-ui/core/InputLabel";

import {withSnackbar} from "notistack";
import secureLocalStorage from "react-secure-storage";

class EditPump extends Component {
    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = !errors;

        this.setState(newState);
    }, 300);

    constructor(props) {
        super(props);
        this.state = {
            zonesArray: [],
            partner_type: "",
            values: {
                partnerId: this.props.pump.id,
                ownerName: this.props.pump.owned_by,
                mobile: this.props.pump.mobile,
                email: this.props.pump.email,
                password: "",
                pumpName: this.props.pump.name,
                dealersOf: this.props.pump.pumps[0].pump_brand,
                licenseNumber: this.props.pump.pp_license_number,
                address: this.props.pump.pumps[0].pump_address,
                latitude: this.props.pump.pumps[0].pump_lat_lon.lat,
                longitude: this.props.pump.pumps[0].pump_lat_lon.lon,
                city: this.props.pump.city_name,
                pan_card: this.props.pump.pan_card,
                cst_tin_number: this.props.pump.cst_tin_number,
                vat_tin_number: this.props.pump.vat_tin_number,
                bank_account_number: this.props.pump.bank_acc_no,
                ifsc: this.props.pump.ifsc,
                gst_number: this.props.pump.gst_number,
                zoneSelected: this.props.pump.zone[0].zone,
                regionSelected: this.props.pump.region[0].region,
                region: [],
                state: this.props.pump.pumps[0].pump_state_code,
                partner_type: this.props.pump.partner_type,
                pinCode: this.props.pump.pincode,
                automation: this.props.pump.automation
            },
            touched: {
                ownerName: false,
                mobile: false,
                email: false,
                pumpName: false,
                dealersOf: false,
                licenseNumber: false,
                address: false,
                latitude: false,
                longitude: false,
                city: false,
                state: false,
                pinCode: false
            },
            errors: {
                ownerName: null,
                mobile: null,
                email: null,
                pumpName: null,
                dealersOf: null,
                licenseNumber: null,
                address: null,
                latitude: null,
                longitude: null,
                city: null,
                state: null,
                pinCode: null
            },
            isValid: false,
            isLoading: false,
            submitError: false
        };
    }

    handleFieldChange = (field, value) => {
        if (field === "regionSelected" && value !== "") {
            this.getRegionsAndZone(value);
        }

        const newState = {...this.state};

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    handleSubmit = event => {
        event.preventDefault();
        this.EditPartner(this.state.values);
        return false;
    };

    EditPartner = partnerFormData => {
        this.props.handleEditPartner(
            secureLocalStorage.getItem("accessToken"),
            partnerFormData
        );
        return false;
    };

    redirectOnSuccess = () => {
        this.props.onModificationSuccess();
    };

    getRegionsAndZone = region => {
        const requestBody = {
            region
        };
        reposWebApi
            .post("/executives/get_region_zone", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(response => {
                this.setState({
                    zonesArray: response.data
                });
            });
    };

    componentDidMount() {
        this.getRegionsAndZone(this.props.pump.region[0].region);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.partnerModificationSuccess) {
            this.props.handleReloadPartner();
            this.props.enqueueSnackbar("Pump Update Successful!", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
            this.redirectOnSuccess();
        }
    }

    render() {
        const {classes} = this.props;
        const {values, touched, errors} = this.state;

        const dealers_of = [
            {
                value: "HPCL",
                label: "Hindustan Petroleum Corporation Limited"
            },
            {
                value: "BPCL",
                label: "Bharat Petroleum Corporation Limited"
            },
            {
                value: "IOCL",
                label: "Indian Oil Corporation Limited"
            },
            {
                value: "SHELL",
                label: "Royal Dutch SHELL Oil and Gas Company "
            },
            {
                value: "ESSAR",
                label: "Essar Oil Nayara Energy"
            },
            {
                value: "REL",
                label: "Reliance Petroleum"
            },
            {
                value: "OTHER",
                label: "Others"
            }
        ];

        const states = [
            {
                key: "AN",
                name: "Andaman and Nicobar Islands"
            },
            {
                key: "AP",
                name: "Andhra Pradesh"
            },
            {
                key: "AR",
                name: "Arunachal Pradesh"
            },
            {
                key: "AS",
                name: "Assam"
            },
            {
                key: "BR",
                name: "Bihar"
            },
            {
                key: "CG",
                name: "Chandigarh"
            },
            {
                key: "CH",
                name: "Chhattisgarh"
            },
            {
                key: "DN",
                name: "Dadra and Nagar Haveli"
            },
            {
                key: "DD",
                name: "Daman and Diu"
            },
            {
                key: "DL",
                name: "Delhi"
            },
            {
                key: "GA",
                name: "Goa"
            },
            {
                key: "GJ",
                name: "Gujarat"
            },
            {
                key: "HR",
                name: "Haryana"
            },
            {
                key: "HP",
                name: "Himachal Pradesh"
            },
            {
                key: "JK",
                name: "Jammu and Kashmir"
            },
            {
                key: "JH",
                name: "Jharkhand"
            },
            {
                key: "KA",
                name: "Karnataka"
            },
            {
                key: "KL",
                name: "Kerala"
            },
            {
                key: "LD",
                name: "Lakshadweep"
            },
            {
                key: "MP",
                name: "Madhya Pradesh"
            },
            {
                key: "MH",
                name: "Maharashtra"
            },
            {
                key: "MN",
                name: "Manipur"
            },
            {
                key: "ML",
                name: "Meghalaya"
            },
            {
                key: "MZ",
                name: "Mizoram"
            },
            {
                key: "NL",
                name: "Nagaland"
            },
            {
                key: "OD",
                name: "Odisha"
            },
            {
                key: "PY",
                name: "Puducherry"
            },
            {
                key: "PB",
                name: "Punjab"
            },
            {
                key: "RJ",
                name: "Rajasthan"
            },
            {
                key: "SK",
                name: "Sikkim"
            },
            {
                key: "TN",
                name: "Tamil Nadu"
            },
            {
                key: "TS",
                name: "Telangana"
            },
            {
                key: "TR",
                name: "Tripura"
            },
            {
                key: "UP",
                name: "Uttar Pradesh"
            },
            {
                key: "UK",
                name: "Uttarakhand"
            },
            {
                key: "WB",
                name: "West Bengal"
            }
        ];

        const partner_type = [
            {
                key: "PAF",
                name: "Partner as fuelent"
            },
            {
                key: "PAR",
                name: "Partner as RO"
            },
            {
                key: "SAR",
                name: "Supplier as RO"
            },
            {
                key: "SAD",
                name: "Supplier as DEPO"
            }
        ];

        const automation = [
            {
                key: false,
                name: "No "
            },
            {
                key: true,
                name: "Yes"
            }
        ];

        const regions = ["NORTH", "EAST", "WEST", "SOUTH"];

        const showOwnerNameError = touched.ownerName && errors.ownerName;
        const showPumpNameError = touched.pumpName && errors.pumpName;
        const showMobileError = touched.mobile && errors.mobile;
        const showEmailError = touched.email && errors.email;
        const showLicenseNoError = touched.licenseNumber && errors.licenseNumber;
        const showAddressError = touched.address && errors.address;
        const showLatitudeError = touched.latitude && errors.latitude;
        const showLongitudeError = touched.longitude && errors.longitude;
        const showCityError = touched.city && errors.city;
        const showPinCodeError = touched.pinCode && errors.pinCode;

        return (
            <div className={classes.root}>
                <form autoComplete="off" noValidate method={"POST"} action={"#"}>
                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Basic Information
                        </Typography>
                        <TextField
                            className={classes.textField}
                            label="Owner name"
                            margin="dense"
                            required
                            value={values.ownerName}
                            id={"owner_name"}
                            fullWidth
                            onChange={event =>
                                this.handleFieldChange("ownerName", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showOwnerNameError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.ownerName[0]}
                                </Typography>
                            )}
                        </div>

                        <TextField
                            className={classes.textField}
                            label="Mobile"
                            margin="dense"
                            type="number"
                            value={values.mobile}
                            id={"mobile_number"}
                            fullWidth
                            onChange={event =>
                                this.handleFieldChange("mobile", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showMobileError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.mobile[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label="Email"
                            margin="dense"
                            type="email"
                            id={"email"}
                            fullWidth
                            value={values.email}
                            onChange={event =>
                                this.handleFieldChange("email", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showEmailError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.email[0]}
                                </Typography>
                            )}
                        </div>
                    </div>

                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Pump Details
                        </Typography>
                        <TextField
                            className={classes.textField}
                            label="Pump name"
                            margin="dense"
                            required
                            value={values.pumpName}
                            id={"pump_name"}
                            fullWidth
                            onChange={event =>
                                this.handleFieldChange("pumpName", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showPumpNameError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.pumpName[0]}
                                </Typography>
                            )}
                        </div>

                        <TextField
                            className={classes.textField}
                            label={"Dealers Of"}
                            margin="dense"
                            fullWidth
                            value={this.state.values.dealersOf}
                            onChange={event =>
                                this.handleFieldChange("dealersOf", event.target.value)
                            }
                            // required
                            id={"select_dealer_type"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            value={this.state.dealersOf}
                            {dealers_of.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            className={classes.textField}
                            label="License Number"
                            onChange={event =>
                                this.handleFieldChange("licenseNumber", event.target.value)
                            }
                            margin="dense"
                            id={"license_number"}
                            fullWidth
                            value={values.licenseNumber}
                            variant="outlined"
                        />
                        <div>
                            {showLicenseNoError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.licenseNumber[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label={"Partner Type"}
                            margin="dense"
                            fullWidth
                            value={values.partner_type}
                            onChange={event =>
                                this.handleFieldChange("partner_type", event.target.value)
                            }
                            // required
                            id={"select_partner_type"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            value={this.state.partner_type}
                            <option key={0} value={""}></option>
                            {partner_type.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            className={classes.textField}
                            label={"Automation"}
                            margin="dense"
                            fullWidth
                            value={values.automation}
                            onChange={event =>
                                this.handleFieldChange("automation", event.target.value)
                            }
                            // required
                            id={"automation"}
                            select
                            SelectProps={{native: true}}
                            variant="outlined"
                        >
                            value={this.state.automation}
                            <option key={0} value={""}></option>
                            {automation.map(option => (
                                <option key={option.key} value={option.key}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </div>

                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Legal Information
                        </Typography>
                        <TextField
                            className={classes.textField}
                            label="Pan Number"
                            onChange={event =>
                                this.handleFieldChange("pan_card", event.target.value)
                            }
                            margin="dense"
                            id={"pan_card"}
                            fullWidth
                            value={values.pan_card}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="GST Number"
                            onChange={event =>
                                this.handleFieldChange("gst_number", event.target.value)
                            }
                            margin="dense"
                            id={"gst_number"}
                            fullWidth
                            value={values.gst_number}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="VAT TIN Number"
                            onChange={event =>
                                this.handleFieldChange("vat_tin_number", event.target.value)
                            }
                            margin="dense"
                            id={"vat_tin_number"}
                            fullWidth
                            value={values.vat_tin_number}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="CST TIN Number"
                            onChange={event =>
                                this.handleFieldChange("cst_tin_number", event.target.value)
                            }
                            margin="dense"
                            id={"cst_tin_number"}
                            fullWidth
                            value={values.cst_tin_number}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="Bank Account Number"
                            onChange={event =>
                                this.handleFieldChange(
                                    "bank_account_number",
                                    event.target.value
                                )
                            }
                            margin="dense"
                            id={"bank_account_number"}
                            fullWidth
                            value={values.bank_account_number}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            label="IFSC"
                            onChange={event =>
                                this.handleFieldChange("ifsc", event.target.value)
                            }
                            margin="dense"
                            id={"ifsc"}
                            fullWidth
                            value={values.ifsc}
                            variant="outlined"
                        />
                    </div>

                    <div>
                        {showCityError && (
                            <Typography className={classes.fieldError} variant="body2">
                                {errors.city[0]}
                            </Typography>
                        )}
                    </div>

                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Address
                        </Typography>
                        <TextField
                            className={classes.textField}
                            label="Address"
                            margin="dense"
                            type="text"
                            id={"address"}
                            fullWidth
                            value={values.address}
                            onChange={event =>
                                this.handleFieldChange("address", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showAddressError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.address[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label="Latitude"
                            margin="dense"
                            type="number"
                            fullWidth
                            id={"latitude"}
                            value={values.latitude}
                            onChange={event =>
                                this.handleFieldChange("latitude", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showLatitudeError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.latitude[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label="Longitude"
                            margin="dense"
                            type="number"
                            id={"longitude"}
                            fullWidth
                            value={values.longitude}
                            onChange={event =>
                                this.handleFieldChange("longitude", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showLongitudeError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.longitude[0]}
                                </Typography>
                            )}
                        </div>

                        <TextField
                            type={"number"}
                            className={classes.textField}
                            label={"Pin Code"}
                            onChange={event =>
                                this.handleFieldChange("pinCode", event.target.value)
                            }
                            onInput={e => {
                                e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 6);
                            }}
                            margin="dense"
                            id={"pinCode"}
                            fullWidth
                            required
                            value={values.pinCode}
                            variant="outlined"
                        />
                        <div>
                            {showPinCodeError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.pinCode[0]}
                                </Typography>
                            )}
                        </div>

                        <TextField
                            className={classes.textField}
                            label="City"
                            onChange={event =>
                                this.handleFieldChange("city", event.target.value)
                            }
                            margin="dense"
                            id={"city"}
                            fullWidth
                            required
                            value={values.city}
                            variant="outlined"
                        />

                        <InputLabel htmlFor="regionSelect">Region</InputLabel>
                        <select
                            id={"regionSelect"}
                            value={this.state.values.regionSelected}
                            style={{
                                width: "100%",
                                padding: 7,
                                margin: "6px 0",
                                color: "gray",
                                border: "1px solid #cfcfcf"
                            }}
                            onChange={event =>
                                this.handleFieldChange("regionSelected", event.target.value)
                            }
                        >
                            <option value={""}>{"Select Any"}</option>
                            {regions.map(option => (
                                <option value={option}>{option}</option>
                            ))}
                        </select>

                        <InputLabel htmlFor="zoneSelect">Zone</InputLabel>
                        <select
                            id={"zoneSelect"}
                            value={this.state.values.zoneSelected}
                            style={{
                                width: "100%",
                                padding: 7,
                                margin: "6px 0",
                                color: "gray",
                                border: "1px solid #cfcfcf"
                            }}
                            onChange={event =>
                                this.handleFieldChange("zoneSelected", event.target.value)
                            }
                        >
                            <option value={""}>{"Select Any"}</option>
                            {this.state.zonesArray.map(option => (
                                <option value={option}>{option}</option>
                            ))}
                        </select>

                        <select
                            id={"stateSelect"}
                            value={this.state.values.state}
                            style={{
                                width: "100%",
                                padding: 7,
                                margin: "6px 0",
                                color: "gray",
                                border: "1px solid #cfcfcf"
                            }}
                            onChange={event =>
                                this.handleFieldChange("state", event.target.value)
                            }
                        >
                            {states.map(option => (
                                <option value={option.key}>{option.name}</option>
                            ))}
                        </select>

                        <Button
                            className={classes.addNewPartnerButton}
                            color="primary"
                            name="create_partner_submit"
                            id="create_partner_submit"
                            onClick={e => this.handleSubmit(e)}
                            size="large"
                            variant="contained"
                        >
                            Modify Partner
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

EditPump.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingPartner: state.isLoadingPartner,
        partnerModificationIsLoading: state.partnerModificationIsLoading,
        partnerModificationError: state.partnerModificationError,
        partnerModificationSuccess: state.partnerModificationSuccess,
        partnerModificationSuccessMessage: state.partnerModificationSuccessMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleEditPartner: (accessToken, partnerForm) => {
            dispatch(editPartner(accessToken, partnerForm));
        },
        handleReloadPartner: () => {
            dispatch(reloadPartner());
        }
    };
};

export default withSnackbar(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withStyles(styles))(EditPump))
);
