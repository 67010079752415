/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import validate from "validate.js";
import {makeStyles} from "@mui/styles";
import {Box, Button, Container, Grid, LinearProgress, Paper, TextField, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {loginRequest} from "../../actions/actions";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

const config = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
};

const schema = {
    inputName: {
        presence: {allowEmpty: false, message: "is required"},
        email: false
    },
    c_code: {
        presence: {allowEmpty: false, message: "is required"}
    }
};

const useStyles = makeStyles(theme => ({
    fontStyle: {
        color: "#02A29F",
        fontWeight: 400,
        lineHeight: "12px"
    },
    submitButton: {
        color: "#FFF",
        background: "#02A29F"
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
}));

const LoginForm = props => {
    const {className, setSelected, ...rest} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();

    function validateEmail(e_mail) {
        // eslint-disable-next-line
        var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

        return re.test(e_mail);
    }

    function telephoneCheck(str) {
        // eslint-disable-next-line
        // var a = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
        var a = /^[0-9]/;
        return a.test(str);
    }

    function validateEmailPhone(str) {
        if (str) {
            if (loginState === "email") {
                if (validateEmail(str)) {
                    return true;
                } else {
                    enqueueSnackbar("In-valid E-mail, please correct and try again!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        }
                    });
                }
            } else {
                if (telephoneCheck(str)) {
                    return true;
                } else {
                    enqueueSnackbar(
                        "In-valid Mobile Number, please correct and try again!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right"
                            }
                        }
                    );
                }
            }
        }
    }

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const [loginState, setLoginState] = useState("phone");

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleChange = (event, type) => {
        //event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSubmit = async event => {
        setIsLoading(true);
        event.preventDefault();
        dispatch(
            loginRequest(formState.values, history, enqueueSnackbar, setIsLoading)
        );
    };

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    return (
        <>
            <form
                {...rest}
                // className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
            >
                <Container>
                    <Box display="flex" flexDirection="column">
                        <Paper
                            size="small"
                            elevation={0}
                            style={{
                                borderRadius: "12%",
                                padding: "10%",
                                marginBottom: "3%",
                                textDecoration: "none",
                                background: "rgb(255 255 255 / 5%)",
                                boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                zIndex: 10
                            }}
                        >
                            <Box display="flex" flexDirection="column">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    style={{marginTop: "-5"}}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        style={{marginBottom: "2%"}}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: "0.95rem",
                                                                fontWeight: "900",
                                                                padding: "0.5%"
                                                            }}
                                                        >
                                                            <Typography
                                                                color={"#FFFFFF"}
                                                                style={{
                                                                    fontSize: "0.95rem",
                                                                    fontWeight: "900",
                                                                    padding: "0.5%"
                                                                }}
                                                            >
                                                                {"Login"}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        justifyContent={"space-between"}
                                                        mb={3}
                                                        mt={2}
                                                    >
                                                        {/* *********** phone number (old) ***************** */}
                                                        <>
                                                            <TextField
                                                                id={"userName"}
                                                                error={hasError("userName")}
                                                                helperText={
                                                                    hasError("inputName")
                                                                        ? formState.errors?.userName[0]
                                                                        : null
                                                                }
                                                                placeholder={"Phone Number"}
                                                                name="userName"
                                                                onChange={event =>
                                                                    handleChange(event, "userName")
                                                                }
                                                                value={formState.values.userName || ""}
                                                                variant="standard"
                                                                InputProps={{
                                                                    disableUnderline: true
                                                                }}
                                                                style={{
                                                                    background: "#fff",
                                                                    width: "100%",
                                                                    padding: "3%"
                                                                }}
                                                            />
                                                        </>
                                                    </Box>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        justifyContent={"space-between"}
                                                        mb={3}
                                                    >
                                                        {/* *********** password ***************** */}
                                                        <>
                                                            <TextField
                                                                error={hasError("password")}
                                                                helperText={
                                                                    hasError("password")
                                                                        ? formState.errors?.password[0]
                                                                        : null
                                                                }
                                                                id={"Password"}
                                                                placeholder={"Password"}
                                                                name="password"
                                                                type="password"
                                                                onChange={event =>
                                                                    handleChange(event, "password")
                                                                }
                                                                value={formState.values?.password || ""}
                                                                variant="standard"
                                                                InputProps={{
                                                                    disableUnderline: true
                                                                }}
                                                                style={{
                                                                    background: "#fff",
                                                                    width: "100%",
                                                                    padding: "3%"
                                                                }}
                                                            />
                                                        </>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    style={{marginTop: "2%", justifyContent: "space"}}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Box>
                                                {isLoading ? (
                                                    <Container
                                                        style={{
                                                            marginTop: 40,
                                                            alignItems: "center",
                                                            textAlign: "center"
                                                        }}
                                                        maxWidth="xl"
                                                        className={classes.loadingBar}
                                                    >
                                                        <LinearProgress/>
                                                        Logging you in .....
                                                    </Container>
                                                ) : (
                                                    <Button
                                                        id={"Log In button"}
                                                        className={classes.submitButton}
                                                        // disabled={!formState.isValid}
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        fullWidth
                                                    >
                                                        Proceed
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </form>
        </>
    );
};

LoginForm.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
    // history: PropTypes.object.isRequired
};

export default LoginForm;
