import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    withStyles
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

// Shared components
import {Portlet, PortletContent} from "components";

import GoogleMaps from "../PolygonMap/MapForModifyPolygon";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import MapForPolygon from "../../../AllGeofences/components/PolygonMap/MapForPolygon";
import Grid from "@material-ui/core/Grid";
import {modifyGeofence} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import secureLocalStorage from "react-secure-storage";

class UsersTable extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        latLon: "",
        reg_no: "",
        geofenceInfo: "",
        openModifyDialog: false,
        clearMap: false,
        geofenceId: "",
        polygonArray: [],
        snackIsOpen: false,
        searchText: ""
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    openDialog(latLon, geofenceInfo) {
        const coords = latLon?.map(ll => {
            return {lat: ll[1], lng: ll[0]};
        });

        this.setState({
            open: true,
            coords: coords,
            geofenceInfo: geofenceInfo
        });
    }

    openDialogForModify(geofenceId) {
        this.setState({
            openModifyDialog: true,
            geofenceId: geofenceId
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleCloseModifyDialog() {
        this.setState({
            openModifyDialog: false
        });
        window.location.reload();
    }

    getUpdatedPolygonArray = polygonArray => {
        this.setState({
            polygonArray: polygonArray
        });
    };

    handleSubmit = () => {
        if (this.state.polygonArray.length === 0) {
            alert(
                "You haven't created a geofence! Please draw a polygon and click save"
            );
            return false;
        } else
            this.props.handleModifyGeofence(
                secureLocalStorage.getItem("accessToken"),
                this.state.geofenceId,
                this.state.polygonArray
            );
    };

    render() {
        const {classes, className} = this.props;
        const rootClassName = classNames(classes.root, className);

        if (this.props.dataCount === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            return (
                <Portlet className={rootClassName}>
                    <PortletContent noPadding>
                        <PerfectScrollbar>
                            <TableContainer style={{maxHeight: 640}}>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            style={{
                                                backdropFilter: "blur(5px)",
                                                background: "rgb(255 255 255 / 36%)",
                                                backgroundBlendMode: "exclusion",
                                                boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 10
                                            }}
                                        >
                                            {/*<TableCell align="left">Id</TableCell>*/}
                                            <TableCell align="left">Customer</TableCell>
                                            <TableCell align="left">Customer Asset</TableCell>
                                            <TableCell align="left">Geofence Name</TableCell>
                                            <TableCell align="left">Updated at</TableCell>
                                            <TableCell align="left">Type</TableCell>
                                            <TableCell align="left">View on Map</TableCell>
                                            <TableCell align="left">Modify</TableCell>
                                            <TableCell align="left">Radius</TableCell>
                                            <TableCell align="left">Slug</TableCell>
                                            <TableCell align="left">Created At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {this.props.noDataFound ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={"13"}>
                                                    <NoDatafound/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {this.props.allGeofencesData.map(geofences => (
                                                <TableRow
                                                    className={classes.tableRow}
                                                    hover
                                                    key={geofences.id}
                                                >
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.customer_name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.customer_asset_name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.updated_at}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.type}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Button
                                                            onClick={() =>
                                                                this.openDialog(
                                                                    geofences.lat_lon_polygon.coordinates,
                                                                    geofences.name
                                                                )
                                                            }
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Button
                                                            onClick={() =>
                                                                this.openDialogForModify(geofences.id)
                                                            }
                                                        >
                                                            Modify
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.radius}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.slug === null ? "-" : geofences.slug}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {geofences.created_at}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </PerfectScrollbar>

                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose.bind(this)}
                            fullWidth={true}
                        >
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <MapForPolygon
                                        markerInfo={this.state.geofenceInfo}
                                        google={this.props.google}
                                        coords={this.state.coords}
                                    />
                                </div>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={this.state.openModifyDialog}
                            onClose={this.handleCloseModifyDialog.bind(this)}
                            fullWidth={true}
                            // TransitionComponent={this.transition}
                        >
                            <AppBar
                                color={"white"}
                                style={{
                                    height: "70px"
                                }}
                                className={classes.appBar}
                            >
                                <Toolbar>
                                    <Grid
                                        justify="space-between" // Add it here :)
                                        container
                                        spacing={12}
                                    >
                                        <Grid item>
                                            <div
                                                style={{
                                                    marginTop: "7px",
                                                    padding: "19px 8px"
                                                }}
                                            >
                                                <Typography variant="button">
                                                    Modify Geofence
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item style={{marginTop: "10px"}}></Grid>
                                        <Grid item>
                                            <div>
                                                {this.props.isLoading ? (
                                                    <CircularProgress color={"white"}/>
                                                ) : this.props.geofenceModificationSuccess !== null ? (
                                                    <div>
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color: "#fff",
                                                                backgroundColor: "#36a536",
                                                                padding: "10px 15px",
                                                                borderRadius: "5px"
                                                            }}
                                                        >
                                                            {this.props.geofenceModificationSuccess ===
                                                            "GeoFence Updated successfully"
                                                                ? "Successful"
                                                                : "Failed"}
                                                        </Typography>
                                                        <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={this.handleCloseModifyDialog.bind(this)}
                                                            aria-label="close"
                                                        >
                                                            <CloseIcon/>
                                                        </IconButton>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Button
                                                            color="inherit"
                                                            style={{padding: "26px 8px"}}
                                                            onClick={this.handleSubmit}
                                                        >
                                                            save
                                                        </Button>
                                                        <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={this.handleCloseModifyDialog.bind(this)}
                                                            aria-label="close"
                                                        >
                                                            <CloseIcon/>
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                            <DialogContent style={{padding: 0}}>
                                <div style={{padding: 0}}>
                                    <GoogleMaps getUpdatedPolygon={this.getUpdatedPolygonArray}/>
                                </div>
                            </DialogContent>
                        </Dialog>

                        {this.props.noDataFound ? (
                            ""
                        ) : (
                            <TablePagination
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                component="div"
                                count={this.props.dataCount}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                page={this.props.page}
                                rowsPerPage={this.props.rowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        )}
                    </PortletContent>
                </Portlet>
            );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoading: state.isLoading,
        geofenceModificationSuccess: state.geofenceModificationSuccess,
        geofenceModificationFail: state.geofenceModificationFail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleModifyGeofence: (accessToken, geofenceId, polygonArray) => {
            dispatch(modifyGeofence(accessToken, geofenceId, polygonArray));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(UsersTable));
