import React, {useEffect, useState} from "react";
import {Dashboard as DashboardLayout} from "layouts";
import {Box, Typography} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import {mapStateToProps} from "../FetchDataFromReducer/mapStateToProps";
import {compose} from "underscore";
import moment from "moment";
import {withStyles} from "@material-ui/styles";
import styles from "../AllPumps/components/CreatePumpSingleSignOn/styles";
import {Container, Paper} from "@mui/material";

const WelcomePage = ({stateData}) => {
    const [dateTime, setDateTime] = useState(new Date());
    let currentTime = new Date();

    const hour = currentTime.getHours();

    let message;

    if (hour < 12) {
        message = "Good morning..!";
    } else if (hour < 18) {
        message = "Good afternoon..!";
    } else {
        message = "Good evening..!";
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <DashboardLayout title="Welcome">
            <div
                style={{
                    // backgroundImage: 'url("https://cdn.dribbble.com/users/6117646/screenshots/14975149/media/8f26446e227baeb76f1ae01e8dc1c558.gif")',
                    backgroundRepeat: "repeat-x",
                    width: "100%",
                    height: "100vh",
                    // backgroundAttachment: "fixed",
                    backgroundSize: "100% auto"
                }}
            >
                <Container
                    maxWidth={false}
                    style={{display: "flex", justifyContent: "center"}}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        style={{width: "720px", borderRadius: "20%"}}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            style={{marginTop: "20%"}}
                        >
                            <Box display="flex" flexDirection="column">
                                <Paper
                                    size="small"
                                    elevation={0}
                                    style={{
                                        borderRadius: "15%",
                                        padding: "10%",
                                        marginBottom: "3%",
                                        textDecoration: "none",
                                        background: "rgb(255 255 255 / 5%)",
                                        boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                        zIndex: 10
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <div style={{textAlign: "center"}}>
                                                <Typography variant={"h6"} style={{fontWeight: 600}}>
                                                    {moment(dateTime).format("MMMM DD, YYYY")},{" "}
                                                    {formatAMPM(dateTime)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{textAlign: "center"}}>
                                                <Typography
                                                    variant={"h1"}
                                                    style={{
                                                        fontWeight: 600,
                                                        color: "#3a3535"
                                                    }}
                                                >
                                                    {message}
                                                </Typography>
                                            </div>
                                            <div style={{textAlign: "center"}}>
                                                <Typography
                                                    variant={"h2"}
                                                    style={{
                                                        fontWeight: 600,
                                                        color: "#2a2626"
                                                    }}
                                                >
                                                    {stateData?.userDetails?.first_name?.toUpperCase()}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{textAlign: "center"}}>
                                                <Typography
                                                    style={{
                                                        fontSize: "3.3em",
                                                        fontWeight: 900,
                                                        lineHeight: "50px",
                                                        background:
                                                            "-webkit-linear-gradient(276deg, rgb(9 11 11) 6.51%, rgb(2, 162, 159) 80.84%)",
                                                        WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent"
                                                    }}
                                                >
                                                    Welcome to Repos..!
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps)(
    compose(withStyles(styles))(WelcomePage)
);
