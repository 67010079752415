import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import validate from "validate.js";
import _ from "underscore";

// Material components
import {Button, TextField, Typography, withStyles} from "@material-ui/core";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";
import {createRefueler} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import CircularProgress from "@material-ui/core/CircularProgress";
import reposWebApi from "../../../../actions/configuration";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";

class CreateRefueler extends Component {
    state = {
        values: {
            registrationNumber: "",
            capacity: "",
            selectedPartner: "",
            selectedDriver: "",
            selectedTracker: "",
            setPickup: 0,
            authorization: 0
        },
        touched: {
            registrationNumber: false,
            capcity: false,
            partnerId: false,
            selectedPartner: false,
            selectedDriver: false,
            selectedTracker: false,
            setPickup: false,
            authorization: false
        },
        errors: {
            registrationNumber: null,
            capcity: null,
            partnerId: null,
            selectedPartner: [],
            selectedDriver: [],
            selectedTracker: [],
            setPickup: null,
            authorization: null
        },
        isValid: false,
        isLoading: false,
        submitError: false,
        allPartnersData: [],
        allDriversData: [],
        selectedPartner: null,
        selectedDriver: null,
        selectedTracker: null,
        setPickup: 0,
        authorization: 0
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = !errors;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
    };

    handleSubmit = event => {
        event.preventDefault();
        this.CreateRefueler(this.state.values);
        return false;
    };

    onTagsChange = (event, values) => {
        event.preventDefault();
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched["selectedPartner"] = true;
        newState.values["selectedPartner"] = values.id;
        this.setState(newState, this.validateForm);
        this.setState({
            selectedPartner: values.id
        });
    };

    onTagsChangeDriver = (event, values) => {
        event.preventDefault();
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched["selectedDriver"] = true;
        newState.values["selectedDriver"] = values.id;
        this.setState(newState, this.validateForm);
        this.setState({
            selectedDriver: values.id
        });
    };

    onTagsChangeTracker = (event, values) => {
        event.preventDefault();
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched["selectedTracker"] = true;
        newState.values["selectedTracker"] = values.id;
        this.setState(newState, this.validateForm);
        this.setState({
            selectedTracker: values.id
        });
    };

    CreateRefueler = () => {
        this.props.handleCreateRefueler(
            secureLocalStorage.getItem("accessToken"),
            this.state
        );
        return false;
    };

    redirectOnSuccess = () => {
        window.location.reload();
    };

    handleOnChange = ({target}) => {
    };

    componentDidMount() {
        reposWebApi
            .get("/partner/minimal", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    limit: 500
                }
            })
            .then(response => {
                reposWebApi
                    .get("/partner/list_driver_for_binding", {
                        headers: {
                            Authorization:
                                "Bearer " + secureLocalStorage.getItem("accessToken")
                        },
                        params: {
                            limit: 500
                        }
                    })
                    .then(drivers => {
                        reposWebApi
                            .get("/tracker/list_tracker_for_binding", {
                                headers: {
                                    Authorization:
                                        "Bearer " + secureLocalStorage.getItem("accessToken")
                                },
                                params: {
                                    limit: 500
                                }
                            })
                            .then(trackers => {
                                this.setState({
                                    allPartnersData: decryption(response).data,
                                    allDrivers: decryption(drivers).data.results,
                                    allTrackes: decryption(trackers).data.results
                                });
                            });
                    });
            });
    }

    validate = number => {
        const validateNumberPlate = new RegExp(
            /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/
        );
        const bhNumber = new RegExp(/^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/);
        if (validateNumberPlate.test(number) || bhNumber.test(number)) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const {classes} = this.props;
        const {values, touched, errors} = this.state;

        const showregistrationNumberError =
            touched.registrationNumber && errors.registrationNumber;
        const showCapacityError = touched.capcity && errors.capcity;
        const showselectedPartnerError =
            touched.selectedPartner && errors.selectedPartner;
        const showselectedDriverError =
            touched.selectedDriver && errors.selectedDriver;
        const showselectedTrackerError =
            touched.selectedTracker && errors.selectedTracker;

        return (
            <div className={classes.root}>
                <form autoComplete="off" noValidate method={"POST"} action={"#"}>
                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Basic Information
                        </Typography>
                        <TextField
                            className={classes.textField}
                            error={
                                !this.validate(values.registrationNumber) &&
                                values.registrationNumber !== undefined
                            }
                            helperText={
                                this.validate(values.registrationNumber) ||
                                values.registrationNumber === undefined
                                    ? null
                                    : "Please Enter Valid Registration number"
                            }
                            label="Registration Number 'eg. MH 12 XX XXXX'"
                            margin="dense"
                            fullWidth
                            required
                            value={values.registrationNumber}
                            id={"registrationNumber"}
                            onChange={event =>
                                this.handleFieldChange(
                                    "registrationNumber",
                                    event.target.value
                                        ?.toUpperCase()
                                        ?.replace(
                                            new RegExp(
                                                "^([A-Z]{2})([0-9]{2})([A-Z]{1,2})([0-9]{4})|([0-9]{2})(BH)([0-9]{4})([A-Z]{1,2})$"
                                            ),
                                            "$1 $2 $3 $4"
                                        )
                                )
                            }
                            variant="outlined"
                        />
                        <div>
                            {showregistrationNumberError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.registrationNumber[0]}
                                </Typography>
                            )}
                        </div>
                    </div>

                    <div className={classes.field}>
                        <TextField
                            className={classes.textField}
                            label="Capacity"
                            type="number"
                            fullWidth
                            onChange={event =>
                                this.handleFieldChange("capacity", event.target.value)
                            }
                            margin="dense"
                            helperText={"Min 3 digits"}
                            id={"capacity"}
                            value={values.capcity}
                            variant="outlined"
                        />
                        <div>
                            {showCapacityError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.capcity[0]}
                                </Typography>
                            )}
                        </div>
                        <Autocomplete
                            id="selectedPartner"
                            onChange={this.onTagsChange}
                            options={this.state.allPartnersData}
                            fullWidth
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Select Partner"
                                    variant="outlined"
                                    onChange={this.handleOnChange}
                                />
                            )}
                        />
                        <div>
                            {showselectedPartnerError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.selectedPartner[0]}
                                </Typography>
                            )}
                        </div>
                        <Autocomplete
                            id="combo-box-for-tracker"
                            onChange={this.onTagsChangeTracker}
                            options={this.state.allTrackes}
                            style={{margin: "10px 0"}}
                            fullWidth
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Select Tracker"
                                    variant="outlined"
                                    onChange={this.handleOnChange}
                                />
                            )}
                        />
                        <div>
                            {showselectedTrackerError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.selectedTracker[0]}
                                </Typography>
                            )}
                        </div>
                        <Autocomplete
                            id="combo-box-for-driver"
                            onChange={this.onTagsChangeDriver}
                            options={this.state.allDrivers}
                            style={{margin: "10px 0"}}
                            fullWidth
                            getOptionLabel={option => option.driver_name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Select Operator"
                                    variant="outlined"
                                    onChange={this.handleOnChange}
                                />
                            )}
                        />
                        <div>
                            {showselectedDriverError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.selectedDriver[0]}
                                </Typography>
                            )}
                        </div>
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel htmlFor="select_collection_type-native-simple">
                                Order By
                            </InputLabel>
                            <Select
                                fullWidth
                                native
                                value={values.setPickup}
                                onChange={event =>
                                    this.handleFieldChange("setPickup", event.target.value)
                                }
                                label="Order By"
                                inputProps={{
                                    name: "order_type",
                                    id: "select_order_type"
                                }}
                            >
                                <option value={0}>Door To Door Delivery</option>
                                <option value={1}>Self Pickup</option>
                            </Select>
                        </FormControl>
                        <div>
                            {showselectedDriverError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.setPickup}
                                </Typography>
                            )}
                        </div>
                        <FormControl
                            fullWidth
                            variant={"outlined"}
                            style={{margin: "10px 0"}}
                        >
                            <InputLabel htmlFor="select_collection_type-native-simple">
                                Authorisation
                            </InputLabel>
                            <Select
                                fullWidth
                                native
                                value={values.authorization}
                                onChange={event =>
                                    this.handleFieldChange("authorization", event.target.value)
                                }
                                label="Authorisation"
                                inputProps={{
                                    name: "authorisation",
                                    id: "authorisation"
                                }}
                            >
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.field}>
                        {this.props.refuelerCreationIsLoading ? (
                            <CircularProgress className={classes.progress}/>
                        ) : this.props.refuelerCreationSuccess ? (
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_partner_submit"
                                id="create_partner_submit"
                                // disabled={!this.state.isValid}
                                onClick={e => this.redirectOnSuccess(e)}
                                size="large"
                                variant="contained"
                            >
                                GO TO ALL RPP
                            </Button>
                        ) : (
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_partner_submit"
                                id="create_partner_submit"
                                disabled={
                                    !this.state.isValid ||
                                    (!this.validate(values.registrationNumber) &&
                                        values.registrationNumber !== undefined)
                                }
                                onClick={e => this.handleSubmit(e)}
                                size="large"
                                variant="contained"
                            >
                                Add New RPP
                            </Button>
                        )}
                        {this.props.refuelerCreationSuccess ? (
                            <Typography className={classes.fieldPassed} variant="body2">
                                {this.props.refuelerCreationSuccessMessage}
                            </Typography>
                        ) : (
                            ""
                        )}
                        {this.props.refuelerCreationError ? (
                            this.props.refuelerCreationSuccess ? (
                                ""
                            ) : (
                                <Typography className={classes.fieldError} variant="body2">
                                    Operator must be unique
                                </Typography>
                            )
                        ) : (
                            ""
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

CreateRefueler.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingPartner: state.isLoadingPartner,
        refuelerCreationIsLoading: state.refuelerCreationIsLoading,
        refuelerCreationError: state.refuelerCreationError,
        refuelerCreationSuccess: state.refuelerCreationSuccess,
        refuelerCreationSuccessMessage: state.refuelerCreationSuccessMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCreateRefueler: (accessToken, refuelerFormData) => {
            dispatch(createRefueler(accessToken, refuelerFormData));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(CreateRefueler));
