import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {Card, Grid, Typography, withStyles} from "@material-ui/core";
import compose from "recompose/compose";
import styles from "../../../AllAssets/style";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    overline: {
        marginTop: theme.spacing(1)
    }
}));

const RevenueStatistics = props => {
    const {className, ...rest} = props;
    const nf = new Intl.NumberFormat("en-IN");
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <Grid alignItems="center" container justify="space-between">
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        {" "}
                        ₹ {nf.format(props.revenueData?.revenue_data?.total_revenue)}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Total Revenue Generated
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        ₹{" "}
                        {nf.format(
                            props.revenueData?.revenue_data?.avg_diesel_revenue_per_customer
                        )}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Avg Revenue per Customer
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        ₹{" "}
                        {nf.format(
                            props.revenueData?.revenue_data?.avg_diesel_revenue_per_partner
                        )}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Avg Revenue per Partner
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        {" "}
                        ₹{" "}
                        {nf.format(
                            props.revenueData?.revenue_data?.avg_diesel_revenue_per_rpp
                        )}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Avg Diesel Revenue per RPP
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

RevenueStatistics.propTypes = {
    className: PropTypes.string
};

export default compose(withStyles(styles))(RevenueStatistics);
