import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
// Material components
import {Paper, TablePagination, Tooltip, Typography, withStyles} from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@material-ui/core/Dialog";
import EditDriver from "../EditDriver/EditDriver";
import List from "@material-ui/core/List";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {EditOutlined as EditIcon} from "@material-ui/icons";
import secureLocalStorage from "react-secure-storage";

class Cards extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        open: false,
        imageUrl: "",
        users: [],
        right: false
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    resetPasswordBtnSubmit = (event, driverId) => {
        event.preventDefault();
        this.props.resetPassword(driverId);
    };

    reloadRequest = () => {
        this.setState({
            right: false
        });
        this.props.reloadRequest();
    };

    toggleDrawer = (anchor, open, driver) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        this.setState({
            [anchor]: open,
            singleDriver: driver
        });
    };

    openDialog(imageUrl) {
        this.setState({
            open: true,
            imageUrl: imageUrl
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    render() {
        const {classes, allDriverData} = this.props;

        if (this.props.dataCount === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else {
                return (
                    <>
                        {/* {(this.props.isLoading) ? <div className={classes.progressWrapper}>
                                                        <LinearProgress fullWidth className={classes.progress}/>
                                                    </div> : null}
                    {
                        this.props.noDataFound ? <NoDatafound/> :  */}
                        <>
                            {allDriverData.map(driver => (
                                <Box sx={{flexGrow: 2}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Paper elevation={2} className={classes.paper}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Box
                                                            py={1}
                                                            px={2}
                                                            display="flex"
                                                            className={classes.header}
                                                            style={{
                                                                background:
                                                                    "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                                            }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                className={classes.textWhite}
                                                                style={{color: "primary"}}
                                                            >
                                                                {driver.driver_name === ""
                                                                    ? "Repos Operator"
                                                                    : driver.driver_name}
                                                            </Typography>
                                                            <Button
                                                                onClick={this.toggleDrawer(
                                                                    "right",
                                                                    true,
                                                                    driver
                                                                )}
                                                                disabled={
                                                                    secureLocalStorage.getItem("access_type") ===
                                                                    false
                                                                }
                                                            >
                                                                <EditIcon
                                                                    driver={driver}
                                                                    style={{color: "white"}}
                                                                />
                                                            </Button>
                                                        </Box>
                                                        <Box p={2} spacing={2}>
                                                            <Grid
                                                                container
                                                                spacing={3}
                                                                align="space-around"
                                                                justifyContent="center"
                                                            >
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Mobile Number
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                            align="center"
                                                                        >
                                                                            {driver.mobile === null
                                                                                ? "Not available"
                                                                                : driver.mobile}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Vehicle Number
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                            align="center"
                                                                            style={{wordBreak: "break-word"}}
                                                                        >
                                                                            {driver.vehicle_registration_number ===
                                                                            null
                                                                                ? "Not available"
                                                                                : driver.vehicle_registration_number}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                {/* <Grid item xs={4}>
                                                        <Box
                                                            px={1} py={0}
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                        <Typography variant="subtitle2" color="textPrimary" align="center">
                                                            Serial Number
                                                        </Typography>
                                                        <Typography color="textSecondary" variant="body2">
                                                        {tracker.serial_number}
                                                        </Typography>
                                                        </Box>
                                                    </Grid> */}
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className={classes.marginRightAuto}
                                                                >
                                                                    <Box
                                                                        px={1}
                                                                        py={0}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textPrimary"
                                                                            align="center"
                                                                        >
                                                                            Partner Name
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                            align="center"
                                                                        >
                                                                            {driver.partner_name}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={12} align={"right"}>
                                                                    <Box px={2}>
                                                                        <Tooltip
                                                                            title="Reset Password"
                                                                            aria-label="reset"
                                                                        >
                                                                            <IconButton
                                                                                onClick={event =>
                                                                                    this.resetPasswordBtnSubmit(
                                                                                        event,
                                                                                        driver.id
                                                                                    )
                                                                                }
                                                                                aria-label="delete"
                                                                                className={classes.margin}
                                                                                size="small"
                                                                                disabled={
                                                                                    secureLocalStorage.getItem(
                                                                                        "access_type"
                                                                                    ) === false
                                                                                }
                                                                            >
                                                                                <RotateLeftIcon
                                                                                    color={"primary"}
                                                                                    className={classes.extendedIcon}
                                                                                >
                                                                                    Reset
                                                                                </RotateLeftIcon>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </>
                        {/* // } */}

                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose.bind(this)}
                        >
                            <DialogTitle>License Image</DialogTitle>
                            <DialogContent>
                                <img src={this.state.imageUrl} alt=""/>
                            </DialogContent>
                        </Dialog>
                        <Drawer
                            anchor={"right"}
                            open={this.state.right}
                            onClose={this.toggleDrawer("right", false)}
                        >
                            <List className={classes.list}>
                                <div>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <img
                                                alt="add_driver_logo"
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    margin: "10px",
                                                    borderRadius: "50px"
                                                }}
                                                src={"/images/transport.png"}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography
                                                className={classes.nameText}
                                                variant="h3"
                                                style={{
                                                    marginTop: "10%",
                                                    marginLeft: "5px",
                                                    color: "#808080"
                                                }}
                                            >
                                                Modify Operator
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <EditDriver
                                        driver={this.state.singleDriver}
                                        modifyOperatorSuccess={this.reloadRequest}
                                    />
                                </div>
                            </List>
                        </Drawer>
                        {this.props.noDataFound ? (
                            ""
                        ) : (
                            <TablePagination
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                component="div"
                                count={this.props.dataCount}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                page={this.props.page}
                                rowsPerPage={this.props.rowsPerPage}
                                rowsPerPageOptions={[10, 20, 50]}
                            />
                        )}
                    </>
                );
            }
        }
    }
}

Cards.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

Cards.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withStyles(styles)(Cards);
