import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
import classNames from "classnames";
// Material helpers
// Material components
import {Input, withStyles} from "@material-ui/core";
// Material icons
import {Search as SearchIcon} from "@material-ui/icons";
// Component styles
import styles from "./styles";

class SearchInput extends Component {
    state = {
        searchString: ""
    };

    handleFieldChange = value => {
        this.setState({
            searchString: value
        });
        value.replace(" ", "");
        if (value.length >= 3 || value.length === 0) {
            // if (value.length >= 3){
            this.props.getsearchterm(value);
        }
    };

    render() {
        const {classes, className, onChange, style, ...rest} = this.props;
        const rootClassName = classNames(classes.root, className);
        return (
            <div className={rootClassName} style={style}>
                <SearchIcon className={classes.icon}/>
                <Input
                    {...rest}
                    searchString={this.state.searchString}
                    value={
                        this?.props?.isDatum
                            ? this.props.searchValue
                            : this.state.searchString
                    }
                    className={classes.input}
                    style={{width: "220px"}}
                    id={"search_input"}
                    disableUnderline
                    onChange={event => {
                        this?.props?.isDatum
                            ? this?.props?.getsearchterm(event.target.value)
                            : this.handleFieldChange(event.target.value);
                    }}
                />
            </div>
        );
    }
}

SearchInput.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object
};

export default withStyles(styles)(SearchInput);
