import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
// Material helpers
// Material components
import {withStyles} from "@material-ui/core";
// Shared layouts
import {Dashboard as DashboardLayout} from "layouts";
// Custom components
import {UsersTable, UsersToolbar} from "./components";
// Component styles
import styles from "./style";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {getAllDrivers, resetDriverPassword, versionControl} from "../../actions/actions";
import {withSnackbar} from "notistack";
import axios from "axios";
import {Breakpoint} from "react-socks";
import Cards from "views/AllDrivers/components/Cards/Cards";
import secureLocalStorage from "react-secure-storage";

class AllDrivers extends Component {
    signal = true;

    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0,
            open: true
        };
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getDriverData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getDriverData("");
        }
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page});
        this.getDriverData(this.state.searchString, limit, offset);
    };

    getDriverData = (
        searchTerm = "",
        limit = this.state.rowsPerPage,
        offset = 0,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllDrivers(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            abortController
        );
    };

    resetPassword = selectedDriver => {
        this.props.handleResetPassword(
            secureLocalStorage.getItem("accessToken"),
            selectedDriver,
            this.props
        );
    };

    reloadRequest = () => {
        this.getDriverData();
    };

    versionControl = () => {
        this.versionControl();
        this.props.version();
    };

    componentDidMount() {
        this.getDriverData();
    }

    render() {
        const {classes} = this.props;
        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="All Operators">
                    <div className={classes.root}>
                        <UsersToolbar
                            searchedTerm={this.onSearchTerm}
                            allDrivers={this.props.allDriverData}
                            reloadRequest={this.reloadRequest}
                        />
                        <Breakpoint medium up>
                            <UsersTable
                                allDriverData={this.props.allDriverData}
                                dataCount={this.props.dataCount}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPagination={this.onPagination}
                                noDataFound={this.props.noDataFound}
                                isLoading={this.props.isLoading}
                                resetPassword={this.resetPassword}
                                reloadRequest={this.reloadRequest}
                                passwordResetSuccess={this.props.passwordResetSuccess}
                            />
                        </Breakpoint>
                        <Breakpoint small down>
                            <Cards
                                allDriverData={this.props.allDriverData}
                                dataCount={this.props.dataCount}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPagination={this.onPagination}
                                noDataFound={this.props.noDataFound}
                                isLoading={this.props.isLoading}
                                resetPassword={this.resetPassword}
                                reloadRequest={this.reloadRequest}
                                passwordResetSuccess={this.props.passwordResetSuccess}
                            />
                        </Breakpoint>
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllDrivers.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allDriverData: state.allDriverData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        isLoading: state.isLoading,
        passwordResetSuccess: state.passwordResetSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllDrivers: (
            accessToken,
            searchString,
            limit,
            offset,
            abortController
        ) => {
            dispatch(
                getAllDrivers(accessToken, searchString, limit, offset, abortController)
            );
        },
        handleResetPassword: (accessToken, driverId, snackBar) => {
            dispatch(resetDriverPassword(accessToken, driverId, snackBar));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllDrivers)));
