import React from "react";
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import styles from "../../../AllAssets/style";
import compose from "recompose/compose";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Portlet, PortletContent} from "components";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import {EditOutlined as EditIcon, MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import {Skeleton} from "@material-ui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import secureLocalStorage from "react-secure-storage";
import Checkbox from "@material-ui/core/Checkbox";
import {LeadSourceChoice} from "../../../../constants/constants";

const UsersTable = props => {
    const {
        stateData,
        onPagination,
        rowsPerPage,
        page,
        handleCall,
        selected,
        setSelected,
        param,
        setListLeads,
        setDrawerOpen,
        setTitle,
        HandleDrawer,
        handleDelete,
        className,
        classes,
        ...rest
    } = props;

    /**
     * Handles the change of the page in the pagination.
     *
     * @param {Event} event - The event object triggered by the page change.
     * @param {number} page - The new page number.
     * @return {void} This function does not return anything.
     */

    const handleChangePage = (event, page) => {
        onPagination(rowsPerPage, page, null);
    };

    /**
     * Handles the change in the number of rows per page.
     *
     * @param {Event} event - The event object triggered by the user's action.
     * @return {void} This function does not return a value.
     */

    const handleChangeRowsPerPage = event => {
        onPagination(event.target.value, 0, null);
    };

    /****************************** select check box logic ********************************/

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = stateData?.allUserLeadData?.map(n => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    /****************************** *********** ********************************/

    const findTheValueOfLeadSource = keys => {
        const foundItem = LeadSourceChoice.find(item => item.keys === Number(keys));

        if (foundItem) {
            return foundItem.value; // Output: 'INTERNAL_REFERENCE'
        } else {
            return "Not found";
        }
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Portlet className={classes.root} style={{marginTop: "16px"}}>
                <PortletContent noPadding>
                    <PerfectScrollbar>
                        <TableContainer style={{maxHeight: 640}}>
                            <Table>
                                <TableHead>
                                    <TableRow
                                        style={{
                                            backdropFilter: "blur(5px)",
                                            background: "rgb(255 255 255 / 36%)",
                                            backgroundBlendMode: "exclusion",
                                            boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 10
                                        }}
                                    >
                                        <TableCell align="center">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleSelectAllClick}
                                                        indeterminate={
                                                            selected?.length > 0 &&
                                                            selected?.length <
                                                            stateData?.allUserLeadData?.length
                                                        }
                                                        checked={
                                                            stateData?.allUserLeadData?.length > 0 &&
                                                            selected.length ===
                                                            stateData?.allUserLeadData?.length
                                                        }
                                                        color="primary"
                                                        value="true"
                                                        inputProps={{
                                                            "aria-label": "select all id"
                                                        }}
                                                    />
                                                }
                                                label={selected?.length !== 0 ? " Selected" : "Select"}
                                            />
                                        </TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                        <TableCell align="center">Lead Created By</TableCell>
                                        <TableCell align="center">Lead owner name</TableCell>
                                        <TableCell align="center">Lead Passed To</TableCell>
                                        <TableCell align="center">Company Name</TableCell>
                                        <TableCell align="center">Partner Name</TableCell>
                                        <TableCell align="center">State</TableCell>
                                        <TableCell align="center">Territory</TableCell>
                                        <TableCell align="center">Lead Source</TableCell>
                                        <TableCell align="center">Company mobile</TableCell>
                                        <TableCell align="center">Product name</TableCell>
                                        {/*<TableCell align="center">Lead Status</TableCell>*/}
                                        <TableCell align="center">Lead Stage</TableCell>
                                        <TableCell align="center">Lead Score</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        {/* <TableCell align="center">Booking Amount</TableCell> */}
                                        <TableCell align="center">Actionable</TableCell>
                                        {(secureLocalStorage.crm_rank === "1" ||
                                            secureLocalStorage.crm_rank === "3") && (
                                            <TableCell align="center">Delete</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                {stateData.noDataFound ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={"16"}>
                                                <NoDatafound/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : stateData?.isLeadTableLoading ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={"16"}>
                                                <LinearProgress
                                                    fullWidth
                                                    className={classes.progress}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {stateData?.allUserLeadData?.map((listLead, index) => {
                                            const isItemSelected = isSelected(listLead.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    className={classes.tableRow}
                                                    hover
                                                    tabIndex={-1}
                                                    key={listLead.id}
                                                >
                                                    {/**
                                                     * Renders a table cell containing a checkbox.
                                                     *
                                                     * @returns {JSX.Element} The rendered table cell with a checkbox.
                                                     */}

                                                    <TableCell padding="checkbox" align="center">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            onClick={event => handleClick(event, listLead.id)}
                                                            inputProps={{
                                                                "aria-labelledby": labelId
                                                            }}
                                                        />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <div>
                                                            {stateData?.isLoading ? (
                                                                <Box>
                                                                    <Skeleton
                                                                        color="inherit"
                                                                        style={{height: "30px", width: "38px"}}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        onClick={() =>
                                                                            HandleDrawer("Edit", listLead)
                                                                        }
                                                                    >
                                                                        <EditIcon
                                                                            className={classes.extendedIcon}
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        </div>

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color: listLead?.created_by
                                                                    ? "#000000"
                                                                    : "#d55c5c"
                                                            }}
                                                        >
                                                            {listLead?.created_by_name
                                                                ? listLead?.created_by_name
                                                                : "no data"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color: listLead.lead_owner_name
                                                                    ? "#808080"
                                                                    : "#d55c5c"
                                                            }}
                                                        >
                                                            {listLead.lead_owner_name
                                                                ? listLead.lead_owner_name
                                                                : "no data"}
                                                        </Typography>
                                                    </TableCell>


                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color: listLead?.created_by
                                                                    ? "#000000"
                                                                    : "#d55c5c"
                                                            }}
                                                        >
                                                            {listLead?.passed_to
                                                                ? listLead?.passed_to
                                                                : "no data"}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{color: "#808080"}}
                                                        >
                                                            {listLead.company_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{color: "#808080"}}
                                                        >
                                                            {listLead.partner_name}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color: listLead.partner_state
                                                                    ? "#808080"
                                                                    : "#d55c5c"
                                                            }}
                                                        >
                                                            {listLead.partner_state
                                                                ? listLead.partner_state
                                                                : "no data"}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color: listLead.partner_territory
                                                                    ? "#808080"
                                                                    : "#d55c5c"
                                                            }}
                                                        >
                                                            {listLead.partner_territory
                                                                ? listLead.partner_territory
                                                                : "no data"}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{
                                                                color:
                                                                    findTheValueOfLeadSource(
                                                                        listLead.lead_source
                                                                    ) !== "Not found"
                                                                        ? "#0c0c0c"
                                                                        : "#d55c5c",
                                                                fontWeight: 900
                                                            }}
                                                        >
                                                            {findTheValueOfLeadSource(listLead.lead_source)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{color: "#808080"}}
                                                        >
                                                            {parseFloat(listLead.company_mobile)}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{color: "#808080"}}
                                                        >
                                                            {listLead.product_display === "RPP"
                                                                ? "RFS"
                                                                : listLead.product_display}
                                                        </Typography>
                                                    </TableCell>

                                                    {/*<TableCell align="center">*/}
                                                    {/*    <Typography*/}
                                                    {/*        className={classes.nameText}*/}
                                                    {/*        variant="body1"*/}
                                                    {/*        style={{*/}
                                                    {/*            color: listLead.lead_status_display*/}
                                                    {/*                ? "#808080"*/}
                                                    {/*                : "#d55c5c"*/}
                                                    {/*        }}*/}
                                                    {/*    >*/}
                                                    {/*        {listLead.lead_status_display*/}
                                                    {/*            ? listLead.lead_status_display*/}
                                                    {/*            : "no data"}*/}
                                                    {/*    </Typography>*/}
                                                    {/*</TableCell>*/}

                                                    <TableCell align="center">
                                                        <Typography
                                                            className={classes.nameText}
                                                            variant="body1"
                                                            style={{color: "#808080"}}
                                                        >
                                                            {listLead?.lead_stage_display}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {listLead.lead_score === null ||
                                                        listLead.lead_score === "" ? (
                                                            <Typography
                                                                variant={"overline"}
                                                                color={"error"}
                                                                style={{textTransform: "none"}}
                                                            >
                                                                Not Available
                                                            </Typography>
                                                        ) : (
                                                            listLead?.lead_score
                                                        )}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {listLead.quantity === null ||
                                                        listLead.quantity === "" ? (
                                                            <Typography
                                                                variant={"overline"}
                                                                color={"error"}
                                                                style={{textTransform: "none"}}
                                                            >
                                                                Not Available
                                                            </Typography>
                                                        ) : (
                                                            listLead.quantity
                                                        )}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Box display={"flex"} flexDirection={"row"}>
                                                            <div>
                                                                {stateData?.isLoading ? (
                                                                    <Box mr={2}>
                                                                        <Skeleton
                                                                            color="inherit"
                                                                            style={{height: "30px", width: "38px"}}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Tooltip title="Nurturing">
                                                                        <Button
                                                                            onClick={() =>
                                                                                HandleDrawer("Nurturing", listLead)
                                                                            }
                                                                        >
                                                                            <img
                                                                                src="/images/nuturing.svg"
                                                                                alt="nuturing"
                                                                            />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                            {/*<div>*/}
                                                            {/*    {stateData?.isLoading ? (*/}
                                                            {/*        <Box mr={2}>*/}
                                                            {/*            <Skeleton*/}
                                                            {/*                color="inherit"*/}
                                                            {/*                style={{height: "30px", width: "38px"}}*/}
                                                            {/*            />*/}
                                                            {/*        </Box>*/}
                                                            {/*    ) : listLead.product === 2 ||*/}
                                                            {/*    listLead.product === 3 ? (*/}
                                                            {/*        <Tooltip title="Questionnaire">*/}
                                                            {/*            <Button*/}
                                                            {/*                onClick={() =>*/}
                                                            {/*                    HandleDrawer("Questionnaire", listLead)*/}
                                                            {/*                }*/}
                                                            {/*            >*/}
                                                            {/*                <InfoOutlinedIcon/>*/}
                                                            {/*            </Button>*/}
                                                            {/*        </Tooltip>*/}
                                                            {/*    ) : (*/}
                                                            {/*        <Tooltip title=".">*/}
                                                            {/*            <Button>/!* <InfoOutlinedIcon/> *!/</Button>*/}
                                                            {/*        </Tooltip>*/}
                                                            {/*    )}*/}
                                                            {/*</div>*/}

                                                            <div>
                                                                {stateData?.isLoading ? (
                                                                    <Box mr={2}>
                                                                        <Skeleton
                                                                            color="inherit"
                                                                            style={{height: "30px", width: "38px"}}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Tooltip title="Add Product Details">
                                                                        <Button
                                                                            onClick={() =>
                                                                                HandleDrawer(
                                                                                    "Add Product Details",
                                                                                    listLead
                                                                                )
                                                                            }
                                                                        >
                                                                            <ProductionQuantityLimitsIcon/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </div>

                                                            {
                                                                <Tooltip
                                                                    title={
                                                                        listLead.product === 1 ||
                                                                        listLead.product === 3
                                                                            ? !listLead?.rpp_invoice_type ||
                                                                            !listLead?.rpp_loading_type ||
                                                                            !listLead?.rpp_chassis ||
                                                                            !listLead?.rpp_variant
                                                                                ? "Please Fill The Product Details"
                                                                                : ""
                                                                            : !listLead.datum_variant
                                                                                ? "Please Fill The Product Details"
                                                                                : ""
                                                                    }
                                                                >
                                                                    <div>
                                                                        {stateData?.isLoading ? (
                                                                            <Box mr={2}>
                                                                                <Skeleton
                                                                                    color="inherit"
                                                                                    style={{
                                                                                        height: "30px",
                                                                                        width: "38px"
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        ) : (
                                                                            <Tooltip title="Negotiation">
                                                                                <Button
                                                                                    disabled={
                                                                                        listLead.product === 1 || listLead.product === 3 ?
                                                                                            !listLead?.rpp_invoice_type ||
                                                                                            !listLead?.rpp_loading_type ||
                                                                                            !listLead?.rpp_chassis ||
                                                                                            !listLead?.rpp_variant
                                                                                            : !listLead.datum_variant
                                                                                    }
                                                                                    onClick={() =>
                                                                                        HandleDrawer(
                                                                                            "Negotiation",
                                                                                            listLead
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <CurrencyRupeeIcon/>
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </Tooltip>
                                                            }

                                                            <div>
                                                                {stateData?.isLoading ? (
                                                                    <Box mr={2}>
                                                                        <Skeleton
                                                                            color="inherit"
                                                                            style={{height: "30px", width: "38px"}}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Tooltip title="Purchase Order">
                                                                        <Button
                                                                            onClick={() =>
                                                                                HandleDrawer("Purchase Order", listLead)
                                                                            }
                                                                        >
                                                                            <FileUploadOutlinedIcon/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </div>

                                                            <div>
                                                                {stateData?.isLoading ? (
                                                                    <Box mr={2}>
                                                                        <Skeleton
                                                                            color="inherit"
                                                                            style={{height: "30px", width: "38px"}}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Tooltip title="Booking Amount">
                                                                        <Button
                                                                            onClick={() =>
                                                                                HandleDrawer("Booking Amount", listLead)
                                                                            }
                                                                        >
                                                                            <BookmarkAddedOutlinedIcon/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </div>

                                                            {stateData?.isLoading ? (
                                                                <Box>
                                                                    <Skeleton
                                                                        color="inherit"
                                                                        style={{height: "30px", width: "38px"}}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Tooltip title="More">
                                                                    <Button>
                                                                        <MoreIcon
                                                                            onClick={() =>
                                                                                HandleDrawer(
                                                                                    "More Information",
                                                                                    listLead
                                                                                )
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                    {(secureLocalStorage.getItem("crm_rank") === 1 ||
                                                        secureLocalStorage.getItem("crm_rank") === 2 ||
                                                        secureLocalStorage.getItem("crm_rank") === 3 ||
                                                        secureLocalStorage.getItem("crm_rank") === 14) && (
                                                        <TableCell className={classes.tableCell}>
                                                            {stateData?.isLoading ? (
                                                                <Box>
                                                                    <Skeleton
                                                                        color="inherit"
                                                                        style={{
                                                                            height: "30px",
                                                                            width: "38px"
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                (secureLocalStorage.getItem("department") ===
                                                                    13 ||
                                                                    secureLocalStorage.getItem("department") ===
                                                                    10 ||
                                                                    secureLocalStorage.getItem("department") ===
                                                                    5 ||
                                                                    secureLocalStorage.getItem("department") ===
                                                                    1) &&
                                                                secureLocalStorage.getItem("access_type") ===
                                                                true && (
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            onClick={() => handleDelete(listLead.id)}
                                                                        >
                                                                            <DeleteIcon/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )
                                                            )}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>

                        <Divider/>
                        {!stateData?.noDataFound &&
                            (stateData?.isLoading ? (
                                <LinearProgress fullWidth className={classes.progress}/>
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={stateData.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    // onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[10, 100, 500]}
                                    onPageChange={handleChangePage}
                                />
                            ))}
                    </PerfectScrollbar>
                </PortletContent>
            </Portlet>
        </div>
    );
};

UsersTable.propTypes = {
    className: PropTypes.string,
    stateData: PropTypes.object,
    onPagination: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    handleCall: PropTypes.func,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    param: PropTypes.object,
    classes: PropTypes.object
};
export default compose(withStyles(styles))(UsersTable);
