import React, {Component} from "react";
import {GoogleApiWrapper, Map, Polygon} from "google-maps-react";
import InfoWindowEx from "../../../../components/GoogleMaps/InfoWindowEx";

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {}
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props.place_,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    render() {
        const style = {
            width: "100%",
            height: "50vh"
        };

        return (
            <div style={style}>
                <Map
                    google={this.props.google}
                    initialCenter={{
                        lat: this.props.coords[0].lat,
                        lng: this.props.coords[1].lng
                    }}
                    style={{width: "100%", height: "100%", position: "relative"}}
                    className={"map"}
                    zoom={13}
                >
                    <Polygon
                        onClick={this.onMarkerClick}
                        key={"place.id"}
                        place_={"place"}
                        position={{
                            lat: this.props.coords[0].lat,
                            lng: this.props.coords[1].lng
                        }}
                        paths={this.props.coords}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#0000FF"
                        fillOpacity={0.35}
                    />
                    <InfoWindowEx
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                    >
                        <div>
                            <h3>{this.props.markerInfo}</h3>
                        </div>
                    </InfoWindowEx>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyC5-RRQjjGOPGIAFt-tuy6ClXrjjFZFvxw"
})(MapContainer);
