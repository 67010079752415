import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
// Material helpers
// Material components
import {Box, Grid, withStyles} from "@material-ui/core";
// Shared layouts
import {Dashboard as DashboardLayout} from "layouts";
// Custom components
import {Profit, RevenueStatistics, Users} from "./components";
import {connect} from "react-redux";
import compose from "recompose/compose";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import OrderStatisticsInsights from "./components/OrderStatistics/OrderStatisticsInsights";
import Orders from "./components/Orders";
import Refuelers from "./components/Refuelers";
import {getAllDashboardData, versionControl} from "../../actions/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomerStatistics from "./components/CustomerStatistics";
import RPPStatistics from "./components/RPPStatistics";
import ReposOrderInsights from "./components/OrderStatistics/ReposOrderInsights";
import OrdersDirectMode from "./components/OrdersDirectMode";
import DatumStatistics from "./components/DatumStatistics/DatumStatistics";
import secureLocalStorage from "react-secure-storage";

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    item: {
        height: "100%"
    },
    internalCard: {
        margin: "15px",
        height: "100%"
    },
    nameText: {
        display: "inline-block",
        fontWeight: 300,
        color: "gray"
    },
    nameText1: {
        display: "inline-block",
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: "300",
        fontSize: "1rem"
    }
    // progressBar:{
    //     "& .MuiLinearProgress-colorPrimary": {
    //         //backgroundColor: "red",
    //         backgroundColor: "linearGradient(0deg, #138808 33%, #ffffff 33%, #ffffff 66%, #FF9933 66% )"
    //     },
    //     "& .MuiLinearProgress-barColorPrimary": {
    //         //backgroundColor: "green",
    //         background: "linear-gradient(0deg, #138808 33%, #ffffff 33%, #ffffff 66%, #FF9933 66% )"
    //     },
    // }
});

class Dashboard extends Component {
    state = {
        selected: "",
        open: true,
        setOpen: true,
        showStatistics: false
    };

    handleClick() {
        this.state.setOpen(true);
    }

    handleClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }

        this.state.setOpen(false);
    }

    versionControl = () => {
        this.versionControl();
        this.props.version();
    };

    toggleStatistics = () => {
        this.setState(prevState => ({
            showStatistics: !prevState.showStatistics
        }));
    };

    componentDidMount() {
        this.props.handleGetAllDashboardData(
            secureLocalStorage.getItem("accessToken")
        );
    }

    render() {
        const {classes} = this.props;
        const {showStatistics} = this.state;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
            return (
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        open={this.state.open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        ContentProps={{
                            "aria-describedby": "message-id"
                        }}
                        message={<span id="message-id">Note archived</span>}
                        action={[]}
                    />
                </div>
            );
        } else {
            return (
                <DashboardLayout title="Dashboard">
                    {this.props.dashboardData === null ? (
                        <div className={classes.progressWrapper}>
                            <LinearProgress
                                variant={"indeterminate"}
                                className={classes.progressBar}
                            />
                        </div>
                    ) : (
                        <div className={classes.root}>
                            <Grid container spacing={4}>
                                <Grid item lg={3} sm={6} xl={3} xs={12}>
                                    <Orders
                                        ordersData={this.props.dashboardData?.order_data}
                                        directModeData={this.props.directModeData}
                                        className={classes.item}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xl={3} xs={12}>
                                    <Users
                                        customerData={this.props.dashboardData?.customer_data}
                                        className={classes.item}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xl={3} xs={12}>
                                    <Refuelers
                                        refuelerData={this.props.dashboardData?.refueler_data}
                                        className={classes.item}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xl={3} xs={12}>
                                    <Profit
                                        className={classes.item}
                                        allDatumData={this.props.dashboardData}
                                    />
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Typography className={classes.nameText} variant="h3">
                                        Orders Overview - Direct Mode
                                    </Typography>
                                    <Typography
                                        className={classes.nameText}
                                        variant="caption"
                                        display="block"
                                        style={{float: "right"}}
                                        gutterBottom
                                    >
                                        (*Quantity is in Litres*)
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <OrdersDirectMode
                                        orderData={this.props.dashboardData?.order_data}
                                    />
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Typography className={classes.nameText} variant="h3">
                                        Orders Overview - OTP Mode
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <ReposOrderInsights
                                        otpModeData={this.props.directModeData?.otp_mode}
                                        ordersStatsData={this.props.dashboardData?.order_data}
                                    />
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Typography className={classes.nameText} variant="h4">
                                            Order Insights
                                        </Typography>
                                        <Typography
                                            className={classes.nameText1}
                                            component={NavLink}
                                            to="/OrderInsights"
                                        >
                                            View More
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <OrderStatisticsInsights
                                        orderData={this.props.dashboardData?.order_data}
                                    />
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Typography className={classes.nameText} variant="h3">
                                            Customer Overview
                                        </Typography>
                                        <Typography
                                            className={classes.nameText1}
                                            component={NavLink}
                                            to="/CustomerOverview"
                                        >
                                            View More
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <CustomerStatistics
                                        orderData={this.props.dashboardData?.order_data}
                                        customerData={this.props.dashboardData?.customer_data}
                                    />
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Typography className={classes.nameText} variant="h3">
                                            Repos Petrol Pumps and Partners Overview
                                        </Typography>
                                        <Typography
                                            className={classes.nameText1}
                                            component={NavLink}
                                            to="/PartnerOverview"
                                        >
                                            View More
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <RPPStatistics cutomerData={this.props.dashboardData}/>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Typography className={classes.nameText} variant="h3">
                                            Revenue Overview
                                        </Typography>
                                        <Typography
                                            className={classes.nameText1}
                                            onClick={this.toggleStatistics}
                                        >
                                            Show Statistics
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    {showStatistics && (
                                        <RevenueStatistics revenueData={this.props.dashboardData}/>
                                    )}
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Typography className={classes.nameText} variant="h3">
                                            Datum Overview
                                        </Typography>
                                        <Typography
                                            className={classes.nameText1}
                                            component={NavLink}
                                            to="/DatumOverview"
                                        >
                                            View More
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <DatumStatistics datumData={this.props.dashboardData}/>
                                </Grid>
                                {/*<Grid*/}
                                {/*    item*/}
                                {/*    lg={4}*/}
                                {/*    md={6}*/}
                                {/*    xl={3}*/}
                                {/*    xs={12}*/}
                                {/*>*/}
                                {/*  <ProductList className={classes.item} />*/}
                                {/*</Grid>*/}
                                {/*<Grid*/}
                                {/*    item*/}
                                {/*    lg={8}*/}
                                {/*    md={12}*/}
                                {/*    xl={9}*/}
                                {/*    xs={12}*/}
                                {/*>*/}
                                {/*  <OrdersTable className={classes.item} />*/}
                                {/*</Grid>*/}
                            </Grid>
                        </div>
                    )}
                </DashboardLayout>
            );
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess,
        loginError: state.loginError,
        dashboardData: state.dashboardData,
        directModeData: state.directModeData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllDashboardData: accessToken => {
            dispatch(getAllDashboardData(accessToken));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(Dashboard));
