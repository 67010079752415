import React, {Component} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    colors,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import {Portlet, PortletContent} from "components";
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import Label from "../../../Dashboard/components/Label";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import {withSnackbar} from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import compose from "recompose/compose";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import reposWebApi from "../../../../actions/configuration";
import qs from "qs";
import {sendLogs} from "../../../../actions/actions";
import {connect} from "react-redux";
import secureLocalStorage from "react-secure-storage";

class UsersTable extends Component {
    state = {
        id: null,
        amount: 0,
        date: "",
        rowsPerPage: 10,
        page: 0,
        open: false,
        actionOpen: false,
        imageUrl: "",
        users: [],
        right: false,
        orderData: {},
        status: 0,
        snackIsOpen: false,
        paymentStatus: 0,
        selectedOrders: [],
        actionSelector: null,
        subActionSelect: null,
        delivered_quantity: "",
        deliveryMode: "DIR",
        orderStatus: "",
        orderType: "",
        deliveryModes: "",
        payment_status: "",
        order_status: "",
        done_by_lmd: false,
        orderState: []
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page, null);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0, null);
    };

    handleDrawerOpen = (e, side, open, order) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            right: open,
            orderData: order
        });
    };

    handleDrawerClose = () => {
        this.setState({
            right: false,
            actionOpen: false,
            actionSelector: null,
            subActionSelect: null,
            deliveryMode: "DIR",
            delivered_quantity: "",
            orderStatus: "",
            payment_status: "",
            selectedOrders: []
        });
    };

    handleSelectAll = event => {
        const {allOrdersData} = this.props;

        let selectedOrders;

        if (event.target.checked) {
            selectedOrders = allOrdersData.map(user => user.id);
        } else {
            selectedOrders = [];
        }

        this.props.onSelected(selectedOrders);

        this.setState({
            selectedOrders
        });
    };

    handleChange = event => {
        if (event.target.value === 2) {
            this.setState({
                subActionSelect: null,
                actionSelector: event.target.value,
                delivered_quantity: ""
            });
        } else {
            this.setState({
                actionSelector: event.target.value,
                delivered_quantity: ""
            });
        }
    };

    refreshData = () => {
        this.handleDrawerClose();
        const {refreshData} = this.props;
        refreshData();
    };

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    handleSubmit = () => {
        let requestBody;
        let requestBodyForPayment;
        if (this.state.actionSelector === "1") {
            requestBody = {
                order_status: this.state.subActionSelect,
                order_id: JSON.stringify(this.state.id),
                lmd_dispensed_quantity: this.state.delivered_quantity,
                lmd_order_mode: this.state.deliveryMode,
                date: this.state.date
            };
            reposWebApi
                .post("/order/change_order_status_lmd", qs.stringify(requestBody), {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        this.props.enqueueSnackbar("Status updated successfully!", {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center"
                            }
                        });
                        this.refreshData();
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.getItem("mobile"),
                            "/order/change_order_status_lmd",
                            qs.stringify(requestBody)
                        );
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.getItem("mobile"),
                            "/order/change_order_status_lmd",
                            qs.stringify(requestBody)
                        );
                    }
                    if (error.response.status === 500) {
                        this.props.enqueueSnackbar("Something went wrong!", {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center"
                            }
                        });
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.getItem("mobile"),
                            "/order/change_order_status_lmd",
                            qs.stringify(requestBody)
                        );
                    }
                });
        } else {
            requestBodyForPayment = {
                payment_mode: this.state.subActionSelect,
                order_id: JSON.stringify(this.state.id),
                amount: this.state.delivered_quantity,
                date: this.state.date
            };

            reposWebApi
                .post("/order/lmd_mark_as_paid", qs.stringify(requestBodyForPayment), {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        this.props.enqueueSnackbar("Payment mode updated successfully!", {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center"
                            }
                        });
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.getItem("mobile"),
                            "/order/lmd_mark_as_paid",
                            qs.stringify({
                                payment_mode: this.state.subActionSelect,
                                order_id: JSON.stringify(this.state.id),
                                amount: this.state.delivered_quantity
                            })
                        );
                        this.refreshData();
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                    } else {
                        this.props.enqueueSnackbar("Something went wrong!", {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center"
                            }
                        });
                    }
                });
        }
    };

    handleChangeSubAction = event => {
        this.setState({
            subActionSelect: event.target.value
        });
    };

    handleChangeDeliveredQuantityAndMode = e => {
        if (e.target.name === "delivered_quantity") {
            this.setState({
                [e.target.name]: parseFloat(e.target.value)
            });
        } else if (e.target.name === "date") {
            this.setState({
                [e.target.name]: e.target.value
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };

    handleClickOpen = (event, id, amount, payments_status, order_status) => {
        let newselectedOrders = [];
        newselectedOrders = newselectedOrders.concat(this.state.selectedOrders, id);
        this.setState({
            actionOpen: true,
            id: id,
            amount: amount,
            payment_status: payments_status,
            order_status: order_status,
            selectedOrders: newselectedOrders
        });
    };

    render() {
        const {classes, className, allOrdersData} = this.props;
        const currentDate = new Date();
        const currentDateString = currentDate.toISOString().split("T")[0];

        const nf = new Intl.NumberFormat("en-IN");
        const orderStatusColors = {
            1: colors.grey[600],
            2: colors.orange[600],
            3: colors.green[600],
            4: colors.red[600],
            5: colors.purple[600],
            6: colors.indigo[600],
            7: colors.amber[600],
            8: colors.yellow[600],
            10: colors.red[800],
            13: colors.blueGrey[800],
            14: colors.red["A700"],
            15: colors.blue[400]
        };

        const rootClassName = classNames(classes.root, className);

        // const handleChangePaymentMode = event => {
        //     this.setState({
        //         paymentStatus: event.target.value
        //     });
        // };

        // const handleMarkAsPaid = (event, orderId) => {
        //     event.preventDefault();
        //     this.props.changePaymentStatus(orderId, this.state.paymentStatus)
        // };

        // const handleOrderChangeSubmit = event => {
        //     this.props.changeStatus(this.state.orderData.id, this.state.status)
        // };

        if (this.props?.dataCount === 0) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props?.isLoading && allOrdersData?.length === 0) {
                return (
                    <div className={classes.progressWrapper}>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <>
                        <Portlet className={rootClassName}>
                            <PortletContent noPadding>
                                <PerfectScrollbar>
                                    <TableContainer style={{maxHeight: 640}}>
                                        <Table>
                                            {this.props?.isLoading ? (
                                                <TableCell colSpan={"16"}>
                                                    <div className={classes.progressWrapper}>
                                                        <LinearProgress
                                                            fullWidth
                                                            className={classes.progress}
                                                        />
                                                    </div>
                                                </TableCell>
                                            ) : null}
                                            <TableHead>
                                                <TableRow
                                                    style={{
                                                        backdropFilter: "blur(5px)",
                                                        background: "rgb(255 255 255 / 36%)",
                                                        backgroundBlendMode: "exclusion",
                                                        boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 10
                                                    }}
                                                >
                                                    {/*<TableCell padding="checkbox">*/}
                                                    {/*    <Checkbox*/}
                                                    {/*        checked={this.state.selectedOrders.length === allOrdersData.length}*/}
                                                    {/*        color="primary"*/}
                                                    {/*        indeterminate={*/}
                                                    {/*            this.state.selectedOrders.length > 0 &&*/}
                                                    {/*            this.state.selectedOrders.length < allOrdersData.length*/}
                                                    {/*        }*/}
                                                    {/*        onChange={this.handleSelectAll}*/}
                                                    {/*    />*/}
                                                    {/*</TableCell>*/}
                                                    <TableCell align="left">Action</TableCell>
                                                    <TableCell align="left">Delivery Date</TableCell>
                                                    <TableCell align="left">Company</TableCell>
                                                    <TableCell align="left">Quantity</TableCell>
                                                    <TableCell align="left">Partner</TableCell>
                                                    <TableCell align="left">Assigned RPP</TableCell>
                                                    <TableCell align="left">Total Amount</TableCell>
                                                    <TableCell align="left">Status</TableCell>
                                                    <TableCell align="center">More</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {this.props.noDataFound ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={"16"}>
                                                            <NoDatafound/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {allOrdersData?.map(order => (
                                                        <TableRow
                                                            className={classes.tableRow}
                                                            hover
                                                            key={order.id}
                                                            selected={
                                                                this.state.selectedOrders.indexOf(order.id) !==
                                                                -1
                                                            }
                                                        >
                                                            <TableCell padding="checkbox">
                                                                {order.status_code_int === 4 ||
                                                                order.status_code_int === 14 ? (
                                                                    ""
                                                                ) : (
                                                                    <Checkbox
                                                                        checked={
                                                                            this.state.selectedOrders.indexOf(
                                                                                order.id
                                                                            ) !== -1
                                                                        }
                                                                        color="primary"
                                                                        onChange={event =>
                                                                            this.handleClickOpen(
                                                                                event,
                                                                                order.id,
                                                                                order.grand_total_amount,
                                                                                order.payment_status,
                                                                                order.status
                                                                            )
                                                                        }
                                                                        value="true"
                                                                        disabled={
                                                                            secureLocalStorage.getItem(
                                                                                "access_type"
                                                                            ) === false
                                                                        }
                                                                        id={order?.id}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                {order.created_at}
                                                            </TableCell>
                                                            <TableCell
                                                                className={classes.tableCell}
                                                                style={{fontSize: 14}}
                                                            >
                                                                {order.customer_name}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                {nf.format(order.quantity)}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                {order.pump_name}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                {order.assigned_refueler}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                &#8377;{" "}
                                                                {nf.format(
                                                                    order.grand_total_amount === null
                                                                        ? "-"
                                                                        : order.grand_total_amount
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                <Label
                                                                    color={
                                                                        order.status_code_int === 1
                                                                            ? orderStatusColors["1"]
                                                                            : order.status_code_int === 2
                                                                                ? orderStatusColors["2"]
                                                                                : order.status_code_int === 3
                                                                                    ? orderStatusColors["3"]
                                                                                    : order.status_code_int === 4
                                                                                        ? orderStatusColors["4"]
                                                                                        : order.status_code_int === 5
                                                                                            ? orderStatusColors["5"]
                                                                                            : order.status_code_int === 6
                                                                                                ? orderStatusColors["6"]
                                                                                                : order.status_code_int === 7
                                                                                                    ? orderStatusColors["7"]
                                                                                                    : order.status_code_int === 8
                                                                                                        ? orderStatusColors["8"]
                                                                                                        : order.status_code_int === 10
                                                                                                            ? orderStatusColors["10"]
                                                                                                            : order.status_code_int === 13
                                                                                                                ? orderStatusColors["13"]
                                                                                                                : order.status_code_int === 14
                                                                                                                    ? orderStatusColors["14"]
                                                                                                                    : order.status_code_int === 15
                                                                                                                        ? orderStatusColors["15"]
                                                                                                                        : ""
                                                                    }
                                                                >
                                                                    {order.status_code_int === 1
                                                                        ? "Driver Assignment Pending"
                                                                        : order.status_code_int === 2
                                                                            ? "Assigned"
                                                                            : order.status_code_int === 3
                                                                                ? "Delivered"
                                                                                : order.status_code_int === 4
                                                                                    ? "Canceled"
                                                                                    : order.status_code_int === 5
                                                                                        ? "Dispensing"
                                                                                        : order.status_code_int === 6
                                                                                            ? "Dispatched"
                                                                                            : order.status_code_int === 7
                                                                                                ? "Completion Pending"
                                                                                                : order.status_code_int === 8
                                                                                                    ? "Pending"
                                                                                                    : order.status_code_int === 10
                                                                                                        ? "Aborted"
                                                                                                        : order.status_code_int === 13
                                                                                                            ? "Order Expired"
                                                                                                            : order.status_code_int === 14
                                                                                                                ? "Order Rejected"
                                                                                                                : order.status_code_int === 15
                                                                                                                    ? "Finserv Pending"
                                                                                                                    : ""}
                                                                </Label>
                                                            </TableCell>

                                                            <TableCell
                                                                className={classes.tableCell}
                                                                align={"left"}
                                                            >
                                                                <Button
                                                                    onClick={e =>
                                                                        this.handleDrawerOpen(
                                                                            e,
                                                                            "right",
                                                                            true,
                                                                            order
                                                                        )
                                                                    }
                                                                    style={{float: "right"}}
                                                                >
                                                                    <MoreIcon/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                                <Drawer
                                    anchor="right"
                                    open={this.state.right}
                                    onClose={this.handleDrawerClose}
                                >
                                    <div className={classes.root}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Order Id</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.order_id}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            color={
                                                                this.state.orderData.status_code_int === 1
                                                                    ? orderStatusColors["1"]
                                                                    : this.state.orderData.status_code_int === 2
                                                                        ? orderStatusColors["2"]
                                                                        : this.state.orderData.status_code_int === 3
                                                                            ? orderStatusColors["3"]
                                                                            : this.state.orderData.status_code_int === 4
                                                                                ? orderStatusColors["4"]
                                                                                : this.state.orderData.status_code_int === 5
                                                                                    ? orderStatusColors["5"]
                                                                                    : this.state.orderData.status_code_int === 6
                                                                                        ? orderStatusColors["6"]
                                                                                        : this.state.orderData.status_code_int === 7
                                                                                            ? orderStatusColors["7"]
                                                                                            : this.state.orderData.status_code_int === 8
                                                                                                ? orderStatusColors["8"]
                                                                                                : this.state.orderData.status_code_int === 10
                                                                                                    ? orderStatusColors["10"]
                                                                                                    : this.state.orderData.status_code_int === 13
                                                                                                        ? orderStatusColors["13"]
                                                                                                        : this.state.orderData.status_code_int === 14
                                                                                                            ? orderStatusColors["14"]
                                                                                                            : this.state.orderData.status_code_int === 15
                                                                                                                ? orderStatusColors["15"]
                                                                                                                : ""
                                                            }
                                                        >
                                                            {this.state.orderData.status_code_int === 1
                                                                ? "Driver Assignment Pending"
                                                                : this.state.orderData.status_code_int === 2
                                                                    ? "Assigned"
                                                                    : this.state.orderData.status_code_int === 3
                                                                        ? "Delivered"
                                                                        : this.state.orderData.status_code_int === 4
                                                                            ? "Canceled"
                                                                            : this.state.orderData.status_code_int === 5
                                                                                ? "Dispensing"
                                                                                : this.state.orderData.status_code_int === 6
                                                                                    ? "Dispatched"
                                                                                    : this.state.orderData.status_code_int === 7
                                                                                        ? "Completion Pending"
                                                                                        : this.state.orderData.status_code_int === 8
                                                                                            ? "Pending"
                                                                                            : this.state.orderData.status_code_int === 10
                                                                                                ? "Aborted"
                                                                                                : this.state.orderData.status_code_int === 13
                                                                                                    ? "Order Expired"
                                                                                                    : this.state.orderData.status_code_int === 14
                                                                                                        ? "Order Rejected"
                                                                                                        : this.state.orderData.status_code_int === 15
                                                                                                            ? "Finerv Pending"
                                                                                                            : ""}
                                                        </Label>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Asset Name</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.asset_name}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Customer Name</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.customer_name}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>ETA</TableCell>
                                                    <TableCell>
                                                        {!this.state.orderData.time_slot_alloted
                                                            ? "-"
                                                            : this.state.orderData.time_slot_alloted}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Last Updated</TableCell>
                                                    <TableCell>
                                                        {this.state.orderData.updated_at}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Delivery Date</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData?.delivery_date}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Delivered On</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.delivered_on === null
                                                            ? "NA"
                                                            : this.state.orderData.delivered_on}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Placed At</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData?.created_at}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align={"left"}>Quantity</TableCell>
                                                    <TableCell align="left">
                                                        {nf.format(
                                                            this.state.orderData.quantity
                                                                ? this.state.orderData.quantity
                                                                : "0"
                                                        )}{" "}
                                                        Litres
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Delivered Quantity</TableCell>
                                                    <TableCell align="left" style={{width: "150px"}}>
                                                        {this.state.orderData?.status === "DVD"
                                                            ? parseFloat(
                                                            this.state.orderData.delivered_quantity
                                                        )?.toFixed(2) + " Litres"
                                                            : "NA"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell align="left" style={{width: "150px"}}>
                                                        {this.state.orderData?.customer_address}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Rate</TableCell>
                                                    <TableCell align="left">
                                                        &#8377;{" "}
                                                        {nf.format(
                                                            this.state.orderData.rate
                                                                ? this.state.orderData.rate
                                                                : "0"
                                                        )}{" "}
                                                        /L
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Sub Total</TableCell>
                                                    <TableCell align="left">
                                                        &#8377;{" "}
                                                        {nf.format(
                                                            this.state.orderData.sub_total_amount
                                                                ? this.state.orderData.sub_total_amount
                                                                : "0"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Collected Amount</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.collected_amount
                                                            ? this.state.orderData.collected_amount
                                                            : "0"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Balance Amount</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.balance_amount
                                                            ? this.state.orderData.balance_amount
                                                            : "0"}
                                                    </TableCell>
                                                </TableRow>
                                                {this.state.orderData.credit_amount ? (
                                                    <TableRow>
                                                        <TableCell> Credit Amount </TableCell>
                                                        <TableCell align="left">
                                                            {this.state.orderData.credit_amount}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null}
                                                {this.state.orderData.credit_days ? (
                                                    <TableRow>
                                                        <TableCell> Credit Days </TableCell>
                                                        <TableCell align="left">
                                                            {this.state.orderData.credit_days} Days
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null}
                                                {(this.state.orderData.available_credit ||
                                                    this.state.orderData.available_credit === 0) &&
                                                this.state.orderData.is_finserv_enabled ? (
                                                    <TableRow>
                                                        <TableCell> Available Credit </TableCell>
                                                        <TableCell align="left">
                                                            {nf.format(this.state.orderData.available_credit)}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null}
                                                {this.state.orderData.credit_limit ? (
                                                    <TableRow>
                                                        <TableCell> Credit Limit </TableCell>
                                                        <TableCell align="left">
                                                            &#8377;{" "}
                                                            {nf.format(this.state.orderData.credit_limit)}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null}
                                                {this.state.orderData.gst_number ? (
                                                    <TableRow>
                                                        <TableCell> GST Number </TableCell>
                                                        <TableCell align="left">
                                                            {this.state.orderData.gst_number}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null}
                                                <TableRow>
                                                    <TableCell>Tax</TableCell>
                                                    <TableCell align="left">
                                                        &#8377;{" "}
                                                        {nf.format(
                                                            this.state.orderData.tax_amount
                                                                ? this.state.orderData.tax_amount
                                                                : "0"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Delivery Charges</TableCell>
                                                    <TableCell align="left">
                                                        &#8377;{" "}
                                                        {nf.format(
                                                            this.state.orderData.delivery_charges
                                                                ? this.state.orderData.delivery_charges
                                                                : "0"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Grand Total</TableCell>
                                                    <TableCell align="left">
                                                        &#8377;{" "}
                                                        {nf.format(
                                                            this.state.orderData.grand_total_amount
                                                                ? this.state.orderData.grand_total_amount
                                                                : "0"
                                                        )}
                                                        {/* {this.state.orderData.grand_total_amount ? this.state.orderData.grand_total_amount : '0'} */}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Payment Mode</TableCell>
                                                    <TableCell align="left">
                                                        {this.state.orderData.payment_method}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Order Type</TableCell>
                                                    <TableCell>
                                                        {this.state.orderData.self_pickup
                                                            ? "Self Pickup"
                                                            : "Door Step Delivery"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Payment Status</TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            color={
                                                                this.state.orderData.payment_status === "ITD"
                                                                    ? orderStatusColors["1"]
                                                                    : this.state.orderData.payment_status ===
                                                                    "PND"
                                                                        ? orderStatusColors["2"]
                                                                        : this.state.orderData.payment_status ===
                                                                        "CLD"
                                                                            ? orderStatusColors["5"]
                                                                            : this.state.orderData.payment_status ===
                                                                            "SCS"
                                                                                ? orderStatusColors["3"]
                                                                                : this.state.orderData.payment_status ===
                                                                                "FLD"
                                                                                    ? orderStatusColors["4"]
                                                                                    : this.state.orderData.payment_status ===
                                                                                    "SBL"
                                                                                        ? orderStatusColors["6"]
                                                                                        : orderStatusColors["7"]
                                                            }
                                                        >
                                                            {this.state.orderData.payment_status === "ITD"
                                                                ? "Initiated"
                                                                : this.state.orderData.payment_status === "PND"
                                                                    ? "Pending"
                                                                    : this.state.orderData.payment_status === "CLD"
                                                                        ? "Cancelled"
                                                                        : this.state.orderData.payment_status === "SCS"
                                                                            ? "Success"
                                                                            : this.state.orderData.payment_status === "FLD"
                                                                                ? "Failed"
                                                                                : this.state.orderData.payment_status === "SBL"
                                                                                    ? "Success By LMD"
                                                                                    : "Other"}
                                                        </Label>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Drawer>

                                <Dialog
                                    disableBackdropClick
                                    disableEscapeKeyDown
                                    open={this.state.actionOpen}
                                    fullWidth={true}
                                    maxWidth={"xs"}
                                    onClose={this.handleDrawerClose}
                                >
                                    <DialogTitle
                                        style={{
                                            background:
                                                "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                        }}
                                        id="simple-dialog-title"
                                    >
                                        <div style={{margin: "15px 0px"}}>
                                            <Typography style={{color: "#fff"}} variant="h4">
                                                Actions
                                            </Typography>
                                            <Typography
                                                style={{color: "#fff"}}
                                                variant={"subtitle1"}
                                            >
                                                Update delivery status and payment status!
                                            </Typography>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            style={{marginTop: 10}}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    {(this.state.payment_status === "SCS" ||
                                                        this.state.payment_status === "SBL") &&
                                                    (this.state.order_status === "DVD" ||
                                                        this.state.order_status === "CAN" ||
                                                        this.state.order_status === "ABT") ? (
                                                        <>
                                                            <Typography
                                                                style={{color: "#a9332f"}}
                                                                variant={"subtitle2"}
                                                            >
                                                                Order and payment status is success, you cannot
                                                                change the order and payment status for this
                                                                order
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <InputLabel id="update-status-outlined-label">
                                                                Update Status
                                                            </InputLabel>
                                                            <Select
                                                                labelId="update-status-outlined-label"
                                                                id="update-status-outlined-label"
                                                                variant={"outlined"}
                                                                native
                                                                fullWidth
                                                                onChange={this.handleChange}
                                                                value={this.state.actionSelector}
                                                                label="Update Status"
                                                            >
                                                                <option aria-label="None" value=""/>
                                                                {this.state.payment_status === "SCS" ? (
                                                                    <>
                                                                        <option value={1}>
                                                                            Change Order Status
                                                                        </option>
                                                                    </>
                                                                ) : this.state.order_status === "DVD" ||
                                                                this.state.order_status === "CAN" ||
                                                                this.state.order_status === "ABT" ? (
                                                                    <>
                                                                        <option value={2}>Mark as Paid</option>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <option value={1}>
                                                                            Change Order Status
                                                                        </option>
                                                                        <option value={2}>Mark as Paid</option>
                                                                    </>
                                                                )}
                                                            </Select>
                                                        </>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {this.state.actionSelector === "1" && (
                                                        <FormControl
                                                            variant="outlined"
                                                            className={classes.formControl}
                                                            fullWidth
                                                        >
                                                            <InputLabel id="payment-mode-selector">
                                                                Delivery Status
                                                            </InputLabel>
                                                            <Select
                                                                fullWidth
                                                                variant={"outlined"}
                                                                labelId="payment-mode-selector"
                                                                id="payment-mode-selector"
                                                                native
                                                                value={this.state.subActionSelect}
                                                                onChange={this.handleChangeSubAction}
                                                                label="Delivery Status"
                                                            >
                                                                <option value={0}>Select Mode</option>
                                                                <option value={"DVD"}>Delivered</option>
                                                                <option value={"ABT"}>Abort</option>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                    {this.state.actionSelector === "2" && (
                                                        <div style={{marginTop: 10}}>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                fullWidth
                                                            >
                                                                <InputLabel id="payment-mode-selector">
                                                                    Payment Mode
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    variant={"outlined"}
                                                                    labelId="payment-mode-selector"
                                                                    id="payment-mode-selector"
                                                                    value={this.state.subActionSelect}
                                                                    onChange={this.handleChangeSubAction}
                                                                    label="Payment Mode"
                                                                >
                                                                    <MenuItem value={0}>Select Mode</MenuItem>
                                                                    {/*<MenuItem value={"COD"}>Cash on Delivery</MenuItem>*/}
                                                                    <MenuItem value={"OP"}>
                                                                        Online Payment
                                                                    </MenuItem>
                                                                    <MenuItem value={"CHQ"}>Cheque</MenuItem>
                                                                    <MenuItem value={"NERT"}>NEFT/RTGS</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                    {this.state.actionSelector === "2" &&
                                                    (this.state.subActionSelect === "COD" ||
                                                        this.state.subActionSelect === "OP" ||
                                                        this.state.subActionSelect === "CHQ" ||
                                                        this.state.subActionSelect === "NERT") ? (
                                                        <div style={{marginTop: 10}}>
                                                            <TextField
                                                                error={
                                                                    this.state.delivered_quantity >
                                                                    this.state.amount
                                                                }
                                                                label="Amount"
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                name={"delivered_quantity"}
                                                                value={this.state.delivered_quantity}
                                                                onChange={e =>
                                                                    this.handleChangeDeliveredQuantityAndMode(e)
                                                                }
                                                                size="medium"
                                                                type={"number"}
                                                                fullWidth
                                                                helperText={
                                                                    this.state.delivered_quantity >
                                                                    this.state.amount
                                                                        ? "Entered amount should not be greater than Grand Total Amount"
                                                                        : ""
                                                                }
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                size={"medium"}
                                                                id="date"
                                                                label="Date"
                                                                name="date"
                                                                type="date"
                                                                inputProps={{
                                                                    max: currentDateString
                                                                }}
                                                                style={{marginTop: 10}}
                                                                onChange={e =>
                                                                    this.handleChangeDeliveredQuantityAndMode(e)
                                                                }
                                                                className={classes.textField}
                                                                variant={"outlined"}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {this.state.subActionSelect === "DVD" ? (
                                                        <div style={{marginTop: 10}}>
                                                            <TextField
                                                                label="Actual delivered quantity"
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                name={"delivered_quantity"}
                                                                value={this.state.delivered_quantity}
                                                                onChange={e =>
                                                                    this.handleChangeDeliveredQuantityAndMode(e)
                                                                }
                                                                size="medium"
                                                                type={"number"}
                                                                fullWidth
                                                            />

                                                            <RadioGroup
                                                                onChange={e =>
                                                                    this.handleChangeDeliveredQuantityAndMode(e)
                                                                }
                                                                value={this.state.deliveryMode}
                                                                row
                                                                aria-label="deliveryMode"
                                                                name="deliveryMode"
                                                                style={{marginTop: 10}}
                                                            >
                                                                <FormControlLabel
                                                                    value={"DIR"}
                                                                    control={<Radio color="primary"/>}
                                                                    label="Direct Mode"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value={"MAN"}
                                                                    control={<Radio color="primary"/>}
                                                                    label="Manual Mode"
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                            <TextField
                                                                fullWidth
                                                                size={"medium"}
                                                                id="date"
                                                                label="Date"
                                                                name="date"
                                                                type="date"
                                                                inputProps={{
                                                                    max: currentDateString
                                                                }}
                                                                style={{marginTop: 10}}
                                                                onChange={e =>
                                                                    this.handleChangeDeliveredQuantityAndMode(e)
                                                                }
                                                                className={classes.textField}
                                                                variant={"outlined"}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="text"
                                            color="default"
                                            onClick={this.handleDrawerClose}
                                        >
                                            Cancel
                                        </Button>
                                        {(this.state.delivered_quantity !== "" ||
                                            this.state.subActionSelect === "ABT") &&
                                        this.state.delivered_quantity <= this.state.amount ? (
                                            <Button
                                                variant="contained"
                                                onClick={this.handleSubmit}
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                                    color: "#fff"
                                                }}
                                            >
                                                Update
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </DialogActions>
                                </Dialog>

                                {this.props.noDataFound ? (
                                    ""
                                ) : (
                                    <TablePagination
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page"
                                        }}
                                        component="div"
                                        count={this.props.dataCount}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page"
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        page={this.props.page}
                                        rowsPerPage={this.props.rowsPerPage}
                                        rowsPerPageOptions={[10, 100, 500]}
                                    />
                                )}
                            </PortletContent>
                        </Portlet>
                    </>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(UsersTable)));
