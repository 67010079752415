import React, {Component} from "react";
import {Button, TextField, Typography, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import qs from "qs";
import compose from "recompose/compose";
import styles from "../../../AllRefuellers/components/CreateRefueler/styles";
import {activateRpp, getAllRefuellers} from "../../../../actions/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import reposWebApi from "../../../../actions/configuration";
import {withSnackbar} from "notistack";
import FormControl from "@material-ui/core/FormControl";
import {Autocomplete} from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";

class Cariq extends Component {
    state = {
        selectedRPP: "",
        registration_number: "",
        refueler_id: "",
        allRPP: [],
        isValid: false
    };

    getRpplist = () => {
        reposWebApi
            .get("/refueler/list_without_cariq", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(rpp => {
                this.setState({
                    allRPP: decryption(rpp).data
                });
            });
    };
    handleFieldChange = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    };

    carIQNumber = (event, value) => {
        event.preventDefault();
        this.setState({
            refueler_id: value.id
        });
    };

    handleSubmit = message => {
        this.setState({
            refueler_id: parseInt(this.state.refueler_id),
            registration_number: this.state.registration_number
        });

        let requestBody = {
            refueler_id: parseInt(this.state.refueler_id),
            registration_number: this.state.registration_number
        };

        reposWebApi
            .post("/refueler/assign_cariq", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                }
            })
            .then(res => {
                if (res.status === 201 || res.status === 200) {
                    this.props.enqueueSnackbar("CarIQ created and binded successufully", {
                        variant: "success"
                    });
                    this.getRpplist();
                }
            })
            .catch(error => {
                if (error.response.status === 403) {
                    this.props.enqueueSnackbar(
                        "CarIQ is already assigned other refueler",
                        {
                            variant: "error"
                        }
                    );
                } else if (error.response.status === 404) {
                    this.props.enqueueSnackbar("CarIQ you have entered is not found", {
                        variant: "error"
                    });
                }
            });

        if (this.state.refueler_id === "") {
            alert("Please Select the Refueler");
        } else {
            if (this.state.registration_number === "") {
                alert("Registration Number can not be empty");
            } else {
            }
        }
    };

    componentDidMount() {
        this.getRpplist();
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography
                    variant="subtitle2"
                    style={{marginTop: "2%", color: "#808080"}}
                >
                    Basic Information
                </Typography>
                <div style={{textAlign: "center"}}>
                    {this.props.isLoading ? (
                        <CircularProgress/>
                    ) : (
                        <div className={classes.field}>
                            <TextField
                                className={classes.textField}
                                label="Cariq Registration Number"
                                margin="dense"
                                fullWidth
                                required
                                value={this.state.registration_number}
                                id={"registrationNumber"}
                                onChange={event =>
                                    this.handleFieldChange(
                                        "registration_number",
                                        event.target.value
                                    )
                                }
                                variant="outlined"
                            />
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                style={{width: "100%", marginTop: "12px", textAlign: "left"}}
                            >
                                <Autocomplete
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Select RPP"
                                    onChange={this.carIQNumber}
                                    options={this.state.allRPP}
                                    fullWidth
                                    getOptionLabel={options =>
                                        options.vehicle_registration_number
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Select RPP"
                                            variant="outlined"
                                            value={this.state.refueler_id}
                                            onChange={event =>
                                                this.handleFieldChange(
                                                    "refueler_id",
                                                    event.target.value
                                                )
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_carIQ_submit"
                                id="create_carIQ_submit"
                                //disabled={!this.state.isValid}
                                size="large"
                                variant="contained"
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allRefuellerData: state.allRefuellerData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allPartnersMinimalData: state.allPartnersMinimalData,
        isLoading: state.isLoading,
        isLoadingActivatingRefueler: state.isLoadingActivatingRefueler,
        activatingRefuelerFailed: state.activatingRefuelerFailed
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleGetAllRefuellers: (
            accessToken,
            searchString,
            limit,
            offset,
            partner
        ) => {
            dispatch(
                getAllRefuellers(accessToken, searchString, limit, offset, partner)
            );
        },
        handleChangeActivationStatus: (accessToken, id, status) => {
            dispatch(activateRpp(accessToken, id, status));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(Cariq)));
