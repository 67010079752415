export default theme => ({
    root: {},
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    deleteButton: {
        color: theme.palette.danger.main,
        marginRight: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    importIcon: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    exportIcon: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    nameText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        color: "gray"
    },
    drawerWidth: {
        zIndex: 1200,
        width: "340px"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        position: "absolute",
        right: 0,
        top: "1.5%"
    }
});
