import React, {Component} from "react";

// Externals ToolBar
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import {IconButton, Typography, withStyles} from "@material-ui/core";
// Component styles
import styles from "./styles";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {CloseOutlined as CloseButton} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {withSnackbar} from "notistack";
import AddUser from "../AddUser";
import UploadIcon from "@mui/icons-material/Upload";
import {importFile} from "actions/actions";
import Dialog from "@material-ui/core/Dialog";
import clsx from "clsx";
import {rest} from "underscore";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: "",
            drawerIsOpen: false,
            file: null,
            openDialog: false
        };

        this.onSearch = this.onSearch.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            searchString: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    handleDrawerOpen = () => {
        this.setState({drawerIsOpen: true});
    };

    redirectOnSuccess = () => {
        this.setState({drawerIsOpen: false});
        this.props.userCreationSuccessReloadRequest();
    };

    handleDrawerClose = () => {
        this.setState({drawerIsOpen: false, openDialog: false});
        // window.location.reload();
    };

    handleFileChange = event => {
        // Update state with the selected file
        this.setState({
            file: event
        });
    };

    handleFileUpload = () => {
        const {file} = this.state;
        const {enqueueSnackbar, importExcelFile, accessToken} = this.props;
        if (!file) {
            enqueueSnackbar("Please select a file.", {variant: "warning"});
            return;
        }
        // Call the action creator to upload the file
        importExcelFile(
            secureLocalStorage.getItem("accessToken"),
            file,
            enqueueSnackbar
        );
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        Accessibility Management
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <div className={classes.row}>
                            {/* <SearchInput
                                className={classes.searchInput}
                                placeholder="Search by Order Id or Company Name"
                                getsearchterm={this.onSearch}
                            /> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} align="right">
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={this.handleDrawerOpen}
                            id={"add_partner_btn"}
                            style={{marginRight: "10px", lineHeight: "2"}}
                        >
                            {" "}
                            Add{" "}
                        </Button>
                    </Grid>
                </Grid>
                <SwipeableDrawer
                    anchor={"right"}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerWidth
                    }}
                    open={this.state.drawerIsOpen}
                    onClose={this.handleDrawerClose}
                    onOpen={this.handleDrawerOpen}
                    ModalProps={{
                        BackdropProps: {
                            classes: {
                                root: classes.BackdropProps
                            }
                        }
                    }}
                >
                    <div className={classes.drawerHeading} style={{marginLeft: "10px"}}>
                        <Typography
                            variant="h2"
                            style={{marginTop: "5%", color: "#808080"}}
                        >
                            Add User
                        </Typography>
                    </div>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <CloseButton/>
                        </IconButton>
                    </div>
                    <AddUser
                        onClose={this.handleDrawerClose}
                        userCreationSucess={this.redirectOnSuccess}
                    />
                </SwipeableDrawer>
                <Dialog open={this.state.openDialog} onClose={this.handleDrawerClose}>
                    <div {...rest} className={clsx(classes.root, className)}>
                        <Button
                            className={classes.addNewPartnerButton}
                            variant="contained"
                            component="label"
                            color="success"
                            value={this?.state?.file}
                            id="purchase_order"
                            // onChange={handleInputChange}
                            size="medium"
                        >
                            Upload PO
                            <UploadIcon/>
                            <input
                                type="file"
                                hidden
                                onChange={e => this.handleFileChange(e.target.files[0])}
                            />
                        </Button>

                        <Button onClick={this.handleFileUpload}>Submit</Button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading
    };
};

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

const mapDispatchToProps = dispatch => {
    return {
        importExcelFile: (accessToken, file, enqueueSnackbar) => {
            dispatch(importFile(accessToken, file, enqueueSnackbar));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(UsersToolbar)));
