import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
// Material helpers
// Material components
import {Card, Grid, withStyles} from "@material-ui/core";
// Shared layouts
import {Dashboard as DashboardLayout} from "layouts";
// Custom components
import {connect} from "react-redux";
import compose from "recompose/compose";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import {getAllOrderData} from "../../../actions/actions";
import secureLocalStorage from "react-secure-storage";

// Component styles
const styles = theme => ({
    root: {},
    content: {
        padding: 0
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    overline: {
        marginTop: theme.spacing(1)
    },
    nameText: {
        display: "inline-block",
        fontWeight: 300,
        color: "gray",
        padding: "1% 3%"
    },
    card: {
        margin: "0 3%"
    }
});

class OrderInsightsOverview extends Component {
    signal = true;
    state = {
        selected: "",
        open: true,
        setOpen: true,
        orderTotal: 0,
        error: null
    };

    async getAllOrderData() {
        try {
            this.setState({isLoading: true});

            const {orders, orderTotal} = await getAllOrderData();

            if (this.signal) {
                this.setState({
                    isLoading: false,
                    orders,
                    orderTotal
                });
            }
        } catch (error) {
            if (this.signal) {
                this.setState({
                    isLoading: false,
                    error
                });
            }
        }
    }

    handleClick() {
        this.state.setOpen(true);
    }

    handleClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }

        this.state.setOpen(false);
    }

    getAllOrderData = () => {
        this.props.handlegetAllOrderData(secureLocalStorage.getItem("accessToken"));
    };

    componentDidMount() {
        this.getAllOrderData();
        window.scrollTo(0, 0);
    }

    render() {
        const {classes} = this.props;
        const {orderInsight} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
            return (
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        open={this.state.open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        ContentProps={{
                            "aria-describedby": "message-id"
                        }}
                        message={<span id="message-id">Note archived</span>}
                        action={[]}
                    />
                </div>
            );
        } else {
            return (
                <DashboardLayout title="Order Insights">
                    <Typography className={classes.nameText} variant="h3">
                        Order Insights
                    </Typography>
                    <Card className={classes.card}>
                        <Grid alignItems="center" container justify="space-between">
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_credit_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total Credit Orders
                                </Typography>
                            </Grid>
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_ddd_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total DDD Orders
                                </Typography>
                            </Grid>
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_delivered_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total Delivered Orders
                                </Typography>
                            </Grid>
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_direct_mode_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total Direct Mode Orders
                                </Typography>
                            </Grid>
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_online_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total Online Orders
                                </Typography>
                            </Grid>
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_pre_payment_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total Pre-Payment Orders
                                </Typography>
                            </Grid>
                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                <Typography variant="h2">
                                    {orderInsight?.total_self_pickup_orders}
                                </Typography>
                                <Typography className={classes.overline} variant="overline">
                                    Total Self-Pickup Orders
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>

                    <Typography className={classes.nameText} variant="h3">
                        State Wise Self-Pickup Credit Orders
                    </Typography>
                    <Card className={classes.card}>
                        <Grid alignItems="center" container>
                            {orderInsight?.state_wise_self_pickup_credit_orders?.map(
                                order => {
                                    return (
                                        <>
                                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                                <Typography variant="h2">{order?.orders}</Typography>
                                                <Typography
                                                    className={classes.overline}
                                                    variant="overline"
                                                >
                                                    {
                                                        order?.customer_asset__asset_address__city__state__name
                                                    }
                                                </Typography>
                                            </Grid>
                                        </>
                                    );
                                }
                            )}
                        </Grid>
                    </Card>

                    <Typography className={classes.nameText} variant="h3">
                        State Wise Self-Pickup Non Credit Orders
                    </Typography>
                    <Card className={classes.card}>
                        <Grid alignItems="center" container>
                            {orderInsight?.state_wise_self_pickup_non_credit_orders?.map(
                                order => {
                                    return (
                                        <>
                                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                                <Typography variant="h2">{order?.orders}</Typography>
                                                <Typography
                                                    className={classes.overline}
                                                    variant="overline"
                                                >
                                                    {
                                                        order?.customer_asset__asset_address__city__state__name
                                                    }
                                                </Typography>
                                            </Grid>
                                        </>
                                    );
                                }
                            )}
                        </Grid>
                    </Card>

                    {/* <Typography className={classes.nameText} variant="h3">
            State Wise Direct Mode Orders
          </Typography>
          <Card className={classes.card}>
            <Grid alignItems="center" container>
              {orderInsight?.total_state_wise_direct_mode_orders?.map(
                (order) => {
                  return (
                    <>
                      <Grid className={classes.item} item md={4} sm={6} xs={12}>
                        <Typography variant="h2">{order?.orders}</Typography>
                        <Typography
                          className={classes.overline}
                          variant="overline"
                        >
                          {
                            order?.customer_asset__asset_address__city__state__name
                          }
                        </Typography>
                      </Grid>
                    </>
                  );
                }
              )}
            </Grid>
          </Card> */}

                    <Typography className={classes.nameText} variant="h3">
                        State Wise Online Credit Orders
                    </Typography>
                    <Card className={classes.card}>
                        <Grid alignItems="center" container>
                            {orderInsight?.total_state_wise_online_credit_orders?.map(
                                order => {
                                    return (
                                        <>
                                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                                <Typography variant="h2">{order?.orders}</Typography>
                                                <Typography
                                                    className={classes.overline}
                                                    variant="overline"
                                                >
                                                    {
                                                        order?.order__customer_asset__asset_address__city__state__name
                                                    }
                                                </Typography>
                                            </Grid>
                                        </>
                                    );
                                }
                            )}
                        </Grid>
                    </Card>

                    <Typography className={classes.nameText} variant="h3">
                        State Wise Online Non Credit Orders
                    </Typography>
                    <Card className={classes.card}>
                        <Grid alignItems="center" container>
                            {orderInsight?.total_state_wise_online_non_credit_orders?.map(
                                order => {
                                    return (
                                        <>
                                            <Grid className={classes.item} item md={4} sm={6} xs={12}>
                                                <Typography variant="h2">{order?.orders}</Typography>
                                                <Typography
                                                    className={classes.overline}
                                                    variant="overline"
                                                >
                                                    {
                                                        order?.customer_asset__asset_address__city__state__name
                                                    }
                                                </Typography>
                                            </Grid>
                                        </>
                                    );
                                }
                            )}
                        </Grid>
                    </Card>
                </DashboardLayout>
            );
        }
    }
}

OrderInsightsOverview.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess,
        loginError: state.loginError,
        dashboardData: state.dashboardData,
        orderInsight: state.orderInsight,
        directModeData: state.directModeData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handlegetAllOrderData: accessToken => {
            dispatch(getAllOrderData(accessToken));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(OrderInsightsOverview));
