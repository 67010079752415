import React, {Component} from "react";

export default class GpIcon extends Component {
    render() {
        return (
            <div>
                <img src="/images/GP_1.png" alt={"GP"} style={{height: "22px"}}/>
            </div>
        );
    }
}
