import {createBrowserHistory} from "history";
import secureLocalStorage from "react-secure-storage";
import fernet from "fernet";

export const history = createBrowserHistory();

const iState = {
    isLoginSuccess: false,
    isLoginPending: false,
    isLoadingUser: false,
    loginError: false,
    accessToken: "",
    isLoggedIn: false,
    userDetails: "",
    allCustomerData: [],
    FleetCardData: [],
    dataCount: 0,
    noDataFound: false,
    refreshtoken: "",
    allDriverData: [],
    allRefuellerData: [],
    allPartnersMinimalData: [],
    allPartnersData: [],
    allOrdersData: [],
    allCityData: [],
    allMarkersData: [],
    allGeofencesData: [],
    allAssetsData: [],
    allUserData: [],
    allServiceData: [],
    sessionExpired: false,
    internalServerError: false,
    allTrackersData: [],
    isLoading: false,
    isPrice: false,
    pumpCreationError: false,
    pumpCreationSuccess: false,
    pumpCreationisLoading: false,
    pumpCreationSuccessMessage: "",
    orderAssignmentFail: "",
    orderAssignedSuccessfully: null,
    orderSequenceChanged: null,
    orderSequenceChangeFailed: "",
    geofenceModificationFail: "",
    geofenceModificationSuccess: null,
    dashboardData: null,
    isLoadingOrderChange: false,
    orderUnassignedSuccessfully: false,
    paymentStatusChanged: false,
    isLoadingPaymentStatusChange: false,
    statusChanged: null,
    error: false,
    passwordResetSuccess: false,
    operatorCreationSuccess: false,
    operatorCreationSuccessMessage: "",
    operatorCreationIsLoading: false,
    operatorCreationError: false,
    operatorModificationSuccess: false,
    operatorModificationSuccessMessage: "",
    operatorModificationIsLoading: false,
    operatorModificationError: false,
    userCreationSuccess: false,
    userCreationSuccessMessage: false,
    userCreationIsLoading: false,
    userCreationError: false,
    userModificationSuccess: false,
    userModificationSuccessMessage: "",
    userModificationIsLoading: false,
    userModificationError: false,
    refuelerCreationSuccess: false,
    refuelerCreationSuccessMessage: "",
    refuelerCreationIsLoading: false,
    refuelerCreationError: false,
    customerPasswordResetLoading: false,
    customerPasswordResetSuccess: false,
    customerPasswordResetFail: false,
    partnerModificationSuccess: false,
    directModeData: [],
    department: secureLocalStorage.getItem("department"),
    is_crm: secureLocalStorage.getItem("is_crm"),
    access_type: "",
    sendingLogsFail: "",
    sendingLogsSuccess: "",
    sendingLogsLoading: false,
    addFleetCardFail: "",
    addFleetCardSuccess: false,
    addlFeetCard: false,
    isOnline: true,
    orderInsight: [],
    partnerData: [],
    customerData: [],
    datumData: [],
    orderStatus: [],
    datumDetails: [],
    datumAnalytics: [],
    report: [],
    subasset: [],
    customer: [],
    leadOwner: [],
    getStateOfIndia: [],
    getTerritories: [],
    allUserLeadData: [],
    rppPrice: [],
    getDiscount: [],
    datumPrice: [],
    uploadProgress: "",
    isUpload: false,
    categories: [],
    subCategories: [],
    leadAnalytics: [],
    listLogInfo: [],
    companyType: []
};

export default function reducer(state = iState, action) {
    let secret = new fernet.Secret(
        "K_5H0OzfMLk_FIcGQkg_H5W3WHNBRQWMoAmKCuEhNeY="
    );
    let token = new fernet.Token({
        secret: secret
    });
    token.encode("string");

    switch (action.type) {
        case "LOGIN_SUCCESS":
            let toke = new fernet.Token({
                secret: secret,
                token: action.payload?.data?.response_data,
                ttl: 0
            });

            secureLocalStorage.setItem("isAuthenticated", true);
            secureLocalStorage.setItem(
                "accessToken",
                JSON.parse(toke.decode())?.access_token
            );
            secureLocalStorage.setItem(
                "refreshToken",
                JSON.parse(toke.decode())?.refresh_token
            );
            secureLocalStorage.setItem(
                "is_crm",
                JSON.parse(toke.decode())?.user_details?.is_crm
            );
            secureLocalStorage.setItem(
                "crm_rank",
                JSON.parse(toke.decode())?.user_details?.crm_rank
            );
            secureLocalStorage.setItem(
                "department",
                JSON.parse(toke.decode())?.user_details?.department
            );
            secureLocalStorage.setItem(
                "access_type",
                JSON.parse(toke.decode())?.user_details?.access_type
            );

            return {
                ...iState,
                isLoginPending: true,
                isLoginSuccess: true,
                accessToken: JSON.parse(toke.decode())?.access_token,
                refreshtoken: JSON.parse(toke.decode())?.refresh_token,
                allDriverData: [],
                allUserData: [],
                allRefuellerData: [],
                allPartnersMinimalData: [],
                allPartnersData: [],
                allOrdersData: [],
                allCityData: [],
                allMarkersData: [],
                isLoading: true,
                refuelerCreationError: false,
                department: JSON.parse(
                    toke.decode()
                )?.user_details?.department?.toString(),
                userDetails: JSON.parse(toke.decode())?.user_details,
                access_type: JSON.parse(toke.decode())?.user_details.access_type,
                is_crm: JSON.parse(toke.decode())?.user_details.is_crm,
                crm_rank: JSON.parse(toke.decode())?.user_details?.crm_rank
            };

        case "LOGIN_ATTEMPT":
            return {
                ...state,
                isLoading: true
            };

        case "LOGIN_ERROR":
            return {
                ...state,
                loginError: action.payload,
                isLoading: false
            };
        case "INVALID_LOGIN":
            return {
                ...state,
                loginError: action.payload,
                isLoading: false
            };

        case "LOG_OUT":
            secureLocalStorage.removeItem("accessToken");
            return {
                ...state,
                isLoginPending: false,
                sessionExpired: false,
                isLoading: false,
                department: ""
            };
        case "SESSION_EXPIRED":
            return {
                ...state,
                sessionExpired: action.payload
            };

        case "GET_ALL_CUSTOMER":
        case "GET_ALL_CUSTOMER_SEARCH":
        case "GET_ALL_CUSTOMER_BY_LIMIT":
        case "GET_ALL_CUSTOMER_BY_DATE":
            if (action.payload.count === 0) {
                return {
                    allCustomerData: action.payload.results,
                    isLoginSuccess: true,
                    dataCount: action.payload.count,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                allCustomerData: action.payload.results,
                isLoginSuccess: true,
                dataCount: action.payload.count,
                noDataFound: false
            };
        case "GET_ALL_DRIVERS":
        case "GET_ALL_DRIVERS_SEARCH":
        case "GET_ALL_DRIVERS_BY_LIMIT":
            if (action.payload.count === 0) {
                return {
                    allDriverData: action.payload.results,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            } else
                return {
                    ...state,
                    allDriverData: action.payload.results,
                    dataCount: action.payload.count,
                    emptyTable: false,
                    isLoading: false,
                    noDataFound: false
                };
        case "GET_ALL_REFUELLERS":
        case "GET_ALL_REFUELLERS_SEARCH":
        case "GET_ALL_REFUELLERS_BY_LIMIT":
        case "GET_ALL_REFUELLERS_PARTNER":
            if (action.payload.refuellerData.results.length === 0) {
                return {
                    allRefuellerData: action.payload.refuellerData.results,
                    dataCount: action.payload.refuellerData.count,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false,
                    refuelerCreationError: false
                };
            }
            return {
                ...state,
                isLoading: false,
                allRefuellerData: action.payload.refuellerData.results,
                isLoginSuccess: true,
                dataCount: action.payload.refuellerData.count,
                noDataFound: false,
                refuelerCreationError: false
            };
        case "GET_ALL_PARTNERS":
        case "GET_ALL_PARTNERS_SEARCH":
        case "GET_ALL_PARTNERS_BY_CITY":
        case "GET_ALL_PARTNERS_BY_REFUELLER":
        case "GET_ALL_PARTNERS_BY_LIMIT":
            if (action.payload.partnersData.data.results.length === 0) {
                return {
                    allPartnersData: action.payload.partnersData.data.results,
                    allRefuellerData: action.payload.refuellerData,
                    dataCount: action.payload.partnersData.data.count,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                allPartnersData: action.payload.partnersData.data.results,
                allRefuellerData: action.payload.refuellerData,
                allCityData: action.payload.cityData.results,
                isLoginSuccess: true,
                dataCount: action.payload.partnersData.data.count,
                noDataFound: false,
                isLoading: false
            };

        case "GET_ALL_ORDERS":
        case "GET_ALL_ORDERS_SEARCH":
        case "GET_ALL_ORDERS_BY_ORDERTYPE":
        case "GET_ALL_ORDERS_BY_LIMIT":
        case "GET_ALL_ORDERS_BY_STATUS":
        case "GET_ALL_ORDERS_BY_DELIVERYMODE":
        case "GET_ALL_ORDERS_BY_DATE":
            if (action.payload.ordersData.data.results.length === 0) {
                return {
                    allOrdersData: action?.payload?.ordersData,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            }
            // if(action.payload.ordersData.data.results == null){
            //     return{
            //         allOrdersData: action.payload.ordersData,
            //         isLoginSuccess: true,
            //         noDataFound: true,
            //         isLoading: false,
            //     }
            // }
            return {
                ...state,
                isLoading: false,
                allOrdersData: action.payload.ordersData.data.results,
                isLoginSuccess: true,
                dataCount: action.payload.ordersData.data.count,
                noDataFound: false
            };
        case "INTERNAL_SERVER_ERROR_FOR_ORDER":
            return {
                isLoading: false,
                isUpload: false
            };
        case "ALL_MARKERS_CLEAR":
            return {
                allMarkersData: [],
                isLoginSuccess: true,
                isLoading: true
            };
        case "GET_ALL_MARKERS":
            if (action?.payload?.count === 0) {
                return {
                    allMarkersData: action?.payload,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: true
                };
            }
            return {
                ...state,
                allMarkersData: action?.payload,
                isLoginSuccess: true,
                dataCount: action?.payload?.count,
                noDataFound: false,
                isLoading: false
            };
        case "GET_ALL_GEOFENCES":
        case "GET_ALL_GEOFENCES_SEARCH":
        case "GET_ALL_GEOFENCES_BY_CUSTOMER":
        case "GET_ALL_GEOFENCES_BY_LIMIT":
            if (action.payload.allGeofencesData.data.results.length === 0) {
                return {
                    allGeofencesData: action.payload.allGeofencesData,
                    allCustomerData: action.payload.allCustomerData,
                    allPartnersMinimalData: action.payload,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                allGeofencesData: action.payload.allGeofencesData.data.results,
                allCustomerData: action.payload.allCustomerData,
                isLoginSuccess: true,
                dataCount: action.payload.allGeofencesData.data.count,
                noDataFound: false,
                isLoading: false
            };
        case "GET_ALL_ASSETS":
        case "GET_ALL_ASSETS_SEARCH":
        case "GET_ALL_ASSETS_TYPE":
        case "GET_ALL_ASSETS_BY_CUSTOMER":
        case "GET_ALL_ASSETS_BY_LIMIT":
            if (action.payload.allAssetsData.data.count === 0) {
                return {
                    allAssetsData: action.payload.allAssetsData,
                    allCustomerData: action.payload.allCustomerData,
                    allPartnersMinimalData: action.payload,
                    isLoginSuccess: true,
                    isLoading: false,
                    noDataFound: true
                };
            }
            return {
                ...state,
                isLoading: false,
                allAssetsData: action.payload.allAssetsData.data.results,
                allCustomerData: action.payload.allCustomerData,
                isLoginSuccess: true,
                dataCount: action.payload.allAssetsData.data.count,
                noDataFound: false
            };
        case "INTERNAL_SERVER_ERROR": {
            return {
                ...state,
                internalServerError: action.payload
            };
        }
        case "GET_ALL_TRACKERS":
        case "GET_ALL_TRACKERS_BY_LIMIT":
        case "GET_ALL_TRACKERS_SEARCH":
            if (action.payload.trackerData.count === 0) {
                return {
                    allRefuellerData: action.payload.refuellerData.results,
                    allTrackersData: action.payload.trackerData.results,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                allRefuellerData: action.payload.refuellerData.results,
                allTrackersData: action.payload.trackerData.results,
                isLoginSuccess: true,
                dataCount: action.payload.trackerData.count,
                noDataFound: false
            };

        // WRITE OPERATIONS
        case "CREATING_PARTNER":
            return {
                ...state,
                pumpCreationisLoading: true
            };
        case "CREATE_PARTNER_SUCCESS":
            return {
                ...state,
                pumpCreationSuccess: true,
                pumpCreationSuccessMessage: action.payload,
                pumpCreationisLoading: false
            };
        case "CREATE_PARTNER_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingPartner: false,
                pumpCreationError: true,
                pumpCreationisLoading: false
            };
        case "CREATING_OPERATOR":
            return {
                ...state,
                operatorCreationIsLoading: true
            };
        case "CREATING_OPERATOR_SUCCESS":
            return {
                ...state,
                operatorCreationSuccess: true,
                // operatorCreationSuccessMessage: action.payload,
                operatorCreationIsLoading: false,
                operatorCreationError: false
            };
        case "CREATE_OPERATOR_AGAIN":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingOperator: false,
                operatorCreationError: false,
                operatorCreationIsLoading: false,
                operatorCreationSuccess: false
            };
        case "CREATING_OPERATOR_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingOperator: false,
                operatorCreationError: true,
                operatorCreationIsLoading: false
            };
        case "EDITING_OPERATOR":
            return {
                ...state,
                operatorModificationIsLoading: true
            };
        case "EDITING_OPERATOR_SUCCESS":
            return {
                ...state,
                operatorModificationSuccess: true,
                operatorModificationSuccessMessage: action.payload,
                operatorModificationIsLoading: false
            };
        case "EDITING_OPERATOR_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingOperator: false,
                operatorModificationError: true,
                operatorModificationIsLoading: false
            };
        case "EDIT_PARTNER":
            return {
                ...state,
                partnerModificationIsLoading: true
            };
        case "EDIT_PARTNER_SUCCESS":
            return {
                ...state,
                partnerModificationSuccess: true,
                partnerModificationSuccessMessage: action.payload,
                partnerModificationIsLoading: false
            };
        case "EDIT_PARTNER_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingPartner: false,
                partnerModificationError: true,
                partnerModificationIsLoading: false
            };
        case "EDIT_PARTNER_RELOAD":
            return {
                ...state,
                partnerModificationSuccess: false
            };
        case "GET_ALL_REFUELLERS_AND_ORDERS":
        case "GET_ALL_REFUELLERS_AND_ORDERS_BY_LIMIT":
        case "GET_ALL_REFUELLERS_AND_ORDERS_SEARCH":
            if (action.payload.refuellerData.count === 0) {
                return {
                    allRefuellerData: action.payload.refuellerData,
                    allPartnersMinimalData: action.payload.partnersData,
                    allOrdersData: action.payload.ordersData.data,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                allRefuellerData: action.payload.refuellerData.results,
                allPartnersMinimalData: action.payload.partnersData,
                allOrdersData: action.payload.ordersData.data.results,
                isLoginSuccess: true,
                dataCount: action.payload.refuellerData.count,
                isLoading: false,
                noDataFound: false
            };
        case "GET_ALL_SERVICE":
        case "GET_ALL_SERVICE_SEARCH":
        case "GET_ALL_SERVICE_BY_LIMIT":
        case "GET_ALL_SERVICE_BY_DATE":
            if (action.payload.count === 0) {
                return {
                    allServiceData: action.payload.results,
                    isLoginSuccess: true,
                    dataCount: action.payload.count,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                allServiceData: action.payload.results,
                isLoginSuccess: true,
                dataCount: action.payload.count,
                noDataFound: false
            };
        case "GET_ALL_FLEET_CARD_PAYMENTS":
        case "GET_ALL_FLEET_CARD_PAYMENTS_SEARCH":
        case "GET_ALL_FLEET_CARD_PAYMENTS_BY_LIMIT":
        case "GET_ALL_FLEET_CARD_PAYMENTS_BY_DATE":
            if (action.payload.count === 0) {
                return {
                    FleetCardData: action.payload.results,
                    isLoginSuccess: true,
                    dataCount: action.payload.count,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                FleetCardData: action.payload.results,
                isLoginSuccess: true,
                dataCount: action.payload.count,
                noDataFound: false
            };
        case "GET_ALL_PARTNERS_LOADING":
        case "GET_ALL_ORDERS_LOADING":
        case "GET_ALL_REFUELERS_LOADING":
        case "GET_ALL_REFUELLERS_AND_ORDERS_LOADING":
        case "GET_ALL_TRACKERS_LOADING":
        case "GET_ALL_CUSTOMERS_LOADING":
        case "GET_ALL_GEOFENCES_LOADING":
        case "GET_ALL_ASSETS_LOADING":
        case "GET_ALL_USERS_LOADING":
        case "GET_ALL_DRIVERS_LOADING":
        case "GET_DASHBOARD_DATA_LOADING":
        case "GET_DATUM_DATA_LOADING":
        case "GET_PARTNER_DATA_LOADING":
        case "GET_CUSTOMER_DATA_LOADING":
        case "GET_ORDER_DATA_LOADING":
        case "REFUELER_ASSIGNMENT_LOADING":
        case "MODIFY_GEOFENCE_LOADING":
        case "GET_LEAD_OWNER_LOADING":
        case "GET_CUMMINS_LEAD_OWNER_LOADING":
        case "GET_RPP_PRICE_LOADING":
        case "GET_DATUM_PRICE_LOADING":
        case "GET_DISCOUNT_LOADING":
        case "IS_LOADING":
            return {
                ...state,
                isLoading: true,
                isLeadApiError: false
            };
        case "GET_ALL_LEADS_LOADING":
            return {
                ...state,
                isLeadTableLoading: true
            };
        case "IS_PRICE":
            return {
                ...state,
                isPrice: true
            };
        case "REFUELER_ASSIGNMENT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                orderAssignedSuccessfully: action.payload
            };
        case "REFUELER_ASSIGNMENT_FAIL":
            return {
                ...state,
                isLoading: false,
                orderAssignmentFail: action.payload
            };
        case "REFUELER_ASSIGNMENT_SEQUENCE_CHANGED":
            return {
                ...state,
                isLoading: false,
                orderSequenceChanged: action.payload
            };
        case "REFUELER_ASSIGNMENT_SEQUENCE_CHANGE_FAILED":
            return {
                ...state,
                isLoading: false,
                orderSequenceChangeFailed: action.payload
            };
        case "MODIFY_GEOFENCE":
            return {
                ...state,
                isLoading: false,
                geofenceModificationSuccess: action.payload.data
            };
        case "MODIFY_GEOFENCE_FAIL":
            return {
                ...state,
                isLoading: false,
                geofenceModificationFail: action.payload.data
            };
        case "GET_DASHBOARD_DATA":
            return {
                ...state,
                isLoading: false,
                dashboardData: action.payload.utilitiesData,
                directModeData: action.payload.directModeData
            };
        case "CHANGE_ORDER_STATUS_LOADING":
            return {
                ...state,
                isLoadingOrderChange: true
                // statusChanged: action.payload.data
            };
        case "CHANGE_ORDER_STATUS":
            return {
                ...state,
                isLoadingOrderChange: false,
                statusChanged: action.payload.data
            };
        case "CHANGE_ORDER_STATUS_ERROR":
            return {
                ...state,
                isLoadingOrderChange: false,
                statusChanged: action.payload.data
            };
        case "CHANGE_PAYMENT_STATUS_LOADING":
            return {
                ...state,
                isLoadingPaymentStatusChange: true,
                paymentStatusChanged: true
            };
        case "CHANGE_PAYMENT_STATUS":
            return {
                ...state,
                isLoadingPaymentStatusChange: false,
                paymentStatusChanged: true
            };
        case "ORDER_UNASSIGN_FROM_REFUELER":
            return {
                ...state,
                orderUnassignedSuccessfully: true
            };
        case "CHANGE_REFUELER_STATUS":
            return {
                ...state,
                refuelerStatusChanged: true
            };
        case "CHANGE_REFUELER_STATUS_FAILED":
            return {
                ...state,
                refuelerStatusChanged: action.payload
            };
        case "CHANGE_PARTNER_PASSWORD_LOADNG":
            return {
                ...state,
                isLoading: false,
                passwordResetLoading: true
            };
        case "CHANGE_PARTNER_PASSWORD_SUCCESS":
            return {
                ...state,
                passwordResetLoading: false,
                passwordResetSuccess: true
            };
        case "CHANGE_PARTNER_PASSWORD_FAIL":
            return {
                ...state,
                passwordResetLoading: false,
                passwordResetSuccess: action.payload
            };
        case "CHANGE_CUSTOMER_PASSWORD_LOADNG":
            return {
                ...state,
                isLoading: false,
                customerPasswordResetLoading: true
            };
        case "CHANGE_CUSTOMER_PASSWORD_SUCCESS":
            return {
                ...state,
                customerPasswordResetLoading: false,
                customerPasswordResetSuccess: true
            };
        case "CHANGE_CUSTOMER_PASSWORD_FAIL":
            return {
                ...state,
                customerPasswordResetLoading: false,
                customerPasswordResetFail: action.payload
            };
        case "CHANGE_DRIVER_PASSWORD_LOADNG":
            return {
                ...state,
                isLoading: false,
                driverPasswordResetLoading: true
            };
        case "CHANGE_DRIVER_PASSWORD_SUCCESS":
            return {
                ...state,
                driverPasswordResetLoading: false,
                driverPasswordResetSuccess: true
            };
        case "CHANGE_DRIVER_PASSWORD_FAIL":
            return {
                ...state,
                driverPasswordResetLoading: false,
                driverPasswordResetFail: action.payload
            };
        case "ERROR":
            return {
                ...state,
                error: action.payload,
                isLoadingPaymentStatusChange: false
            };
        case "CREATING_REFUELER":
            return {
                ...state,
                refuelerCreationIsLoading: true
            };
        case "CREATING_REFUELER_SUCCESS":
            return {
                ...state,
                refuelerCreationSuccess: true,
                refuelerCreationSuccessMessage: action.payload,
                refuelerCreationIsLoading: false
            };
        case "CREATING_REFUELER_RESET":
            return {
                ...state,
                isLoginSuccess: true,
                refuelerCreationError: false,
                refuelerCreationIsLoading: false
            };
        case "CREATING_REFUELER_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                refuelerCreationError: true,
                refuelerCreationIsLoading: false
            };
        case "ACTIVATING_REFUELER":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingActivatingRefueler: true
            };
        case "ACTIVATING_REFUELER_SUCCESS":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingActivatingRefueler: false
            };
        case "ACTIVATING_REFUELER_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingActivatingRefueler: false,
                activatingRefuelerFailed: action.payload
            };
        case "CREATING_USER":
            return {
                ...state,
                userCreationIsLoading: true
            };
        case "CREATING_USER_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "CREATE_USER_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "GET_ALL_USERS":
            if (action.payload.count === 0) {
                return {
                    allUserData: action.payload.results,
                    dataCount: action.payload.count,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false
                };
            }
            return {
                ...state,
                allUserData: action.payload.results,
                dataCount: action.payload.count,
                isLoginSuccess: true,
                noDataFound: false,
                isLoading: false
            };
        case "DELETE_USER":
            return {
                ...state,
                isLoading: false,
                deleteUserData: action.payload
            };
        case "DELETE_USER_LOADING":
            return {
                ...state,
                isLoading: true
            };
        case "EDIT_USER":
            return {
                ...state,
                userModificationIsLoading: true
            };
        case "EDIT_USER_SUCCESS":
            return {
                ...state,
                userModificationSuccess: true,
                userModificationSuccessMessage: action.payload,
                userModificationIsLoading: false
            };
        case "EDIT_USER_RELOAD":
            return {
                ...state,
                userModificationSuccess: false
            };
        case "EDIT_USER_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUser: false,
                userModificationError: true,
                userModificationIsLoading: true
            };
        case "SENDING_LOGS_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoading: false,
                sendingLogsFail: action.payload
            };
        case "SENDING_LOGS_SUCCESS":
            return {
                ...state,
                sendingLogsSuccess: action.payload
            };
        case "SENDING_LOGS":
            return {
                ...state,
                sendingLogsLoading: true
            };
        case "ADD_FLEET_CARD":
            return {
                ...state,
                addFleetCardSuccess: action.payload
            };
        case "ADD_FLEET_CARD_SUCCESS":
            return {
                ...state,
                addFleetCardSuccess: true
            };
        case "ADD_FLEET_CARD_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoading: false,
                addFleetCardFail: action.payload
            };
        case "GET_DATUM_DATA":
            return {
                ...state,
                isLoading: false,
                datumData: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_CUSTOMER_DATA":
            return {
                ...state,
                isLoading: false,
                customerData: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_PARTNER_DATA":
            return {
                ...state,
                isLoading: false,
                partnerData: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_ORDER_DATA":
            return {
                ...state,
                isLoading: false,
                orderInsight: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_ORDER_STATUS":
            return {
                ...state,
                isLoading: false,
                orderStatus: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_DATUM_DETAILS":
        case "GET_DATUM_DETAILS_SEARCH":
        case "GET_DATUM_DETAILS_FILTER":
        case "GET_DATUM_DETAILS_LIMIT":
        case "GET_DATUM_DETAILS_BY_DATE":
        case "GET_DATUM_DETAILS_STATUS":
            return {
                ...state,
                isLoading: false,
                datumDetails: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_DATUM_ANALYTICS":
            return {
                ...state,
                isLoading: false,
                datumAnalytics: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_ORDER_REPORT":
            return {
                ...state,
                isLoading: false,
                report: action?.payload
            };
        case "GET_SUB_ASSET_ANALYTICS":
            return {
                ...state,
                isLoading: false,
                subasset: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "GET_CUSTOMER_ANALYTICS":
            return {
                ...state,
                isLoading: false,
                customer: action?.payload,
                directModeData: action?.payload?.directModeData
            };
        case "ADD_LEAD":
            return {
                ...state,
                userCreationSuccess: false
            };
        case "ADD_LEAD_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "ADD_LEAD_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "ADD_RPP_INFO":
            return {
                ...state
            };
        case "ADD_RPP_INFO_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "ADD_RPP_INFO_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "PURCHASE_ORDER_INFO":
            return {
                ...state
            };
        case "PURCHASE_ORDER__SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false,
                isUpload: false
            };
        case "PURCHASE_ORDER__FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false,
                isUpload: false
            };
        case "NEGO_INFO":
            return {
                ...state
            };
        case "NEGO_INFO_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "NEGO_INFO_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "BOOKING_INFO":
            return {
                ...state
            };
        case "BOOKING_INFO_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "BOOKING_INFO_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "GET_ALL_LEADS":
            if (action.payload.count === 0) {
                return {
                    ...state,
                    allUserLeadData: action.payload.results,
                    dataCount: action.payload.count,
                    isLoginSuccess: true,
                    noDataFound: true,
                    isLoading: false,
                    isLeadTableLoading: false,
                    isLeadApiError: false
                };
            }
            return {
                ...state,
                allUserLeadData: action.payload.results,
                dataCount: action.payload.count,
                isLeadTableLoading: false,
                isLoginSuccess: true,
                noDataFound: false,
                isLoading: false
            };

        case "GET_ALL_LEADS_ERROR":
            return {
                isLoading: false,
                isLeadTableLoading: false,
                isLeadApiError: true
            };

        case "GET_LEAD_OWNER":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                leadOwner: action?.payload?.results
            };

        case "GET_CUMMINS_LEAD_OWNER":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                leadOwner: action?.payload?.results
            };
        case "GET_RPP_PRICE":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                rppPrice: action?.payload?.rpp_price
            };
        case "PRICE_NOT_FOUND":
            return {
                ...state,
                isLoading: false,
                isLoginSuccess: true,
                rppPriceNotUpdate: action?.payload
            };
        case "GET_DISCOUNT":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                getDiscount: action?.payload
            };
        case "GET_STATE":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                getStateOfIndia: action?.payload
            };
        case "GET_TERRITORIES": {
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                getTerritories: action?.payload
            };
        }
        case "ADD_DATUM_INFO":
            return {
                ...state
            };
        case "ADD_DATUM_INFO_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "ADD_DATUM_INFO_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "GET_DATUM_PRICE":
            return {
                ...state,
                isLoading: false,
                isPrice: false,
                noDataFound: false,
                isLoginSuccess: true,
                datumPrice: action?.payload?.datum_price
            };
        case "UPLOAD_PROGRESS":
            return {
                ...state,
                isUpload: true,
                uploadProgress: action?.payload
            };
        case "ADD_TRACKER_INFO":
            return {
                ...state
            };
        case "ADD_TRACKER_INFO_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "ADD_TRACKER_INFO_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "COMPANY_INFO":
            return {
                ...state
            };
        case "COMPANY_DETAILS_SUCCESS":
            return {
                ...state,
                // userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "COMPANY_DETAILS_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "GET_CATEGORY":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                categories: action?.payload?.categories
            };
        case "GET_SUB_CATEGORY":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                subCategories: action?.payload?.sub_categories_list
            };
        case "GET_COMPANY_TYPE":
            return {
                ...state,
                isLoading: false,
                noDataFound: false,
                isLoginSuccess: true,
                companyType: action?.payload?.company_list
            };
        case "FINSERV_OWNED_INFO":
            return {
                ...state
            };
        case "FINSERV_OWNED_INFO_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "FINSERV_OWNED_INFO_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "OFFLINE":
            return {
                ...state,
                isOnline: false
            };
        case "ONLINE":
            return {
                ...state,
                isOnline: true
            };
        case "Add_M_CAPITAL":
            return {
                ...state,
                addmcapital: action.payload
            };
        case "ADD_M_CAPITAL_SUCCESS":
            return {
                ...state,
                addmcapital: true
            };
        case "ADD_M_CAPITAL_FAILED":
            return {
                ...state,
                isLoginSuccess: true,
                isLoading: false,
                addmcapitalfail: action.payload
            };
        case "GET_LEAD_ANALYTICS":
            return {
                ...state,
                isLoading: false,
                leadAnalytics: action?.payload
            };
        case "GET_LIST_LOG_INFO":
            return {
                ...state,
                isLoadingLog: false,
                userCreationSuccess: false,
                listLogInfo: action?.payload
            };
        case "GET_LIST_LOG_INFO_LOADING":
            return {
                ...state,
                isLoadingLog: true,
                userCreationSuccess: false
            };
        case "ADD_LOG_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false
            };
        case "ADD_LOG_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false
            };
        case "OPEN_UPLOAD":
            return {
                ...state,
                userCreationSuccess: false,
                userCreationIsLoading: false,
                userCreationError: false,
                isUpload: false
            };
        case "IMPORT_FILE_SUCCESS":
            return {
                ...state,
                userCreationSuccess: true,
                userCreationIsLoading: false,
                userCreationError: false,
                isUpload: false
            };
        case "IMPORT_FILE_FAIL":
            return {
                ...state,
                isLoginSuccess: true,
                isLoadingUseer: false,
                userCreationError: true,
                userCreationIsLoading: false,
                userCreationSuccess: false,
                isUpload: false
            };
        case "Load_BULK_DELETE":
            return {
                ...state,
                isLoadingDialog: true,
                leadAPI_call: undefined
            };
        case "BULK_DELETE":
            return {
                ...state,
                isLoadingDialog: false,
                leadAPI_call: action.payload
            };
        case "ERORR_BULK_DELETE":
            return {
                ...state,
                isLoadingDialog: false,
                leadAPI_call: undefined
            };

        case "LOAD_REASON":
            return {
                ...state,
                isLoadingReason: true,
                state_reason: {}
            };

        case "LEAD_REASON":
            return {
                ...state,
                isLoadingReason: false,
                state_reason: action.payload
            };

        case "EXPORT_REPORT_LOAD":
            return {
                ...state,
                isLoadingExport: true
            };

        case "EXPORT_REPORT":
            return {
                ...state,
                isLoadingExport: false
            };

        default:
            return state;
    }
}
