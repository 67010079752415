export const state = [
    {
        value: "AN",
        name: "ANDAMAN AND NICOBAR ISLANDS"
    },
    {
        value: "AP",
        name: "ANDHRA PRADESH"
    },
    {
        value: "AR",
        name: "ARUNACHAL PRADESH"
    },
    {
        value: "AS",
        name: "ASSAM"
    },
    {
        value: "BR",
        name: "BIHAR"
    },
    {
        value: "CT",
        name: "CHHATTISGARH"
    },
    {
        value: "CH",
        name: "CHANDIGARH"
    },
    {
        value: "DD",
        name: "DAMAN AND DIU"
    },
    {
        value: "DL",
        name: "DELHI"
    },
    {
        value: "DN",
        name: "DADRA AND NAGAR HAVELI"
    },
    {
        value: "GA",
        name: "GOA"
    },
    {
        value: "GJ",
        name: "GUJARAT"
    },
    {
        value: "HR",
        name: "HARYANA"
    },
    {
        value: "HP",
        name: "HIMACHAL PRADESH"
    },
    {
        value: "JH",
        name: "JHARKHAND"
    },
    {
        value: "JK",
        name: "JAMMU AND KASHMIR"
    },
    {
        value: "KA",
        name: "KARNATAKA"
    },
    {
        value: "KL",
        name: "KERALA"
    },
    {
        value: "LD",
        name: "LAKSHADWEEP"
    },
    {
        value: "LA",
        name: "LADAKH"
    },
    {
        value: "MH",
        name: "MAHARASHTRA"
    },
    {
        value: "MP",
        name: "MADHYA PRADESH"
    },
    {
        value: "MN",
        name: "MANIPUR"
    },
    {
        value: "ML",
        name: "MEGHLAYA"
    },
    {
        value: "MZ",
        name: "MIZORAM"
    },
    {
        value: "NL",
        name: "NAGALAND"
    },
    {
        value: "OD",
        name: "ODISHA"
    },
    {
        value: "PB",
        name: "PUNJAB"
    },
    {
        value: "PY",
        name: "PUDUCHERRY"
    },
    {
        value: "RJ",
        name: "RAJASTHAN"
    },
    {
        value: "SK",
        name: "SIKKIM"
    },
    {
        value: "TN",
        name: "TAMIL NADU"
    },
    {
        value: "TR",
        name: "TRIPURA"
    },
    {
        value: "TS",
        name: "TELANGANA"
    },
    {
        value: "UK",
        name: "UTTARAKHAND"
    },
    {
        value: "UP",
        name: "UTTAR PRADESH"
    },
    {
        value: "WB",
        name: "WEST BENGAL"
    }
];

export const product = [
    {
        value: 1,
        name: "RFS"
    },
    {
        value: 2,
        name: "Datum"
    },
    {
        value: 3,
        name: "Mobile Datum"
    },
    {
        value: 4,
        name: "Datus"
    },
    {
        value: 6,
        name: "Concept Enquiry"
    },
    {
        value: 7,
        name: "HSD"
    },
    {
        value: 8,
        name: "Repos Owned Datum"
    },
    {
        value: 9,
        name: "Repos Pay Card"
    }
];

export const leadStatus = [
    {
        value: 1,
        name: "Hot"
    },
    {
        value: 2,
        name: "Warm"
    },
    {
        value: 3,
        name: "Cold"
    }
];

export const leadStage = [
    {
        key: "9",
        name: "New "
    },
    {
        key: "14",
        name: "FUTURE PROSPECT "
    },
    {
        key: "30",
        name: "EOI PROSPECT "
    },
    {
        key: "10",
        name: "Unanswered "
    },
    {
        key: "12",
        name: "Follow up"
    },
    {
        key: "1",
        name: "Qualified"
    },
    {
        key: "13",
        name: "Not Qualified"
    },
    {
        key: "17",
        name: "Invalid"
    },
    {
        key: "20",
        name: "Meeting Done"
    },
    {
        key: "21",
        name: "Quotation Sent"
    },
    {
        key: "29",
        name: "Meeting Set"
    },
    {
        key: "22",
        name: "Negotiation"
    },
    {
        key: "23",
        name: "Chassis Inward"
    },
    {
        key: "24",
        name: "Chassis Transit"
    },
    {
        key: "25",
        name: "PO Sent to Dealer"
    },
    {
        key: "26",
        name: "Booking Received"
    },
    {
        key: "27",
        name: "Follow-up"
    },
    {
        key: "28",
        name: "Lost Customer/Not Interested"
    },

    {
        key: "23",
        name: "RFS Booking-Chassis inward",
    },
    {
        key: "24",
        name: "RFS Booking-Chassis In-transit",
    },
    {
        key: "25",
        name: "RFD Booking-PO Sent to Dealer",
    },
    {
        key: "26",
        name: "RFD Booking-Amount Received",
    },
    {
        key: "31",
        name: "Future Prospect"
    }
];

export const cummins_dealer = [
        {
            "value": 1,
            "name": "Cummins Sales"
        },
        {
            "value": 2,
            "name": "Deccan Sales"
        },
        {
            "value": 3,
            "name": "Deccan Sales"
        },
        {
            "value": 4,
            "name": "Deki Corporation Bhutan"
        },
        {
            "value": 5,
            "name": "Garuda Bihar"
        },
        {
            "value": 6,
            "name": "Garuda Jharkhand"
        },
        {
            "value": 7,
            "name": "Garuda Jorhat/Tezpur/Tinsukhia"
        },
        {
            "value": 8,
            "name": "Garuda Kolkata"
        },
        {
            "value": 9,
            "name": "Garuda Rourkela"
        },
        {
            "value": 10,
            "name": "Garuda Guwahati/Silchar/Agartala"
        },
        {
            "value": 11,
            "name": "Gulati Diesels"
        },
        {
            "value": 12,
            "name": "Jakson Limited Nepal"
        },
        {
            "value": 13,
            "name": "Jakson Ventures"
        },
        {
            "value": 14,
            "name": "JNM"
        },
        {
            "value": 15,
            "name": "Karam Sales"
        },
        {
            "value": 16,
            "name": "Maniranjan"
        },
        {
            "value": 17,
            "name": "NEW Age Techsol"
        },
        {
            "value": 18,
            "name": "Pal Svam"
        },
        {
            "value": 19,
            "name": "ROD"
        },
        {
            "value": 20,
            "name": "ROI"
        },
        {
            "value": 21,
            "name": "Srinivasa Bhubaneshwar"
        },
        {
            "value": 22,
            "name": "Srinivasa Sales"
        },
        {
            "value": 23,
            "name": "Sudhir Sales"
        },
        {
            "value": 24,
            "name": "Sunitha Sales"
        },
        {
            "value": 25,
            "name": "Sunny Diesels"
        },
        {
            "value": 26,
            "name": "Trident"
        },
        {
            "value": 27,
            "name": "Trident Mumbai"
        },
        {
            "value": 28,
            "name": "Trident Pune/Kolhapur"
        },
        {
            "value": 29,
            "name": "Trinity"
        },
        {
            "value": 30,
            "name": "Trinity CSN"
        },
        {
            "value": 31,
            "name": "Trinity Goa"
        },
        {
            "value": 32,
            "name": "Trinity Mumbai"
        },
        {
            "value": 33,
            "name": "URSS Tech"
        }
    ]
;

export const stages = [
    {
        key: 10,
        name: "Unanswered "
    },
    {
        key: 12,
        name: "Follow up"
    },
    {
        key: 1,
        name: "Qualified"
    },
    {
        key: 13,
        name: "Not Qualified"
    },
    {
        key: 17,
        name: "Invalid"
    },
    {
        key: 14,
        name: "Future Prospect"
    },
    {
        key: 30,
        name: "EOI Prospect "
    }
    // {
    //     key: 11,
    //     name: "Details Sent",
    // },
    // {
    //     key: 7,
    //     name: "Quotation sent",
    // },
    // {
    //     key: 14,
    //     name: "EOI Prospects",
    // },
    // {
    //     key: 15,
    //     name: "Meeting Set",
    // },
    // {
    //     key: 2,
    //     name: "Meeting Done",
    // },
    // {
    //     key: 16,
    //     name: "Post meeting follow up",
    // },
    // {
    //     key: 3,
    //     name: "Negotiation ",
    // },
    // {
    //     key: 4,
    //     name: "PO",
    // },
    // {
    //     key: 5,
    //     name: "Booking",
    // },
    // {
    //     key: 6,
    //     name: "Won",
    // },
    // {
    //     key: 8,
    //     name: "Lost",
    // },
];

export const departmentList = [
    {
        key: 1,
        name: "Operational Excellence"
    },
    {
        key: 2,
        name: "OE Partner Onboarding"
    },
    {
        key: 3,
        name: "OE Order Fulfillment"
    },
    {
        key: 4,
        name: "Business Associates"
    },
    {
        key: 5,
        name: "Admin"
    },
    {
        key: 6,
        name: "Business Development"
    },
    {
        key: 7,
        name: "Finance Team"
    },
    {
        key: 8,
        name: "Tech Team"
    },
    {
        key: 9,
        name: "Service Engineer"
    },
    {
        key: 10,
        name: "Founders Office"
    },
    {
        key: 11,
        name: "Cummins Ho"
    },
    {
        key: 12,
        name: "Cummins Dealer"
    }
];

export const priorityType = [
    {
        key: "P0",
        name: "P0"
    },
    {
        key: "P1",
        name: "P1"
    },
    {
        key: "P2",
        name: "P2"
    },
    {
        key: "P3",
        name: "P3"
    }
];


export const PostQualification = [
    {
        key: "20",
        name: "Meeting Done"
    },
    {
        key: "21",
        name: "Quotation Sent"
    },
    {
        key: "29",
        name: "Meeting Set"
    },
    {
        key: "22",
        name: "Negotiation"
    },
    {
        key: "23",
        name: "RFS Booking-Chassis inward",
    },
    {
        key: "24",
        name: "RFS Booking-Chassis In-transit",
    },
    {
        key: "25",
        name: "RFD Booking-PO Sent to Dealer",
    },
    {
        key: "26",
        name: "RFD Booking-Amount Received",
    },
    {
        key: "27",
        name: "Follow-up"
    },
    {
        key: "28",
        name: "Lost Customer/Not Interested"
    },
    {
        key: "31",
        name: "Future Prospect"
    }
];

/************************** DatumQuestions *************************/

export const Employee_Size = [
    {
        key: 1,
        name: "1 – 9 Employees "
    },
    {
        key: 2,
        name: "10 – 49 Employees "
    },
    {
        key: 3,
        name: "50 – 249 Employees "
    },
    {
        key: 4,
        name: "250 or more Employees "
    }
];

export const Turn_Over = [
    {
        key: 1,
        name: "₹1 crore to ₹5 crores "
    },
    {
        key: 2,
        name: "₹5 crores to ₹10 crores "
    },
    {
        key: 3,
        name: "₹10 crores to ₹25 crores "
    },
    {
        key: 4,
        name: "₹25 crores to ₹50 crores "
    },
    {
        key: 5,
        name: "₹50 crores to ₹100 crores "
    },
    {
        key: 6,
        name: "More than ₹100 crores "
    }
];

export const Asset_Owned = [
    {
        key: 1,
        name: "0-10 "
    },
    {
        key: 2,
        name: "10-20 "
    },
    {
        key: 3,
        name: "20-50"
    },
    {
        key: 4,
        name: "50-70 "
    },
    {
        key: 5,
        name: "70-100 "
    },
    {
        key: 6,
        name: "100 & above "
    }
];

export const consumption = [
    {
        key: 1,
        name: "100 - 500 Liter "
    },
    {
        key: 2,
        name: "500 - 1000 Liter "
    },
    {
        key: 3,
        name: " 1000 - 2000 Liter"
    },
    {
        key: 4,
        name: "2000 - 5000 Liter "
    },
    {
        key: 5,
        name: "5000 - 10000 Liter "
    },
    {
        key: 6,
        name: "10000 - 20000 Liter "
    },
    {
        key: 7,
        name: "Above 20000 Liter "
    },
    {
        key: 8,
        name: "Above 30000 Liter "
    }
];

export const usage = [
    {
        key: 1,
        name: "Equipments "
    },
    {
        key: 2,
        name: "Transportation"
    },
    {
        key: 3,
        name: "DG Set"
    },
    {
        key: 4,
        name: "Partner Owned "
    },
    {
        key: 5,
        name: "Test"
    }
];

/************************** end *************************/

export const LeadSourceChoice = [
    {keys: 1, value: "Performance Marketing Campaign"},
    {keys: 2, value: "Digital Webinar RFD"},
    {keys: 3, value: "Digital Webinar RFS"},
    // {keys: 4, value: "Digital Webinar Dealership"},
    {keys: 5, value: "Referrals"},
    {keys: 6, value: "Existing Customer"},
    // {keys: 7, value: "Existing Partner"},
    {keys: 8, value: "Events"},
    {keys: 9, value: "Database"},
    {keys: 10, value: "Walk In"},
    {keys: 11, value: "OMC"},
    {keys: 12, value: "OEM"},
    // {keys: 13, value: "IB"},
    {keys: 14, value: "After Sales"},
    {keys: 15, value: "Dealership"},
    {keys: 16, value: "Inbound"},
    // {keys: 17, value: "Digital Webinar Zoom"},
    // {keys: 18, value: "Micro Segment"},
    // {keys: 19, value: "Social Inlead"},
];

export const SubLeadSourceForREFERRAlS = [
    {keys: 1, value: "REFERRAL TEAMS"},
    {keys: 2, value: "REFERRAL PARTNERS"},
    {keys: 3, value: "REFERRAL BUSINESS"},
    {keys: 4, value: "REFERRAL OTHER"},
];

export const SubLeadSourceForInbound = [
    {keys: 1, value: "INBOUND HELPDESK"},
    {keys: 2, value: "INBOUND SOCIAL MEDIA"},
];

export const invertArrToJson = data => {
    const convertedData = Object.keys(data).map(key => ({
        key: parseInt(key),
        value: data[key]
    }));

    return convertedData;
};


/******************* customer tab *******************/


// export const Employee_Size = [
//     {
//         key: 1,
//         name: "1 – 9 Employees "
//     },
//     {
//         key: 2,
//         name: "10 – 49 Employees "
//     },
//     {
//         key: 3,
//         name: "50 – 249 Employees "
//     },
//     {
//         key: 4,
//         name: "250 or more Employees "
//     }
// ];
//
// export const Turn_Over = [
//     {
//         key: 1,
//         name: "₹1 crore to ₹5 crores "
//     },
//     {
//         key: 2,
//         name: "₹5 crores to ₹10 crores "
//     },
//     {
//         key: 3,
//         name: "₹10 crores to ₹25 crores "
//     },
//     {
//         key: 4,
//         name: "₹25 crores to ₹50 crores "
//     },
//     {
//         key: 5,
//         name: "₹50 crores to ₹100 crores "
//     },
//     {
//         key: 6,
//         name: "More than ₹100 crores "
//     }
// ];
//
// export const Asset_Owned = [
//     {
//         key: 1,
//         name: "0-10 "
//     },
//     {
//         key: 2,
//         name: "10-20 "
//     },
//     {
//         key: 3,
//         name: "20-50"
//     },
//     {
//         key: 4,
//         name: "50-70 "
//     },
//     {
//         key: 5,
//         name: "70-100 "
//     },
//     {
//         key: 6,
//         name: "100 & above "
//     }
// ];
//
// export const consumption = [
//     {
//         key: 1,
//         name: "100 - 500 Liter "
//     },
//     {
//         key: 2,
//         name: "500 - 1000 Liter "
//     },
//     {
//         key: 3,
//         name: " 1000 - 2000 Liter"
//     },
//     {
//         key: 4,
//         name: "2000 - 5000 Liter "
//     },
//     {
//         key: 5,
//         name: "5000 - 10000 Liter "
//     },
//     {
//         key: 6,
//         name: "10000 - 20000 Liter "
//     },
//     {
//         key: 7,
//         name: "Above 20000 Liter "
//     },
//     {
//         key: 8,
//         name: "Above 30000 Liter "
//     }
// ];
//
// export const usage = [
//     {
//         key: 1,
//         name: "Equipments "
//     },
//     {
//         key: 2,
//         name: "Transportation"
//     },
//     {
//         key: 3,
//         name: "DG Set"
//     },
//     {
//         key: 4,
//         name: "Partner Owned "
//     },
//     {
//         key: 5,
//         name: "Test"
//     }
// ];
//
// /******************* customer tab *******************/
