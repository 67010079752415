import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {withStyles} from "@material-ui/core";

// Material components
// import { CircularProgress, Typography } from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Shared services
// Custom components
import {UsersTable, UsersToolbar} from "./components";

// Component styles
import styles from "./style";
import {getAllTrackers, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import axios from "axios";
import {Breakpoint} from "react-socks";
import Cards from "views/AllTrackers/components/Cards/Cards";
import secureLocalStorage from "react-secure-storage";

class AllTrackers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            rowsPerPage: 10,
            page: 0
        };
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getTrackerData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getTrackerData("");
        }
    };

    onSelectedRefueller = value => {
        if (value === "") {
            this.getTrackerData("", this.state.rowsPerPage, 0, null);
        } else this.getTrackerData("", this.state.rowsPerPage, 0, value);
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        this.setState({rowsPerPage: limit, page});

        this.getTrackerData(this.state.searchString, limit, offset);
    };

    getTrackerData = (
        searchTerm = "",
        limit = this.state.rowsPerPage,
        offset = 0,
        refueller = null,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllTrackers(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            refueller,
            abortController
        );
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getTrackerData();
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <DashboardLayout title="All Trackers">
                    <div className={classes.root}>
                        {/*{*/}
                        {/*  this.props.trackerData === 0*/}
                        {/*      ?*/}
                        {/*      <div className={classes.progressWrapper}>*/}
                        {/*        <CircularProgress className={classes.progress}/>*/}
                        {/*      </div>*/}
                        {/*      :*/}
                        <div>
                            <UsersToolbar
                                allRefuellerData={this.props.allRefuellerData}
                                searchedTerm={this.onSearchTerm}
                                allTrackerData={this.props.allTrackersData}
                                selectedRefueller={this.onSelectedRefueller}
                            />
                            <Breakpoint medium up>
                                <UsersTable
                                    dataCount={this.props.dataCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    allTrackerData={this.props.allTrackersData}
                                    page={this.state.page}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                />
                            </Breakpoint>
                            <Breakpoint small down>
                                <Cards
                                    dataCount={this.props.dataCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    allTrackerData={this.props.allTrackersData}
                                    page={this.state.page}
                                    onPagination={this.onPagination}
                                    noDataFound={this.props.noDataFound}
                                    isLoading={this.props.isLoading}
                                />
                            </Breakpoint>
                        </div>
                        {/*}*/}
                    </div>
                </DashboardLayout>
            );
        return true;
    }
}

AllTrackers.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allRefuellerData: state.allRefuellerData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        allTrackersData: state.allTrackersData,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllTrackers: (
            accessToken,
            searchString,
            limit,
            offset,
            refueller,
            abortController
        ) => {
            dispatch(
                getAllTrackers(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    refueller,
                    abortController
                )
            );
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(AllTrackers));
