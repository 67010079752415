import React, {useState} from "react";
import {Dashboard as DashboardLayout} from "layouts";
import {connect} from "react-redux";
import {compose} from "underscore";
import {withStyles} from "@material-ui/styles";
import styles from "../AllPumps/components/CreatePumpSingleSignOn/styles";
import {mapStateToProps} from "../FetchDataFromReducer/mapStateToProps";
import {Portlet, PortletContent} from "../../components";
import {Box, Grid, Typography} from "@mui/material";
import {InputAdornment, TableContainer, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import decryption from "../../encryption/Decryption";
import JSONPretty from "react-json-pretty";

let JSONPrettyMon = require("react-json-pretty/dist/monikai");

const Decryption = () => {
    // const [params, setParams] = useState(undefined);
    const [apiBody, setApiBody] = useState({
        method: "get"
    });
    // const [isJson, setJson] = useState(false);
    const [response, setResponse] = useState(undefined);
    // const [isOpen, setIsOpen] = useState(false);

    // const regex = /^{.*}$/;

    // const validate = json => {
    //     const valid = regex.test(json);
    //     setParams(json);
    //     if (!valid) {
    //         console.log("The JSON document is invalid.");
    //         setJson(false);
    //     } else {
    //         console.log("The JSON document is valid.");
    //         setJson(true);
    //     }
    // };

    const handleChange = event => {
        event.preventDefault();
        setApiBody({
            ...apiBody,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async event => {
        event.preventDefault();
        // setIsOpen(true);
        let param ={
            data: apiBody.endPoint
        }
        setResponse(decryption(param))

        // if (apiBody?.method === "get") {
        //     await reposWebApi[apiBody?.method](apiBody?.endPoint, {
        //         headers: {
        //             Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
        //         },
        //         params: params
        //     })
        //         .then(response => {
        //             setIsOpen(false);
        //             setResponse(decryption(response));
        //         })
        //         .catch(error => {
        //             setIsOpen(false);
        //             if (error?.response?.status === 500) {
        //                 setResponse(error.response);
        //             }
        //             if (
        //                 error?.response?.status === 502 ||
        //                 error?.response?.status === 504
        //             ) {
        //                 setResponse(error.response);
        //             } else {
        //                 setResponse(error.response);
        //             }
        //         });
        // } else {
        //     await reposWebApi[apiBody?.method](
        //         apiBody?.endPoint,
        //         qs.stringify(params),
        //         {
        //             headers: {
        //                 Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
        //             }
        //         }
        //     )
        //         .then(response => {
        //             setIsOpen(false);
        //             setResponse(decryption(response));
        //         })
        //         .catch(error => {
        //             setIsOpen(false);
        //             if (error?.response?.status === 500) {
        //                 setResponse(error.response);
        //             }
        //             if (
        //                 error?.response?.status === 502 ||
        //                 error?.response?.status === 504
        //             ) {
        //                 setResponse(error.response);
        //             } else {
        //                 setResponse(error.response);
        //             }
        //         });
        // }
    };

    return (
        <DashboardLayout title="Decryption">
            <div>
                <Portlet>
                    <PortletContent noPadding>
                        <Box padding={5}>
                            <Grid container spacing={2}>
                                {/*<Grid item xs={2}>*/}
                                {/*    <div>*/}
                                {/*        <FormControl fullWidth variant={"outlined"}>*/}
                                {/*            <InputLabel id="demo-simple-select-autowidth-label">*/}
                                {/*                Method*/}
                                {/*            </InputLabel>*/}
                                {/*            <Select*/}
                                {/*                fullWidth*/}
                                {/*                labelId="method"*/}
                                {/*                id="select-method"*/}
                                {/*                value={apiBody.method}*/}
                                {/*                onChange={event => handleChange(event)}*/}
                                {/*                autoWidth*/}
                                {/*                name={"method"}*/}
                                {/*                label="Age"*/}
                                {/*            >*/}
                                {/*                <MenuItem value={"get"}>GET</MenuItem>*/}
                                {/*                <MenuItem value={"post"}>POST</MenuItem>*/}
                                {/*                <MenuItem value={"delete"}>DELETE</MenuItem>*/}
                                {/*            </Select>*/}
                                {/*        </FormControl>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <TextField
                                        value={apiBody?.endPoint}
                                        name={"endPoint"}
                                        onChange={event => handleChange(event)}
                                        id="endPoint"
                                        label="Encrypted Data"
                                        variant="outlined"
                                        multiline
                                        rows={8}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"></InputAdornment>
                                            )
                                        }}
                                        // placeholder={"/customer/all/"}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/*{isOpen ? (*/}
                                    {/*    <CircularProgress/>*/}
                                    {/*) : (*/}
                                        <Button
                                            variant="contained"
                                            disabled={
                                                // apiBody?.method === undefined ||
                                                apiBody?.endPoint === undefined
                                            }
                                            color={"primary"}
                                            onClick={handleSubmit}
                                        >
                                            Decrypt Repose
                                        </Button>
                                    {/*)}*/}
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <TextField*/}
                                {/*        onChange={event => validate(event.target.value)}*/}
                                {/*        id="params"*/}
                                {/*        label="Params in json format"*/}
                                {/*        variant="outlined"*/}
                                {/*        placeholder={'{"key":"value"}'}*/}
                                {/*        value={params}*/}
                                {/*        fullWidth*/}
                                {/*        multiline*/}
                                {/*        rows={5}*/}
                                {/*        error={!isJson}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Typography variant="h6">Response</Typography>
                                    <TableContainer style={{maxHeight: 640}}>
                                        <Box mt={3} display={"flex"} flexDirection={"row"}>
                                            <JSONPretty
                                                theme={JSONPrettyMon}
                                                data={response}
                                            ></JSONPretty>
                                        </Box>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Box>
                    </PortletContent>
                </Portlet>
            </div>
        </DashboardLayout>
    );
};
export default connect(mapStateToProps)(
    compose(withStyles(styles))(Decryption)
);
