import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Grid, TextField, Typography, withStyles} from "@material-ui/core";

// Shared components
import {SearchInput} from "components";

// Component styles
import styles from "./styles";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: ""
        };
    }

    onSearch = searchTerm => {
        this.setState({
            searchString: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    };

    onFromDate = e => {
        const {selectFromDate} = this.props;
        selectFromDate(e.target.value);

        this.setState({
            state: e.target.value
        });
    };

    onToDate = e => {
        const {selectToDate} = this.props;
        selectToDate(e.target.value);
        this.setState({
            state: e.target.value
        });
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{
                            fontWeight: 300,
                            color: "gray",
                            marginBottom: "4%"
                        }}
                    >
                        All Fleet Card Payments
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search by VAN, Transaction ref id or Payer Name "
                            getsearchterm={this.onSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.row} style={{marginTop: "0px"}}>
                            <TextField
                                size={"small"}
                                id="date"
                                label="From"
                                type="date"
                                onChange={event => this.onFromDate(event)}
                                style={{marginRight: "10px"}}
                                variant={"outlined"}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <TextField
                                size={"small"}
                                id="date"
                                label="To"
                                type="date"
                                className={classes.textField}
                                variant={"outlined"}
                                onChange={event => this.onToDate(event)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
