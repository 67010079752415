import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {
    AddLead,
    getAllLeads,
    getCumminsLeadOwner,
    getLeadOwner,
    getRegionsAndState,
    getStateByTerritory,
    updateLead
} from "actions/actions.js";
import Autocomplete from "@mui/material/Autocomplete";
import secureLocalStorage from "react-secure-storage";
import {
    cummins_dealer,
    LeadSourceChoice,
    SubLeadSourceForInbound,
    SubLeadSourceForREFERRAlS
} from "../../../../constants/constants";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const region = ["NORTH", "EAST", "WEST", "SOUTH"];

// const datum = ["RPP 1", "RPP 2", "RPP 3"];

const AddLeadForm = props => {
    const dispatch = useDispatch();
    const {
        stateData,
        listLeads,
        handleDrawerClose,
        idEdit,
        param,
        className,
        ...rest
    } = props;
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    // const mobileNumber = new RegExp(/^[789]\d{9}$/);

    const [formData, setFormData] = useState({
        company_name: listLeads?.company_name,
        partner_name: listLeads?.partner_name,
        company_mobile: listLeads?.company_mobile,
        product: listLeads?.product ? listLeads?.product : 2,
        repos_id: listLeads?.created_by_id,
        lead_owner: listLeads?.lead_owner,
        lead_status: listLeads?.lead_status ? listLeads?.lead_status : 1,
        comment: listLeads?.comment,
        owner_type: listLeads?.owner_type,
        partner_email: listLeads?.partner_email,
        partner_designation: listLeads?.partner_designation,
        region: listLeads?.region,
        partner_state: listLeads?.state_code,
        partner_territory: listLeads?.partner_territory,
        lead_source: listLeads?.lead_source,
        booking_amount_recieved: listLeads?.booking_amount_recieved ? 1 : 0,
        cummins_dealer: listLeads?.cummins_dealer,
        sub_lead_source: listLeads?.sublead_source
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // For Cummins User
        if (
            secureLocalStorage.getItem("department") === 11 ||
            secureLocalStorage.getItem("department") === 12
        ) {
            dispatch(
                getCumminsLeadOwner(
                    secureLocalStorage.getItem("accessToken"),
                    undefined,
                    setIsLoading
                )
            );
        } else {
            dispatch(
                getLeadOwner(
                    secureLocalStorage.getItem("accessToken"),
                    undefined,
                    setIsLoading
                )
            );
        }
        if (idEdit) {
            const param = {
                region: listLeads?.region
            };
            dispatch(
                getRegionsAndState(
                    secureLocalStorage.getItem("accessToken"),
                    enqueueSnackbar,
                    param
                )
            );
            const state_name = {
                state_name: listLeads?.state_code
            };
            dispatch(
                getStateByTerritory(
                    secureLocalStorage.getItem("accessToken"),
                    enqueueSnackbar,
                    state_name
                )
            );
        }
    }, [dispatch]);

    const handleInputChange = event => {
        const {id, value} = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
        if (event.target.id === "repos_id") {
            setFormData({
                ...formData,
                [id]: value.replace(/[^\w\s]/gi, "").toUpperCase()
            });
        }
        if (event.target.id === "region") {
            const param = {
                region: value.trim()
            };
            dispatch(
                getRegionsAndState(
                    secureLocalStorage.getItem("accessToken"),
                    enqueueSnackbar,
                    param
                )
            );
        }

        if (event.target.id === "partner_email") {
            setFormData({
                ...formData,
                [id]: value.toLowerCase()
            });
        }

        if (event.target.id === "partner_state") {
            const param = {
                state_name: value
            };
            dispatch(
                getStateByTerritory(
                    secureLocalStorage.getItem("accessToken"),
                    enqueueSnackbar,
                    param
                )
            );
        }
    };

    const handleAutoSelect = (event, value) => {
        setFormData({
            ...formData,
            lead_owner: value?.id
        });
        // setLead_name(value?.name);
    };

    const handleRadioValue = event => {
        setFormData({
            ...formData,
            owner_type: event.target.value
        });
    };

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param));
    };

    const handleSubmit = () => {
        handleDrawerClose();
        {
            idEdit
                ? dispatch(
                    updateLead(
                        secureLocalStorage.getItem("accessToken"),
                        formData,
                        listLeads.id,
                        enqueueSnackbar,
                        handleDrawerClose,
                        getLeadList
                    )
                )
                : dispatch(
                    AddLead(
                        secureLocalStorage.getItem("accessToken"),
                        formData,
                        enqueueSnackbar,
                        handleDrawerClose,
                        getLeadList
                    )
                );
        }
    };

    const onSearchTerm = event => {
        // const param = {
        //     search: event,
        // };
        dispatch(getLeadOwner(secureLocalStorage.getItem("accessToken")));
    };

    /************************** Validators functions ******************************/
    const isFormValid = () => {
        const requiredFieldsForCRM = [
            "company_name",
            "partner_name",
            "company_mobile",
            "product",
            // "repos_id",
            "lead_owner",
            // "lead_status",
            // "comment",
            "lead_source",
            // "owner_type",
            "partner_email",
            // "partner_designation",
            "region",
            "partner_state"
            // "partner_territory",
        ];

        return requiredFieldsForCRM.every(field => formData[field]);
    };

    // const validatePhoneNumber = (number) => {
    //     return mobileNumber.test(number);
    // };

    const validateEmail = e_mail => {
        // eslint-disable-next-line
        var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

        return re.test(e_mail);
    };
    /************************* ************ ****************************/

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <TextField
                label="Company/Partner Name"
                margin="dense"
                type="text"
                id="company_name"
                error={!formData.company_name || formData.company_name === ""}
                fullWidth
                required
                value={formData.company_name}
                onChange={handleInputChange}
                variant="outlined"
            />
            <TextField
                className={classes.textField}
                error={!formData.partner_name || formData.partner_name === ""}
                label="Contact Person Name"
                margin="dense"
                type="text"
                value={formData.partner_name}
                id={"partner_name"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />
            <TextField
                label="Mobile"
                margin="dense"
                type="number"
                id="company_mobile"
                disabled={idEdit}
                fullWidth
                required
                error={!formData.company_mobile || formData.company_mobile === ""}
                // error={!validatePhoneNumber(formData.company_mobile)}
                value={formData.company_mobile}
                onChange={handleInputChange}
                variant="outlined"
            />
            <TextField
                required
                className={classes.textField}
                label="Email"
                margin="dense"
                error={!validateEmail(formData.partner_email)}
                type="text"
                value={formData.partner_email}
                id={"partner_email"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />
            <TextField
                className={classes.textField}
                label="Designation "
                margin="dense"
                type="text"
                value={formData.partner_designation}
                id={"partner_designation"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            {/* <TextField
                helperText={
                    "Please Enter Valid Repos Id"
                }
                label="Lead Created By (Repos Id)"
                margin="dense"
                type="text"
                id="repos_id"
                fullWidth
                required
                value={formData.repos_id}
                onChange={handleInputChange}
                variant="outlined"
            /> */}
            <Typography
                style={{fontSize: "12px", whiteSpace: "nowrap", lineHeight: "19px"}}
            >
                Select Region *
            </Typography>
            <TextField
                className={classes.textField}
                // label={"Region"}
                id={"region"}
                margin="dense"
                onChange={handleInputChange}
                required
                error={formData.region === null || formData.region === ""}
                fullWidth
                select
                SelectProps={{native: true}}
                variant="outlined"
                value={formData.region}
            >
                <option key={0} value={""}></option>
                {region.map(option => (
                    <option key={option} id={option} value={option}>
                        {option}
                    </option>
                ))}
            </TextField>
            <Typography
                style={{fontSize: "12px", whiteSpace: "nowrap", lineHeight: "19px"}}
            >
                Select State *
            </Typography>
            <TextField
                className={classes.textField}
                // label={"Select State"}
                id={"partner_state"}
                margin="dense"
                onChange={handleInputChange}
                // required
                error={formData.partner_state === null || formData.partner_state === ""}
                disabled={!formData.region}
                fullWidth
                select
                SelectProps={{native: true}}
                variant="outlined"
                value={formData.partner_state}
                defaultValue={formData.partner_state}
            >
                {/* value={values.state} */}
                <option key={0} value={""}></option>
                {/* ))} */}
                {stateData?.getStateOfIndia?.map(option => (
                    <option
                        key={option.stateCode}
                        id={option.stateCode}
                        value={option.stateCode}
                    >
                        {option.stateName}
                    </option>
                ))}
            </TextField>
            <Typography
                style={{fontSize: "12px", whiteSpace: "nowrap", lineHeight: "19px"}}
            >
                Select Territory
            </Typography>
            <TextField
                className={classes.textField}
                // label={"Territory"}
                id={"partner_territory"}
                margin="dense"
                onChange={handleInputChange}
                // required
                disabled={!formData.partner_state}
                fullWidth
                select
                SelectProps={{native: true}}
                variant="outlined"
                value={formData.partner_territory}
            >
                {/* value={values.state} */}
                <option key={0} value={""}></option>
                {/* ))} */}
                {stateData?.getTerritories?.map(option => (
                    <option key={option} id={option} value={option}>
                        {option}
                    </option>
                ))}
            </TextField>

            {(secureLocalStorage.getItem("department") === 11 || secureLocalStorage.getItem("department") === 12) ? (
                <TextField
                    className={classes.textField}
                    label={"Product"}
                    margin="dense"
                    fullWidth
                    value={formData.product}
                    onChange={handleInputChange}
                    // required
                    id={"product"}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    {/* <option value={1}>RPP</option> */}
                    <option value={2}>Datum</option>
                    {/* <option value={3}>Mobile Datum</option> */}
                    {/* <option value={4}>Datus</option> */}
                </TextField>
            ) : (
                <>
                    <TextField
                        className={classes.textField}
                        label={"Product"}
                        margin="dense"
                        fullWidth
                        value={formData.product}
                        error={!formData.product || formData.product < 1}
                        onChange={handleInputChange}
                        // required
                        id={"product"}
                        select
                        SelectProps={{native: true}}
                        variant="outlined"
                    >
                        <option value={1}>RFS</option>
                        <option value={2}>Datum</option>
                        <option value={3}>Mobile Datum</option>
                        <option value={4}>Datus</option>
                        <option value={6}>Concept Enquiry</option>
                        <option value={7}>HSD</option>
                        <option value={8}>Repos Owned Datum</option>
                        <option value={9}>Repos Pay Card</option>
                    </TextField>

                    {/***************** Radio button for add lead ***************/}
                    {formData.product < 2 && (
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={formData.owner_type}
                                onChange={handleRadioValue}
                            >
                                <FormControlLabel
                                    id={"pump"}
                                    value="Petrol pump"
                                    control={<Radio/>}
                                    label="Petrol Pump"
                                />
                                <FormControlLabel
                                    id={"start_up"}
                                    value="Start up"
                                    control={<Radio/>}
                                    label="Start up"
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                </>
            )}
            {/****************************** ******************************/}

            {secureLocalStorage.getItem("department") === 11 ||
            secureLocalStorage.getItem("department") === 12 ? (
                <>
                    {formData.product <= 2 && (
                        <FormControlLabel
                            control={
                                <Switch
                                    className={classes.switch}
                                    checked={formData.booking_amount_recieved}
                                    onChange={event => {
                                        const newValue = event.target.checked ? 1 : 0;
                                        handleInputChange({
                                            target: {
                                                id: "booking_amount_recieved",
                                                value: newValue
                                            }
                                        });
                                    }}
                                />
                            }
                            label="Booking Amount Received"
                        />
                    )}
                </>
            ) : (
                <>
                    {formData.product === 2 && (
                        <FormControlLabel
                            control={
                                <Switch
                                    className={classes.switch}
                                    checked={formData.booking_amount_recieved}
                                    onChange={event => {
                                        const newValue = event.target.checked ? 1 : 0;
                                        handleInputChange({
                                            target: {
                                                id: "booking_amount_recieved",
                                                value: newValue
                                            }
                                        });
                                    }}
                                />
                            }
                            label="Booking Amount Received"
                        />
                    )}
                </>
            )}

            <TextField
                className={classes.textField}
                label={"Cummins Dealership"}
                margin="dense"
                fullWidth
                value={formData.cummins_dealer}
                onChange={handleInputChange}
                // required
                id={"cummins_dealer"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option value={""}></option>
                {cummins_dealer.map((option, index) =>
                    <option value={option.name}>{option.name}</option>
                )}
                {/*<option value={"Trident"}>Trident</option>*/}
                {/*<option value={"Trinity"}>Trinity</option>*/}
                {/*<option value={"JNM"}>JNM</option>*/}
                {/*<option value={"Maniranjan"}>Maniranjan</option>*/}
                {/*<option value={"New Age Techsol"}>New Age Techsol</option>*/}
                {/*<option value={"Karam Sales"}>Karam Sales</option>*/}
            </TextField>

            {listLeads?.lead_owner === undefined ? (
                <Autocomplete
                    style={{marginBottom: "7px", marginTop: "7px"}}
                    size={"small"}
                    id="lead_owner"
                    error={true}
                    onChange={handleAutoSelect}
                    options={stateData?.leadOwner}
                    // defaultValue={{id: listLeads?.lead_owner, name: listLeads?.lead_owner_name}}
                    loading={isLoading}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={
                                formData?.lead_owner === null ||
                                formData?.lead_owner === undefined
                            }
                            required
                            fullWidth
                            label="Lead Assigned To *"
                            variant="outlined"
                            // value={formData.lead_owner}
                            onChange={e => onSearchTerm(e.target.value)}
                        />
                    )}
                />
            ) : (
                <Autocomplete
                    style={{marginBottom: "7px", marginTop: "7px"}}
                    size={"small"}
                    id="lead_owner"
                    onChange={handleAutoSelect}
                    options={stateData?.leadOwner}
                    defaultValue={{
                        id: listLeads?.lead_owner,
                        name: listLeads?.lead_owner_name
                    }}
                    loading={isLoading}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={
                                formData?.lead_owner === null ||
                                formData?.lead_owner === undefined
                            }
                            fullWidth
                            label="Lead Assigned To *"
                            variant="outlined"
                            // value={formData.lead_owner}
                            onChange={e => onSearchTerm(e.target.value)}
                        />
                    )}
                />
            )}

            <TextField
                className={classes.textField}
                label={"Lead Status"}
                margin="dense"
                fullWidth
                value={formData.lead_status}
                onChange={handleInputChange}
                // required
                id={"lead_status"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                {/* value={this.state.partner_type} */}
                {/* <option key={0} value={""}></option> */}

                <option value={1}>Hot</option>
                <option value={2}>Warm</option>
                <option value={3}>Cold</option>
            </TextField>
            <TextField
                className={classes.textField}
                label={"Lead Source"}
                margin="dense"
                fullWidth
                value={formData.lead_source}
                onChange={handleInputChange}
                error={
                    formData?.lead_source === null || formData?.lead_source === undefined
                }
                required
                id={"lead_source"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option key={0} value={""}></option>
                {LeadSourceChoice.map((item, index) => (
                    <option value={item.keys} id={index}>
                        {item.value}
                    </option>
                ))}
            </TextField>

            {/****to do add a sub lead source when referrals[4], inbound[2] selected and [hide for others] *****/}
            {(parseInt(formData.lead_source) === 5 || parseInt(formData.lead_source) === 16) &&
                <TextField
                className={classes.textField}
                label={"Sub-Lead Source"}
                margin="dense"
                fullWidth
                value={formData.sub_lead_source}
                onChange={handleInputChange}
                error={
                    formData?.lead_source === null || formData?.lead_source === undefined
                }
                required
                id={"sub_lead_source"}
                select
                SelectProps={{native: true}}
                variant="outlined"
            >
                <option key={0} value={""}></option>
                {parseInt(formData.lead_source) === 5 ? SubLeadSourceForREFERRAlS.map((item, index) => (
                    <option value={item.keys} id={index}>
                        {item.value}
                    </option>
                )):
                    SubLeadSourceForInbound.map((item, index) => (
                        <option value={item.keys} id={index}>
                            {item.value}
                        </option>
                    ))
                }
            </TextField>}


            <TextField
                label="Comment"
                margin="dense"
                placeholder="Reason For Buying"
                id="comment"
                required
                fullWidth
                value={formData.comment}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={3}
            />

            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                disabled={
                    !isFormValid() ||
                    // !validatePhoneNumber(formData.company_mobile) ||
                    !validateEmail(formData.partner_email)
                }
                onClick={handleSubmit}
            >
                Save
            </Button>
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                // disabled={!isFormValid() || !validatePhoneNumber(formData.company_mobile) || !validateEmail(formData.partner_email)}
                onClick={handleDrawerClose}
            >
                Cancel
            </Button>
        </div>
    );
};
AddLeadForm.propTypes = {
    className: PropTypes.string
};
// export default compose(withStyles(styles))(AddLeadForm);

export default connect()(compose(withStyles(styles))(AddLeadForm));
