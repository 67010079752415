export default [
    {
        id: "DEV738089",
        title: "Dropbox",
        description:
            "Dropbox is a file hosting service that offers cloud storage, file synchronization, a personal cloud.",
        imageUrl: "/images/products/product_1.png",
        totalDownloads: "594",
        price: "10",
        status: "published",
        createdAt: "27/03/2019"
    },
    {
        id: "DEV795381",
        title: "Medium Corporation",
        description:
            "Medium is an online publishing platform developed by Evan Williams, and launched in August 2012.",
        imageUrl: "/images/products/product_2.png",
        totalDownloads: "625",
        price: "27",
        status: "archived",
        createdAt: "31/03/2019"
    },
    {
        id: "DEV774585",
        title: "Slack",
        description:
            "Slack is a cloud-based set of team collaboration tools and services, founded by Stewart Butterfield.",
        imageUrl: "/images/products/product_3.png",
        totalDownloads: "857",
        price: "20",
        status: "published",
        createdAt: "03/04/2019"
    },
    {
        id: "DEV654476",
        title: "Lyft",
        description:
            "Lyft is an on-demand transportation company based in San Francisco, California.",
        imageUrl: "/images/products/product_4.png",
        totalDownloads: "406",
        price: "28",
        status: "draft",
        createdAt: "04/04/2019"
    },
    {
        id: "DEV958347",
        title: "GitHub",
        description:
            "GitHub is a web-based hosting service for version control of code using Git.",
        imageUrl: "/images/products/product_5.png",
        totalDownloads: "835",
        price: "32",
        status: "published",
        createdAt: "04/04/2019"
    },
    {
        id: "DEV773559",
        title: "Squarespace",
        description:
            "Squarespace provides software as a service for website building and hosting. Headquartered in NYC.",
        imageUrl: "/images/products/product_6.png",
        totalDownloads: "835",
        price: "32",
        status: "published",
        createdAt: "04/04/2019"
    }
];
