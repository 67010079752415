import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import validate from "validate.js";
import _ from "underscore";

// Material components
import {
    Button,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";
import {createOperator} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import CircularProgress from "@material-ui/core/CircularProgress";
import reposWebApi from "../../../../actions/configuration";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import secureLocalStorage from "react-secure-storage";
import decryption from "encryption/Decryption";

class CreateDriver extends Component {
    state = {
        success: false,
        values: {
            fullName: "",
            mobile: "",
            email: "",
            password: "",
            confirmPassword: "",
            user_name: "",
            licenseNumber: "",
            insurance: "",
            viewCnfPass: false,
            viewPass: false,
            reset: false
        },
        touched: {
            fullName: false,
            mobile: false,
            email: false,
            password: false,
            confirmPassword: false,
            user_name: false,
            licenseNumber: false,
            insurance: false
        },
        errors: {
            fullName: null,
            mobile: null,
            email: null,
            password: null,
            confirmPassword: null,
            user_name: null,
            licenseNumber: null,
            insurance: null
        },
        isValid: false,
        isLoading: false,
        submitError: false,
        allPartnersData: [],
        selectedPartner: null
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = !errors;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = {...this.state};
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        this.setState(newState, this.validateForm);
    };

    handleSubmit = event => {
        event.preventDefault();
        const newState = {...this.state};
        newState.submitError = null;
        newState.values["reset"] = false;
        this.createOperator(this.state.values);
        this.props.pageLoad();
        // this.props.onClose('right', false);
        // if(this.state.success) {
        //
        //     this.props.pageLoad();
        //     this.props.onClose('right', false);
        //     this.state={
        //         success: this.props.operatorCreationSuccess
        //     }
        // }
        return false;
    };

    restorePage = () => {
        const newState = {...this.state};
        newState.submitError = null;
        newState.values["reset"] = true;
        newState.values["fullName"] = "";
        newState.values["mobile"] = "";
        newState.values["email"] = "";
        newState.values["password"] = "";
        newState.values["confirmPassword"] = "";
        newState.values["licenseNumber"] = "";
        newState.values["insurance"] = "";
        newState.values["viewCnfPass"] = false;
        this.createOperator(this.state.values);
    };

    onTagsChange = (event, values) => {
        event.preventDefault();
        this.setState({
            selectedPartner: values.id
        });
    };

    createOperator = operatorFormData => {
        this.props.handlecreateOperator(
            secureLocalStorage.getItem("accessToken"),
            operatorFormData,
            this.state.selectedPartner
        );
        return false;
    };

    handleClose = () => {
        this.props.onClose("right", false);
        this.state = {
            success: false
        };
    };

    viewPassword = () => {
        if (this.state.viewPass) {
            this.setState({
                viewPass: false
            });
        } else {
            this.setState({
                viewPass: true
            });
        }
    };

    viewConfirmPassword = () => {
        if (this.state.viewCnfPass) {
            this.setState({
                viewCnfPass: false
            });
        } else {
            this.setState({
                viewCnfPass: true
            });
        }
    };

    handleOnChange = ({target}) => {
    };

    componentDidMount() {
        reposWebApi
            .get("/partner/minimal", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: {
                    limit: 500
                }
            })
            .then(response => {
                this.setState({
                    allPartnersData: decryption(response).data
                });
            });
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    render() {
        const {classes} = this.props;
        const {values, touched, errors} = this.state;

        const showFullNameError = touched.fullName && errors.fullName;
        const showMobileError = touched.mobile && errors.mobile;
        const showEmailError = touched.email && errors.email;
        const showPasswordError = touched.password && errors.password;
        const showUserNameError = touched.user_name && errors.user_name;
        const showLicenseNoError = touched.licenseNumber && errors.licenseNumber;
        const showInsuranceError = touched.insurance && errors.insurance;
        let showConfirmPasswordError;

        showConfirmPasswordError = values.password !== values.confirmPassword;

        return (
            <div className={classes.root}>
                <form autoComplete="off" noValidate method={"POST"} action={"#"}>
                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Basic Information
                        </Typography>
                        <TextField
                            className={classes.textField}
                            label="Full Name"
                            margin="dense"
                            fullWidth
                            required
                            value={values.fullName}
                            id={"owner_name"}
                            onChange={event =>
                                this.handleFieldChange("fullName", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showFullNameError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.fullName[0]}
                                </Typography>
                            )}
                        </div>

                        <TextField
                            // className={classes.textField}
                            inputProps={{
                                className: classes.textField,
                                maxlength: 10
                            }}
                            label="Mobile"
                            margin="dense"
                            type="number"
                            fullWidth
                            value={values.mobile}
                            id={"mobile_number"}
                            onChange={event =>
                                this.handleFieldChange(
                                    "mobile",
                                    (event.target.value = Math.max(
                                        0,
                                        parseInt(event.target.value)
                                    )
                                        .toString()
                                        .slice(0, 10))
                                )
                            }
                            variant="outlined"
                        />
                        <div>
                            {showMobileError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.mobile[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label="Email"
                            margin="dense"
                            fullWidth
                            type="email"
                            id={"email"}
                            value={values.email}
                            onChange={event =>
                                this.handleFieldChange("email", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showEmailError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.email[0]}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <FormControl
                                className={classes.textField}
                                required
                                fullWidth
                                style={{margin: "10px 0 5px"}}
                                size={"small"}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    label="Password"
                                    id="outlined-adornment-password"
                                    type={this.state.viewPass ? "text" : "password"}
                                    value={values.password}
                                    onChange={event =>
                                        this.handleFieldChange("password", event.target.value)
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.viewPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.viewPass ? (
                                                    <Visibility/>
                                                ) : (
                                                    <VisibilityOff/>
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div>
                            {showPasswordError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.password[0]}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <FormControl
                                className={classes.textField}
                                required
                                fullWidth
                                style={{margin: "10px 0 5px"}}
                                size={"small"}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="outlined-adornment-ConfirmPassword">
                                    Confirm Password
                                </InputLabel>
                                <OutlinedInput
                                    label="Confirm Password"
                                    id="outlined-adornment-password"
                                    type={this.state.viewCnfPass ? "text" : "password"}
                                    value={values.confirmPassword}
                                    onChange={event =>
                                        this.handleFieldChange(
                                            "confirmPassword",
                                            event.target.value
                                        )
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.viewConfirmPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.viewCnfPass ? (
                                                    <Visibility/>
                                                ) : (
                                                    <VisibilityOff/>
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div>
                            {values.password !== "" && values.confirmPassword !== "" ? (
                                showConfirmPasswordError ? (
                                    <Typography className={classes.fieldError} variant="body2">
                                        {"Password and confirm password not match"}
                                    </Typography>
                                ) : (
                                    <Typography className={classes.fieldPassed} variant="body2">
                                        {"Passwords matched"}
                                    </Typography>
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className={classes.field}>
                        <Typography
                            variant="subtitle2"
                            style={{marginTop: "2%", color: "#808080"}}
                        >
                            Legal Details
                        </Typography>
                        <TextField
                            className={classes.textField}
                            // helperText="Please specify the Pump Name"
                            label="User name"
                            fullWidth
                            margin="dense"
                            required
                            value={values.user_name}
                            id={"driver_user_name"}
                            onChange={event =>
                                this.handleFieldChange("user_name", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showUserNameError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.user_name[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label="License Number"
                            fullWidth
                            onChange={event =>
                                this.handleFieldChange("licenseNumber", event.target.value)
                            }
                            margin="dense"
                            id={"license_number"}
                            value={values.licenseNumber}
                            variant="outlined"
                        />
                        <div>
                            {showLicenseNoError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.licenseNumber[0]}
                                </Typography>
                            )}
                        </div>
                        <TextField
                            className={classes.textField}
                            label="Insurance"
                            margin="dense"
                            type="text"
                            fullWidth
                            id={"insurance"}
                            inputProps={{maxLength: 30}}
                            value={values.insurance}
                            onChange={event =>
                                this.handleFieldChange("insurance", event.target.value)
                            }
                            variant="outlined"
                        />
                        <div>
                            {showInsuranceError && (
                                <Typography className={classes.fieldError} variant="body2">
                                    {errors.insurance[0]}
                                </Typography>
                            )}
                        </div>
                        <Autocomplete
                            required
                            fullWidth
                            style={{margin: "10px 0 5px"}}
                            size={"small"}
                            id="combo-box-demo"
                            onChange={this.onTagsChange}
                            options={this.state.allPartnersData}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Select Partner"
                                    variant="outlined"
                                    onChange={this.handleOnChange}
                                />
                            )}
                        />
                    </div>
                    <div className={classes.field}>
                        {this.props.operatorCreationIsLoading ? (
                            <CircularProgress className={classes.progress}/>
                        ) : this.props.operatorCreationSuccess ? (
                            // this.props.onClose('right', false)
                            <Grid container>
                                <Grid item xs={5}>
                                    <Button
                                        className={classes.addNewPartnerButton}
                                        color="primary"
                                        name="create_partner_submit"
                                        id="create_newOperator_submit"
                                        // disabled={!this.state.isValid}
                                        onClick={e => this.restorePage(e)}
                                        size="large"
                                        variant="contained"
                                    >
                                        Add New
                                    </Button>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                    <Button
                                        className={classes.addNewPartnerButton}
                                        color="inherit"
                                        name="create_partner_submit"
                                        id="close_submit"
                                        // disabled={!this.state.isValid}
                                        onClick={e => this.handleClose(e)}
                                        size="large"
                                        variant="contained"
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : this.props.operatorCreationError ? (
                            <>
                                <Typography className={classes.fieldError} variant="body2">
                                    Email or Mobile already Exists! please choose another email or
                                    mobile.
                                </Typography>
                                <Button
                                    className={classes.addNewPartnerButton}
                                    color="primary"
                                    name="create_operator_submit"
                                    id="create_operator_submit"
                                    disabled={!this.state.isValid}
                                    onClick={e => this.handleSubmit(e)}
                                    size="large"
                                    variant="contained"
                                >
                                    Add New Operator
                                </Button>
                            </>
                        ) : (
                            <Button
                                className={classes.addNewPartnerButton}
                                color="primary"
                                name="create_operator_submit"
                                id="create_operator_submit"
                                disabled={!this.state.isValid}
                                onClick={e => this.handleSubmit(e)}
                                size="large"
                                variant="contained"
                            >
                                Add New Operator
                            </Button>
                        )}
                        {this.props.operatorCreationSuccess ? (
                            <Typography className={classes.fieldPassed} variant="body2">
                                {this.props.operatorCreationSuccessMessage}
                            </Typography>
                        ) : (
                            ""
                        )}
                        {/*{*/}
                        {/*    this.props.operatorCreationError ?*/}
                        {/*        this.props.operatorCreationSuccess ?*/}
                        {/*            '' :*/}
                        {/*            <Typography*/}
                        {/*                className={classes.fieldError}*/}
                        {/*                variant="body2"*/}
                        {/*            >*/}
                        {/*                Email or Mobile already Exists! please choose another email or mobile.*/}
                        {/*            </Typography> : ''*/}
                        {/*}*/}
                    </div>
                </form>
            </div>
        );
    }
}

CreateDriver.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoadingPartner: state.isLoadingPartner,
        operatorCreationIsLoading: state.operatorCreationIsLoading,
        operatorCreationError: state.operatorCreationError,
        operatorCreationSuccess: state.operatorCreationSuccess,
        operatorCreationSuccessMessage: state.operatorCreationSuccessMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handlecreateOperator: (accessToken, operatorForm, partnerId) => {
            dispatch(createOperator(accessToken, operatorForm, partnerId));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(CreateDriver));
