import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
// Material components
import {Typography, withStyles} from "@material-ui/core";

// Material icons
import {AssignmentOutlined as OrderIcon} from "@material-ui/icons";

// Shared components
import {Paper} from "components";

// Component styles
import styles from "./styles";

class Orders extends Component {
    render() {
        const {
            classes,
            className,
            ordersData,
            directModeData,
            ...rest
        } = this.props;
        const nf = new Intl.NumberFormat("en-IN");
        const rootClassName = classNames(classes.root, className);

        return (
            <Paper {...rest} className={rootClassName}>
                <div className={classes.content}>
                    <div className={classes.details}>
                        <Typography className={classes.title} variant="body2">
                            TOTAL ORDERS
                        </Typography>
                        <Typography className={classes.value} variant="h3">
                            {nf.format(
                                ordersData?.order_count +
                                directModeData?.direct_mode?.total_direct_count
                            )
                                // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                        </Typography>
                    </div>
                    <div className={classes.iconWrapper}>
                        <OrderIcon className={classes.icon}/>
                    </div>
                </div>
                <div className={classes.footer}>
                    {/*<Typography*/}
                    {/*  className={classes.difference}*/}
                    {/*  variant="body2"*/}
                    {/*>*/}
                    {/*  <ArrowDownwardIcon />*/}
                    {/*  12%*/}
                    {/*</Typography>*/}
                    <Typography className={classes.caption} variant="caption">
                        Since all time
                    </Typography>
                </div>
            </Paper>
        );
    }
}

Orders.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Orders);
