import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {Card, Grid, Typography, withStyles} from "@material-ui/core";
import compose from "recompose/compose";
import styles from "../../../AllAssets/style";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    overline: {
        marginTop: theme.spacing(1)
    }
}));

const CustomerStatistics = props => {
    const {className, ...rest} = props;

    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <Grid alignItems="center" container justify="space-between">
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        {props.customerData?.all_customer_count
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        All Customer Count
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    {/*<Typography variant="h2">{props.customerData.order_frequency_avg}</Typography>*/}
                    <Typography variant="h2">
                        {props.orderData?.min_order_quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Minimum Quantity Ordered
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        {props.orderData?.max_order_quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Maximum Quantity Ordered
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} sm={6} xs={12}>
                    <Typography variant="h2">
                        {props.customerData?.customer_added_last_30_days
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        {/*<Label*/}
                        {/*    className={classes.label}*/}
                        {/*    color={colors.green[600]}*/}
                        {/*>*/}
                        {/*  Live*/}
                        {/*</Label>*/}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Customers added in Last 30 Days
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

CustomerStatistics.propTypes = {
    className: PropTypes.string
};

export default compose(withStyles(styles))(CustomerStatistics);
