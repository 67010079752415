import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getNetworkError} from "../../../../actions/actions";
import {useSnackbar} from "notistack";

const Internet = () => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        function onlineHandler() {
            enqueueSnackbar("Welcome back to online", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                }
            });
            dispatch(getNetworkError(false));
        }

        function offlineHandler() {
            enqueueSnackbar("You are offline now", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                }
            });
            dispatch(getNetworkError(true));
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);

        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, [dispatch, enqueueSnackbar]);
};
export default Internet;
