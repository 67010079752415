export default theme => ({
    root: {},
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    spacer1: {
        flexGrow: 8
    },
    spacer2: {
        flexGrow: 1
    },
    deleteButton: {
        color: theme.palette.danger.main,
        marginRight: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    importIcon: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    exportIcon: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    nameText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        color: "gray"
    },
    hoverBox: {
        borderRadius: "20px",
        boxShadow: "0 0 11px rgba(33,33,33,.2)",
        transform: "scale(1.01)",
        transition:
            ".3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)",
        "&:hover": {
            boxShadow: "inset 0 0 5px rgba(33,33,33,.2)",
            transform: "scale(1.0)"
        }
    }
});
