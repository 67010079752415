import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Button, Grid, IconButton, SwipeableDrawer, Typography, withStyles} from "@material-ui/core";

// Material icons
// Shared components
import {SearchInput} from "components";
import {CloseOutlined as CloseButton} from "@material-ui/icons";

// Component styles
import styles from "./styles";
import AddTrackerForm from "../AddTrackerForm";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: ""
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            selectedValue: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    onSelect(value) {
        const {selectedRefueller} = this.props;
        selectedRefueller(value);
    }

    handleDrawerOpen = () => {
        this.setState({drawerIsOpen: true});
    };

    handleDrawerClose = () => {
        this.setState({drawerIsOpen: false});
        // window.location.reload();
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        // const exportCSV = () => {
        //   var csvRow =[];
        //   var A = [['Vehicle NNumber','Last Location', 'Last Stock', 'Last Updated At', 'Partner', 'Driver', 'Refueler Model', 'Tracker Device Id', 'Model Type', ' CarIQ', 'CarIQ Device ID', 'Current Rate', 'Current Density']];
        //   var allRefuellerData = this.props.allRefuellerData;
        //
        //   var firstNameForFileName = '';
        //   var lastNameForFileName = '';
        //
        //   for (var item =0; item < allRefuellerData.length; item++){
        //     A.push([item, allRefuellerData[item].vehicle_registration_number, allRefuellerData[item].last_stock,allRefuellerData[item].last_feed_at,allRefuellerData[item].partner_name,allRefuellerData[item].driver,allRefuellerData[item].refueler_model,allRefuellerData[item].tracker_device, allRefuellerData[item].model_type, allRefuellerData[item].is_cariq_activated, allRefuellerData[item].cariq, allRefuellerData[item].current_rate, allRefuellerData[item].current_density])
        //     firstNameForFileName = allRefuellerData[0].vehicle_registration_number;
        //     lastNameForFileName  = allRefuellerData[allRefuellerData.length-1].vehicle_registration_number;
        //   }
        //
        //   for (var i=0; i< A.length; ++i){
        //     csvRow.push(A[i].join(","))
        //   }
        //
        //   var today = new Date();
        //   var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        //   var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //   var dateTime = date+'_'+time;
        //
        //   var csvString = csvRow.join("%0A");
        //
        //   var a = document.createElement("a");
        //
        //   a.href='data:attachement/csv,' + csvString;
        //
        //   a.target ="_Blank";
        //
        //   a.download= "Refueller List" + firstNameForFileName + "_to_" + lastNameForFileName+ "at"+ dateTime + ".csv";
        //
        //   document.body.appendChild(a);
        //
        //   a.click();
        //
        // };

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        All Trackers
                    </Typography>
                </div>
                <div className={classes.row}>
                    <span className={classes.spacer}/>
                </div>
                <div className={classes.row}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search Trackers"
                        getsearchterm={this.onSearch}
                    />
                    <Grid item xs={12} md={8} align="right">
                        <div>
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={this.handleDrawerOpen}
                                id={"add_partner_btn"}
                                style={{marginRight: "10px", lineHeight: "2"}}
                                //disabled={secureLocalStorage.access_type=== false ? true  : false}
                                disabled={secureLocalStorage.getItem("access_type") === false}
                            >
                                {" "}
                                Add Tracker{" "}
                            </Button>
                        </div>
                    </Grid>
                </div>
                <SwipeableDrawer
                    anchor={"right"}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerWidth
                    }}
                    open={this.state.drawerIsOpen}
                    onClose={this.handleDrawerClose}
                    onOpen={this.handleDrawerOpen}
                    ModalProps={{
                        BackdropProps: {
                            classes: {
                                root: classes.BackdropProps
                            }
                        }
                    }}
                >
                    <div className={classes.drawerHeading} style={{marginLeft: "10px"}}>
                        <Typography
                            variant="h2"
                            style={{marginTop: "5%", color: "#808080"}}
                        >
                            Add Tracker
                        </Typography>
                    </div>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <CloseButton/>
                        </IconButton>
                    </div>
                    {/* <CreatePumpSingleSignOn pumpCreationSuccessCallback={this.redirectOnSuccess}/> */}
                    <AddTrackerForm handleDrawerClose={this.handleDrawerClose}/>
                </SwipeableDrawer>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
