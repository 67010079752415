import React, {Component} from "react";
import {Breakpoint} from "react-socks";

// Material helpers
import {Divider, withStyles} from "@material-ui/core";

// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Component styles
import styles from "./style";
import {UsersTable, UsersToolbar} from "./components/index";
import {getServiceRequest, sendLogs, versionControl} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {withSnackbar} from "notistack";
import axios from "axios";
import Cards from "./components/Cards/Cards";
import qs from "qs";
import reposWebApi from "../../actions/configuration";
import secureLocalStorage from "react-secure-storage";

class AllServiceRequest extends Component {
    state = {
        searchString: "",
        rowsPerPage: 10,
        page: 0,
        open: true,
        fromDateFilter: null,
        toDate: null,
        EngineerData: []
    };

    constructor(props) {
        super(props);
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getServiceRequestData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getServiceRequestData("");
        }
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page});
        this.getServiceRequestData(this.state.searchString, limit, offset);
    };

    // resetPassword = (selectedCustomer) => {
    //     this.props.handleResetPassword( secureLocalStorage.getItem("accessToken"), selectedCustomer, this.props);
    // }

    getServiceRequestData = (
        searchTerm = this.state.searchString,
        limit = this.state.rowsPerPage,
        offset = 0,
        fromDate = this.state.fromDateFilter,
        toDate = this.state.toDate,
        abortController = this.abortController
    ) => {
        this.props.handleGetServiceRequest(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            fromDate,
            toDate,
            abortController
        );
    };

    onSelectFromDate = fromDate => {
        this.setState({
            fromDateFilter: fromDate
        });

        if (fromDate === "") {
            this.getServiceRequestData("", 10, 0, (fromDate = null));
        } else {
            this.getServiceRequestData("", 10, 0, fromDate);
        }
    };

    onSelectToDate = toDate => {
        this.setState({
            toDate: toDate
        });

        if (toDate === "") {
            this.getServiceRequestData(
                "",
                10,
                0,
                this.state.fromDateFilter,
                (toDate = null)
            );
        } else {
            this.getServiceRequestData("", 10, 0, this.state.fromDateFilter, toDate);
        }
    };

    getEngineerData = searchTerm => {
        let queryParams = {};
        queryParams.search = searchTerm;
        reposWebApi
            .get("/executives/get_service_eng/all", {
                cancelToken: this.abortController.token,
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
                },
                params: queryParams
            })
            .then(Engineers => {
                if (Engineers.status === 200) {
                    this.setState({
                        EngineerData: Engineers.data,
                        isLoading: false
                    });
                }
            });
    };

    handleChangeEdit = (e, value, type) => {
        this.setState({
            requestStatus: e.target.value
        });
        var queryParams;

        const config = {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("accessToken")
            }
        };
        if (type === "status") {
            queryParams = {
                id: value.id,
                status: e.target.value,
                assigned_service_eng: value.assigned_service_eng
            };
        } else {
            queryParams = {
                id: value.id,
                status: value.status,
                assigned_service_eng: e.target.outerText
            };
        }
        reposWebApi
            .post("/executives/edit_complain", qs.stringify(queryParams), config)
            .then(Engineers => {
                if (Engineers.status === 200) {
                    this.props.enqueueSnackbar(
                        type === "status"
                            ? "Status Changed Successfully"
                            : "Engineer Assigned successfully!",
                        {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            }
                        }
                    );
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.getItem("mobile"),
                        "/executives/edit_complain",
                        qs.stringify(queryParams)
                    );
                    this.getServiceRequestData();
                }
            });
    };

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getEngineerData();
        this.getServiceRequestData();
        const handler = e => this.setState({mobileView: e.matches});
        window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <div>
                    <DashboardLayout title="Service Requests">
                        <div className={classes.root}>
                            <UsersToolbar
                                allCustomerData={this.props.allServiceData}
                                searchedTerm={this.onSearchTerm}
                                selectFromDate={this.onSelectFromDate}
                                selectToDate={this.onSelectToDate}
                            />
                            <Breakpoint medium up>
                                <div className={classes.content}>
                                    <UsersTable
                                        getServiceRequestData={this.getServiceRequestData}
                                        EngineerData={this.state.EngineerData}
                                        allCustomerData={this.props.allServiceData}
                                        dataCount={this.props.dataCount}
                                        page={this.state.page}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onPagination={this.onPagination}
                                        noDataFound={this.props.noDataFound}
                                        isLoading={this.props.isLoading}
                                        resetPassword={this.resetPassword}
                                        mobileView={this.state.mobileView}
                                        handleChangeEdit={this.handleChangeEdit}
                                    />
                                </div>
                            </Breakpoint>
                        </div>
                        <Breakpoint small down>
                            <Divider
                                style={{
                                    marginBottom: "4%",
                                    marginTop: "10%",
                                    color: "#aeafb0"
                                }}
                            />
                            <Cards
                                getServiceRequestData={this.getServiceRequestData}
                                EngineerData={this.state.EngineerData}
                                allCustomerData={this.props.allServiceData}
                                resetPassword={this.resetPassword}
                                dataCount={this.props.dataCount}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                onPagination={this.onPagination}
                                noDataFound={this.props.noDataFound}
                                isLoading={this.props.isLoading}
                                mobileView={this.state.mobileView}
                                handleChangeEdit={this.handleChangeEdit}
                            />
                        </Breakpoint>
                    </DashboardLayout>
                </div>
            );
        return true;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allServiceData: state.allServiceData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        sessionExpired: state.sessionExpired,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetServiceRequest: (
            accessToken,
            searchString,
            limit,
            offset,
            fromDate,
            toDate,
            abortController
        ) => {
            dispatch(
                getServiceRequest(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    fromDate,
                    toDate,
                    abortController
                )
            );
        },
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        },
        version: () => {
            dispatch(versionControl());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllServiceRequest)));
