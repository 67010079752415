import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {Card, Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    overline: {
        marginTop: theme.spacing(1)
    }
}));

const OpertorStatisticsInsights = props => {
    const {className, ...rest} = props;

    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <Grid alignItems="center" container justify="space-between">
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                    <Typography variant="h2">
                        {props.cutomerData.order_data.avg_total_quantity} Litres
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Average Quantity
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                    <Typography variant="h2">
                        {props.cutomerData.order_data.min_order_quantity} Litres
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Minimum Ordered Quantity
                    </Typography>
                </Grid>
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                    <Typography variant="h2">
                        {props.cutomerData.order_data.max_order_quantity} Litres
                        {/*<Label*/}
                        {/*    className={classes.label}*/}
                        {/*    color={colors.green[600]}*/}
                        {/*>*/}
                        {/*  Live*/}
                        {/*</Label>*/}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        Max Quantity Ordered
                    </Typography>
                </Grid>
                {/*<Grid*/}
                {/*  className={classes.item}*/}
                {/*  item*/}
                {/*  md={4}*/}
                {/*  sm={6}*/}
                {/*  xs={12}*/}
                {/*>*/}
                {/*  <div className={classes.titleWrapper}>*/}
                {/*    <Typography*/}
                {/*      component="span"*/}
                {/*      variant="h2"*/}
                {/*    >*/}
                {/*      200*/}
                {/*    </Typography>*/}
                {/*    <Label*/}
                {/*      className={classes.label}*/}
                {/*      color={colors.green[600]}*/}
                {/*    >*/}
                {/*      Live*/}
                {/*    </Label>*/}
                {/*  </div>*/}
                {/*  <Typography*/}
                {/*    className={classes.overline}*/}
                {/*    variant="overline"*/}
                {/*  >*/}
                {/*    Watching now*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}
            </Grid>
        </Card>
    );
};

OpertorStatisticsInsights.propTypes = {
    className: PropTypes.string
};

export default OpertorStatisticsInsights;
