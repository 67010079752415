import React, {Component} from "react";
import {Breakpoint} from "react-socks";

// Material helpers
import {Divider, withStyles} from "@material-ui/core";

// Shared layouts
import {Dashboard as DashboardLayout} from "./../../layouts";

// Component styles
import styles from "./style";
import {UsersTable, UsersToolbar} from "./components/index";
import {
    AddFleetCart,
    AddMCapital,
    getAllCustomer,
    getCustomerAnalytics,
    getSubAssetAnalytics,
    resetCustomerPassword,
    versionControl
} from "../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import {withSnackbar} from "notistack";
import axios from "axios";
import Cards from "./components/Cards/Cards";
import secureLocalStorage from "react-secure-storage";

class AllCustomers extends Component {
    state = {
        searchString: "",
        rowsPerPage: 10,
        page: 0,
        open: true,
        fromDateFilter: null,
        toDate: null,
        fromDateCustomer: null,
        toDateCustomer: null,
        customerId: ""
    };

    constructor(props) {
        super(props);
        this.abortController = "";
    }

    onSearchTerm = searchTerm => {
        if (searchTerm.length >= 3) {
            if (this.abortController) {
                this.abortController.cancel("cancel request");
            }
            this.abortController = axios.CancelToken.source();
            this.setState({
                searchString: searchTerm,
                rowsPerPage: 10,
                page: 0
            });
            this.getCustomersData(searchTerm);
        } else {
            this.setState({
                searchString: "",
                rowsPerPage: 10,
                page: 0
            });
            this.getCustomersData("");
        }
    };

    onPagination = (limit, page) => {
        let offset = limit * page;

        //todo: if user is pagination with search?

        this.setState({rowsPerPage: limit, page});
        this.getCustomersData(this.state.searchString, limit, offset);
    };

    resetPassword = selectedCustomer => {
        this.props.handleResetPassword(
            secureLocalStorage.getItem("accessToken"),
            selectedCustomer,
            this.props
        );
    };

    getCustomersData = (
        searchTerm,
        limit = this.state.rowsPerPage,
        offset = this.state.offset,
        fromDate = this.state.fromDateFilter,
        toDate = this.state.toDate,
        abortController = this.abortController
    ) => {
        this.props.handleGetAllCustomer(
            secureLocalStorage.getItem("accessToken"),
            searchTerm,
            limit,
            offset,
            fromDate,
            toDate,
            abortController
        );
    };

    addFleetCard = (
        accessToken,
        amount,
        date_of_transaction,
        transition_reference_id,
        customer_id
    ) => {
        this.props.handleAddFleetCard(
            secureLocalStorage.getItem("accessToken"),
            amount,
            date_of_transaction,
            transition_reference_id,
            customer_id
        );
    };

    getCustomerAnalytics = (accessToken, customerId, fromDate, toDate) => {
        this.props.handelCustomerAnalytics(
            secureLocalStorage.getItem("accessToken"),
            customerId,
            fromDate,
            toDate
        );
    };

    getSubAssetAnalytics = (accessToken, customerId, fromDate, toDate) => {
        this.props.handelSubAssetAnalytics(
            secureLocalStorage.getItem("accessToken"),
            customerId,
            fromDate,
            toDate
        );
    };

    addMMCapital = (accessToken, enable, customerId, enqueueSnackbar) => {
        this.props.handelMCapital(
            secureLocalStorage.getItem("accessToken"),
            enable,
            customerId,
            enqueueSnackbar
        );
    };

    onSelectFromDate = fromDate => {
        this.setState({
            fromDateFilter: fromDate
        });

        if (fromDate === "") {
            this.getCustomersData("", 10, 0, (fromDate = null));
        } else {
            this.getCustomersData("", 10, 0, fromDate);
        }
    };

    onSelectToDate = toDate => {
        this.setState({
            toDate: toDate
        });

        if (toDate === "") {
            this.getCustomersData(
                "",
                10,
                0,
                this.state.fromDateFilter,
                (toDate = null)
            );
        } else {
            this.getCustomersData("", 10, 0, this.state.fromDateFilter, toDate);
        }
    };

    versionControl = () => {
        this.props.version();
    };

    componentDidMount() {
        this.versionControl();
        this.getCustomersData();
        const handler = e => this.setState({mobileView: e.matches});
        window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
    }

    render() {
        const {classes} = this.props;

        if (!secureLocalStorage.getItem("accessToken")) {
            this.props.history.push("/sign-in");
        } else
            return (
                <div>
                    <DashboardLayout title="Customers">
                        <div className={classes.root}>
                            <UsersToolbar
                                allCustomerData={this.props.allCustomerData}
                                searchedTerm={this.onSearchTerm}
                                selectFromDate={this.onSelectFromDate}
                                selectToDate={this.onSelectToDate}
                            />
                            <Breakpoint medium up>
                                <div className={classes.content}>
                                    <UsersTable
                                        allCustomerData={this.props.allCustomerData}
                                        dataCount={this.props.dataCount}
                                        page={this.state.page}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onPagination={this.onPagination}
                                        noDataFound={this.props.noDataFound}
                                        isLoading={this.props.isLoading}
                                        resetPassword={this.resetPassword}
                                        mobileView={this.state.mobileView}
                                        addFleetCard={this.addFleetCard}
                                        customerAnalytics={this.getCustomerAnalytics}
                                        subAssetAnalytics={this.getSubAssetAnalytics}
                                        mCapital={this.addMMCapital}
                                    />
                                </div>
                            </Breakpoint>
                        </div>
                        <Breakpoint small down>
                            <Divider
                                style={{
                                    marginBottom: "4%",
                                    marginTop: "10%",
                                    color: "#aeafb0"
                                }}
                            />
                            <Cards
                                addFleetCard={this.addFleetCard}
                                customerAnalytics={this.getCustomerAnalytics}
                                subAssetAnalytics={this.getSubAssetAnalytics}
                                mCapital={this.addMMCapital}
                                allCustomerData={this.props.allCustomerData}
                                resetPassword={this.resetPassword}
                                dataCount={this.props.dataCount}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                onPagination={this.onPagination}
                                noDataFound={this.props.noDataFound}
                                isLoading={this.props.isLoading}
                                mobileView={this.state.mobileView}
                            />
                        </Breakpoint>
                    </DashboardLayout>
                </div>
            );
        return true;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        allCustomerData: state.allCustomerData,
        dataCount: state.dataCount,
        noDataFound: state.noDataFound,
        sessionExpired: state.sessionExpired,
        isLoading: state.isLoading,
        customer: state.customer,
        subAsset: state.subAsset
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleGetAllCustomer: (
            accessToken,
            searchString,
            limit,
            offset,
            fromDate,
            toDate,
            abortController
        ) => {
            dispatch(
                getAllCustomer(
                    accessToken,
                    searchString,
                    limit,
                    offset,
                    fromDate,
                    toDate,
                    abortController
                )
            );
        },
        handleResetPassword: (accessToken, customerId, snackBar) => {
            dispatch(resetCustomerPassword(accessToken, customerId, snackBar));
        },
        handleAddFleetCard: (
            accessToken,
            amount,
            date_of_transaction,
            transition_reference_id,
            customer_id
        ) => {
            dispatch(
                AddFleetCart(
                    accessToken,
                    amount,
                    date_of_transaction,
                    transition_reference_id,
                    customer_id
                )
            );
        },
        version: () => {
            dispatch(versionControl());
        },
        handelCustomerAnalytics: (accessToken, customerId, fromDate, toDate) => {
            dispatch(getCustomerAnalytics(accessToken, customerId, fromDate, toDate));
        },
        handelSubAssetAnalytics: (accessToken, customerId, fromDate, toDate) => {
            dispatch(getSubAssetAnalytics(accessToken, customerId, fromDate, toDate));
        },
        handelMCapital: (accessToken, enable, customer_id, enqueueSnackbar) => {
            dispatch(AddMCapital(accessToken, enable, customer_id, enqueueSnackbar));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(AllCustomers)));
