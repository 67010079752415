import React, {Component} from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Clear} from "@mui/icons-material";

// Material helpers
// Material components
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";

import NoDatafound from "../NoDataInTable";

// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {MoreHorizOutlined as MoreIcon} from "@material-ui/icons";
import {withSnackbar} from "notistack";
import compose from "recompose/compose";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FleetCardData: [],
            right: false,
            requestStatus: 0
        };
    }

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    handleDrawerOpen = (e, side, open, payment) => {
        e.preventDefault();

        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        this.setState({
            ...this.state,
            right: open,
            FleetCardData: payment
        });
    };
    handleDrawerClose = () => {
        this.setState({right: false});
    };

    handleChange = (e, value, type) => {
        this.setState({
            requestStatus: e.target.value
        });
    };

    onSearchTerm = searchTerm => {
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);
        const nf = new Intl.NumberFormat("en-IN");

        if (this.props.noDataFound === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="left">Payer Name</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                <TableCell align="left">Balance Amount</TableCell>
                                                <TableCell align="left">Amount</TableCell>
                                                <TableCell align="left">
                                                    Transaction Reference ID
                                                </TableCell>
                                                <TableCell align="left">More</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this?.props?.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"8"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {this?.props?.FleetCardData?.map(payment => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={payment?.id}
                                                    >
                                                        <TableCell
                                                            align="left"
                                                            className={classes.tableCell}
                                                        >
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {payment?.payer_name}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {payment?.status === "CNF" ? "Confirmation" : ""}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            ₹ {nf.format(payment?.settled_amount)}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            ₹ {nf.format(payment?.amount)}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {payment?.transition_reference_id}
                                                        </TableCell>

                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align={"left"}
                                                        >
                                                            <Button
                                                                onClick={e =>
                                                                    this.handleDrawerOpen(
                                                                        e,
                                                                        "right",
                                                                        true,
                                                                        payment
                                                                    )
                                                                }
                                                                style={{float: "right"}}
                                                            >
                                                                <MoreIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>
                            {/*drawer for more details*/}

                            <Drawer
                                anchor="right"
                                open={this.state.right}
                                onClose={this.handleDrawerClose}
                            >
                                <div className={classes.root}>
                                    <Box padding={"3%"}>
                                        <Clear
                                            style={{color: "#737373"}}
                                            onClick={this.handleDrawerClose}
                                        />
                                    </Box>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>ID</TableCell>
                                                <TableCell align="left">
                                                    {this.state.FleetCardData?.id}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Created At
                                                </TableCell>
                                                <TableCell>
                                                    {this.state.FleetCardData?.created_at
                                                        ? this.state.FleetCardData?.created_at
                                                        : "No data"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Payer Payment Date
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.FleetCardData?.payer_payment_date
                                                        ? this.state.FleetCardData?.payer_payment_date
                                                        : "No data"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Sender Remark
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.FleetCardData?.sender_remark
                                                        ? this.state.FleetCardData?.sender_remark
                                                        : "No data"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Updated At
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.FleetCardData?.updated_at
                                                        ? this.state.FleetCardData?.updated_at
                                                        : "No data"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    Virtual Account Number
                                                </TableCell>
                                                <TableCell align="left">
                                                    {this.state.FleetCardData?.virtual_account_number
                                                        ? this.state.FleetCardData?.virtual_account_number
                                                        : "No data"}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Drawer>

                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                />
                            )}
                        </PortletContent>
                    </Portlet>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    }
};

export default withSnackbar(compose(withStyles(styles))(UsersTable));
