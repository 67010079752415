import {Box, Button, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import styles from "../../../AllPumps/components/CreatePumpSingleSignOn/styles.jsx";
import {makeStyles, withStyles} from "@material-ui/styles";
import {compose} from "underscore";
import clsx from "clsx";
import {getAllLeads, NegotiationInfo} from "actions/actions.js";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {margin: "24px"},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    fieldPassed: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    submitError: {
        color: theme.palette.danger.main,
        alignText: "center",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const DatumNegotiationForm = props => {
    const {stateData, listLeads, handleDrawerClose, param, className, ...rest} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [saveButton, setSaveButton] = useState(true);
    const [totalButton, setTotalButton] = useState(false);

    const [formData, setFormData] = useState({
        id: listLeads?.id,
        amount: stateData?.datumPrice?.amount ? stateData?.datumPrice?.amount : 0,
        adjustment: 0,
        discount: listLeads?.discount === null ? 0 : listLeads?.discount,
        total_amount: listLeads?.total_amount,
        quantity: listLeads?.quantity,
        tcs_amount: stateData?.datumPrice?.tcs_amount
            ? stateData?.datumPrice?.tcs_amount
            : 0,
        tax_amount: stateData?.datumPrice?.tax_amount
            ? stateData?.datumPrice?.tax_amount
            : 0
    });

    const handleInputChange = event => {
        setSaveButton(true);
        setTotalButton(false);
        const {id, value} = event.target;
        const newDiscount = Math.min(50000, Math.max(0, parseInt(value, 10)));

        /******************** Condition for Datus *************************/

        if (listLeads?.product_display === "DATUS") {
            setFormData({
                ...formData,
                [id]: id === "discount" ? newDiscount : value
                // discount: stateData?.getdiscount?.discount * (formData?.quantity? formData?.quantity : 1),
            });
        } else {
            /******************** Condition for Datum *************************/

            setFormData({
                ...formData,
                [id]: id === "discount" ? newDiscount : value,
                amount: stateData?.datumPrice?.amount
                // discount: stateData?.getdiscount?.discount * (formData?.quantity? formData?.quantity : 1),
            });
        }
    };

    const AmountTotal = () => {
        setSaveButton(false);
        setTotalButton(true);

        // Calculate the total amount
        const totalAmount =
            (formData?.amount - formData.discount) *
            (formData?.quantity ? formData?.quantity : 1);
        // const totalAmount = parseFloat(amount) + parseFloat(adjustment) - parseFloat(discount);
        const percentage = (totalAmount / 100) * 28;

        // Update the formData state with the calculated total amount
        setFormData({
            ...formData,
            id: listLeads?.id,
            total_amount: totalAmount + percentage,
            amount: formData?.amount * (formData?.quantity ? formData?.quantity : 1),
            adjustment: 0,
            tax_amount: percentage
            // discount: stateData.getdiscount.discount * (formData?.quantity? formData?.quantity : 1),// Convert to string if necessary
        });
    };

    const getLeadList = () => {
        dispatch(getAllLeads(secureLocalStorage.getItem("accessToken"), param, enqueueSnackbar));
    };

    const handleSubmit = () => {
        dispatch(
            NegotiationInfo(
                secureLocalStorage.getItem("accessToken"),
                formData,
                enqueueSnackbar,
                handleDrawerClose,
                getLeadList
            )
        );
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography>Quantity</Typography>
            <TextField
                className={classes.textField}
                // label={"Amount"}
                margin="dense"
                type={"number"}
                fullWidth
                value={formData?.quantity}
                onChange={handleInputChange}
                // required
                id={"quantity"}
                // SelectProps={{ native: true }}
                variant="outlined"
            />
            <Typography>Amount</Typography>
            <TextField
                className={classes.textField}
                // label={"Amount"}
                margin="dense"
                disabled={listLeads?.product_display !== "DATUS"}
                fullWidth
                value={formData.amount}
                onChange={handleInputChange}
                // required
                id={"amount"}
                // SelectProps={{ native: true }}
                variant="outlined"
            />
            <Typography>Adjustment</Typography>
            <TextField
                className={classes.textField}
                // label="Adjustment"
                margin="dense"
                type="number"
                disabled={listLeads?.product_display !== "DATUS"}
                value={formData.adjustment}
                id={"adjustment"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            {/************************ No discount for datum X *************************/}
            {(listLeads?.datum_type !== "X" ||
                listLeads?.product_display === "DATUS") && (
                <>
                    <Typography>Discount</Typography>
                    <TextField
                        className={classes.textField}
                        label="Discount"
                        margin="dense"
                        type="number"
                        value={formData.discount}
                        id={"discount"}
                        fullWidth
                        onChange={handleInputChange}
                        variant="outlined"
                    />
                </>
            )}
            <Typography>Tax Amount</Typography>
            <TextField
                className={classes.textField}
                // label={"Discount"}
                margin="dense"
                fullWidth
                value={formData.tax_amount}
                onChange={handleInputChange}
                disabled={listLeads?.product_display !== "DATUS"}
                // required
                id={"tax_amount"}
                Props={{native: true}}
                variant="outlined"
            />
            <Typography>TCS Amount</Typography>
            <TextField
                className={classes.textField}
                // label={"Discount"}
                margin="dense"
                fullWidth
                value={stateData.datumPrice.tcs_amount}
                onChange={handleInputChange}
                disabled={listLeads?.product_display !== "DATUS"}
                // required
                id={"tcs_amount"}
                Props={{native: true}}
                variant="outlined"
            />
            <Typography>Total Amount</Typography>
            <TextField
                className={classes.textField}
                // label="Total Amount"
                margin="dense"
                type="text"
                disabled={listLeads?.product_display !== "DATUS"}
                value={formData.total_amount}
                id={"total_amount"}
                fullWidth
                onChange={handleInputChange}
                variant="outlined"
            />

            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                disabled={totalButton}
                onClick={AmountTotal}
            >
                Calculate Total Amount
            </Button>
            <Tooltip title={formData?.quantity < 1 && "Please select quantity"}>
                <Box>
                    <Button
                        disabled={
                            formData?.total_amount === "" ||
                            formData?.quantity < 1 ||
                            saveButton
                        }
                        className={classes.addNewPartnerButton}
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Box>
            </Tooltip>
            <Button
                className={classes.addNewPartnerButton}
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleDrawerClose}
            >
                Cancel
            </Button>
        </div>
    );
};
export default compose(withStyles(styles))(DatumNegotiationForm);
