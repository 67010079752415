import React, {Component} from "react";
// import { Link } from 'react-router-dom';
// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";

// Material helpers
// Material components
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// Shared helpers
// import { getInitials } from 'helpers';
// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import {EditOutlined as EditIcon} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import EditDriver from "../EditDriver/EditDriver";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import secureLocalStorage from "react-secure-storage";

class UsersTable extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        open: false,
        imageUrl: "",
        users: [],
        right: false
    };

    // handleSelectAll = event => {
    //   const { users, onSelect } = this.props;
    //
    //   let selectedUsers;
    //
    //   if (event.target.checked) {
    //     selectedUsers = users.map(user => user.id);
    //   } else {
    //     selectedUsers = [];
    //   }
    //
    //   this.setState({ selectedUsers });
    //
    //   onSelect(selectedUsers);
    // };

    // handleSelectOne = (event, id) => {
    //   const { onSelect } = this.props;
    //   const { selectedUsers } = this.state;
    //
    //   const selectedIndex = selectedUsers.indexOf(id);
    //   let newSelectedUsers = [];
    //
    //   if (selectedIndex === -1) {
    //     newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    //   } else if (selectedIndex === 0) {
    //     newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    //   } else if (selectedIndex === selectedUsers.length - 1) {
    //     newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    //   } else if (selectedIndex > 0) {
    //     newSelectedUsers = newSelectedUsers.concat(
    //         selectedUsers.slice(0, selectedIndex),
    //         selectedUsers.slice(selectedIndex + 1)
    //     );
    //   }
    //
    //   this.setState({ selectedUsers: newSelectedUsers });
    //   onSelect(newSelectedUsers);
    // };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    resetPasswordBtnSubmit = (event, driverId) => {
        event.preventDefault();
        this.props.resetPassword(driverId);
    };

    reloadRequest = () => {
        this.setState({
            right: false
        });
        this.props.reloadRequest();
    };

    toggleDrawer = (anchor, open, driver) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        this.setState({
            [anchor]: open,
            singleDriver: driver
        });
    };

    openDialog(imageUrl) {
        this.setState({
            open: true,
            imageUrl: imageUrl
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    render() {
        const {classes, className, allDriverData} = this.props;

        const rootClassName = classNames(classes.root, className);

        if (this.props.dataCount === 0) {
            return (
                <div>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else {
            if (this.props.isLoading) {
                return (
                    <div>
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
            } else
                return (
                    <Portlet className={rootClassName}>
                        <PortletContent noPadding>
                            <PerfectScrollbar>
                                <TableContainer style={{maxHeight: 640}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="left">Edit</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Mobile Number</TableCell>
                                                <TableCell align="left">Vehicle Number </TableCell>
                                                <TableCell align="left">Partner</TableCell>
                                                <TableCell align="left">Reset Password</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.noDataFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"8"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {allDriverData?.map(driver => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={driver.id}
                                                    >
                                                        <TableCell className={classes.tableCell}>
                                                            <div className={classes.tableCellInner}>
                                                                <Button
                                                                    onClick={this.toggleDrawer(
                                                                        "right",
                                                                        true,
                                                                        driver
                                                                    )}
                                                                    disabled={
                                                                        secureLocalStorage.getItem(
                                                                            "access_type"
                                                                        ) === false
                                                                    }
                                                                >
                                                                    <EditIcon driver={driver}/>
                                                                </Button>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="h6"
                                                                >
                                                                    {driver.driver_name === ""
                                                                        ? "Repos Operator"
                                                                        : driver.driver_name}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {driver.mobile === null
                                                                ? "Not available"
                                                                : driver.mobile}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {driver.vehicle_registration_number === null
                                                                ? "Not available"
                                                                : driver.vehicle_registration_number}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {driver.partner_name}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Tooltip
                                                                title="Reset Password"
                                                                aria-label="reset"
                                                            >
                                                                <IconButton
                                                                    onClick={event =>
                                                                        this.resetPasswordBtnSubmit(
                                                                            event,
                                                                            driver.id
                                                                        )
                                                                    }
                                                                    aria-label="delete"
                                                                    className={classes.margin}
                                                                    size="small"
                                                                    disabled={
                                                                        secureLocalStorage.getItem(
                                                                            "access_type"
                                                                        ) === false
                                                                    }
                                                                >
                                                                    <RotateLeftIcon
                                                                        color={"primary"}
                                                                        className={classes.extendedIcon}
                                                                    >
                                                                        Reset
                                                                    </RotateLeftIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </PerfectScrollbar>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleClose.bind(this)}
                            >
                                <DialogTitle>License Image</DialogTitle>
                                <DialogContent>
                                    <img src={this.state.imageUrl} alt=""/>
                                </DialogContent>
                            </Dialog>

                            {this.props.noDataFound ? (
                                ""
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50]}
                                />
                            )}
                        </PortletContent>
                        <Drawer
                            anchor={"right"}
                            open={this.state.right}
                            onClose={this.toggleDrawer("right", false)}
                        >
                            <List className={classes.list}>
                                <div>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <img
                                                alt="add_driver_logo"
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    margin: "10px",
                                                    borderRadius: "50px"
                                                }}
                                                src={"/images/transport.png"}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography
                                                className={classes.nameText}
                                                variant="h3"
                                                style={{
                                                    marginTop: "10%",
                                                    marginLeft: "5px",
                                                    color: "#808080"
                                                }}
                                            >
                                                Modify Operator
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <EditDriver
                                        driver={this.state.singleDriver}
                                        modifyOperatorSuccess={this.reloadRequest}
                                    />
                                </div>
                            </List>
                        </Drawer>
                    </Portlet>
                );
        }
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired
};

export default withStyles(styles)(UsersTable);
