export default theme => ({
    root: {},
    tableRow: {
        height: "64px"
    },
    tableCell: {
        whiteSpace: "nowrap"
    },
    tableCellInner: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: 500,
        height: "36px",
        width: "36px"
    },
    nameText: {
        display: "inline-block",
        fontWeight: 500,
        cursor: "pointer"
    },
    progress: {
        display: "block",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    emptyImage: {
        width: "240px",
        maxWidth: "100%",
        height: "auto"
    },
    drawerWidth: {
        zIndex: 1200,
        width: "340px",
        padding: "24px"
    },
    textField: {
        // width: '420px',
        maxWidth: "500px",
        marginRight: theme.spacing(3)
    },
    addNewPartnerButton: {
        marginTop: theme.spacing(2),
        width: "100%"
        // margin: "10px",
    }
});
