export default {
    fullName: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    mobile: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 10,
            minimum: 10
        }
    },
    email: {
        presence: {allowEmpty: false, message: "is required"},
        email: true,
        length: {
            maximum: 128
        }
    },
    password: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    user_name: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    licenseNumber: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    },
    insurance: {
        presence: {allowEmpty: false, message: "is required"},
        length: {
            maximum: 128
        }
    }
};
