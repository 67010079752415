import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
// Material components
import {Grid, withStyles} from "@material-ui/core";

// import { Redirect } from 'react-router-dom';
import Button from "@material-ui/core/Button";

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        // marginTop: '150px',
        textAlign: "center"
    },
    image: {
        display: "inline-block",
        maxWidth: "100%",
        width: "554px"
    },
    linkBtn: {
        display: "block",
        margin: "3% auto"
    }
});

class InternalServerError extends Component {
    state = {
        redirect: false
    };

    returnToDash = () => {
        this.setState({
            redirect: true
        });
    };

    render() {
        const {classes} = this.props;
        // redirecting to dashboard if not logged in
        if (this.state.redirect) {
            this.props.history.push("/dashboard");
            window.location.reload();
        } else
            return (
                <div
                    className={classes.root}
                    style={{background: "#fff", height: "100vh"}}
                >
                    <Grid container justify="center" spacing={4}>
                        <Grid item lg={6} xs={12}>
                            <div className={classes.content}>
                                <img
                                    alt="Under development"
                                    className={classes.image}
                                    src={require("./1408-network.gif")}
                                    style={{background: "#fff0"}}
                                />
                                <Button
                                    variant="contained"
                                    className={classes.linkBtn}
                                    color={"primary"}
                                    onClick={this.returnToDash}
                                >
                                    Go to Dashboard
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
    }
}

InternalServerError.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InternalServerError);
